var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-system-bar', {
    staticClass: "height-auto mb-2",
    attrs: {
      "dense": "",
      "color": "transparent",
      "dark": _vm.active
    }
  }, [_c('div', {
    staticClass: "filter_bar"
  }, [_vm.showFilterButton && _vm.active ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.filter,
      "icon-color": _vm.clearFilterColor,
      "icon-size": "18px",
      "color": "white",
      "tooltip": "Hide filters"
    },
    on: {
      "click": _vm.filterButtonClicked
    }
  }) : _vm._e(), _vm.showFilterButton && !_vm.active ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.filter,
      "icon-color": "clearFilterColor",
      "icon-size": "18px",
      "tooltip": "Show filters"
    },
    on: {
      "click": _vm.filterButtonClicked
    }
  }) : _vm._e(), _c('div', [!_vm.shouldShowAppChip ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.filterMessage,
      expression: "filterMessage"
    }],
    staticClass: "filter_message font-weight-regular",
    staticStyle: {
      "color": "#757575"
    }
  }) : _c('AppChip', {
    staticClass: "chips_wrap",
    attrs: {
      "chips": _vm.chipsFromFilters
    },
    on: {
      "closeClicked": _vm.appChipCloseClicked
    }
  }), _vm.chipsFromFiltersLength ? _c('ButtonGeneric', {
    attrs: {
      "text-color": _vm.clearFilterColor,
      "flat": "",
      "small": ""
    },
    on: {
      "click": _vm.emitClearAllFilters
    }
  }, [_vm._v("Clear filters")]) : _vm._e()], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };