import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { HardwareListType } from '~/db_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { EntityType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
const indexOfSequence = (thingWithSequence, things) => {
    return things.findIndex((_) => _.sequence === thingWithSequence.sequence);
};
let MiniHardwareList = class MiniHardwareList extends Mixins(BaseMini, CurrentUserMixin) {
    entityType = EntityType.HARDWARE_LIST;
    localEntity = null;
    localShowChildren = false;
    sequenceToInstall = null;
    showModalHardwareItemCreate = false;
    showModalHardwareItemEdit = false;
    showModalHardwareListEdit = false;
    selectedHardwareItem = null;
    BASE_FETCH = gql `
    fragment HardwareListTemplateSlotForMini on HardwareListTemplateSlot {
      id
      maxQuantity
      minQuantity
      name
      nodeId
      sequence

      parts: hardwareListTemplateParts {
        nodes {
          drawingNumber
          id
          nodeId

          itemDrawing {
            description
            nodeId
          }
        }
      }
    }
    query HardwareListByIdForMini($id: UUID!) {
      hardwareListById(id: $id) {
        addendumDate
        addendumNumber
        authorizer
        id
        name
        nodeId
        number
        revision
        subType

        comments {
          nodes {
            comment
            id
            nodeId
          }
        }

        document {
          id
          nodeId
          subType
        }

        hardwareListAssemblies {
          nodes {
            addendumDate
            addendumNumber
            externalAsBuiltNumber
            externalBirthDate
            externalCalibrationDate
            externalCalibrationNumber
            externalClass
            externalContractEndingItemNumber
            externalDescription
            externalDrawingNumber
            externalIsTool
            externalLotNumber
            externalSerialNumber
            externalShelfLifeDate
            externalUsageLifeDate
            id
            isExternal
            nodeId
            notes
            quantity
            sequence

            inventory {
              id
              nodeId
            }
          }
        }

        hardwareListTemplate {
          id
          nodeId

          hardwareListTemplateSlots {
            totalCount
            nodes {
              ...HardwareListTemplateSlotForMini
            }
          }
        }
      }
    }
  `;
    allowChildCreation;
    allowEdit;
    forDocumentId;
    hideItemType;
    showChildren;
    transformedHardwareItems;
    get chevronIcon() {
        return this.shouldShowChildContainer ? this.$icons.chevron_up : this.$icons.chevron_down;
    }
    get children() {
        const hlTemplateNodes = this.localEntity?.hardwareListTemplate?.hardwareListTemplateSlots.nodes ?? [];
        // create an array of hardware list items/children that have an inventory or an event assembly
        const hlItems = (this.localEntity?.hardwareListAssemblies.nodes
            .map((assy) => {
            if (!assy?.inventory?.id && !assy?.isExternal) {
                return null;
            }
            // if this item also has a corresponding hardware list template, get that id
            const templateIndex = indexOfSequence(assy, hlTemplateNodes);
            let maybeTemplateId = null;
            if (templateIndex > -1) {
                maybeTemplateId = hlTemplateNodes[templateIndex]?.id ?? null;
            }
            return {
                hardwareListInventoryId: assy.inventory?.id ?? null,
                hardwareListTemplateSlotId: maybeTemplateId,
                hardwareListAssemblyUuid: assy.isExternal ? assy.id : null,
                sequence: assy.sequence
            };
        })
            .filter((_) => _) ?? []); // filter out null items
        // create an array of only empty hardware list templates/slots
        const hlTemplates = (this.localEntity?.hardwareListTemplate?.hardwareListTemplateSlots.nodes
            .map((template) => {
            if (!template) {
                return null;
            }
            return {
                eventAssemblyTemplateId: null,
                hardwareListInventoryId: null,
                hardwareListTemplateSlotId: template.id,
                hardwareListAssemblyUuid: null,
                sequence: template.sequence
            };
        })
            .filter((item) => {
            if (!item) {
                return false; // filter out null items
            }
            // filter out existing items, leaving only empty template slots
            return indexOfSequence(item, hlItems) === -1;
        }) ?? []);
        // smash all the child items together
        const children = [...hlItems, ...hlTemplates];
        // sort by sequence
        children.sort(sortObjectNumericallyBy('sequence'));
        return children;
    }
    get existingInventoryValues() {
        if (!this.localEntity) {
            return;
        }
        return this.localEntity.hardwareListAssemblies.nodes
            .map((node) => node?.inventory?.id)
            .filter((node) => Boolean(node));
    }
    get hardwareListTemplateSlots() {
        if (!this.localEntity?.hardwareListTemplate?.hardwareListTemplateSlots.nodes.length) {
            return [];
        }
        return this.localEntity.hardwareListTemplate.hardwareListTemplateSlots.nodes;
    }
    get hasAnyActions() {
        return this.showChildren || this.shouldShowManageLink || this.inlineClear;
    }
    get hasAssemblyTemplateAdminPermission() {
        return this.currentUserHasPermission(UserPermission.ASSEMBLY_TEMPLATE_ADMIN) && this.isAManageRoute;
    }
    get hasChildren() {
        return Boolean(this.children.length);
    }
    get hasHardwareListAdminPermission() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN);
    }
    get isAManageRoute() {
        return this.$route.fullPath.includes('manage');
    }
    get largestSequenceNumber() {
        return this.children.reduce((acc, child) => (acc = acc > child.sequence ? acc : child.sequence), 0);
    }
    get manageLink() {
        return this.localEntity ? `/hardware/lists/manage/${this.localEntity.id}` : '';
    }
    get nameDisplay() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get number() {
        if (!this.localEntity) {
            return '';
        }
        return this.localEntity?.number || this.localEntity?.number || DEFAULT_DASH;
    }
    get shouldShowChildContainer() {
        return this.showChildren && this.children.length > 0 && this.localShowChildren;
    }
    get shouldShowChildren() {
        return this.showChildren && this.hasChildren && this.localShowChildren;
    }
    get shouldShowCreateChildIcon() {
        return this.allowChildCreation;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        const items = [];
        items.push({
            name: 'Type',
            value: HardwareListTypeDisplay.get(this.localEntity.subType) || DEFAULT_DASH
        });
        if (this.localEntity.authorizer) {
            items.push({
                name: 'Authorizer',
                value: this.localEntity.authorizer
            });
        }
        if (this.localEntity.revision) {
            items.push({
                name: 'Revision',
                value: this.localEntity.revision || DEFAULT_DASH
            });
        }
        if (this.localEntity.addendumNumber) {
            items.push({
                name: 'Addendum #',
                value: this.localEntity.addendumNumber.toString()
            });
        }
        if (this.localEntity.addendumDate) {
            items.push({
                name: 'Addendum',
                value: this.$dateDisplay(this.localEntity.addendumDate)
            });
        }
        return items;
    }
    async executeQuery() {
        if (this.id) {
            this.isFetching = true;
            try {
                const { data } = await this.$apollo.query({
                    query: this.BASE_FETCH,
                    variables: {
                        id: this.id,
                        forDocument: Boolean(this.forDocumentId),
                        forDocumentId: this.forDocumentId
                    }
                });
                this.isFetching = false;
                this.onFetchUpdate(data);
            }
            catch {
                this.isFetching = false;
            }
        }
    }
    get documentType() {
        return this.localEntity && this.localEntity.document?.subType ? this.localEntity?.document?.subType : null;
    }
    get documentTypeDisplay() {
        return this.documentType ? DocumentTypeDisplay.get(this.documentType) : null;
    }
    get isCosmicDocumentHardwareList() {
        const cDocumentsHardwareListTypes = [
            HardwareListType.WORK_ORDER_STEP_HARDWARE,
            HardwareListType.WORK_ORDER_STEP_TOOLS,
            HardwareListType.COSMIC_DOCUMENT_HARDWARE,
            HardwareListType.COSMIC_DOCUMENT_TOOLS
        ];
        return this.localEntity?.subType ? cDocumentsHardwareListTypes.includes(this.localEntity.subType) : false;
    }
    handleEditHardwareListModalEvent() {
        if (this.localEntity && this.isCosmicDocumentHardwareList) {
            this.$notification.add({
                type: 'warning',
                text: 'This Hardware List can only be modified in cDocs.'
            });
        }
        else {
            this.showModalHardwareListEdit = true;
        }
    }
    handleEditHardwareItemModalEvent(child) {
        this.selectedHardwareItem = this.localEntity?.hardwareListAssemblies.nodes?.find((hardwareItem) => hardwareItem?.sequence === child.sequence);
        this.showModalHardwareItemEdit = !this.showModalHardwareItemEdit;
    }
    onFetchUpdate(data) {
        if (data.hardwareList || data.hardwareListById) {
            this.localEntity = data.hardwareList || data.hardwareListById;
        }
    }
    // Called from ui
    onClickOfShowChildren() {
        this.localShowChildren = !this.localShowChildren;
    }
    // called from ui
    onCloseOfModalHardwareItemCreate() {
        this.showModalHardwareItemCreate = false;
        this.sequenceToInstall = null;
    }
    // called from ui
    onCloseOfModalHardwareItemEdit() {
        this.showModalHardwareItemEdit = false;
        this.sequenceToInstall = null;
    }
    // called from ui
    onCloseOfModalHardwareListEdit() {
        this.showModalHardwareListEdit = false;
    }
    // called from ui
    async onHardwareItemsUpdated() {
        await this.executeQuery();
        this.showModalHardwareItemEdit = false;
        this.showModalHardwareItemCreate = false;
    }
    onAddToHardwareListAtSequence(sequence) {
        if (!sequence) {
            this.sequenceToInstall = null;
            return;
        }
        this.sequenceToInstall = sequence;
        this.showModalHardwareItemCreate = true;
    }
    onChangeOfShowChildren(val) {
        this.localShowChildren = val;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], MiniHardwareList.prototype, "forDocumentId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "hideItemType", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], MiniHardwareList.prototype, "showChildren", void 0);
__decorate([
    Prop({
        default: () => [],
        type: Array
    }),
    __metadata("design:type", Array)
], MiniHardwareList.prototype, "transformedHardwareItems", void 0);
__decorate([
    Emit('add-to-hardware-list-at-sequence'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], MiniHardwareList.prototype, "onAddToHardwareListAtSequence", null);
__decorate([
    Watch('showChildren', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], MiniHardwareList.prototype, "onChangeOfShowChildren", null);
MiniHardwareList = __decorate([
    Component
], MiniHardwareList);
export default MiniHardwareList;
