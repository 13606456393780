var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', [_c('AppText', {
    staticClass: "d-inline",
    attrs: {
      "autofocus": _vm.$props.autofocus,
      "color": _vm.computedColor,
      "disabled": _vm.$props.disabled,
      "error-message": _vm.computedErrorMessage,
      "label": _vm.$props.label,
      "message": _vm.computedMessage || 'Hit ENTER to add to list',
      "placeholder": _vm.$props.placeholder,
      "required": _vm.isRequired
    },
    nativeOn: {
      "focusout": function ($event) {
        return _vm.onFocusout.apply(null, arguments);
      },
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "backspace", undefined, $event.key, undefined)) return null;
        return _vm.removeLastItem();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addNewText(_vm.newText);
      }],
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.newText,
      callback: function ($$v) {
        _vm.newText = $$v;
      },
      expression: "newText"
    }
  }), false ? _c('div', {
    staticStyle: {
      "height": "50px"
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "color": _vm.computedColor,
      "disabled": _vm.isNewTextInvalid
    },
    on: {
      "click": function ($event) {
        return _vm.addNewText(_vm.newText);
      }
    }
  })], 1) : _vm._e()], 1), _c('div', {
    class: {
      'selectedChips mb-2': _vm.selectedItems.length
    }
  }, [_c('AppChip', {
    attrs: {
      "chips": _vm.selectedItemsAsAppChips,
      "disabled": _vm.disabled
    },
    on: {
      "closeClicked": _vm.removeItem
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };