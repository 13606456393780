var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { WorkingAssemblyRepoMixin } from '~/nasa_ui/DAL/workingAssemblies';
import { AlertType, AuthorityCode, AuthorityCodeDisplay, EntityType, HandDominanceDisplay, Maybe } from '~/nasa_ui/types';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { AssemblyTemplatePartTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS } from '../constants/tablePagination';
let BaseModalAssembly = class BaseModalAssembly extends Mixins(BaseModal, WorkingAssemblyRepoMixin) {
    AuthorityCode = AuthorityCode;
    AssemblyTemplatePartTableHeaders = AssemblyTemplatePartTableHeaders;
    AuthorityCodeDisplay = AuthorityCodeDisplay;
    HandDominanceDisplay = HandDominanceDisplay;
    parentData = null;
    selectedAssemblyParts = [];
    selectedEntity = null;
    Side = Side;
    duplicateQueryResponse = null;
    formData = {
        authorityCode: null,
        authorityNumber: null,
        comment: null,
        sequence: null,
        installedOn: null,
        quantity: null,
        overrideInstalledDate: null,
        overrideRemovalDate: null
    };
    assemblyTemplate;
    id;
    get assemblyPartTableHeaders() {
        return [
            {
                text: 'Hardware<br>Type',
                value: '_entityType',
                align: 'center',
                width: '50px',
                noEscape: true
            },
            {
                text: 'Drawing number',
                value: 'drawingNumber'
            },
            {
                text: 'Description',
                value: '_description'
            },
            {
                text: 'AsBuilt',
                value: '_asBuiltNumber',
                noEscape: true
            },
            {
                text: 'Side',
                value: '_side',
                noEscape: true
            }
        ];
    }
    get assemblyPartTableItems() {
        return this.selectedAssemblyParts.map((part) => {
            const entityType = part.asBuiltNumber ?? false ? EntityType.ITEM_MASTER : EntityType.ITEM_DRAWING;
            const _entityType = entityType; // need to use buildIcon
            const _description = part.description || DEFAULT_DASH;
            let _asBuiltNumber;
            let _side;
            if (!part.asBuiltNumber) {
                _asBuiltNumber = '<span class="grey--text">N/A</span>';
                _side = '<span class="grey--text">N/A</span>';
            }
            else {
                _asBuiltNumber = this.$asBuiltNumberDisplay(part.asBuiltNumber);
                _side = this.$sideDisplay(part.side);
            }
            return {
                _asBuiltNumber,
                _description,
                _entityType,
                _side,
                ...part
            };
        });
    }
    get isFormValid() {
        if (this.formData.sequence === null ||
            this.formData.sequence === undefined ||
            !this.formData.installedOn ||
            !this.sequenceNumberIsInteger) {
            return false;
        }
        return Boolean(this.formData.sequence >= 0 && !this.isDuplicateBySequenceByParent);
    }
    get installedDate() {
        return this.selectedWorkingAssemblyInventory?.computedInstalledDate;
    }
    get isSequenceValid() {
        const sequenceIsZeroOrMore = Boolean(this.formData.sequence >= 0);
        return Boolean(sequenceIsZeroOrMore &&
            !this.isDuplicateBySequenceByParent &&
            this.sequenceIsWithinSmallIntRange &&
            !isNullOrUndefined(this.formData.sequence));
    }
    get isDuplicateBySequenceByParent() {
        const duplicateIds = this.duplicateQueryResponse?.nodes || [];
        return Boolean(duplicateIds.filter((dupeAssyTemplate) => dupeAssyTemplate?.id !== this.id).length);
    }
    get canDeleteAssembly() {
        if (!this.selectedEntity) {
            return false;
        }
        return Boolean(this.parentData);
    }
    get computedAssemblyTemplateParts() {
        if (!this.selectedAssemblyParts) {
            return null;
        }
        return this.selectedAssemblyParts.map((part) => {
            return {
                asBuiltNumber: part.asBuiltNumber,
                drawingNumber: part.drawingNumber || '',
                side: part.side
            };
        });
    }
    get closeIcon() {
        return this.$icons['strong_close'];
    }
    get hasParent() {
        return Boolean(this.parentAssemblyId);
    }
    get manageAssemblyLink() {
        if (!this.id) {
            return null;
        }
        return `/hardware/${EntityType.ASSEMBLY}/manage/${this.id}`;
    }
    get parentAssemblyId() {
        return this.selectedEntity?.parent?.id;
    }
    get parentDrawingNumber() {
        return this.parentData?.drawingNumber;
    }
    get parentSequence() {
        if (!this.parentData) {
            return null;
        }
        const assyFragment = this.parentData;
        if (assyFragment.sequence) {
            return assyFragment.sequence;
        }
        return null;
    }
    get rowsPerPageItems() {
        return DEFAULT_MODAL_ROWS_PER_PAGE_ITEMS;
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberErrorMessage() {
        if (!this.formData.sequence) {
            ['Sequence number is required'];
        }
        else if (this.isDuplicateBySequenceByParent) {
            return [`Sequence ${this.formData?.sequence} is already being used`];
        }
        else if (!this.sequenceIsWithinSmallIntRange) {
            return 'Sequence number is out of range.';
        }
        else if (!this.sequenceNumberIsInteger) {
            return ['Sequence number must be integer'];
        }
    }
    get sequenceNumberIsInteger() {
        return Number.isInteger(Number(this.formData.sequence));
    }
    get sequenceNumberMessage() {
        if (this.isSequenceValid) {
            return 'Sequence number is accepted.';
        }
    }
    get shouldDisableSequenceField() {
        return !this.hasParent;
    }
    get shouldShowManageLinkAction() {
        return Boolean(this.$route.params.id !== this.id);
    }
    get selectedWorkingAssemblyInventory() {
        return this.selectedEntity?.inventory ?? null;
    }
    get userNeedsApprovalToRemoveAssembly() {
        // checks if there is an open change set template for assembly removals
        return Boolean(this.selectedWorkingAssemblyInventory?.assemblyRemoveRequiresApproval);
    }
    isSequenceZeroOrMore(quantity) {
        return quantity >= 0;
    }
    /**
     * Generic func for creating changesets
     *
     * @param changeSetType ChangeSetType
     */
    async createEntity(variables) {
        if (!variables) {
            return Promise.reject('No query variables passed.');
        }
        const authorityNumber = variables?.authorityNumber || '';
        const postFix = authorityNumber ? ': ' : '';
        const comment = variables?.comment ? `${authorityNumber}${postFix}${variables?.comment}` || '' : '';
        variables.comment = comment;
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation CreateChangeSetForAssemblyManage(
            $authorityCode: String
            $authorityNumber: String
            $comment: String
            $childInventoryId: UUID!
            $inventoryId: UUID!
            $subType: String!
            $payload: JSON
            $openedDate: Date!
          ) {
            createChangeSet(
              input: {
                authorityCode: $authorityCode
                authorityNumber: $authorityNumber
                comment: $comment
                childInventoryId: $childInventoryId # needs to be uuid
                inventoryId: $inventoryId # needs to uuid
                subType: $subType
                payload: $payload
                openedDate: $openedDate
              }
            ) {
              clientMutationId
            }
          }
        `,
                variables
            });
            if (resp.errors) {
                throw resp.errors;
            }
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Change set created! Re-loading assembly tree.'
            });
            this.emitRefreshParentEvent();
            this.emitRefreshSelfEvent();
            this.emitModalClose();
            this.isSaving = false;
            return resp;
        }
        catch (err) {
            this.$errorUtility({
                err
            });
            this.isSaving = true;
        }
    }
    async fetchEntity(id) {
        try {
            if (!id) {
                throw new Error('No id passed when attempting to fetch a working assembly.');
            }
            const resp = await this.getWorkingAssemblyById({ id });
            return resp?.workingAssemblyById;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Error searching Assemblies.'
            });
        }
    }
    async editEntity(editAssemblyMutationVariables) {
        if (!editAssemblyMutationVariables) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'Could not edit the Assembly.'
            });
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation UpdateWorkingAssemblyForManage($input: UpdateWorkingAssemblyByIdInput!) {
            updateWorkingAssemblyById(input: $input) {
              clientMutationId
            }
          }
        `,
                variables: editAssemblyMutationVariables
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Assembly edited.`
            });
            this.emitRefreshParentEvent();
            this.emitRefreshSelfEvent();
            this.emitModalClose();
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Assembly'
            });
        }
    }
    isNotMoreThanMaxQuantity(minQuantity, maxQuantity) {
        return minQuantity <= maxQuantity;
    }
    emitModalClose() { }
    onClickOfCloseModal() { }
    emitRefreshParentEvent() { }
    emitRefreshSelfEvent() { }
    async onSequenceChange() {
        if (!this.formData.sequence) {
            return;
        }
        try {
            const resp = await this.checkForDuplicateViaAssembly();
            this.duplicateQueryResponse = resp?.data.workingAssemblies;
        }
        catch (error) {
            this.duplicateQueryResponse = null;
        }
    }
    async onParentIdChange(parentAssemblyId) {
        if (!parentAssemblyId) {
            return;
        }
        this.parentData = await this.fetchEntity(parentAssemblyId);
    }
    async onAssemblyChange(val) {
        if (val) {
            try {
                this.selectedEntity = await this.fetchEntity(val);
            }
            catch (error) {
                this.$errorUtility({
                    err: error
                });
            }
        }
    }
    checkForDuplicateViaAssembly() {
        if (!this.parentAssemblyId) {
            return;
        }
        return this.$apollo.query({
            query: gql `
        query CheckForAssemblyDuplicatesAssembly($sequence: Int, $parentAssemblyId: UUID!) {
          workingAssemblies(condition: { sequence: $sequence, parentId: $parentAssemblyId }) {
            nodes {
              id
              nodeId
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: {
                sequence: this.formData.sequence,
                parentAssemblyId: this.parentAssemblyId
            }
        });
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], BaseModalAssembly.prototype, "assemblyTemplate", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], BaseModalAssembly.prototype, "id", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssembly.prototype, "emitModalClose", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssembly.prototype, "onClickOfCloseModal", null);
__decorate([
    Emit('refresh-parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssembly.prototype, "emitRefreshParentEvent", null);
__decorate([
    Emit('refresh-self'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseModalAssembly.prototype, "emitRefreshSelfEvent", null);
__decorate([
    Watch('formData.sequence'),
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], BaseModalAssembly.prototype, "onSequenceChange", null);
__decorate([
    Watch('parentAssemblyId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], BaseModalAssembly.prototype, "onParentIdChange", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], BaseModalAssembly.prototype, "onAssemblyChange", null);
BaseModalAssembly = __decorate([
    Component
], BaseModalAssembly);
export default BaseModalAssembly;
