var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { EntityType } from '~/nasa_ui/types';
let SearchFilterDisplay = class SearchFilterDisplay extends Vue {
    active;
    color;
    chipsFromFilters;
    entityType;
    showFilterButton;
    get chipsFromFiltersLength() {
        return this.chipsFromFilters ? this.chipsFromFilters.length : 0;
    }
    get clearFilterColor() {
        return this.entityType ? `var(--v-${this.entityType}-base)` : this.color ? this.color : 'black';
    }
    get filterButtonOptionalStyle() {
        return {
            [this.entityType || this.color]: this.active
        };
    }
    get filterMessage() {
        return `Currently applying <b>${this.chipsFromFiltersLength}</b> filters.`;
    }
    get shouldShowAppChip() {
        if (this.chipsFromFiltersLength === 0) {
            return false;
        }
        return this.chipsFromFiltersLength <= 3;
    }
    appChipCloseClicked(val) { }
    emitClearAllFilters() { }
    filterButtonClicked(val) { }
};
__decorate([
    Prop({
        default: false
    }),
    __metadata("design:type", Boolean)
], SearchFilterDisplay.prototype, "active", void 0);
__decorate([
    Prop({
        default: 'var(--v-primary-lighten2)'
    }),
    __metadata("design:type", String)
], SearchFilterDisplay.prototype, "color", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Array)
], SearchFilterDisplay.prototype, "chipsFromFilters", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof EntityType !== "undefined" && EntityType) === "function" ? _a : Object)
], SearchFilterDisplay.prototype, "entityType", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchFilterDisplay.prototype, "showFilterButton", void 0);
__decorate([
    Emit('appChipCloseClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchFilterDisplay.prototype, "appChipCloseClicked", null);
__decorate([
    Emit('clearAllFilters'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchFilterDisplay.prototype, "emitClearAllFilters", null);
__decorate([
    Emit('filterButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchFilterDisplay.prototype, "filterButtonClicked", null);
SearchFilterDisplay = __decorate([
    Component
], SearchFilterDisplay);
export default SearchFilterDisplay;
