var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.showBorder ? _c('AppFormWrapper', {
    attrs: {
      "color": _vm.color,
      "validate-on-load": "",
      "size": _vm.size
    }
  }, [!_vm.hideHeader ? _c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._t("title", function () {
    return [_vm._v("Authorization")];
  })], 2) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('c-select', {
    attrs: {
      "label": "Authority type",
      "chips": false,
      "color": _vm.EntityType.CHANGE_SET,
      "required": _vm.required,
      "sort": ""
    },
    domProps: {
      "cValue": _vm.authorityCodeFormData.authorityCode,
      "display": _vm.AuthorityCodeDisplay,
      "enum": _vm.AuthorityCode
    },
    on: {
      "c-input": function ($event) {
        _vm.authorityCodeFormData.authorityCode = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": _vm.numberTitle,
      "c-value": _vm.authorityCodeFormData.authorityNumber,
      "required": _vm.required
    },
    on: {
      "c-input": function ($event) {
        _vm.authorityCodeFormData.authorityNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.foundDocumentId ? _c('MiniDocument', {
    attrs: {
      "title": "Document found",
      "id": _vm.foundDocumentId
    }
  }) : _vm._e()], 1)], 1), _vm.showComments ? _c('v-layout', {
    attrs: {
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppMarkdown', {
    attrs: {
      "label": _vm.commentTitle
    },
    model: {
      value: _vm.authorityCodeFormData.comment,
      callback: function ($$v) {
        _vm.$set(_vm.authorityCodeFormData, "comment", $$v);
      },
      expression: "authorityCodeFormData.comment"
    }
  })], 1)], 1) : _vm._e()], 1) : _c('div', {
    attrs: {
      "validate-on-load": ""
    }
  }, [!_vm.hideHeader ? _c('AppHeader', {
    staticClass: "mb-3"
  }, [_vm._t("title", function () {
    return [_vm._v("Authorization")];
  })], 2) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('c-select', {
    attrs: {
      "label": "Authority type",
      "chips": false,
      "color": _vm.EntityType.CHANGE_SET,
      "required": _vm.required,
      "sort": ""
    },
    domProps: {
      "cValue": _vm.authorityCodeFormData.authorityCode,
      "display": _vm.AuthorityCodeDisplay,
      "enum": _vm.AuthorityCode
    },
    on: {
      "c-input": function ($event) {
        _vm.authorityCodeFormData.authorityCode = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs5": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Authority number",
      "monospaced": "",
      "c-value": _vm.authorityCodeFormData.authorityNumber,
      "required": _vm.required
    },
    on: {
      "c-input": function ($event) {
        _vm.authorityCodeFormData.authorityNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm.foundDocumentId ? _c('MiniDocument', {
    attrs: {
      "title": "Document found",
      "id": _vm.foundDocumentId
    }
  }) : _vm._e()], 1)], 1), _vm.showComments ? _c('v-layout', {
    attrs: {
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppMarkdown', {
    attrs: {
      "label": "Comment"
    },
    model: {
      value: _vm.authorityCodeFormData.comment,
      callback: function ($$v) {
        _vm.$set(_vm.authorityCodeFormData, "comment", $$v);
      },
      expression: "authorityCodeFormData.comment"
    }
  })], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };