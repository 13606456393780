var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', _vm._g(_vm._b({
    attrs: {
      "disabled": _vm.computedDisabled,
      "icon": _vm.$icons.clear,
      "color": "var(--v-error-lighten2)",
      "text-color": "var(--cosmic-text)"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_vm._t("icon")];
      },
      proxy: true
    }], null, true)
  }, 'ButtonGeneric', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners), [_vm.$slots.default ? _vm._t("default") : [_vm._v("Clear selection")]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };