var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-expansion-panel', _vm._b({
    staticClass: "app_expansion_panel mb-3",
    class: {
      dense: _vm.dense
    },
    attrs: {
      "expand": ""
    },
    model: {
      value: _vm.isExpanded,
      callback: function ($$v) {
        _vm.isExpanded = $$v;
      },
      expression: "isExpanded"
    }
  }, 'v-expansion-panel', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_c('v-expansion-panel-content', {
    class: _vm.color
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('div', {
    staticClass: "font-weight-regular app_expansion_panel_title"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2)]), _c('v-card', {
    attrs: {
      "light": ""
    }
  }, [_c('v-card-text', {
    class: _vm.fullWidthContent ? 'app_expansion_panel_body pa-0' : 'app_expansion_panel_body'
  }, [_vm._t("default")], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };