var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "lg4": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "First Name",
      "c-value": _vm.formData.firstName
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.firstName = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "lg4": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Middle Name",
      "c-value": _vm.formData.middleName
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.middleName = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "lg4": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Last Name",
      "c-value": _vm.formData.lastName
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.lastName = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "lg6": "",
      "pr-3": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "color": _vm.EntityType.USER,
      "label": "Email"
    },
    model: {
      value: _vm.formData.email,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": "",
      "lg4": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "AUID",
      "c-value": _vm.formData.auid,
      "upper-case": ""
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.auid = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonSave', {
    attrs: {
      "ml-0": "",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.createNewContact
    }
  }, [_vm._v("Create Contact")]), _vm._t("actions-append", null, null, {
    formData: _vm.formData,
    isValid: _vm.isValid,
    isSaving: _vm.isSaving
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };