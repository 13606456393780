var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Debounce } from 'lodash-decorators';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { HardwareListType, Maybe } from '~/db_types';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import { AssemblyTemplatePartTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { buildIcon, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalHardwareListTemplateSlotEdit = class ModalHardwareListTemplateSlotEdit extends Mixins(BaseModal) {
    duplicateQueryResponse = null;
    isSaving = false;
    HardwareListType = HardwareListType;
    HardwareListTypeDisplay = HardwareListTypeDisplay;
    selectedEntity = null;
    hardwareListTemplateParts = [];
    formData = {
        maxQuantity: null,
        minQuantity: null,
        name: null,
        sequence: null
    };
    hardwareListTemplateSlotId;
    get computedHardwareListTemplatePartTableHeaders() {
        return [...AssemblyTemplatePartTableHeaders];
    }
    get computedSequenceMessage() {
        if (this.formData.sequence === this.selectedEntity?.sequence && this.isDuplicate) {
            return 'Sequence accepted.';
        }
        else if (this.formData.sequence !== this.selectedEntity?.sequence && this.isDuplicate) {
            return '';
        }
        else if (!this.isDuplicate) {
            return 'Sequence accepted.';
        }
    }
    get computedSequenceErrorMessage() {
        if (this.formData.sequence === this.selectedEntity?.sequence && this.isDuplicate) {
            return '';
        }
        else if (this.formData.sequence !== this.selectedEntity?.sequence && this.isDuplicate) {
            return this.duplicateAlertText;
        }
        else if (!this.isDuplicate) {
            return '';
        }
    }
    get closeIcon() {
        return this.$icons['strong_close'];
    }
    get duplicateAlertText() {
        return `Sequence number exists.`;
    }
    get hardwareListTemplatePartTableItems() {
        return transformItemMasters(this.hardwareListTemplateParts || [])
            .map((part) => {
            const entityType = part.asBuiltNumber ? EntityType.ITEM_MASTER : EntityType.ITEM_DRAWING;
            return Object.assign({}, part, {
                _entityType: entityType,
                _entityTypeIcon: buildIcon(entityType)
            });
        })
            .map((partTableItem) => {
            if (partTableItem._entityType === EntityType.ITEM_DRAWING) {
                partTableItem._asBuiltNumber = NOT_APPLICABLE;
                partTableItem._side = NOT_APPLICABLE;
            }
            return partTableItem;
        });
    }
    get hardwareListTemplatePartsForMutation() {
        return this.hardwareListTemplateParts.map((part) => {
            return {
                drawingNumber: part.drawingNumber,
                asBuiltNumber: part.asBuiltNumber,
                side: part.side
            };
        });
    }
    get isDuplicate() {
        return this.duplicateQueryResponse?.nodes.length === 1 && this.formData.sequence !== this.selectedEntity?.sequence;
    }
    get isFormValid() {
        return Boolean(this.formData.sequence);
    }
    get rowsPerPageItems() {
        return [
            {
                text: '5',
                value: 5
            },
            {
                text: '10',
                value: 10
            },
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get shouldDisableEditButton() {
        return !this.isFormValid || this.isDuplicate;
    }
    async updateHardwareListTemplateSlot(data) {
        return this.$apollo.mutate({
            mutation: gql `
        mutation ModalHardwareListTemplateSlotEdit($input: UpdateHardwareListTemplateSlotWithPartsInput!) {
          updateHardwareListTemplateSlotWithParts(input: $input) {
            hardwareListTemplateSlot {
              id
              nodeId
            }
          }
        }
      `,
            variables: {
                ...data
            }
        });
    }
    async editHardwareListTemplate(data) {
        try {
            this.isSaving = true;
            const resp = await this.updateHardwareListTemplateSlot(data);
            if (resp?.errors) {
                throw resp.errors;
            }
            if (resp?.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: `${this.selectedEntity?.name || 'Hardware List Template Slot'} edited.`
                });
                this.$message('reload', true);
                this.onClickOfCloseModal();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Could not edit the Hardware List Template Slot'
            });
        }
    }
    async fetchHardwareListTemplateSlot(hardwareListTemplateSlotId) {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicItemDrawingSlimFragment}

          query FetchHardwareListTemplateSlotForEditModal($id: UUID!) {
            hardwareListTemplateSlotById(id: $id) {
              id
              maxQuantity
              minQuantity
              name
              nodeId
              sequence

              hardwareListTemplateParts {
                nodes {
                  nodeId
                  drawingNumber
                  asBuiltNumber
                  side

                  itemMaster {
                    nodeId
                    itemDrawingDescription
                  }

                  itemDrawing {
                    ...CosmicItemDrawingSlim
                  }
                }
              }
            }
          }
        `,
                variables: {
                    id: hardwareListTemplateSlotId
                }
            });
            this.selectedEntity = resp.data.hardwareListTemplateSlotById;
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not fetch the hardware list template slot'
            });
        }
    }
    onClickOfModalHardwareListEditTemplate() {
        if (this.isFormValid &&
            this.formData.maxQuantity !== null &&
            this.formData.minQuantity !== null &&
            this.formData.sequence !== null &&
            !this.isDuplicate) {
            const input = {
                input: {
                    templateSlot: {
                        id: this.selectedEntity?.id,
                        maxQuantity: this.formData.maxQuantity ?? 0,
                        minQuantity: this.formData.minQuantity ?? 0,
                        name: this.formData.name?.trim(),
                        sequence: this.formData.sequence ?? 0,
                        hardwareListTemplateParts: this.hardwareListTemplatePartsForMutation
                    }
                }
            };
            this.editHardwareListTemplate(input);
        }
    }
    onHardwareListTemplateSlotIdChange(val) {
        if (val) {
            this.fetchHardwareListTemplateSlot(val);
        }
    }
    async checkForHardwareListDuplicates() {
        if (!this.isFormValid || !this.formData.sequence) {
            return;
        }
        const data = {
            sequence: this.formData.sequence
        };
        try {
            const resp = await this.$apollo.query({
                query: gql `
          query CheckForHardwareListTemplateSlotDuplicatesOnEdit($sequence: Int) {
            hardwareListTemplateSlots(first: 1, condition: { sequence: $sequence }) {
              nodes {
                id
                nodeId
              }
            }
          }
        `,
                variables: {
                    sequence: data.sequence
                }
            });
            this.duplicateQueryResponse = resp.data.hardwareListTemplateSlots;
        }
        catch (error) {
            this.duplicateQueryResponse = null;
        }
    }
    onSelectedEntityChange(val) {
        if (val) {
            this.formData = Object.assign({}, this.formData, val);
            this.hardwareListTemplateParts = this.selectedEntity?.hardwareListTemplateParts.nodes || [];
        }
    }
    onClickOfCloseModal() { }
    onSelectionOfHardwareListTemplatePart(item) {
        if (!this.hardwareListTemplateParts) {
            this.hardwareListTemplateParts = [];
        }
        this.hardwareListTemplateParts.push(item);
    }
    onClickOfHardwareListTemplatePartTableItem(item) {
        // Remove the clicked hardware
        this.hardwareListTemplateParts = this.hardwareListTemplateParts.filter((part) => part.nodeId !== item.nodeId);
    }
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalHardwareListTemplateSlotEdit.prototype, "hardwareListTemplateSlotId", void 0);
__decorate([
    Watch('hardwareListTemplateSlotId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateSlotEdit.prototype, "onHardwareListTemplateSlotIdChange", null);
__decorate([
    Debounce(200),
    Watch('formData.sequence'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], ModalHardwareListTemplateSlotEdit.prototype, "checkForHardwareListDuplicates", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateSlotEdit.prototype, "onSelectedEntityChange", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListTemplateSlotEdit.prototype, "onClickOfCloseModal", null);
ModalHardwareListTemplateSlotEdit = __decorate([
    Component
], ModalHardwareListTemplateSlotEdit);
export default ModalHardwareListTemplateSlotEdit;
