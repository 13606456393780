var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini inventories",
    class: {
      external_inventory: _vm.isExternal,
      external_tool: _vm.isExternal && _vm.isTool,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_vm.showEntityTypeIcon ? _c('div', {
    staticClass: "mini_icon_sequence_container"
  }, [!_vm.shouldShowSequence ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.iconClass,
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _c('div', {
    staticClass: "mini_chips"
  }, [_c('v-chip', {
    staticClass: "mini_chip seq_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_c('strong', [_vm._v(_vm._s(_vm.sequence))])])])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])]), _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "title": "Description"
    }
  }, [_vm._v(_vm._s(_vm.descriptionDisplay))]), !_vm.isExternal ? _c('small', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.currentLocationDisplay,
      expression: "currentLocationDisplay"
    }],
    staticClass: "caption pl-1",
    attrs: {
      "title": _vm.currentLocationSansHtml
    }
  }) : _vm._e()]), _vm.isExternal ? _c('div', {
    staticClass: "white--text text--lighten3 d-flex justify-center"
  }, [_c('span', {
    staticClass: "icon_wrap"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('span', _vm._g({}, on), [_c('IconNotCosmic')], 1)];
      }
    }], null, false, 1347555132)
  }, [_c('span', [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Inventory")])])], 1)]) : _vm._e(), _vm.isExternal ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Is Tool?: ${_vm.isTool ? 'Yes' : 'No'}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Is Tool?")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    staticClass: "is_tool_display",
    attrs: {
      "value": _vm.isTool
    }
  })], 1)]) : _vm._e(), _vm.isExternal ? _c('div') : _vm._e(), !_vm.isExternal ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `AsBuilt: ${_vm.asBuiltNumberDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("AsBuilt")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.asBuiltNumberDisplay))])]) : _vm._e(), !_vm.isExternal ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Side: ${_vm.sideDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Side")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.sideDisplay))])]) : _vm._e(), !_vm.isExternal ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Size: ${_vm.sizeDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Size")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.sizeDisplay))])]) : _vm._e(), _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Serial: ${_vm.serialNumberDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Serial")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.serialNumberDisplay))])]), _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Lot: ${_vm.lotNumberDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Lot")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.lotNumberDisplay))])]), _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Class: ${_vm.classDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Class")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.classDisplay))])]), _vm.isExternal && _vm.isTool ? _c('div', {
    staticClass: "white--text d-flex justify-center layout column text-xs-center",
    attrs: {
      "title": `Calibration number: ${_vm.calibrationNumberDisplay}`
    }
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Calibration")]), _c('div', {
    staticClass: "font-weight-bold monospace_font"
  }, [_vm._v(_vm._s(_vm.calibrationNumberDisplay))])]) : _vm._e(), _c('div', {
    staticClass: "mini_actions_v2 justify-end"
  }, [!_vm.entity ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({}, on), [_c('ButtonMiniAction', {
          attrs: {
            "icon-color": "#fff",
            "icon": _vm.$icons['eye']
          },
          on: {
            "click": _vm.onClickOfShowDetail
          }
        })], 1)];
      }
    }], null, false, 3741113595)
  }, [_c('span', [_vm._v("Show/Hide detail")])]) : _vm._e(), _vm._t("actions", null, {
    "localEntity": _vm.localEntity
  }), _vm.inlineClear && !_vm.readOnly ? _c('ButtonMiniAction', {
    attrs: {
      "icon-color": "#fff",
      "icon": _vm.$icons.close,
      "tooltip": "Clear selection"
    },
    on: {
      "click": _vm.onClickOfInlineClear
    }
  }) : _vm._e()], 2)]) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.hasLocalEntity ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "type": "linear",
      "center": ""
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowDetail ? _c('AppFormWrapper', {
    attrs: {
      "bottom": "1",
      "color-modifier": "",
      "color": _vm.EntityType.INVENTORY,
      "left": "1",
      "right": "1",
      "size": "1",
      "top": "0"
    }
  }, [_c('div', {
    staticClass: "inventory_mini_details"
  }, [_vm.inventoryNodeId ? _c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.inventoryNodeId,
      "hide-border-bottom": ""
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };