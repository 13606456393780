import { addBinDisplay, addBuildingDisplay, addIsManagedLocationDisplay, addLocationAddressDisplay, addOrganizationDisplay, addRoomDisplay, addStockDisplay } from '../displayMappers';
import { transformContracts } from './contracts';
import { transformOrganizations } from './organizations';
export const transformLocations = (items) => {
    return items
        .map(addBinDisplay)
        .map(addBuildingDisplay)
        .map(addIsManagedLocationDisplay)
        .map(addLocationAddressDisplay)
        .map(addOrganizationDisplay)
        .map(addRoomDisplay)
        .map(addStockDisplay)
        .map((item) => {
        const _item = { ...item };
        _item._contract = _item.contract ? transformContracts([_item.contract])[0] : null;
        _item._organization = item.organization
            ? transformOrganizations([_item.organization])[0]
            : null;
        return _item;
    });
};
export const transformLocationResponses = (items) => {
    return transformLocations(items);
};
export const transformLocationResponseWithRefs = (items) => {
    return transformLocations(items);
};
