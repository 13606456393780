var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "the_footer",
    class: {
      'full-screen': !_vm.navStateIsOpen
    },
    attrs: {
      "id": "footer"
    }
  }, [_c('footer', {
    staticClass: "footer elevation-3"
  }, [_c('a', {
    staticClass: "nasa_logo",
    attrs: {
      "href": "http://www.nasa.gov",
      "target": "_blank",
      "title": "www.nasa.gov"
    }
  }, [_c('c-icon-nasa-meatball', {
    attrs: {
      "height": "70px",
      "width": "70px"
    }
  })], 1), _vm._m(0), _c('div', {
    staticClass: "versions"
  }, [_c('div', {
    staticClass: "user-guide"
  }, [_c('button', {
    staticClass: "reset-btn delinkify"
  }, [_c('a', {
    attrs: {
      "href": _vm.knowledgebaseLink,
      "target": "_blank"
    }
  }, [_vm._v("User Guide")])])]), _c('div', {
    staticClass: "user-feedback"
  }, [_c('button', {
    staticClass: "reset-btn delinkify"
  }, [_c('a', {
    attrs: {
      "href": _vm.feedbackLink,
      "target": "_blank"
    }
  }, [_vm._v("Feedback")])])]), _c('div', {
    staticClass: "reset-button"
  }, [_c('button', {
    staticClass: "reset-btn",
    on: {
      "click": _vm.onClickOfLogout
    }
  }, [_vm._v("Logout")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ul', {
    staticClass: "links"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://www.nasa.gov/offices/odeo/no-fear-act",
      "title": "No Fear Act",
      "target": "_blank"
    }
  }, [_vm._v("No Fear Act")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.nasa.gov/FOIA",
      "title": "FOIA",
      "target": "_blank"
    }
  }, [_vm._v("FOIA")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://oig.nasa.gov",
      "title": "Office of Inspector General",
      "target": "_blank"
    }
  }, [_vm._v(" Office of Inspector General ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "mailto:cuong.q.nguyen@nasa.gov",
      "title": "RNO: Cuong Q. Nguyen"
    }
  }, [_vm._v(" RNO: Cuong Q. Nguyen ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.nasa.gov/accessibility/",
      "title": "Accessibility",
      "target": "_blank"
    }
  }, [_vm._v(" Accessibility ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.nasa.gov/about/contact/index.html",
      "title": "Contact NASA",
      "target": "_blank"
    }
  }, [_vm._v("Contact NASA")])])]);
}];
export { render, staticRenderFns };