import qs from 'qs';
import Vue from 'vue';
import { UserSupportContext } from '~/application.config';
import { UserRole } from '~/db_types';
import { getActiveSupportContext, setActiveSupportContext } from '../mixins/CurrentUserMixin';
import { store } from '../store';
import { createAxiosInstance } from '../vue_plugins/axios';
import { NonnominalSharedRoutes } from './nonnominal';
export const checkSetActiveSupportContextViaQuerystring = (router) => {
    // Router checks for a target context in the url once during startup
    // This flag gets flipped after it's been checked once
    let initialTargetContextChecked = false;
    router.beforeEach(async (to, from, next) => {
        const user = store.currentUser ?? null;
        if (!user) {
            console.error('User not set');
            return next('/no_supports');
        }
        const currentSupportContexts = (user?.supports || []).filter((cosmicContext) => Object.values(UserSupportContext).includes(cosmicContext.organizationCode));
        // Check for ctx query param to see if context needs to be automatically changed
        if (!initialTargetContextChecked) {
            initialTargetContextChecked = true;
            const targetContext = to.query.ctx;
            let currentUserActiveSupportContext = getActiveSupportContext();
            if (targetContext && targetContext !== currentUserActiveSupportContext) {
                const userIsInContext = currentSupportContexts?.some((c) => c?.organizationCode === targetContext);
                if (userIsInContext) {
                    setActiveSupportContext(targetContext);
                    currentUserActiveSupportContext = targetContext;
                    // Send message for navbar to pick up
                    const bus = new Vue();
                    bus.$message('context_updated', targetContext);
                }
            }
            initialTargetContextChecked = false;
            return next();
        }
    });
};
/**
 * Checks the currentUser's permissions for this context against the requirement
 *  set on the route definition itself
 * @returns
 */
export const checkUserForPermissionRequirements = (router) => {
    router.beforeEach((to, from, next) => {
        const user = store.currentUser ?? null;
        if (!user) {
            console.error('User not set ');
            return next('/no_supports');
        }
        const activeUserSupportContextResponse = (user?.supports || []).find((cosmicContext) => cosmicContext.organizationCode === getActiveSupportContext());
        const activeUserSupportPermissions = activeUserSupportContextResponse?.permissions;
        // No permissions for this context
        if (!activeUserSupportContextResponse || !activeUserSupportPermissions) {
            return next('/insufficient_user_permission');
        }
        // Permissions defined on the route itself
        const routeSpecificUserPermissionRequirements = to.meta?.permissions;
        // Not defining permissions is no good but let them through as its a developer implementation issue
        if (!routeSpecificUserPermissionRequirements) {
            console.error(`No permissions specified for ${to.name} route. If anyone can visit this route, specify [].`);
            return next();
        }
        // Empty permissions definition is ok; this means no extra permission is required
        if (!routeSpecificUserPermissionRequirements.length) {
            return next();
        }
        // Of the permissions required (listed on the route itself) which does the user NOT have
        const permissionsCurrentUserDoesntHave = routeSpecificUserPermissionRequirements.filter((permission) => activeUserSupportPermissions[permission] === false ||
            activeUserSupportPermissions[permission] === undefined ||
            activeUserSupportPermissions[permission] === null);
        if (permissionsCurrentUserDoesntHave.length > 0) {
            console.error('User is not authorized to access this page');
            const perms = qs.stringify(permissionsCurrentUserDoesntHave);
            return next({
                path: `/insufficient_user_permission?${perms}`
            });
        }
        return next();
    });
};
export const checkUserForSupportContexts = (router) => {
    router.beforeEach((to, from, next) => {
        const user = store.currentUser ?? null;
        if (!user) {
            console.error('User not set ');
            return next('/no_supports');
        }
        const currentSupportContexts = (user?.supports || []).filter((cosmicContext) => Object.values(UserSupportContext).includes(cosmicContext.organizationCode));
        if (!currentSupportContexts || (currentSupportContexts.length === 0 && to.path !== '/no_supports')) {
            return next('/no_supports');
        }
        const nonNominalRoutes = NonnominalSharedRoutes.map((route) => route.name);
        // If youre going to a nonnominal route carry on
        if (nonNominalRoutes.includes(to.name)) {
            return next();
        }
        const routeSpecificUserSupportRequirements = to.meta?.supportContexts;
        const currentActiveSupportContext = getActiveSupportContext();
        // Not defining supportContexts is no good but let them through as its a developer implementation issue
        if (!routeSpecificUserSupportRequirements) {
            console.error(`No supportContexts specified for ${to.name} route. If anyone can visit this route, specify [].`);
            return next();
        }
        // Empty supportContexts definition is ok; this means no extra permission is required
        if (!routeSpecificUserSupportRequirements.length) {
            return next();
        }
        if (!routeSpecificUserSupportRequirements.includes(currentActiveSupportContext)) {
            console.error('User is not authorized to access this page');
            const support = qs.stringify(routeSpecificUserSupportRequirements);
            return next(`/insufficient_user_support_context?${support}`);
        }
        next();
    });
};
export const checkUserRoleRequirements = (router) => {
    router.beforeEach((to, from, next) => {
        const user = store.currentUser ?? null;
        if (!user) {
            console.error('User not set');
            return next('/insufficient_user_role');
        }
        const nonNominalRoutes = NonnominalSharedRoutes.map((route) => route.name);
        // If youre going to a nonnominal route carry on
        if (nonNominalRoutes.includes(to.name)) {
            return next();
        }
        const routeSpecificUserRoleRequirements = to.meta?.userRoles;
        const currentUserRole = user.role;
        // Not defining userRole is no good but let them through as its a developer implementation issue
        if (!routeSpecificUserRoleRequirements) {
            console.error(`No userRole specified for ${to.name} route. If anyone can visit this route, specify [].`);
            return next();
        }
        // Empty userRole definition is ok; this means "any userRole" is valid
        if (!routeSpecificUserRoleRequirements.length) {
            return next();
        }
        // Admins are allowed access
        if (user.role === UserRole.ADMIN) {
            return next();
        }
        // If you are going to your user manage page that is ok too
        if (to.name === 'UserManageView' && to.params.id === user.id) {
            return next();
        }
        if (!routeSpecificUserRoleRequirements.includes(currentUserRole)) {
            console.error('User is not authorized to access this page');
            const rr = qs.stringify(routeSpecificUserRoleRequirements);
            return next(`/insufficient_user_role?${rr}`);
        }
        next();
    });
};
export const fetchCurrentUser = async () => {
    try {
        const resp = await createAxiosInstance().get('/users/current');
        const currentUser = resp.data;
        if (!currentUser) {
            console.error('Couldnt get the current user');
            return null;
        }
        return currentUser;
    }
    catch (err) {
        return Promise.reject(err);
    }
};
export const getBlurbAboutSearch = (displayEntityType) => `Search, filter, view details of a single ${displayEntityType || ''}.`;
export const getBlurbAboutCreate = (displayEntityType) => `Create ${displayEntityType || ''}.`;
export const getBlurbAboutManage = (displayEntityType) => `View details of a single ${displayEntityType || ''}.`;
export const refreshCurrentUser = (router) => {
    router.beforeEach(async (to, from, next) => {
        const currentUser = await fetchCurrentUser();
        store.currentUser = currentUser;
        next();
    });
};
