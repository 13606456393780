var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { EntityType } from '~/nasa_ui/types';
let SearchInputContainer = class SearchInputContainer extends Vue {
    autofocus;
    darkText;
    disableSearch;
    forceDisableSearch;
    isLoading;
    entityType;
    hint;
    placeholder;
    value;
    searchInput = '';
    onEnterPressed() {
        if (!this.disableSearch) {
            this.emitEnterPressed();
        }
    }
    emitSearchButtonClicked() { }
    emitEnterPressed() { }
    onValueChange(val) {
        this.searchInput = val;
    }
    emitSearchInput() { }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInputContainer.prototype, "autofocus", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInputContainer.prototype, "darkText", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInputContainer.prototype, "disableSearch", void 0);
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInputContainer.prototype, "forceDisableSearch", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInputContainer.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: [String, String] }),
    __metadata("design:type", typeof (_a = typeof EntityType !== "undefined" && EntityType) === "function" ? _a : Object)
], SearchInputContainer.prototype, "entityType", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], SearchInputContainer.prototype, "hint", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], SearchInputContainer.prototype, "placeholder", void 0);
__decorate([
    Prop(),
    __metadata("design:type", String)
], SearchInputContainer.prototype, "value", void 0);
__decorate([
    Emit('searchButtonClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchInputContainer.prototype, "emitSearchButtonClicked", null);
__decorate([
    Emit('enterPressed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchInputContainer.prototype, "emitEnterPressed", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], SearchInputContainer.prototype, "onValueChange", null);
__decorate([
    Watch('searchInput'),
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchInputContainer.prototype, "emitSearchInput", null);
SearchInputContainer = __decorate([
    Component
], SearchInputContainer);
export default SearchInputContainer;
