var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('MiniHardwareList', {
          staticClass: "mb-1",
          attrs: {
            "title": "List information",
            "id": _vm.parentHardwareListId
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs2": "",
            "mt-2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "autofocus": "",
            "label": "Sequence",
            "tooltip": "This controls the order of the items of the list.",
            "tooltip-position": "right"
          },
          model: {
            value: _vm.formData.sequence,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('SelectorHardwareListAssembly', {
          attrs: {
            "value": _vm.hardwareListAssembly
          },
          on: {
            "input": _vm.onHardwareListAssemblyChange
          }
        })], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.showAdditionalMetadata ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppFormWrapper', {
          attrs: {
            "size": "1"
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "clearable": "",
            "label": "Addendum number"
          },
          model: {
            value: _vm.formData.addendumNumber,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "addendumNumber", $$v);
            },
            expression: "formData.addendumNumber"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Addendum"
          },
          model: {
            value: _vm.formData.addendumDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "addendumDate", $$v);
            },
            expression: "formData.addendumDate"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('HardwareListNoteDdl', {
          staticClass: "mt-3",
          attrs: {
            "multiple": ""
          },
          model: {
            value: _vm.formData.notes,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "notes", $$v);
            },
            expression: "formData.notes"
          }
        })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": _vm.shouldDisableEditButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfEditHardwareListAssembly
          }
        }), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };