var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.selectedResult ? _c('SearchAssemblyTemplate', {
    attrs: {
      "existing-values": _vm.existingValues,
      "output-mode": "object",
      "filters": _vm.computedFilters,
      "locked-filters": _vm.lockedSearchFilters
    },
    model: {
      value: _vm.selectedResult,
      callback: function ($$v) {
        _vm.selectedResult = $$v;
      },
      expression: "selectedResult"
    }
  }) : _vm._e(), _vm.selectedResult ? _c('MiniAssemblyTemplate', {
    attrs: {
      "id": _vm.selectedResultId,
      "title": _vm.computedTitle,
      "inline-clear": ""
    },
    on: {
      "inlineClear": _vm.handleInlineClear
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };