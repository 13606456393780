import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types';
import { AssemblyTemplateType, HardwareListType } from '~/db_types/swagger_types';
import BaseModalAssemblyTemplate from '~/nasa_ui/base/BaseModalAssemblyTemplate';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { isNullOrUndefined } from '~/nasa_ui/utils';
let ModalAssemblyTemplateCreate = class ModalAssemblyTemplateCreate extends Mixins(BaseModalAssemblyTemplate) {
    HardwareListType = HardwareListType;
    selectedHardwareList = null;
    formData = {
        installedOn: Side.NONE,
        maxQuantity: 1,
        minQuantity: 1,
        name: null,
        pbsItemId: null,
        sequence: null,
        subType: null
    };
    get computedColor() {
        if (this.isParentGenericAssemblyTemplate) {
            return EntityType.ASSEMBLY_TEMPLATE;
        }
        else if (this.isParentEventAssemblyTemplate) {
            return EntityType.EVENT;
        }
        else if (this.isParentPbsAssemblyTemplate) {
            return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
        }
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    get createAssemblyTemplateRequestPayload() {
        if (!this.isFormValid || !this.parentData || !this.computedAssemblyTemplateType) {
            return null;
        }
        return {
            attributes: {},
            assemblyTemplateParts: this.computedAssemblyTemplateParts,
            hardwareListId: this.selectedHardwareList?.id ?? null,
            installedOn: this.formData.installedOn,
            maxQuantity: this.formData.maxQuantity,
            minQuantity: this.formData.minQuantity,
            name: this.formData.name,
            parentId: this.parentAssemblyTemplateId ? this.parentData.id : undefined,
            pbsItemId: this.formData.pbsItemId,
            sequence: this.formData.sequence || 10,
            subType: this.computedAssemblyTemplateType
        };
    }
    get isParentEventAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isParentGenericAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isParentPbsAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get isParentRootMegaAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.ROOT_MEGA;
    }
    get isParentChildMegaAssemblyTemplate() {
        return this.parentData?.subType === AssemblyTemplateType.CHILD_MEGA;
    }
    get isRoot() {
        return !this.parentAssemblyTemplateId;
    }
    get minIsLessThanMax() {
        return this.formData.minQuantity < this.formData.maxQuantity;
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid;
    }
    get subTypeDisplay() {
        return this.formData.subType ? AssemblyTemplateTypeDisplay.get(this.formData.subType) : DEFAULT_DASH;
    }
    onClickOfCreateAssemblyTemplate() {
        this.createEntity(this.createAssemblyTemplateRequestPayload);
    }
    onMetaPlusEnter() {
        if (!this.isFormValid) {
            return;
        }
        this.createEntity(this.createAssemblyTemplateRequestPayload);
    }
    setNextSequence(val) {
        if (!isNullOrUndefined(val)) {
            this.formData.sequence = val + 10;
        }
    }
};
__decorate([
    Watch('largestSequenceNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateCreate.prototype, "setNextSequence", null);
ModalAssemblyTemplateCreate = __decorate([
    Component
], ModalAssemblyTemplateCreate);
export default ModalAssemblyTemplateCreate;
