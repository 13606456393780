import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Mixins } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { SUB_TYPE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicLinkFragment as CosmicLinkFragmentQuery } from '~/nasa_ui/DAL/link';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import EntityLinksMixin from '~/nasa_ui/mixins/EntityLinksMixin';
import ReadOnlyMixin from '~/nasa_ui/mixins/ReadOnlyMixin';
import { AlertType, UserPermission } from '~/nasa_ui/types';
import { transformLinks } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let AppLinks = class AppLinks extends Mixins(BaseCosmic, CurrentUserMixin, ReadOnlyMixin, EntityLinksMixin) {
    links = [];
    linksColor = 'grey darken-1';
    selectedLink = null;
    shouldShowForm = false;
    showAreYouSure = false;
    get computedTableItems() {
        return transformLinks(this.links);
    }
    get isUserOwnerOfLinksLink() {
        return this.selectedLink?.createdByUserId === this.currentUser?.id;
    }
    get linkCondtionForFetch() {
        if (this.itemDrawing) {
            return this.itemDrawing;
        }
        if (this.itemMaster) {
            return this.itemMaster;
        }
        if (this.itemInstance) {
            return this.itemInstance;
        }
        if (this.documentId) {
            return {
                documentId: this.documentId
            };
        }
        return null;
    }
    get selectedLinkId() {
        return this.selectedLink?.id;
    }
    get tableHeaders() {
        return [
            SUB_TYPE_COL,
            {
                text: `System<br/>type`,
                value: '_entityTypeIcon',
                noEscape: true,
                width: '15%'
            },
            {
                text: 'Current revision',
                value: '_currentRevision'
            },
            {
                text: 'Current version URL',
                value: '_currentVersionUrl',
                noEscape: true
            },
            {
                align: 'right',
                monospacedFont: true,
                text: 'Reference number',
                value: '_referenceNumber'
            },
            {
                text: 'Working version URL',
                value: '_workingVersionUrl',
                noEscape: true
            }
        ];
    }
    get userCanRemoveLink() {
        // If you created it, you can delete it
        if (this.isUserOwnerOfLinksLink) {
            return true;
        }
        // if for some reason we cant get the currentUser perms
        if (!this.currentUserPermissions) {
            return false;
        }
        // Any doc admin will work for now
        if (this.documentId) {
            return Object.entries(this.currentUserPermissions)
                .filter((perm) => perm[0].startsWith('DOCUMENT_'))
                .some((perm) => perm[1] === true);
        }
        // Event admin
        if (this.eventId) {
            return this.currentUserHasPermission(UserPermission.EVENT_ADMIN);
        }
        // Risk open for now i guess
        if (this.riskId) {
            return true;
        }
        // Any hardware perm admin will work for now
        if (this.itemDrawing || this.itemMaster || this.itemInstance) {
            return (this.currentUserHasPermission(UserPermission.ITEM_DRAWING_ADMIN) ||
                this.currentUserHasPermission(UserPermission.ITEM_MASTER_ADMIN) ||
                this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN));
        }
        return false;
    }
    created() {
        this.populateTable();
    }
    // called from ui
    onClickOfTableRow(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedLink = row;
        this.shouldShowForm = true;
    }
    onModalClose() {
        this.shouldShowForm = false;
        this.showAreYouSure = false;
        this.selectedLink = null;
    }
    fetchLinks() {
        const condition = this.linkCondtionForFetch;
        if (!condition) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicLinkFragmentQuery}

        query GetLinksForLinksTab($condition: LinkCondition!) {
          links(condition: $condition) {
            nodes {
              ...CosmicLink
            }
          }
        }
      `,
            variables: {
                condition
            },
            fetchPolicy: 'network-only'
        });
    }
    fetchLinksForItemDrawing() {
        if (!this.linkCondtionForFetch) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicLinkFragmentQuery}

        query GetLinksForTabItemDrawingLinks($drawingNumber: String) {
          links(condition: { asBuiltNumber: null, drawingNumber: $drawingNumber }) {
            nodes {
              ...CosmicLink
            }
          }
        }
      `,
            variables: this.linkCondtionForFetch,
            fetchPolicy: 'network-only' // to accurately populate table after on-page updates
        });
    }
    fetchLinksForItemInstance() {
        if (!this.linkCondtionForFetch) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicLinkFragmentQuery}

        query GetLinksForTabItemInstanceLinks($asBuiltNumber: String, $drawingNumber: String, $side: Side, $id: UUID) {
          itemDrawingLinks: links(condition: { asBuiltNumber: null, drawingNumber: $drawingNumber }) {
            nodes {
              ...CosmicLink
            }
          }

          itemMasterLinks: links(
            condition: {
              asBuiltNumber: $asBuiltNumber
              drawingNumber: $drawingNumber
              itemInstanceId: null
              side: $side
            }
          ) {
            nodes {
              ...CosmicLink
            }
          }

          itemInstanceLinks: links(condition: { itemInstanceId: $id }) {
            nodes {
              ...CosmicLink
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: this.linkCondtionForFetch
        });
    }
    fetchLinksForItemMaster() {
        if (!this.linkCondtionForFetch) {
            return null;
        }
        return this.$apollo.query({
            query: gql `
        ${CosmicLinkFragmentQuery}

        query GetLinksForTabItemMasterLinks($asBuiltNumber: String, $drawingNumber: String, $side: Side) {
          itemDrawingLinks: links(condition: { asBuiltNumber: null, drawingNumber: $drawingNumber }) {
            nodes {
              ...CosmicLink
            }
          }

          itemMasterLinks: links(
            condition: {
              asBuiltNumber: $asBuiltNumber
              drawingNumber: $drawingNumber
              itemInstanceId: null
              side: $side
            }
          ) {
            nodes {
              ...CosmicLink
            }
          }
        }
      `,
            fetchPolicy: 'network-only',
            variables: this.linkCondtionForFetch
        });
    }
    // called from ui
    onClickOfRemoveLinksButton(row) {
        if (this.isReadOnly) {
            return;
        }
        this.selectedLink = row;
        this.showAreYouSure = true;
    }
    // called from ui
    async onAffirmativeClicked() {
        this.showAreYouSure = false;
        try {
            if (!this.selectedLinkId) {
                return;
            }
            const resp = await this.$http.delete(`/links/${this.selectedLinkId}`);
            if (resp) {
                this.populateTable();
                this.$notification.add({
                    text: 'Deleted.',
                    type: AlertType.SUCCESS
                });
            }
        }
        catch (err) {
            this.$errorUtility({
                err
            });
        }
    }
    // called from ui
    onNegativeClicked() {
        this.showAreYouSure = false;
    }
    /**
     * This function executes the appropriate query depending on what entity it's
     * working with and populates the links table. For Item Masters and Item
     * Instances it runs multiple queries in one request and then merges the
     * results.
     *
     * External links should flow downward from the Item Drawing. So a
     * given Item Instance should show links that belong to its Item Drawing and
     * links that belong to its Item Master, as well as the links that belong to
     * the Item Instance itself.
     */
    async populateTable() {
        try {
            if (this.documentId) {
                const resp = await this.fetchLinks();
                if (resp?.data.links) {
                    this.links = resp.data.links.nodes;
                }
            }
            else if (this.eventId) {
                const resp = await this.$http.get(`/links`, {
                    params: {
                        cosmicEventId: this.eventId,
                        take: -1
                    }
                });
                if (resp.data.results) {
                    this.links = resp.data.results;
                }
            }
            else if (this.riskId) {
                const resp = await this.$http.get(`/links`, {
                    params: {
                        riskId: this.riskId,
                        take: -1
                    }
                });
                if (resp.data.results) {
                    this.links = resp.data.results;
                }
            }
            else if (this.organizationCode) {
                const resp = await this.$http.get(`/links`, {
                    params: {
                        organizationCode: this.organizationCode,
                        take: -1
                    }
                });
                if (resp.data.results) {
                    this.links = resp.data.results;
                }
            }
            else if (this.itemDrawing) {
                const resp = await this.fetchLinksForItemDrawing();
                if (resp?.data.links) {
                    this.links = resp.data.links.nodes;
                }
            }
            else if (this.itemMaster) {
                const resp = await this.fetchLinksForItemMaster();
                const links = [];
                if (resp?.data.itemDrawingLinks) {
                    links.push(...resp.data.itemDrawingLinks.nodes);
                }
                if (resp?.data.itemMasterLinks) {
                    links.push(...resp.data.itemMasterLinks.nodes);
                }
                this.links = [...links];
            }
            else if (this.itemInstance) {
                const resp = await this.fetchLinksForItemInstance();
                const links = [];
                if (resp?.data.itemDrawingLinks) {
                    links.push(...resp.data.itemDrawingLinks.nodes);
                }
                if (resp?.data.itemMasterLinks) {
                    links.push(...resp.data.itemMasterLinks.nodes);
                }
                if (resp?.data.itemInstanceLinks) {
                    links.push(...resp.data.itemInstanceLinks.nodes);
                }
                this.links = [...links];
            }
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to get external links.'
            });
        }
    }
    emitUpdated() { }
};
__decorate([
    Emit('updated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppLinks.prototype, "emitUpdated", null);
AppLinks = __decorate([
    Component
], AppLinks);
export default AppLinks;
