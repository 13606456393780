var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { OutputMode } from '~/nasa_ui/types/enums/general';
let SelectorAssemblyTemplate = class SelectorAssemblyTemplate extends Vue {
    selectedResult = null;
    existingValues;
    outputMode;
    titleAsProp;
    searchFilters;
    get computedTitle() {
        if (!this.selectedResult?.subType) {
            return;
        }
        return this.titleAsProp
            ? this.titleAsProp
            : `Selected ${AssemblyTemplateTypeDisplay.get(this.selectedResult?.subType)}`;
    }
    get computedFilters() {
        if (!this.drawingNumberFilter) {
            return [];
        }
        return this.drawingNumberFilter;
    }
    get drawingNumberFilter() {
        if (!this.searchFilters?.drawingNumber?.length) {
            return null;
        }
        return {
            drawingNumber: this.searchFilters?.drawingNumber
        };
    }
    get lockedSearchFilters() {
        if (!this.searchFilters?.drawingNumber?.length) {
            return [];
        }
        return ['drawingNumber'];
    }
    get selectedResultId() {
        return this.selectedResult?.id ?? null;
    }
    handleInlineClear() {
        this.selectedResult = null;
    }
    emitAssemblyTemplateSelected(val) {
        if (this.outputMode === OutputMode.string) {
            return val?.id ?? null;
        }
        return val;
    }
    onChangeOfSelectedResult(val) {
        this.emitAssemblyTemplateSelected(val);
    }
};
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], SelectorAssemblyTemplate.prototype, "existingValues", void 0);
__decorate([
    Prop({
        type: String,
        default: OutputMode.string
    }),
    __metadata("design:type", typeof (_a = typeof OutputMode !== "undefined" && OutputMode) === "function" ? _a : Object)
], SelectorAssemblyTemplate.prototype, "outputMode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], SelectorAssemblyTemplate.prototype, "titleAsProp", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], SelectorAssemblyTemplate.prototype, "searchFilters", void 0);
__decorate([
    Emit('assembly-template-selected'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SelectorAssemblyTemplate.prototype, "emitAssemblyTemplateSelected", null);
__decorate([
    Watch('selectedResult'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], SelectorAssemblyTemplate.prototype, "onChangeOfSelectedResult", null);
SelectorAssemblyTemplate = __decorate([
    Component
], SelectorAssemblyTemplate);
export default SelectorAssemblyTemplate;
