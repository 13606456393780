import { TransactionType } from '~/db_types';
import { AssemblyTemplateType } from '~/db_types/swagger_types';
export const AssemblyTemplateTypeDisplay = new Map([
    [AssemblyTemplateType.ASSEMBLY_TEMPLATE, 'Assembly Template'],
    [AssemblyTemplateType.CHILD_MEGA, 'Child Mega'],
    [AssemblyTemplateType.EVENT_ASSEMBLY, 'Event Assembly'],
    [AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE, 'PBS'],
    [AssemblyTemplateType.ROOT_MEGA, 'Root Mega']
]);
export const AssemblyTemplateTypeSubset = {
    [AssemblyTemplateType.ASSEMBLY_TEMPLATE]: AssemblyTemplateType.ASSEMBLY_TEMPLATE,
    [AssemblyTemplateType.EVENT_ASSEMBLY]: AssemblyTemplateType.EVENT_ASSEMBLY,
    [AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE]: AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE,
    [AssemblyTemplateType.ROOT_MEGA]: AssemblyTemplateType.ROOT_MEGA
};
export const TransactionTypeDisplay = new Map([
    [TransactionType.COSMIC_SYSTEM_CORRECTION, 'System correction'],
    [TransactionType.COST_CORRECTION, 'Cost correction'],
    [TransactionType.INSTALL, 'Install'],
    [TransactionType.INVENTORY_AUDIT_CORRECTION, 'Audit correction'],
    [TransactionType.INVENTORY_AUDIT_VERIFICATION, 'Audit verification'],
    [TransactionType.INVENTORY_CHECK, 'Inv. check'],
    [TransactionType.ISSUE, 'Issue'],
    [TransactionType.ISSUE_ASSEMBLED, 'Issue assembled'],
    [TransactionType.MERGE_CORRECTION, 'Merge correction'],
    [TransactionType.RECEIPT_ASSEMBLED, 'Receipt assembled'],
    [TransactionType.RECEIPT, 'Receipt'],
    [TransactionType.REMOVE, 'Remove'],
    [TransactionType.SERIALIZE, 'Hardware serialization'],
    [TransactionType.SERIALIZE_ISSUED, 'Serialize issued Hardware'],
    [TransactionType.SERIALIZE_FROM_ISSUED, 'Hardware serialized from issued Hardware'],
    [TransactionType.SERIALIZE_FROM, 'Hardware serialized from'],
    [TransactionType.TRANSFER_ASSEMBLED_TO, 'Transfer to - assembled'],
    [TransactionType.TRANSFER_ASSEMBLED_FROM, 'Transfer from - assembled'],
    [TransactionType.TRANSFER_ISSUED_ASSEMBLED, 'Transfer as-issued - assembled'],
    [TransactionType.TRANSFER_ISSUED, 'Transfer as-issued'],
    [TransactionType.TRANSFER_TO, 'Transfer to'],
    [TransactionType.TRANSFER_FROM, 'Transfer from']
]);
