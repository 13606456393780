var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
import { CosmicIcons } from '../utils/ui/CosmicIcons';
let BaseButtonMiniActionImplementation = class BaseButtonMiniActionImplementation extends Vue {
    $icons = CosmicIcons;
    disabled;
    iconColor;
    tooltip;
    tooltipPosition;
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseButtonMiniActionImplementation.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: String,
        default: 'white'
    }),
    __metadata("design:type", String)
], BaseButtonMiniActionImplementation.prototype, "iconColor", void 0);
__decorate([
    Prop(),
    __metadata("design:type", Object)
], BaseButtonMiniActionImplementation.prototype, "tooltip", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: 'left'
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], BaseButtonMiniActionImplementation.prototype, "tooltipPosition", void 0);
BaseButtonMiniActionImplementation = __decorate([
    Component
], BaseButtonMiniActionImplementation);
export default BaseButtonMiniActionImplementation;
