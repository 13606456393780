var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-left": "125"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('c-icon-fa', _vm._g({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showSessionTimeout,
            expression: "showSessionTimeout"
          }],
          staticClass: "session_timeout bounce",
          attrs: {
            "icon-color": "var(--v-error-base)",
            "icon": _vm.$icons.warning,
            "tooltip": "LaunchPad session timeout detected"
          }
        }, on))];
      }
    }])
  }, [_c('v-list', {
    staticClass: "mt-2"
  }, [_c('v-list-tile', {
    on: {
      "click": _vm.onClickOfReload
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.reload
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Reload window")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };