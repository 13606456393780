var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.HARDWARE_LIST,
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('MiniHardwareList', {
          staticClass: "mb-1",
          attrs: {
            "title": "Adding to this List",
            "id": _vm.parentHardwareListId
          }
        }), _c('SelectorHardwareListAssembly', {
          model: {
            value: _vm.selectedHardwareListAssembly,
            callback: function ($$v) {
              _vm.selectedHardwareListAssembly = $$v;
            },
            expression: "selectedHardwareListAssembly"
          }
        }), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.selectedHardwareListAssembly && _vm.showAdditionalMetadata ? _c('AppFormWrapper', {
          attrs: {
            "size": "1"
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "clearable": "",
            "label": "Addendum number"
          },
          model: {
            value: _vm.selectedHardwareListAssembly.addendumNumber,
            callback: function ($$v) {
              _vm.$set(_vm.selectedHardwareListAssembly, "addendumNumber", $$v);
            },
            expression: "selectedHardwareListAssembly.addendumNumber"
          }
        })], 1)], 1)], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Addendum"
          },
          model: {
            value: _vm.selectedHardwareListAssembly.addendumDate,
            callback: function ($$v) {
              _vm.$set(_vm.selectedHardwareListAssembly, "addendumDate", $$v);
            },
            expression: "selectedHardwareListAssembly.addendumDate"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('HardwareListNoteDdl', {
          staticClass: "mt-3",
          attrs: {
            "multiple": ""
          },
          model: {
            value: _vm.selectedHardwareListAssembly.notes,
            callback: function ($$v) {
              _vm.$set(_vm.selectedHardwareListAssembly, "notes", $$v);
            },
            expression: "selectedHardwareListAssembly.notes"
          }
        })], 1)], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isFormValid,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v("Add to List")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };