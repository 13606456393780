var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.formData.inventory && !_vm.externalInputFormIsValid ? _c('ButtonGeneric', {
    staticClass: "ml-0",
    class: {
      'item_instances white--text mb-3': _vm.showingExternalForm
    },
    on: {
      "click": _vm.onClickOfManualModeToggle
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": "",
      "group": ""
    }
  }, [!_vm.showingExternalForm ? _c('c-icon-fa', {
    key: "cursor",
    staticClass: "mr-2",
    attrs: {
      "icon": "fal fa-i-cursor",
      "icon-color": "white"
    }
  }) : _c('c-icon-fa', {
    key: "search",
    staticClass: "mr-2",
    attrs: {
      "icon": "fal fa-search",
      "icon-color": "white"
    }
  })], 1), _vm.showingExternalForm ? _c('span', [_vm._v("Search " + _vm._s(_vm.currentSystemName) + " Inventory")]) : _c('span', [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Hardware input")])], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [!_vm.formData.inventory && !_vm.showingExternalForm ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('SearchInventory', {
    attrs: {
      "output-mode": "object"
    },
    model: {
      value: _vm.formData.inventory,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "inventory", $$v);
      },
      expression: "formData.inventory"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showingExternalForm,
      expression: "showingExternalForm"
    }],
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Hardware")])], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('AppCheckbox', _vm._g({
          attrs: {
            "label": "Is this a Tool?"
          },
          model: {
            value: _vm.formData.externalIsTool,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "externalIsTool", $$v);
            },
            expression: "formData.externalIsTool"
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v("This changes form requirements.")])])], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Description",
      "c-value": _vm.formData.externalDescription,
      "required": _vm.formData.externalIsTool
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalDescription = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Drawing number",
      "c-value": _vm.formData.externalDrawingNumber,
      "required": !_vm.formData.externalIsTool
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalDrawingNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "AsBuilt",
      "c-value": _vm.formData.externalAsBuiltNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalAsBuiltNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Serial",
      "c-value": _vm.formData.externalSerialNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalSerialNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Lot",
      "c-value": _vm.formData.externalLotNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalLotNumber = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-2": ""
    }
  }, [_c('c-select', {
    attrs: {
      "chips": false,
      "label": "Class",
      "sort": ""
    },
    domProps: {
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemInstanceInventoryTypeWithoutMultiple,
      "cValue": _vm.formData.externalClass
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalClass = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "Calibration number",
      "c-value": _vm.formData.externalCalibrationNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalCalibrationNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": "",
      "pr-2": ""
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "CEI number",
      "c-value": _vm.formData.externalContractEndingItemNumber
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalContractEndingItemNumber = $event.detail;
      }
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "my-2": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Birth"
    },
    model: {
      value: _vm.formData.externalBirthDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "externalBirthDate", $$v);
      },
      expression: "formData.externalBirthDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "label": "Calibration"
    },
    model: {
      value: _vm.formData.externalCalibrationDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "externalCalibrationDate", $$v);
      },
      expression: "formData.externalCalibrationDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Usage life expiry"
    },
    model: {
      value: _vm.formData.externalUsageLifeDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "externalUsageLifeDate", $$v);
      },
      expression: "formData.externalUsageLifeDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "label": "Shelf life expiry"
    },
    model: {
      value: _vm.formData.externalShelfLifeDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "externalShelfLifeDate", $$v);
      },
      expression: "formData.externalShelfLifeDate"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({}, on), [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [Boolean(_vm.formData.externalSerialNumber) ? _c('AppBlockquote', {
          attrs: {
            "title": "Qty"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v("1")])]) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.formData.externalSerialNumber ? _c('AppNumber', {
          staticClass: "quantity-input",
          attrs: {
            "label": "Qty",
            "required": ""
          },
          model: {
            value: _vm.formData.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "quantity", $$v);
            },
            expression: "formData.quantity"
          }
        }) : _vm._e()], 1)], 1)];
      }
    }])
  }, [Boolean(_vm.formData.externalSerialNumber) ? _c('span', [_vm._v("Quantity locked at 1 due to serialization.")]) : _c('span', [_vm._v("Must be a non-zero number.")])])], 1)], 1)], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Location",
      "c-value": _vm.formData.externalLocation,
      "color": _vm.EntityType.LOCATION
    },
    on: {
      "c-input": function ($event) {
        _vm.formData.externalLocation = $event.detail;
      }
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.value && !_vm.value.isExternal ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.formData.inventory ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.inventoryNodeId
    }
  }), _c('ButtonClearSelection', {
    staticClass: "my-2",
    on: {
      "click": _vm.reset
    }
  }), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "nudge-top": "40"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', _vm._g({}, on), [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [!_vm.formData.inventory.serialNumber ? _c('AppNumber', {
          staticClass: "quantity-input",
          attrs: {
            "min": 1,
            "label": "Qty",
            "required": ""
          },
          model: {
            value: _vm.formData.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "quantity", $$v);
            },
            expression: "formData.quantity"
          }
        }) : _c('AppNumber', {
          staticClass: "quantity-input",
          attrs: {
            "min": 1,
            "label": "Qty",
            "disabled": ""
          },
          model: {
            value: _vm.formData.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "quantity", $$v);
            },
            expression: "formData.quantity"
          }
        })], 1)], 1)];
      }
    }], null, false, 2103987428)
  }, [_c('span', [_vm._v("Must be a non-zero number.")])])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };