import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { queryFactory } from '~/nasa_ui/DAL/base/mixinFactories';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { CosmicIntervalFragment } from '../base/interval';
import { CosmicItemDrawingSlimFragment } from '../itemDrawing/slim';
import { CosmicItemMasterSlimFragment } from '../itemMaster/slim';
import { CosmicLimitedLifeQuotaFragment, CosmicLimitedLifeRemainingFragment } from '../limitedLife';
import { CosmicItemInstanceFragment } from './slim';
export const CosmicItemInstanceWithLimitedLifeFragment = gql `
  ${CosmicItemInstanceFragment}
  ${CosmicLimitedLifeQuotaFragment}
  ${CosmicLimitedLifeRemainingFragment}
  ${CosmicIntervalFragment}

  fragment CosmicItemInstanceWithLimitedLife on ItemInstance {
    ...CosmicItemInstance

    computedLastServiceDate
    computedLastInspectionDate
    hasLimitedLife
    hasLimitedLifeProperties
    hasLimitedLifeErrors
    hasLimitedLifeEva: hasLimitedLife(limitedLifeType: [EVA])
    hasLimitedLifeCycle: hasLimitedLife(limitedLifeType: [CYCLE])
    hasLimitedLifeShelf: hasLimitedLife(limitedLifeType: [SHELF])
    hasLimitedLifeUsage: hasLimitedLife(limitedLifeType: [USAGE])
    hasLimitedLifeFlight: hasLimitedLife(limitedLifeType: [FLIGHT])
    hasLimitedLifeStatic: hasLimitedLife(limitedLifeType: [STATIC])
    hasLimitedLifeOnOrbit: hasLimitedLife(limitedLifeType: [ON_ORBIT])
    hasLimitedLifeInterval: hasLimitedLife(limitedLifeType: [INTERVAL])
    hasLimitedLifeOperation: hasLimitedLife(limitedLifeType: [OPERATION])
    hasLimitedLifePressurizedTime: hasLimitedLife(limitedLifeType: [PRESSURIZED_TIME])
    inheritLimitedLifeFromMaster

    computedMannedPressurizedTime {
      ...CosmicInterval
    }
    computedUnmannedPressurizedTime {
      ...CosmicInterval
    }
    computedMannedPressurizedTimeSinceLastServ {
      ...CosmicInterval
    }
    limitedLife {
      ...CosmicLimitedLifeQuota
    }
    limitedLifeRemaining {
      ...CosmicLimitedLifeRemaining
    }
  }
`;
export const GetItemInstanceById = gql `
  ${CosmicItemInstanceFragment}

  query GetItemInstanceById($id: UUID!) {
    itemInstanceById(id: $id) {
      ...CosmicItemInstance
    }
  }
`;
export const GetItemInstanceByIdWithOptionalHardware = gql `
  ${CosmicItemInstanceFragment}
  ${CosmicItemDrawingSlimFragment}
  ${CosmicItemMasterSlimFragment}

  query GetItemInstanceByIdWithOptionalHardware(
    $id: UUID!
    $shouldFetchItemDrawing: Boolean = false
    $shouldFetchItemMaster: Boolean = false
  ) {
    itemInstanceById(id: $id) {
      ...CosmicItemInstance

      itemDrawing @include(if: $shouldFetchItemDrawing) {
        ...CosmicItemDrawingSlim
      }

      itemMaster @include(if: $shouldFetchItemMaster) {
        ...CosmicItemMasterSlim
      }
    }
  }
`;
export const GetItemInstanceByNodeId = gql `
  ${CosmicItemInstanceFragment}

  query GetItemInstanceByNodeId($nodeId: ID!) {
    itemInstance(nodeId: $nodeId) {
      ...CosmicItemInstance
    }
  }
`;
export const GetItemInstanceByNodeIdWithLimitedLife = gql `
  ${CosmicItemInstanceWithLimitedLifeFragment}

  query GetItemInstanceByNodeIdWithLimitedLife($nodeId: ID!) {
    itemInstance(nodeId: $nodeId) {
      ...CosmicItemInstanceWithLimitedLife
    }
  }
`;
let ItemInstanceRepoMixin = class ItemInstanceRepoMixin extends Mixins(LoaderMixin) {
    getItemInstanceById = queryFactory(this, GetItemInstanceById);
    getItemInstanceByNodeId = queryFactory(this, GetItemInstanceByNodeId);
    getItemInstanceByIdWithOptionalHardware = queryFactory(this, GetItemInstanceByIdWithOptionalHardware);
    getItemInstanceByNodeIdWithLimitedLife = queryFactory(this, GetItemInstanceByNodeIdWithLimitedLife);
};
ItemInstanceRepoMixin = __decorate([
    Component
], ItemInstanceRepoMixin);
export { ItemInstanceRepoMixin };
