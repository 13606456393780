var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { UserSupportContextToValidLinkTypes } from '~/application.config';
import { LinkType, Maybe } from '~/db_types';
import BaseModalWithUser from '~/nasa_ui/base/BaseModalWithUser';
import { CosmicLinkFragment as CosmicLinkFragmentQuery } from '~/nasa_ui/DAL/link';
import { IItemDrawingProp, IItemInstanceProp, IItemMasterProp } from '~/nasa_ui/mixins/EntityLinksMixin';
import { LinksTypeDisplay } from '~/nasa_ui/types/enums/links';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
let ModalLinkBase = class ModalLinkBase extends BaseModalWithUser {
    selectedLink = null;
    formData = {
        currentRevision: null,
        currentVersionUrl: null,
        referenceNumber: null,
        subType: LinkType.OTHER,
        workingVersionUrl: null
    };
    id;
    documentId;
    eventId;
    itemDrawing;
    itemInstance;
    itemMaster;
    riskId;
    get computedSelectOptions() {
        const linkTypes = UserSupportContextToValidLinkTypes.get(this.currentUserActiveSupportContext);
        return linkTypes
            ? linkTypes.map((linkType) => {
                return {
                    displayText: LinksTypeDisplay.get(linkType),
                    value: linkType
                };
            })
            : [];
    }
    // require at least one field
    get isFormValid() {
        const { subType, ...props } = this.formData;
        return Boolean(Object.values(props).some((p) => Boolean(p)) && subType);
    }
    async fetchEntity(id) {
        if (!id) {
            return;
        }
        const resp = await this.$apollo.query({
            query: gql `
        ${CosmicLinkFragmentQuery}

        query fetchLinkLink($id: UUID!) {
          linkById(id: $id) {
            ...CosmicLink
          }
        }
      `,
            variables: {
                id: id
            }
        });
        if (resp.data.linkById) {
            this.selectedLink = resp.data.linkById;
            this.formData = {
                currentRevision: this.selectedLink?.currentRevision || null,
                currentVersionUrl: this.selectedLink?.currentVersionUrl || null,
                referenceNumber: this.selectedLink?.referenceNumber || null,
                subType: this.selectedLink?.subType || LinkType.OTHER,
                workingVersionUrl: this.selectedLink?.workingVersionUrl || null
            };
        }
    }
    /**
     * if the passed object has any of the listed properties then we CANT create it with api2
     *
     * @param linkObject
     */
    canUseApi2(linkObject) {
        // This should be better typed but ¯\_(ツ)_/¯ ModalCommentBase has an identical method
        //  equally poorly typed
        const api2NoNoFk = [
            'activityId',
            'documentId',
            'itemDrawingNodeId',
            'itemMasterNodeId',
            'itemInstanceNodeId'
        ];
        return Object.keys(linkObject)
            .filter((key) => api2NoNoFk.includes(key))
            .every((key) => isNullOrUndefined(linkObject[key]));
    }
    emitModalClose() { }
    emitReloadExternalLinks() { }
};
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], ModalLinkBase.prototype, "id", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalLinkBase.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalLinkBase.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_b = typeof IItemDrawingProp !== "undefined" && IItemDrawingProp) === "function" ? _b : Object)
], ModalLinkBase.prototype, "itemDrawing", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_c = typeof IItemInstanceProp !== "undefined" && IItemInstanceProp) === "function" ? _c : Object)
], ModalLinkBase.prototype, "itemInstance", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_d = typeof IItemMasterProp !== "undefined" && IItemMasterProp) === "function" ? _d : Object)
], ModalLinkBase.prototype, "itemMaster", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalLinkBase.prototype, "riskId", void 0);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkBase.prototype, "emitModalClose", null);
__decorate([
    Emit('reloadExternalLinks'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkBase.prototype, "emitReloadExternalLinks", null);
ModalLinkBase = __decorate([
    Component
], ModalLinkBase);
export default ModalLinkBase;
