import { __decorate, __metadata } from "tslib";
import flatten from 'lodash/flatten';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { UserRole } from '~/db_types';
import { OrganizationType } from '~/db_types/swagger_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { BASE_TEXT_COL, DESCRIPTION_COL, NAME_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EmailNotificationRepoMixin } from '~/nasa_ui/DAL/email';
import { UserRepoMixin } from '~/nasa_ui/DAL/user';
import { AlertType } from '~/nasa_ui/types';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformAutoHardwareListResponses } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabAdminCrud = class TabAdminCrud extends Mixins(BaseTab, UserRepoMixin, EmailNotificationRepoMixin) {
    autoHardwareLists = null;
    fileAccepted = false;
    isSaving = false;
    OrganizationType = OrganizationType;
    rawResult = null;
    selectedAutoHardwareList = null;
    showCreateAutoHardwareListModal = false;
    showEditAutoHardwareListModal = false;
    showImportUserSpreadsheetModal = false;
    usersToCreate = [];
    emailFormData = {
        data: {},
        messageTextTemplate: '',
        messageHtmlTemplate: '',
        recipientContactId: [],
        subjectTemplate: ''
    };
    formDataOrganizationCreate = {
        code: null,
        subType: OrganizationType.PROCESSOR,
        name: null,
        attributes: {},
        isCageCode: false,
        isActive: true
    };
    formDataAutoHardwareList = {
        description: null,
        drawingNumberPrefix: null,
        name: null
    };
    importUsersTableHeader = [
        {
            text: 'AUID',
            value: 'auid'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'Last Name',
            value: 'lastName'
        },
        {
            text: 'First Name',
            value: 'firstName'
        }
    ];
    tableHeadersAutoHardwareList = [
        NAME_COL,
        DESCRIPTION_COL,
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Drawing number<br/>prefixes',
            value: '_drawingNumberPrefixes'
        })
    ];
    get allParsedUsers() {
        return (this.rawResult || []).map(([auid, email, lastName, firstName], index) => {
            return {
                auid: auid.toUpperCase(),
                email: [email],
                firstName: firstName?.trim(),
                index,
                lastName,
                middleName: null,
                organizationCodes: [this.currentUserActiveSupportContext],
                role: UserRole.USER
            };
        });
    }
    get availableContexts() {
        return this.currentUserSupportContexts;
    }
    get disableEmailTestButton() {
        return (!this.emailFormData.messageHtmlTemplate ||
            !this.emailFormData.subjectTemplate ||
            !this.emailFormData.recipientContactId.length);
    }
    get hasAvailableContexts() {
        return Boolean(this.availableContexts?.length);
    }
    get tableItemsAutoHardwareList() {
        return this.autoHardwareLists ? transformAutoHardwareListResponses(this.autoHardwareLists) : [];
    }
    created() {
        this.emailFormData.recipientContactId = [this.currentUser?.contactId];
        this.fetchAutoHardwareLists();
    }
    async createUsers() {
        try {
            this.$notification.add({
                text: `Checking users for duplicates....`,
                type: AlertType.INFO
            });
            const existingUsers = await this.checkUsersForExistence(this.usersToCreate);
            const existingUserAuids = (existingUsers?.users?.nodes || []).map((user) => user?.auid);
            const usersToCreate = this.usersToCreate.filter((user) => {
                // see if any emails match any of the existing emails
                const matchBasedOnEmail = user.email?.find((email) => {
                    return (existingUsers?.users?.nodes || []).find((user) => user?.email?.find((e) => e === email));
                });
                return (user.auid && !existingUserAuids.includes(user.auid)) || !matchBasedOnEmail;
            });
            if (usersToCreate.length > 0) {
                const resp = await Promise.all(usersToCreate.map((user) => {
                    return this.httpPost('/users', user);
                }));
                if (resp) {
                    this.$notification.add({
                        text: `${resp.length} users created.`,
                        type: AlertType.SUCCESS
                    });
                }
                this.reset();
            }
            else {
                setTimeout(() => {
                    this.$notification.add({
                        text: `All selected users currently exist.`,
                        type: AlertType.WARNING
                    });
                }, 2000);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchAutoHardwareLists() {
        try {
            const params = {
                take: -1
            };
            const resp = await this.$http.get(`auto-hardware-lists`, {
                params
            });
            this.autoHardwareLists = resp.data.results || [];
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfCreatProcessorOrganization() {
        if (!this.formDataOrganizationCreate.code || !this.formDataOrganizationCreate.name) {
            return;
        }
        try {
            const body = {
                attributes: {},
                code: this.formDataOrganizationCreate.code,
                isActive: true,
                name: this.formDataOrganizationCreate.name
            };
            const resp = await this.$http.post(`/organizations/support-contexts`, body);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Support ctx created.'
            });
            this.formDataOrganizationCreate.code = null;
            this.formDataOrganizationCreate.name = null;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    launchCreateAutoHardwareListModal() {
        this.showCreateAutoHardwareListModal = true;
    }
    launchEditAutoHardwareList(item) {
        this.showEditAutoHardwareListModal = true;
        this.selectedAutoHardwareList = item;
        this.formDataAutoHardwareList.description = this.selectedAutoHardwareList.description;
        this.formDataAutoHardwareList.drawingNumberPrefix = this.selectedAutoHardwareList.drawingNumberPrefix;
        this.formDataAutoHardwareList.name = this.selectedAutoHardwareList.name;
    }
    async onClickOfCreateNewAutoHardwareList() {
        try {
            this.isSaving = true;
            const reqBody = {
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                name: this.formDataAutoHardwareList.name,
                description: this.formDataAutoHardwareList.description
            };
            const resp = await this.$http.post(`/auto-hardware-lists`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfSaveAutoHardwareList() {
        if (!this.selectedAutoHardwareList) {
            return;
        }
        try {
            this.isSaving = true;
            const reqBody = {
                drawingNumberPrefix: this.formDataAutoHardwareList.drawingNumberPrefix,
                name: this.formDataAutoHardwareList.name,
                description: this.formDataAutoHardwareList.description
            };
            const resp = await this.$http.put(`/auto-hardware-lists/${this.selectedAutoHardwareList.id}`, reqBody);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Updated.'
            });
            this.isSaving = false;
            this.resetAutoHardwareList();
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async onClickOfDeleteAutoHardwareList(item) {
        // delete it
        try {
            const resp = await this.$http.delete(`/auto-hardware-lists/${item.id}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Deleted.'
            });
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
        this.resetAutoHardwareList();
    }
    resetAutoHardwareList() {
        this.showCreateAutoHardwareListModal = false;
        this.showEditAutoHardwareListModal = false;
        this.selectedAutoHardwareList = null;
        this.formDataAutoHardwareList = {
            description: null,
            drawingNumberPrefix: null,
            name: null
        };
        this.fetchAutoHardwareLists();
    }
    // Called from AppFileUpload @input
    onUserImportFileSelected(file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const strResult = reader.result;
            this.rawResult = strResult
                .split('\n')
                .map((row) => row.split(','))
                .slice(1);
            this.fileAccepted = true;
        };
    }
    reset() {
        this.emailFormData.messageHtmlTemplate = '';
        this.emailFormData.recipientContactId = [];
        this.emailFormData.subjectTemplate = '';
        this.fileAccepted = false;
        this.isSaving = false;
        this.showImportUserSpreadsheetModal = false;
        this.usersToCreate = [];
    }
    checkUsersForExistence(users) {
        return this.getUsersByAuidOrEmail({
            userAuids: users.map((user) => user.auid),
            emails: flatten(users.map((user) => user.email))
        });
    }
    async sendEmail() {
        try {
            const resp = await this.createEmailNotification({
                emailDetails: this.emailFormData
            });
            if (resp?.createNotification?.notification) {
                this.$notification.add({
                    text: `Email sent to human(s).`,
                    type: AlertType.SUCCESS
                });
                this.reset();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onShowImportUserSpreadsheetModalChange() {
        if (!this.showImportUserSpreadsheetModal) {
            this.reset();
        }
    }
};
__decorate([
    Watch('showImportUserSpreadsheetModal'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabAdminCrud.prototype, "onShowImportUserSpreadsheetModalChange", null);
TabAdminCrud = __decorate([
    Component
], TabAdminCrud);
export default TabAdminCrud;
