import { InventoryMethod } from '~/db_types';
export const InventoryMethodDisplay = new Map([
    [InventoryMethod.ARTICLE, 'Article'],
    [InventoryMethod.BAG, 'Bag'],
    [InventoryMethod.BOTTLE, 'Bottle'],
    [InventoryMethod.BOX, 'Box'],
    [InventoryMethod.BUNDLE, 'Bundle'],
    [InventoryMethod.CAN, 'Can'],
    [InventoryMethod.CARD, 'Card'],
    [InventoryMethod.CARTON, 'Carton'],
    [InventoryMethod.CASE, 'Case'],
    [InventoryMethod.CONTAINER, 'Container'],
    [InventoryMethod.CYLINDER, 'Cylinder'],
    [InventoryMethod.DOZEN, 'Dozen'],
    [InventoryMethod.DRUM, 'Drum'],
    [InventoryMethod.EACH, 'Ea'],
    [InventoryMethod.GROSS, 'Gross'],
    [InventoryMethod.JAR, 'Jar'],
    [InventoryMethod.JOB, 'Job'],
    [InventoryMethod.KIT, 'Kit'],
    [InventoryMethod.LOT, 'Lot'],
    [InventoryMethod.PACKAGE, 'Pkg'],
    [InventoryMethod.PAD, 'Pad'],
    [InventoryMethod.PAGE, 'Page'],
    [InventoryMethod.PAIL, 'Pail'],
    [InventoryMethod.PAIR, 'Pair'],
    [InventoryMethod.PER_THOUSAND, 'per 1000'],
    [InventoryMethod.PIECE, 'Piece'],
    [InventoryMethod.REAM, 'Ream'],
    [InventoryMethod.REEL, 'Reel'],
    [InventoryMethod.ROD, 'Rod'],
    [InventoryMethod.ROLL, 'Roll'],
    [InventoryMethod.SET, 'Set'],
    [InventoryMethod.SHEET, 'Sheet'],
    [InventoryMethod.SPOOL, 'Spool'],
    [InventoryMethod.TUBE, 'Tube'],
    [InventoryMethod.UNIT, 'Unit'],
    [InventoryMethod.VIAL, 'Vial']
]);
