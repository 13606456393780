import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass } from '~/db_types/swagger_types';
import BaseSelector from '~/nasa_ui/base/BaseSelector';
import { EntityType, ItemClassesWithoutMultiple, ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types';
let SelectorHardwareListAssembly = class SelectorHardwareListAssembly extends BaseSelector {
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    ItemInstanceInventoryTypeWithoutMultiple = ItemClassesWithoutMultiple;
    showingExternalForm = false;
    formData = {
        externalAsBuiltNumber: null,
        externalBirthDate: null,
        externalCalibrationDate: null,
        externalCalibrationNumber: null,
        externalContractEndingItemNumber: null,
        externalClass: InventoryClass.UNKNOWN,
        externalDescription: null,
        externalDrawingNumber: null,
        externalIsTool: false,
        externalLocation: null,
        externalLotNumber: null,
        externalSerialNumber: null,
        externalShelfLifeDate: null,
        externalUsageLifeDate: null,
        isExternal: false,
        quantity: 1,
        inventory: null,
        sequence: null
    };
    value;
    get externalInputFormHasRequiredFields() {
        return this.formData.externalIsTool
            ? Boolean(this.formData.externalDescription && this.formData.quantity)
            : Boolean(this.formData.externalDrawingNumber && this.formData.quantity);
    }
    get externalInputFormIsValid() {
        return Boolean(this.externalInputFormHasRequiredFields);
    }
    get isFormValid() {
        if (this.formData.inventory) {
            return Boolean(this.formData.quantity);
        }
        return this.formData.externalIsTool
            ? Boolean(this.formData.externalDescription && this.formData.quantity)
            : Boolean(this.formData.externalDrawingNumber && this.formData.quantity);
    }
    // For the AHD
    get inventoryNodeId() {
        if (!this.formData.inventory) {
            return null;
        }
        const nodeId = btoa(`["${EntityType.INVENTORY}","${this.formData.inventory.id}"]`);
        return this.formData.inventory?.id ? nodeId : null;
    }
    onClickOfManualModeToggle() {
        this.showingExternalForm = !this.showingExternalForm;
        if (!this.showingExternalForm) {
            this.reset();
        }
    }
    reset() {
        this.formData = {
            externalAsBuiltNumber: null,
            externalBirthDate: null,
            externalCalibrationDate: null,
            externalCalibrationNumber: null,
            externalContractEndingItemNumber: null,
            externalClass: undefined,
            externalDescription: null,
            externalDrawingNumber: null,
            externalIsTool: false,
            externalLocation: null,
            externalLotNumber: null,
            externalSerialNumber: null,
            externalShelfLifeDate: null,
            externalUsageLifeDate: null,
            isExternal: false,
            quantity: 1,
            inventory: null,
            sequence: 0
        };
        this.emitInput(this.formData);
    }
    emitInput(val) {
        return val;
    }
    onExternalFormUpdate() {
        if (this.externalInputFormIsValid) {
            this.formData.isExternal = true;
            this.formData.inventory = null;
        }
        this.emitInput(this.formData);
    }
    onInventorySelection() {
        this.formData.isExternal = Boolean(!this.formData.inventory);
        this.formData.inventoryId = this.formData.inventory?.id ?? null;
        this.emitInput(this.formData);
    }
    onValueChange(val) {
        if (!val) {
            return;
        }
        if (val.externalDrawingNumber) {
            this.showingExternalForm = true;
        }
        this.formData = val;
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], SelectorHardwareListAssembly.prototype, "value", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectorHardwareListAssembly.prototype, "emitInput", null);
__decorate([
    Watch('formData.externalAsBuiltNumber'),
    Watch('formData.externalBirthDate'),
    Watch('formData.externalCalibrationDate'),
    Watch('formData.externalCalibrationNumber'),
    Watch('formData.externalContractEndingItemNumber'),
    Watch('formData.externalClass'),
    Watch('formData.externalDescription'),
    Watch('formData.externalDrawingNumber'),
    Watch('formData.externalIsTool'),
    Watch('formData.externalLocation'),
    Watch('formData.externalLotNumber'),
    Watch('formData.externalSerialNumber'),
    Watch('formData.externalShelfLifeDate'),
    Watch('formData.externalUsageLifeDate'),
    Watch('formData.quantity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorHardwareListAssembly.prototype, "onExternalFormUpdate", null);
__decorate([
    Watch('formData.inventory'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SelectorHardwareListAssembly.prototype, "onInventorySelection", null);
__decorate([
    Watch('value', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectorHardwareListAssembly.prototype, "onValueChange", null);
SelectorHardwareListAssembly = __decorate([
    Component
], SelectorHardwareListAssembly);
export default SelectorHardwareListAssembly;
