import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
let ButtonChangeSet = class ButtonChangeSet extends BaseButton {
    get computedColor() {
        return this.color || this.EntityType.CHANGE_SET;
    }
    get computedIcon() {
        return this.icon || this.$icons[this.EntityType.CHANGE_SET];
    }
    get computedTextColor() {
        return this.textColor || '#fff';
    }
};
ButtonChangeSet = __decorate([
    Component
], ButtonChangeSet);
export default ButtonChangeSet;
