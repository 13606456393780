var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "entity-type": _vm.EntityType.ITEM_INSTANCE,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.ITEM_INSTANCE,
      "entity-type": _vm.EntityType.ITEM_INSTANCE,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Drawing number"
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "AsBuilt"
    },
    model: {
      value: _vm.searchFilters.asBuiltNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "asBuiltNumber", $$v);
      },
      expression: "searchFilters.asBuiltNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppSelectSerial', {
    attrs: {
      "disabled": _vm.isFilterLocked('serialNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.serialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "serialNumber", $$v);
      },
      expression: "searchFilters.serialNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppSelectLot', {
    attrs: {
      "disabled": _vm.isFilterLocked('lotNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.lotNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "lotNumber", $$v);
      },
      expression: "searchFilters.lotNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppSelectProjectCode', {
    attrs: {
      "allow-create": false,
      "clearable": "",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.projectCode,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "projectCode", $$v);
      },
      expression: "searchFilters.projectCode"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mb-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('c-select', {
    attrs: {
      "chip-color": `var(--v-${_vm.EntityType.ITEM_INSTANCE}-base`,
      "chip-text-color": "white",
      "label": "Class",
      "multiple": "",
      "sort": ""
    },
    domProps: {
      "cValue": _vm.searchFilters.subType,
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemInstanceInventoryType
    },
    on: {
      "c-input": function ($event) {
        _vm.searchFilters.subType = $event.detail;
      }
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "mr-3": "",
      "mb-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "label": "Side",
      "enum": _vm.Side,
      "display": _vm.HandDominanceDisplay,
      "sort": false,
      "chips": false,
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.side,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "side", $$v);
      },
      expression: "searchFilters.side"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "label": "In-Inventory only"
    },
    model: {
      value: _vm.inInventoryLocal,
      callback: function ($$v) {
        _vm.inInventoryLocal = $$v;
      },
      expression: "inInventoryLocal"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }]
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "headers": _vm.ItemInstancesTableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": "",
      "disable-initial-sort": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };