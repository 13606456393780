import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
export var AppDynamicWrapperType;
(function (AppDynamicWrapperType) {
    AppDynamicWrapperType["FORM_WRAPPER"] = "FORM_WRAPPER";
    AppDynamicWrapperType["EXPANSION_PANEL"] = "EXPANSION_PANEL";
    AppDynamicWrapperType["NONE"] = "NONE";
})(AppDynamicWrapperType || (AppDynamicWrapperType = {}));
let AppDynamicWrapper = class AppDynamicWrapper extends Vue {
    AppDynamicWrapperType = AppDynamicWrapperType;
    displayType;
    title;
    get isTypeFormWrapper() {
        return this.displayType === AppDynamicWrapperType.FORM_WRAPPER;
    }
    get isTypeExpansionPanel() {
        return this.displayType === AppDynamicWrapperType.EXPANSION_PANEL;
    }
    get isTypeNone() {
        return this.displayType === AppDynamicWrapperType.NONE;
    }
};
__decorate([
    Prop({
        type: String,
        default: AppDynamicWrapperType.FORM_WRAPPER
    }),
    __metadata("design:type", String)
], AppDynamicWrapper.prototype, "displayType", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppDynamicWrapper.prototype, "title", void 0);
AppDynamicWrapper = __decorate([
    Component
], AppDynamicWrapper);
export default AppDynamicWrapper;
