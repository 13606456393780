import gql from 'graphql-tag';
import { apolloClient } from '~/nasa_ui/apollo.config';
import { CosmicUserSupportContext, LogType } from '~/nasa_ui/types';
import { getActiveSupportContext } from '../mixins/CurrentUserMixin';
import { store } from '../store';
/**
 * Helper function to return browser information.
 * Source: https://stackoverflow.com/a/5918791/7977844
 *
 * @return string - browser name and version. Ex: "Chrome 80"
 */
function sayswho() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
        if (tem != null)
            return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
        M.splice(1, 1, tem[1]);
    return M.join(' ');
}
/**
 * Helper function that turns an Error object into a JSON.stringify-able object.
 *
 * Based on: https://stackoverflow.com/a/53624454/7977844
 *
 * @return object
 */
function jsonFriendlyErrorReplacer(value) {
    if (value instanceof Error) {
        return {
            // Explicitly pull Error's non-enumerable properties
            name: value.name,
            message: value.message,
            stack: value.stack
        };
    }
    return null;
}
const LAUNCHPAD_ERROR_SLUG = 'Error: Network error: Unexpected token';
/**
 * The Log module is used to save log message to the DB. It supports three types
 * of log information:
 *
 * - Info (Log.info)
 * - Error (Log.error)
 * - Telemetry (Log.telemetry)
 */
export default class Log {
    orgNodeIds = new Map([
        [CosmicUserSupportContext.ESOC, 'WyJvcmdhbml6YXRpb25zIiwiRVNPQyJd'],
        [CosmicUserSupportContext.xEMU, 'WyJvcmdhbml6YXRpb25zIiwieEVNVSJd'],
        [CosmicUserSupportContext.CTSD, 'WyJvcmdhbml6YXRpb25zIiwiQ1RTRCJd']
    ]);
    /**
     * Runs the create mutation.
     *
     * @param input - ErrorLogInput
     */
    createErrorLogMutation(input) {
        try {
            return apolloClient.mutate({
                mutation: gql `
          mutation CreateErrorLogForLog($errorLog: ErrorLogInput!) {
            createErrorLog(input: { errorLog: $errorLog }) {
              errorLog {
                nodeId
              }
            }
          }
        `,
                variables: {
                    errorLog: input
                }
            });
        }
        catch (err) {
            return null;
        }
    }
    /**
     * Constructs and returns (a promise for) an ILogInput object with props that
     * are common to all three log types.
     *
     * @param subType - LogType (INFO | ERROR | TELEMETRY)
     */
    async getBaseInput(subType) {
        // get support context (aka org code) from localStorage
        const orgCode = getActiveSupportContext();
        if (!orgCode) {
            console.error('Failed to get organization code.');
            return null;
        }
        // get the user object from localStorage
        const userObj = store.currentUser;
        const userId = userObj?.id;
        const browser = this.getBrowserInfo();
        if (!userId) {
            console.error('Failed to get current user.');
            return null;
        }
        return {
            subType: subType,
            organizationCode: orgCode,
            attributes: {
                browser
            }
        };
    }
    /**
     * Uses an Organization Code to query for an Organization's `nodeId`.
     *
     * @param code - Organization Code (CosmicUserSupportContext)
     */
    getOrganizationId(code) {
        return this.orgNodeIds.get(code);
    }
    /**
     * This method is used for logging Error objects.
     *
     * @param errorInput - { err: Error }
     */
    async error(errorInput) {
        const errString = errorInput.err.toString();
        // When the launchpad session times out it returns HTML here which throws the following error message
        if (errString.startsWith(LAUNCHPAD_ERROR_SLUG)) {
            return;
        }
        console.error(errString);
        // try/catch in case the await fails
        try {
            const input = await this.getBaseInput(LogType.ERROR);
            const error = jsonFriendlyErrorReplacer(errorInput.err);
            if (!input || !error) {
                console.error('Failed to log error.');
                return null;
            }
            input.attributes.err = error;
            input.attributes.fromUrl = window.location.href;
            return await this.createErrorLogMutation(input);
        }
        catch (err) {
            if (err instanceof Error) {
                const errString = err.toString();
                // When the launchpad session times out it returns HTML here which throws the following error message
                if (errString.startsWith(LAUNCHPAD_ERROR_SLUG)) {
                    return;
                }
            }
            return;
        }
    }
    /**
     * Helper method that gets browser name and version as a string and converts it to
     * an object as required by ILogInput.
     */
    getBrowserInfo() {
        const info = sayswho().split(' ');
        return {
            type: info[0],
            version: info[1]
        };
    }
    /**
     * This method is used for logging generic messages.
     *
     * @param infoInput - { message: any }
     */
    async info(infoInput) {
        // try/catch in case the await fails
        try {
            const input = await this.getBaseInput(LogType.INFO);
            if (!input) {
                console.error('Failed to log info.');
                return null;
            }
            input.attributes.message = infoInput.message;
            input.attributes.fromUrl = window.location.href;
            return await this.createErrorLogMutation(input);
        }
        catch (err) {
            return null;
        }
    }
    /**
     * This method is used for logging telemetry information.
     *
     * @param telemetryInput - { fromUrl: string, toUrl: string }
     */
    async telemetry(telemetryInput) {
        // try/catch in case the await fails
        try {
            const input = await this.getBaseInput(LogType.TELEMETRY);
            if (!input) {
                console.error('Failed to log telemetry.');
                return null;
            }
            input.attributes.fromUrl = telemetryInput.fromUrl;
            input.attributes.toUrl = telemetryInput.toUrl;
            if (telemetryInput.message) {
                input.attributes.message = telemetryInput.message;
            }
            return await this.createErrorLogMutation(input);
        }
        catch (err) {
            return null;
        }
    }
}
