var render = function render() {
  var _vm$value;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "cosmicContact"
  }, [_c('AppSelect', _vm._b({
    class: {
      selectedContact: (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.length
    },
    attrs: {
      "chip-color": `var(--v-${_vm.EntityType.CONTACT}-darken1)`,
      "label": _vm.computedLabel,
      "messages": _vm.computedMessages,
      "required": _vm.required,
      "select-options": _vm.contactSelectOptions,
      "value": _vm.value,
      "allow-create": "",
      "clearable": "",
      "create-text": "Contact",
      "item-name": "Contact",
      "multiple": ""
    },
    on: {
      "inlineClear": function ($event) {
        return _vm.emitInput([]);
      },
      "input": _vm.onSelectedContact,
      "option-create": _vm.onClickOfCreateNewContactAppSelect,
      "update:search-input": _vm.onSearchInput
    },
    scopedSlots: _vm._u([{
      key: "select-option",
      fn: function ({
        option
      }) {
        return [_vm._v(" " + _vm._s(_vm.$contactDisplay(option)) + " ("), _c('small', [_vm._v(_vm._s(option.email))]), _vm._v(") ")];
      }
    }, {
      key: "selected-chip-content",
      fn: function ({
        option
      }) {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('ButtonMiniAction', {
          attrs: {
            "icon": "fad fa-info-circle",
            "icon-color": "#fff",
            "tooltip": "View Contact information",
            "tooltip-position": "bottom left"
          },
          on: {
            "click": function ($event) {
              return _vm.onClickOfShowContactInfo(option);
            }
          }
        }), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s(option.displayText) + " ")]), _vm.shownContactInfo && _vm.shownContactInfo.value === option.value ? _c('div', {
          staticClass: "contactCard"
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "align-center": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "First Name",
            "value": _vm.shownContactInfo.firstName
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Middle Name",
            "value": _vm.shownContactInfo.middleName
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": "",
            "pr-3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Last Name",
            "value": _vm.shownContactInfo.lastName
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "color": _vm.EntityType.USER,
            "value": _vm.shownContactInfo.email
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v(" Email "), _c('ButtonMiniAction', {
                staticClass: "pl-2 copyEmail",
                attrs: {
                  "icon-color": "#fff",
                  "icon-size": "14px",
                  "icon": "far fa-copy",
                  "tooltip": "Copy email"
                },
                on: {
                  "click": function ($event) {
                    return _vm.copyEmail(option.value);
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }), _c('AppBlockquote', {
          attrs: {
            "title": "AUID",
            "value": _vm.shownContactInfo.auid,
            "upper-case": ""
          }
        })], 1)], 1), _c('ButtonCancel', {
          ref: "closeButton",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.shownContactInfo = null;
            }
          }
        }, [_vm._v("Close")])], 1) : _vm._e()], 1)];
      }
    }, {
      key: "after-input",
      fn: function () {
        return [!_vm.selectionIncludesYourself && !_vm.searchInput ? _c('ButtonMiniAction', {
          attrs: {
            "icon": _vm.$icons[_vm.EntityType.USER],
            "icon-color": "var(--v-users-base)",
            "icon-size": "24px",
            "tooltip": "Add yourself"
          },
          on: {
            "click": _vm.onClickOfYourself
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, 'AppSelect', {
    ..._vm.$props,
    ..._vm.$attrs
  }, false))], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showInlineCreateForm ? _c('AppFormWrapper', {
    staticClass: "mb-3",
    attrs: {
      "size": "1",
      "color": _vm.EntityType.USER
    }
  }, [_c('AppHeader', {
    attrs: {
      "show-horizontal-rule": false
    }
  }, [_vm._v(_vm._s(_vm.inlineFormTitle))]), _c('ContactCreateForm', {
    on: {
      "save": _vm.onNewContactAdded
    },
    scopedSlots: _vm._u([{
      key: "actions-append",
      fn: function ({
        isSaving
      }) {
        return [!isSaving ? _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showInlineCreateForm = false;
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 1988107284)
  })], 1) : _vm._e()], 1), _vm.showCreateContactModal ? _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.USER,
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCreateContactModal = false;
      }
    }
  }, [_c('template', {
    slot: "content"
  }, [_c('ContactCreateForm', {
    on: {
      "save": _vm.onNewContactAdded
    },
    scopedSlots: _vm._u([{
      key: "actions-append",
      fn: function ({
        isSaving
      }) {
        return [!isSaving ? _c('ButtonCancel', {
          on: {
            "click": function ($event) {
              _vm.showCreateContactModal = false;
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 2567403936)
  })], 1)], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };