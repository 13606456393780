import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType } from '~/nasa_ui/types';
let MiniHardwareListDynamicItem = class MiniHardwareListDynamicItem extends Mixins(BaseMini, HardwareListRepo) {
    externalInventory = null;
    showRemoveNonCosmicInventoryConfirmation = false;
    allowEdit;
    hardwareListItem;
    get inventoryEntity() {
        if (!this.isExternal) {
            return null;
        }
        return this.externalInventory;
    }
    get isEventAssembly() {
        return Boolean(this.hardwareListItem.eventAssemblyTemplateId);
    }
    get isExternal() {
        return Boolean(this.hardwareListItem.hardwareListAssemblyUuid);
    }
    get isHardwareListTemplate() {
        return Boolean(this.hardwareListItem.hardwareListTemplateSlotId && !this.isInventory);
    }
    get isInventory() {
        return Boolean(this.hardwareListItem.hardwareListInventoryId);
    }
    emitShowModalHardwareListEditItemModal(localEntity) {
        return localEntity;
    }
    // Called from ui
    async onRemoveNonCosmicInventory() {
        try {
            const uuid = this.externalInventory?.id; // this is the hardware list assy id
            if (!uuid) {
                return;
            }
            const resp = await this.deleteHardwareListAssemblyById({
                id: uuid
            });
            if (resp?.deleteHardwareListAssemblyById) {
                if (this.$route.params.id) {
                    this.$message(`reload_${this.$route.params.id}`); // keeping this for legacy reasons
                }
                this.emitDeleteNonCosmicInventory(); // emitting this new event to make the hardware list refresh
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: `Non-${this.currentSystemName} Inventory deleted.`
                });
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    emitDeleteNonCosmicInventory() { }
    onAddToHardwareListAtSequence(sequence) { }
    onChangeOfHardwareListItem(hardwareListItem) {
        if (!this.isExternal) {
            return;
        }
        this.fetchHardwareListAssembly(hardwareListItem.hardwareListAssemblyUuid);
    }
    async fetchHardwareListAssembly(id) {
        const resp = await this.getHardwareListAssemblyById({ id });
        if (resp?.hardwareListAssemblyById) {
            this.externalInventory = resp.hardwareListAssemblyById;
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListDynamicItem.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        default: null,
        type: Object
    }),
    __metadata("design:type", Object)
], MiniHardwareListDynamicItem.prototype, "hardwareListItem", void 0);
__decorate([
    Emit('emit-show-edit-hardware-list-item-modal'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MiniHardwareListDynamicItem.prototype, "emitShowModalHardwareListEditItemModal", null);
__decorate([
    Emit('delete-non-cosmic-inventory'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListDynamicItem.prototype, "emitDeleteNonCosmicInventory", null);
__decorate([
    Emit('add-to-hardware-list-at-sequence'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", void 0)
], MiniHardwareListDynamicItem.prototype, "onAddToHardwareListAtSequence", null);
__decorate([
    Watch('hardwareListItem', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MiniHardwareListDynamicItem.prototype, "onChangeOfHardwareListItem", null);
MiniHardwareListDynamicItem = __decorate([
    Component
], MiniHardwareListDynamicItem);
export default MiniHardwareListDynamicItem;
