export var AlternateType;
(function (AlternateType) {
    AlternateType["PREFERRED"] = "PREFERRED";
    AlternateType["ALTERNATE"] = "ALTERNATE";
    AlternateType["TRAINING_PREFERRED"] = "TRAINING_PREFERRED";
    AlternateType["TRAINING_ALTERNATE"] = "TRAINING_ALTERNATE";
})(AlternateType || (AlternateType = {}));
export const AlternateTypeDisplay = new Map([
    [AlternateType.PREFERRED, 'PREFERRED'],
    [AlternateType.ALTERNATE, 'ALTERNATE'],
    [AlternateType.TRAINING_PREFERRED, 'TRAINING PREFERRED'],
    [AlternateType.TRAINING_ALTERNATE, 'TRAINING ALTERNATE']
]);
export var GroundSupportEquipmentType;
(function (GroundSupportEquipmentType) {
    GroundSupportEquipmentType["_2_KN_LOAD_CELL"] = "_2_KN_LOAD_CELL";
    GroundSupportEquipmentType["AIR_FLOW_MONITOR"] = "AIR_FLOW_MONITOR";
    GroundSupportEquipmentType["ALARM_PANEL"] = "ALARM_PANEL";
    GroundSupportEquipmentType["AMPLIFIER"] = "AMPLIFIER";
    GroundSupportEquipmentType["ANALYZER"] = "ANALYZER";
    GroundSupportEquipmentType["APFR_GSE_TETHER_ASSY"] = "APFR_GSE_TETHER_ASSY";
    GroundSupportEquipmentType["ATTENUATOR"] = "ATTENUATOR";
    GroundSupportEquipmentType["BATTERY_CHARGER_DISCHARGER"] = "BATTERY_CHARGER_DISCHARGER";
    GroundSupportEquipmentType["BATTERY_CHARGER_EMU_LIB"] = "BATTERY_CHARGER_EMU_LIB";
    GroundSupportEquipmentType["BATTERY_HITESTER"] = "BATTERY_HITESTER";
    GroundSupportEquipmentType["BLEED_FIXTURE"] = "BLEED_FIXTURE";
    GroundSupportEquipmentType["BREAKOUT_BOX"] = "BREAKOUT_BOX";
    GroundSupportEquipmentType["BUBBLE_FLOWMETER_KIT"] = "BUBBLE_FLOWMETER_KIT";
    GroundSupportEquipmentType["CABLE"] = "CABLE";
    GroundSupportEquipmentType["CALIBRATED_LEAK"] = "CALIBRATED_LEAK";
    GroundSupportEquipmentType["CALIPER"] = "CALIPER";
    GroundSupportEquipmentType["CARRYING_FRAME"] = "CARRYING_FRAME";
    GroundSupportEquipmentType["CCC_SIMULATOR"] = "CCC_SIMULATOR";
    GroundSupportEquipmentType["CLEAN_ROOM_LSS"] = "CLEAN_ROOM_LSS";
    GroundSupportEquipmentType["CLEAN_ROOM_SOP"] = "CLEAN_ROOM_SOP";
    GroundSupportEquipmentType["CONTACT_TOOL"] = "CONTACT_TOOL";
    GroundSupportEquipmentType["COOLER"] = "COOLER";
    GroundSupportEquipmentType["COUNTER"] = "COUNTER";
    GroundSupportEquipmentType["CRIMP_TOOL"] = "CRIMP_TOOL";
    GroundSupportEquipmentType["CURRENT_METER"] = "CURRENT_METER";
    GroundSupportEquipmentType["DATA_ACQUISITION_UNIT"] = "DATA_ACQUISITION_UNIT";
    GroundSupportEquipmentType["DECADE_RESISTOR"] = "DECADE_RESISTOR";
    GroundSupportEquipmentType["DESKTOP_COMPUTER"] = "DESKTOP_COMPUTER";
    GroundSupportEquipmentType["DIA_PUMP"] = "DIA_PUMP";
    GroundSupportEquipmentType["DIAL_CHAMFER_GAUGE"] = "DIAL_CHAMFER_GAUGE";
    GroundSupportEquipmentType["DIGITAL_LEVEL"] = "DIGITAL_LEVEL";
    GroundSupportEquipmentType["DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY"] = "DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY";
    GroundSupportEquipmentType["ELECTRICAL_GLOVES"] = "ELECTRICAL_GLOVES";
    GroundSupportEquipmentType["ELECTRONIC_LOAD"] = "ELECTRONIC_LOAD";
    GroundSupportEquipmentType["EMU_DISPLAY_STAND_ASSY"] = "EMU_DISPLAY_STAND_ASSY";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE_FRAME"] = "EMU_GROUND_TEST_FIXTURE_FRAME";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE"] = "EMU_GROUND_TEST_FIXTURE";
    GroundSupportEquipmentType["EMU_LIFTING_FIXTURE"] = "EMU_LIFTING_FIXTURE";
    GroundSupportEquipmentType["EMU_OFFSET_HOLDING_FIXTURE"] = "EMU_OFFSET_HOLDING_FIXTURE";
    GroundSupportEquipmentType["EQUIPMENT_TBD"] = "EQUIPMENT_TBD";
    GroundSupportEquipmentType["FACILITY_B_A_SYSTEM"] = "FACILITY_B_A_SYSTEM";
    GroundSupportEquipmentType["FACILITY_BA_SYSTEM"] = "FACILITY_BA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_DA_SYSTEM"] = "FACILITY_DA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H20_SYSTEM"] = "FACILITY_H20_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H2O_SYSTEM"] = "FACILITY_H2O_SYSTEM";
    GroundSupportEquipmentType["FACILITY_N2_SYSTEM"] = "FACILITY_N2_SYSTEM";
    GroundSupportEquipmentType["FACILITY_O2_SYSTEM"] = "FACILITY_O2_SYSTEM";
    GroundSupportEquipmentType["FC_FLOW_CONTROLLER"] = "FC_FLOW_CONTROLLER";
    GroundSupportEquipmentType["FCD_FLOW_CONTROL_DISPLAY"] = "FCD_FLOW_CONTROL_DISPLAY";
    GroundSupportEquipmentType["FILTER"] = "FILTER";
    GroundSupportEquipmentType["FIXTURE"] = "FIXTURE";
    GroundSupportEquipmentType["FLEXHOSE"] = "FLEXHOSE";
    GroundSupportEquipmentType["FLOW_BENCH"] = "FLOW_BENCH";
    GroundSupportEquipmentType["FLOWMETER"] = "FLOWMETER";
    GroundSupportEquipmentType["FORCE_GAUGE"] = "FORCE_GAUGE";
    GroundSupportEquipmentType["FORK_HOOK"] = "FORK_HOOK";
    GroundSupportEquipmentType["FORKLIFT"] = "FORKLIFT";
    GroundSupportEquipmentType["FT_FLOW_TRANSDUCER"] = "FT_FLOW_TRANSDUCER";
    GroundSupportEquipmentType["FTD_FLOW_TRANSDUCER_DISPLAY"] = "FTD_FLOW_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["FUME_HOOD"] = "FUME_HOOD";
    GroundSupportEquipmentType["FUNCTION_GENERATOR"] = "FUNCTION_GENERATOR";
    GroundSupportEquipmentType["GAGE_ACTIVE_WIF_PROBE"] = "GAGE_ACTIVE_WIF_PROBE";
    GroundSupportEquipmentType["GHD_HOISTING_SLING"] = "GHD_HOISTING_SLING";
    GroundSupportEquipmentType["GLOVE_MEASURE_DEVICE"] = "GLOVE_MEASURE_DEVICE";
    GroundSupportEquipmentType["GLUE_BENCH"] = "GLUE_BENCH";
    GroundSupportEquipmentType["GO_GAGE"] = "GO_GAGE";
    GroundSupportEquipmentType["GO_NO_GO_GAUGE"] = "GO_NO_GO_GAUGE";
    GroundSupportEquipmentType["GROUND_HANDLING_DEVICE"] = "GROUND_HANDLING_DEVICE";
    GroundSupportEquipmentType["HEIGHT_EXTENDER_ASSY"] = "HEIGHT_EXTENDER_ASSY";
    GroundSupportEquipmentType["HELIUM_LEAK_PROBE"] = "HELIUM_LEAK_PROBE";
    GroundSupportEquipmentType["HIGH_POT_TESTER"] = "HIGH_POT_TESTER";
    GroundSupportEquipmentType["HIGH_RESISTANCE_METER"] = "HIGH_RESISTANCE_METER";
    GroundSupportEquipmentType["HOIST"] = "HOIST";
    GroundSupportEquipmentType["HOSE_ASSEMBLY"] = "HOSE_ASSEMBLY";
    GroundSupportEquipmentType["HOSE_SWAGING_CART"] = "HOSE_SWAGING_CART";
    GroundSupportEquipmentType["HYGRO_THERMOMETER"] = "HYGRO_THERMOMETER";
    GroundSupportEquipmentType["IMPACT_O_GRAPH"] = "IMPACT_O_GRAPH";
    GroundSupportEquipmentType["IONIZER"] = "IONIZER";
    GroundSupportEquipmentType["IR_THERMOMETER"] = "IR_THERMOMETER";
    GroundSupportEquipmentType["LAB_TOP_COMPUTER"] = "LAB_TOP_COMPUTER";
    GroundSupportEquipmentType["LAUNCH_ENCLOSURE_LIFTING_EYE"] = "LAUNCH_ENCLOSURE_LIFTING_EYE";
    GroundSupportEquipmentType["LIFTING_BEAM"] = "LIFTING_BEAM";
    GroundSupportEquipmentType["LIFTING_FIXTURE"] = "LIFTING_FIXTURE";
    GroundSupportEquipmentType["LIFTING_STRAP"] = "LIFTING_STRAP";
    GroundSupportEquipmentType["LTA_SUSPENSION_ALIGNMENT_FIXTURE"] = "LTA_SUSPENSION_ALIGNMENT_FIXTURE";
    GroundSupportEquipmentType["MAN_LIFT"] = "MAN_LIFT";
    GroundSupportEquipmentType["MEASURING_TAPE"] = "MEASURING_TAPE";
    GroundSupportEquipmentType["MERCURY_VAPOR_ANALYZER"] = "MERCURY_VAPOR_ANALYZER";
    GroundSupportEquipmentType["MICROMETER"] = "MICROMETER";
    GroundSupportEquipmentType["MODULATION_METER"] = "MODULATION_METER";
    GroundSupportEquipmentType["MOISTURE_MONITOR"] = "MOISTURE_MONITOR";
    GroundSupportEquipmentType["MONITOR_GROUND"] = "MONITOR_GROUND";
    GroundSupportEquipmentType["MOUNTING_FRAME"] = "MOUNTING_FRAME";
    GroundSupportEquipmentType["MULITPLE_WATER_CONNECTOR"] = "MULITPLE_WATER_CONNECTOR";
    GroundSupportEquipmentType["MULTIMETER"] = "MULTIMETER";
    GroundSupportEquipmentType["MULTIPLE_WATER_CONNECTOR_"] = "MULTIPLE_WATER_CONNECTOR_";
    GroundSupportEquipmentType["MULTITESTER"] = "MULTITESTER";
    GroundSupportEquipmentType["NECK_PLATE_INTERFACE_FIXTURE"] = "NECK_PLATE_INTERFACE_FIXTURE";
    GroundSupportEquipmentType["O2_MONITOR"] = "O2_MONITOR";
    GroundSupportEquipmentType["OHMMETER"] = "OHMMETER";
    GroundSupportEquipmentType["OSCILLATOR"] = "OSCILLATOR";
    GroundSupportEquipmentType["OSCILLOSCOPE"] = "OSCILLOSCOPE";
    GroundSupportEquipmentType["OVEN"] = "OVEN";
    GroundSupportEquipmentType["PALLET_JACK"] = "PALLET_JACK";
    GroundSupportEquipmentType["PANEL_METER"] = "PANEL_METER";
    GroundSupportEquipmentType["PARTICLE_COUNTER"] = "PARTICLE_COUNTER";
    GroundSupportEquipmentType["PH_METER"] = "PH_METER";
    GroundSupportEquipmentType["PIN_RETENTION_TOOL"] = "PIN_RETENTION_TOOL";
    GroundSupportEquipmentType["PLATE_MILLIOHM_REFERENCE"] = "PLATE_MILLIOHM_REFERENCE";
    GroundSupportEquipmentType["PLSS_WEIGHT_BOX"] = "PLSS_WEIGHT_BOX";
    GroundSupportEquipmentType["POWER_SUPPLY"] = "POWER_SUPPLY";
    GroundSupportEquipmentType["PRESSURE_GAUGE"] = "PRESSURE_GAUGE";
    GroundSupportEquipmentType["PRESSURE_REGULATOR"] = "PRESSURE_REGULATOR";
    GroundSupportEquipmentType["PRESSURE_SWITCH"] = "PRESSURE_SWITCH";
    GroundSupportEquipmentType["PROBE"] = "PROBE";
    GroundSupportEquipmentType["PT_PRESSURE_TRANSDUCER"] = "PT_PRESSURE_TRANSDUCER";
    GroundSupportEquipmentType["PTD_PRESSURE_TRANSDUCER_DISPLAY"] = "PTD_PRESSURE_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["RADIO_TEST_SET"] = "RADIO_TEST_SET";
    GroundSupportEquipmentType["RECEIVER"] = "RECEIVER";
    GroundSupportEquipmentType["RECORDERS"] = "RECORDERS";
    GroundSupportEquipmentType["REGULATOR"] = "REGULATOR";
    GroundSupportEquipmentType["RELIEF_VALVE"] = "RELIEF_VALVE";
    GroundSupportEquipmentType["SAMPLE_CYLINDER"] = "SAMPLE_CYLINDER";
    GroundSupportEquipmentType["SCALE"] = "SCALE";
    GroundSupportEquipmentType["SEMU_LIFTING_BAR_ASSY"] = "SEMU_LIFTING_BAR_ASSY";
    GroundSupportEquipmentType["SENSOR_MODULE"] = "SENSOR_MODULE";
    GroundSupportEquipmentType["SHACKLE"] = "SHACKLE";
    GroundSupportEquipmentType["SHIPPING_CONTAINER"] = "SHIPPING_CONTAINER";
    GroundSupportEquipmentType["SHUCK_TEST_MICROMETER_GAUGE_ASSY"] = "SHUCK_TEST_MICROMETER_GAUGE_ASSY";
    GroundSupportEquipmentType["SOLDERING_STATION"] = "SOLDERING_STATION";
    GroundSupportEquipmentType["SOUND_LEVEL_CALIBRATOR"] = "SOUND_LEVEL_CALIBRATOR";
    GroundSupportEquipmentType["SOUND_LEVEL_METER"] = "SOUND_LEVEL_METER";
    GroundSupportEquipmentType["SOUND_PRESSURE_METER"] = "SOUND_PRESSURE_METER";
    GroundSupportEquipmentType["STATIC_LOCATOR"] = "STATIC_LOCATOR";
    GroundSupportEquipmentType["SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT"] = "SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT";
    GroundSupportEquipmentType["SURFACE_RESIST_IND"] = "SURFACE_RESIST_IND";
    GroundSupportEquipmentType["SURFACE_RESISTIVITY_METER"] = "SURFACE_RESISTIVITY_METER";
    GroundSupportEquipmentType["TD_TEMPERATURE_DISPLAY"] = "TD_TEMPERATURE_DISPLAY";
    GroundSupportEquipmentType["TEMP_HUMIDITY_RECORDER"] = "TEMP_HUMIDITY_RECORDER";
    GroundSupportEquipmentType["TEMPERATURE_CONTROLLER"] = "TEMPERATURE_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_LIMIT_CONTROLLER"] = "TEMPERATURE_LIMIT_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_RECORDER"] = "TEMPERATURE_RECORDER";
    GroundSupportEquipmentType["TEST_STAND"] = "TEST_STAND";
    GroundSupportEquipmentType["THERMOCOUPLE_DISPLAY"] = "THERMOCOUPLE_DISPLAY";
    GroundSupportEquipmentType["THERMOMETER"] = "THERMOMETER";
    GroundSupportEquipmentType["TIMER_CLOCK"] = "TIMER_CLOCK";
    GroundSupportEquipmentType["TIMER"] = "TIMER";
    GroundSupportEquipmentType["TORQUE_DRIVER"] = "TORQUE_DRIVER";
    GroundSupportEquipmentType["TORQUE_METER"] = "TORQUE_METER";
    GroundSupportEquipmentType["TORQUE_TESTER"] = "TORQUE_TESTER";
    GroundSupportEquipmentType["TORQUE_TRANSDUCER"] = "TORQUE_TRANSDUCER";
    GroundSupportEquipmentType["TORQUE_WRENCH"] = "TORQUE_WRENCH";
    GroundSupportEquipmentType["TT_TEMPERATURE_TRANSMITTER"] = "TT_TEMPERATURE_TRANSMITTER";
    GroundSupportEquipmentType["VACUUM_CHAMBER_ASSY_22_LITER"] = "VACUUM_CHAMBER_ASSY_22_LITER";
    GroundSupportEquipmentType["VACUUM_DISPLAY"] = "VACUUM_DISPLAY";
    GroundSupportEquipmentType["VACUUM_OVEN"] = "VACUUM_OVEN";
    GroundSupportEquipmentType["VACUUM_PUMP"] = "VACUUM_PUMP";
    GroundSupportEquipmentType["VACUUM_SENSOR"] = "VACUUM_SENSOR";
    GroundSupportEquipmentType["VEHICLE_BOBTAIL_TRUCK"] = "VEHICLE_BOBTAIL_TRUCK";
    GroundSupportEquipmentType["VELOCITY_METER"] = "VELOCITY_METER";
    GroundSupportEquipmentType["VENT_HOOD"] = "VENT_HOOD";
    GroundSupportEquipmentType["VITAL_SIGNS_SIMILATOR"] = "VITAL_SIGNS_SIMILATOR";
    GroundSupportEquipmentType["VOLTMETER"] = "VOLTMETER";
    GroundSupportEquipmentType["VRMS_METER"] = "VRMS_METER";
    GroundSupportEquipmentType["WATER_CONNECTOR_AIRLOCK"] = "WATER_CONNECTOR_AIRLOCK";
    GroundSupportEquipmentType["WATER_TANK"] = "WATER_TANK";
    GroundSupportEquipmentType["WATER_TRAP"] = "WATER_TRAP";
    GroundSupportEquipmentType["WAVEFORM_GENERATOR"] = "WAVEFORM_GENERATOR";
    GroundSupportEquipmentType["WAVEFORM_MONITOR"] = "WAVEFORM_MONITOR";
    GroundSupportEquipmentType["WEIGHT_BOX"] = "WEIGHT_BOX";
    GroundSupportEquipmentType["WELD_EXHAUST_HOOD"] = "WELD_EXHAUST_HOOD";
    GroundSupportEquipmentType["WIRE_CRIMPER"] = "WIRE_CRIMPER";
    GroundSupportEquipmentType["WIRE_STRIPPER"] = "WIRE_STRIPPER";
    GroundSupportEquipmentType["WRIST_STRAP_FOOTWEAR_CHECKER"] = "WRIST_STRAP_FOOTWEAR_CHECKER";
    GroundSupportEquipmentType["X_RAY_MACHINE"] = "X_RAY_MACHINE";
})(GroundSupportEquipmentType || (GroundSupportEquipmentType = {}));
export var MakeBuySupply;
(function (MakeBuySupply) {
    MakeBuySupply["MAKE"] = "MAKE";
    MakeBuySupply["BUY"] = "BUY";
    MakeBuySupply["SUPPLY"] = "SUPPLY";
})(MakeBuySupply || (MakeBuySupply = {}));
export const ItemMasterMakeBuySupplyDisplay = new Map([
    [MakeBuySupply.MAKE, 'MAKE'],
    [MakeBuySupply.BUY, 'BUY'],
    [MakeBuySupply.SUPPLY, 'SUPPLY']
]);
export var LimitedLifeCodes;
(function (LimitedLifeCodes) {
    LimitedLifeCodes["CYC"] = "CYC";
    LimitedLifeCodes["BOT"] = "BOT";
    LimitedLifeCodes["CCC"] = "CCC";
    LimitedLifeCodes["REG"] = "REG";
    LimitedLifeCodes["SSA"] = "SSA";
    LimitedLifeCodes["BAT"] = "BAT";
    LimitedLifeCodes["FAN"] = "FAN";
    LimitedLifeCodes["CAL"] = "CAL";
})(LimitedLifeCodes || (LimitedLifeCodes = {}));
export const LimitedLifeCodesDisplay = new Map([
    [LimitedLifeCodes.CYC, 'CYC'],
    [LimitedLifeCodes.BOT, 'BOT'],
    [LimitedLifeCodes.CCC, 'CCC'],
    [LimitedLifeCodes.REG, 'REG'],
    [LimitedLifeCodes.SSA, 'SSA'],
    [LimitedLifeCodes.BAT, 'BAT'],
    [LimitedLifeCodes.FAN, 'FAN'],
    [LimitedLifeCodes.CAL, 'CAL']
]);
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["GOVERNMENT"] = "GOVERNMENT";
    OrganizationType["MANUFACTURER"] = "MANUFACTURER";
    OrganizationType["PROCESSOR"] = "PROCESSOR";
    OrganizationType["SUPPLIER"] = "SUPPLIER";
    OrganizationType["VENDOR"] = "VENDOR";
    OrganizationType["CONTRACTOR"] = "CONTRACTOR";
})(OrganizationType || (OrganizationType = {}));
