import { ItemInstanceInventoryType } from '~/db_types';
import { InventoryClass } from '~/db_types/swagger_types';
export const ItemInstanceInventoryTypeDisplay = new Map([
    [ItemInstanceInventoryType.CLASS_I, 'I'],
    [ItemInstanceInventoryType.CLASS_II, 'II'],
    [ItemInstanceInventoryType.CLASS_III, 'III'],
    [ItemInstanceInventoryType.CLASS_IE, 'IE'],
    [ItemInstanceInventoryType.CLASS_IIIC, 'IIIC'],
    [ItemInstanceInventoryType.CLASS_IIIW, 'IIIW'],
    [ItemInstanceInventoryType.GROUND_SUPPORT_EQUIPMENT, 'GSE'],
    [ItemInstanceInventoryType.SPECIAL_TEST_EQUIPMENT, 'STE/D'],
    [ItemInstanceInventoryType.PUBLIC_AFFAIRS_ONLY, 'PAO'],
    [ItemInstanceInventoryType._PROTOTYPE, 'PRT'],
    [ItemInstanceInventoryType.PROGRAM_SUPPORT_EQUIPMENT, 'PSE'],
    [ItemInstanceInventoryType.UC, 'UC'],
    [ItemInstanceInventoryType.UNKNOWN, 'Unknown'],
    [ItemInstanceInventoryType.MULTIPLE, 'Multiple']
]);
export const InventoryClassDisplay = new Map([
    [InventoryClass._PROTOTYPE, 'PRT'],
    [InventoryClass.CLASS_I, 'I'],
    [InventoryClass.CLASS_IE, 'IE'],
    [InventoryClass.CLASS_II, 'II'],
    [InventoryClass.CLASS_III, 'III'],
    [InventoryClass.CLASS_IIIC, 'IIIC'],
    [InventoryClass.CLASS_IIIW, 'IIIW'],
    [InventoryClass.GROUND_SUPPORT_EQUIPMENT, 'GSE'],
    [InventoryClass.PROGRAM_SUPPORT_EQUIPMENT, 'PSE'],
    [InventoryClass.PUBLIC_AFFAIRS_ONLY, 'PAO'],
    [InventoryClass.SPECIAL_TEST_EQUIPMENT, 'STE/D'],
    [InventoryClass.UC, 'UC'],
    [InventoryClass.UNKNOWN, 'Unknown']
]);
