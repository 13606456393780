var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseVue from './BaseVue';
/**
 * AVAILABLE PROPS:
 *
 * autofocus
 * clearable - puts lil 'x'
 * disabled - greys out the field
 * label
 * placeholder
 * type
 * required
 * rules - array of functions
 * value
 */
let BaseInput = class BaseInput extends BaseVue {
    autofocus;
    color;
    clearable;
    disabled;
    errorMessages;
    hideErrorMessage;
    hint;
    messages;
    label;
    loading;
    placeholder;
    prefix;
    required;
    outline;
    rules;
    value;
    /*
     * This prop allows us to pre-compute rule validation outside of BaseInput.
     * It is most useful for multi-component validation where one component's validity is
     * determined by another's value. Simply compute your validation elsewhere and pass
     * in the result as an Array<boolean | string> with the string being the optional
     * error message to be used by Vuetify.
     *
     * const myComputedRule = propertyOne < propertyTwo;
     * [ myComputedRule || 'my rule error message' ]
     */
    preComputedRules;
    searchValue = '';
    get computedColor() {
        return this.color;
    }
    get computedErrorMessage() {
        if (!this.errorMessages?.length) {
            return;
        }
        if (Array.isArray(this.errorMessages) && this.errorMessages?.length) {
            return this.errorMessages[0];
        }
        // it's a string
        if (typeof this.errorMessages === 'string' && this.errorMessages.length) {
            return this.errorMessages;
        }
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return '* Required';
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return '* Required';
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return '* Required';
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string')[0];
    }
    get computedLabel() {
        return this.label;
    }
    get computedMessage() {
        if (Array.isArray(this.messages) && this.messages?.length) {
            return this.messages[0];
        }
        // it's a string
        if (typeof this.messages === 'string' && this.messages?.length) {
            return this.messages;
        }
        return '';
    }
    // for legacy inputs
    get computedMessages() {
        // this accounts for a dropdown that is both required and has messages, previously
        // wouldn't show the messages prop if required was true
        if (this.searchValue) {
            return Array.isArray(this.messages) ? this.messages : [this.messages];
        }
        // if required and no value, show required message
        if (this.required && this.$get(this, 'value', null) === null) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', undefined) === undefined) {
            return ['REQUIRED'];
        }
        if (this.required && this.$get(this, 'value', '') === '') {
            return ['REQUIRED'];
        }
        if (this.required && Array.isArray(this.value) && this.value.length === 0) {
            return ['REQUIRED'];
        }
        if (this.messages) {
            return Array.isArray(this.messages) ? this.messages : [this.messages];
        }
        // show messages based on rules
        // @ts-ignore
        return this.localRulesBaseOnProps.filter((_) => typeof _ === 'string');
    }
    get hasErrorMessages() {
        return Boolean(this.errorMessages.length);
    }
    get localRulesBaseOnProps() {
        const rules = [...this.rules];
        if (this.required) {
            rules.push(this.defaultRules.required);
        }
        return rules;
    }
    defaultRules = {
        required: (value) => !!value || (Array.isArray(value) && value.length > 0) || 'REQUIRED'
    };
    emitInput(val) {
        // trim value for whitespace
        return val && val.trim ? val.trim() : val;
    }
    emitValidity(hasError) {
        return hasError;
    }
    focus(refName = 'input') {
        const $el = this.$refs[refName];
        if ($el) {
            $el.focus();
        }
    }
    emitInlineClear() { }
};
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "autofocus", void 0);
__decorate([
    Prop({
        type: String,
        default: 'primary'
    }),
    __metadata("design:type", String)
], BaseInput.prototype, "color", void 0);
__decorate([
    Prop({
        default: true,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "clearable", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "disabled", void 0);
__decorate([
    Prop({ default: () => [], type: [Array, String] }),
    __metadata("design:type", Object)
], BaseInput.prototype, "errorMessages", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "hideErrorMessage", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseInput.prototype, "hint", void 0);
__decorate([
    Prop({ type: [Array, String] }),
    __metadata("design:type", Object)
], BaseInput.prototype, "messages", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseInput.prototype, "label", void 0);
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Object)
], BaseInput.prototype, "loading", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseInput.prototype, "placeholder", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseInput.prototype, "prefix", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "required", void 0);
__decorate([
    Prop({ default: false, type: Boolean }),
    __metadata("design:type", Boolean)
], BaseInput.prototype, "outline", void 0);
__decorate([
    Prop({ default: () => [], type: Array }),
    __metadata("design:type", Array)
], BaseInput.prototype, "rules", void 0);
__decorate([
    Prop({ default: '', type: [String, Number, Array, Boolean, Object] }),
    __metadata("design:type", Object)
], BaseInput.prototype, "value", void 0);
__decorate([
    Prop({
        type: Array,
        required: false,
        default: () => {
            return [];
        }
    }),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], BaseInput.prototype, "preComputedRules", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], BaseInput.prototype, "emitInput", null);
__decorate([
    Emit('validity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], BaseInput.prototype, "emitValidity", null);
__decorate([
    Emit('inlineClear'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseInput.prototype, "emitInlineClear", null);
BaseInput = __decorate([
    Component
], BaseInput);
export default BaseInput;
