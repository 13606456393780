var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._l(_vm.computedChips, function (chip, index) {
    return _c('v-chip', {
      key: `${chip.value}_${index}`,
      staticClass: "font-weight-bold text--color",
      attrs: {
        "close": chip.close,
        "color": chip.color,
        "disabled": chip.disabled,
        "outline": chip.outline,
        "text-color": chip.textColor,
        "text": chip.text
      },
      on: {
        "input": function ($event) {
          return _vm.onClickOfClose(chip);
        }
      },
      nativeOn: {
        "keyup": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
          return _vm.onClickOfClose(chip);
        }
      }
    }, [_vm._v(_vm._s(chip.text))]);
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };