var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe, Side } from '~/db_types';
import { AssemblyTemplateResponseWithChildren, AssemblyTemplateType } from '~/db_types/swagger_types';
import { transformAssemblyTemplateParts } from '~/nasa_ui/base/BaseModalAssemblyTemplate';
import BaseTreeMini from '~/nasa_ui/base/BaseTreeMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AlertType, EntityType, HandDominanceDisplay, UserPermission } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
import { buildTree } from '~/nasa_ui/utils';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { AssemblyTemplatePartTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let MiniAssemblyTemplate = class MiniAssemblyTemplate extends Mixins(BaseTreeMini) {
    AssemblyTemplatePartTableHeaders = AssemblyTemplatePartTableHeaders;
    entityType = EntityType.ASSEMBLY_TEMPLATE;
    localEntity = null;
    showModalAssemblyTemplateClone = false;
    showModalAssemblyTemplateCreate = false;
    showDetail = false;
    showModalAssemblyTemplateEdit = false;
    allowChildCreation;
    allowEdit;
    allowInstall;
    clearableId;
    sequence;
    get assemblyTemplatePartDescription() {
        return this.hasSingleAssemblyTemplatePart ? this.myAssemblyTemplateParts[0]?.drawingDescription : null;
    }
    get assemblyTemplatePartSide() {
        return this.hasSingleAssemblyTemplatePart ? this.myAssemblyTemplateParts[0]?.side || null : null;
    }
    get assemblyTemplatePartSerial() {
        return this.shouldDisplaySerialChip
            ? this.$serialNumberDisplay(this.myAssemblyTemplateParts[0]?.serialNumber)
            : null;
    }
    get assemblyTemplatePartsTitle() {
        if (this.myAssemblyTemplateParts.length === 0) {
            return;
        }
        return `Parts selected:

${this.myAssemblyTemplateParts.map((atp) => atp?.drawingNumber).join(`
`)}
    `;
    }
    get assemblyTemplatePartTableItems() {
        const atps = this.myAssemblyTemplateParts.map((atp) => {
            return {
                asBuiltNumber: atp?.asBuiltNumber ?? null,
                description: atp?.drawingDescription ?? null,
                drawingNumber: atp?.drawingNumber ?? null,
                side: atp?.side ?? null
            };
        });
        return atps.map(transformAssemblyTemplateParts);
    }
    get assemblyTemplateRowsPerPageItems() {
        return [
            {
                text: '50',
                value: 50
            },
            {
                text: '100',
                value: 100
            },
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get computedColor() {
        if (this.isGenericAssemblyTemplate) {
            return EntityType.ASSEMBLY_TEMPLATE;
        }
        else if (this.isEventAssemblyTemplate) {
            return EntityType.EVENT;
        }
        else if (this.isPbsAssemblyTemplate) {
            return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
        }
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    get computedIcon() {
        if (this.isGenericAssemblyTemplate) {
            return this.$icons[EntityType.ASSEMBLY_TEMPLATE];
        }
        else if (this.isEventAssemblyTemplate) {
            return this.$icons[EntityType.ASSEMBLY_TEMPLATE];
        }
        else if (this.isPbsAssemblyTemplate) {
            return this.$icons[EntityType.PRODUCT_BREAKDOWN_STRUCTURE];
        }
        return this.$icons[EntityType.ASSEMBLY_TEMPLATE];
    }
    get computedManageLink() {
        return this.buildAssemblyTemplateManageLink(this.computedId);
    }
    get computedHardwareListDisplayHref() {
        return this.localEntity?.hardwareListId ? `/hardware/lists/manage/${this.localEntity.hardwareListId}` : null;
    }
    get computedHardwareListDisplay() {
        return this.localEntity?.hardwareListId ? this.localEntity.hardwareList?.number : null;
    }
    get computedTrackingDocumentDisplayHref() {
        return this.localEntity?.documentId ? `/${EntityType.DOCUMENT}/manage/${this.localEntity.documentId}` : null;
    }
    get computedTrackingDocumentIcon() {
        const subType = this.localEntity?.documentType;
        if (!subType) {
            return DEFAULT_DASH;
        }
        const subTypeIcon = this.$icons[subType];
        const defaultIcon = this.$icons[EntityType.DOCUMENT];
        if (!subType || subTypeIcon === undefined) {
            return defaultIcon;
        }
        return subTypeIcon;
    }
    get computedTrackingDocumentDisplay() {
        return this.localEntity?.documentNumber || DEFAULT_DASH;
    }
    get documentId() {
        return this.localEntity?.documentId ?? null;
    }
    get hardwareListId() {
        return this.localEntity?.hardwareListId ?? null;
    }
    get hasAnyActions() {
        return this.allowChildCreation || this.allowEdit || this.allowInstall || this.inlineClear;
    }
    get hasChildren() {
        return this.myChildren.length > 0;
    }
    get hasDocumentAttached() {
        return Boolean(this.localEntity?.documentId);
    }
    get hasHardwareListAttached() {
        return Boolean(this.localEntity?.hardwareListId);
    }
    get hasSingleAssemblyTemplatePart() {
        return this.myAssemblyTemplateParts.length === 1;
    }
    get iconForAssemblyTemplatePartMultiple() {
        if (this.hasSingleAssemblyTemplatePart) {
            return null;
        }
        const parts = this.localEntity?.assemblyTemplateParts || [];
        const hasAsBuiltNumber = parts.some((part) => part?.asBuiltNumber);
        const _atIcon = hasAsBuiltNumber ? this.$icons[EntityType.ITEM_MASTER] : this.$icons[EntityType.ITEM_DRAWING];
        return this.isEventAssemblyTemplate ? this.$icons[EntityType.INVENTORY] : _atIcon;
    }
    get iconForAssemblyTemplatePartSingle() {
        if (!this.hasSingleAssemblyTemplatePart) {
            return null;
        }
        const parts = this.localEntity?.assemblyTemplateParts || [];
        const hasAsBuiltNumber = parts.some((part) => part?.asBuiltNumber);
        const _atIcon = hasAsBuiltNumber ? this.$icons[EntityType.ITEM_MASTER] : this.$icons[EntityType.ITEM_DRAWING];
        return this.isEventAssemblyTemplate ? this.$icons[EntityType.INVENTORY] : _atIcon;
    }
    get rootAssemblyTemplateId() {
        return this.isRoot ? this.localEntity?.id : this.parentAssemblyTemplateId;
    }
    get isChildMegaAssemblyTemplate() {
        return this.localEntity?.subType === AssemblyTemplateType.CHILD_MEGA;
    }
    get isEventAssemblyTemplate() {
        return this.localEntity?.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isGenericAssemblyTemplate() {
        return this.localEntity?.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isPbsAssemblyTemplate() {
        return this.localEntity?.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get isRoot() {
        return Boolean(!this.localEntity?.parentId && this.localEntity?.sequence === 0);
    }
    get isRootMegaAssemblyTemplate() {
        return this.localEntity?.subType === AssemblyTemplateType.ROOT_MEGA;
    }
    get localPbsItemId() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.pbsItemId || DEFAULT_DASH;
    }
    get localSequence() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.localEntity.sequence;
    }
    get maxQuantityDisplay() {
        return this.$numberDisplay(this.localEntity?.maxQuantity);
    }
    get minQuantityDisplay() {
        return this.$numberDisplay(this.localEntity?.minQuantity);
    }
    get myAssemblyTemplateParts() {
        return this.localEntity?.assemblyTemplateParts || [];
    }
    get myChildren() {
        const children = this.localEntity?.children || [];
        return this.showChildren ? children.sort(sortObjectNumericallyBy('sequence')) : [];
    }
    get name() {
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get parentAssemblyTemplateId() {
        return this.localEntity?.parentId;
    }
    get sequenceOverride() {
        return this.sequence || DEFAULT_DASH;
    }
    get singleAssemblyTemplatePartDisplay() {
        const atps = this.myAssemblyTemplateParts;
        return this.$itemNumberDisplay(atps[0]);
    }
    get singleAssemblyTemplatePartSideNotNONE() {
        return this.hasSingleAssemblyTemplatePart ? this.myAssemblyTemplateParts[0]?.side !== Side.NONE : false;
    }
    get shouldAllowHardwareListUpdates() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_ADMIN);
    }
    get shouldDisplayDescriptionChip() {
        return this.shouldDisplayDrawingNumberChip;
    }
    get shouldDisplayDrawingNumberChip() {
        return this.myAssemblyTemplateParts.length >= 1 ? Boolean(this.singleAssemblyTemplatePartDisplay) : false;
    }
    get shouldDisplayHardwareListChip() {
        return this.hasHardwareListAttached;
    }
    get shouldDisplayTrackingDocumentChip() {
        return Boolean(this.localEntity?.documentId);
    }
    get shouldDisplaySideChip() {
        return (this.singleAssemblyTemplatePartDisplay &&
            this.singleAssemblyTemplatePartSideNotNONE &&
            this.assemblyTemplatePartSide);
    }
    get shouldDisplaySerialChip() {
        return this.hasSingleAssemblyTemplatePart && this.myAssemblyTemplateParts[0]?.serialNumber;
    }
    get shouldHighlightConnection() {
        if (!this.computedId) {
            return false;
        }
        if (this.highlightConnection) {
            const upcasedUserTypedSearch = this.highlightConnection.trim().toLocaleUpperCase();
            const matchesAssemblyTemplateName = this.name === DEFAULT_DASH
                ? false
                : this.name
                    .split(' ')
                    .map((name) => name.toLocaleUpperCase())
                    .some((word) => upcasedUserTypedSearch.includes(word));
            const matchesDrawingNumber = this.myAssemblyTemplateParts.some((node) => {
                return upcasedUserTypedSearch.includes((node?.drawingNumber || '').toLocaleUpperCase());
            });
            const matchesDescription = this.myAssemblyTemplateParts.some((node) => {
                return upcasedUserTypedSearch.includes((node?.drawingDescription || '').toLocaleUpperCase());
            });
            const matchesAsBuiltNumber = this.myAssemblyTemplateParts.some((node) => {
                return upcasedUserTypedSearch.includes((node?.asBuiltNumber || '').toLocaleUpperCase());
            });
            const matchesSerialNumber = this.myAssemblyTemplateParts.some((node) => {
                return upcasedUserTypedSearch.includes((node?.serialNumber || '').toLocaleUpperCase());
            });
            return (matchesAssemblyTemplateName ||
                matchesDrawingNumber ||
                matchesDescription ||
                matchesAsBuiltNumber ||
                matchesSerialNumber);
        }
        return false;
    }
    get shouldOverrideSequence() {
        return Boolean(this.sequence !== null);
    }
    get shouldShowCreateChildIcon() {
        return this.allowChildCreation;
    }
    get shouldShowChildContainer() {
        return this.showChildren && this.myChildren.length > 0 && this.localShowChildren;
    }
    get shouldShowMinMaxFields() {
        return (this.showMinMax && this.isGenericAssemblyTemplate) || (this.showMinMax && this.isEventAssemblyTemplate);
    }
    get shouldShowPbsField() {
        return this.localPbsItemId !== DEFAULT_DASH;
    }
    get subTypeDisplay() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return AssemblyTemplateTypeDisplay.get(this.localEntity.subType);
    }
    get installedOnDisplay() {
        return this.localEntity?.installedOn ? HandDominanceDisplay.get(this.localEntity.installedOn) : DEFAULT_DASH;
    }
    buildAssemblyTemplateManageLink(id) {
        if (this.isGenericAssemblyTemplate) {
            return `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage/${id}#information`;
        }
        else if (this.isEventAssemblyTemplate) {
            return `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage/${id}#information`;
        }
        else if (this.isPbsAssemblyTemplate) {
            return `/${EntityType.PRODUCT_BREAKDOWN_STRUCTURE}/manage/${id}#information`;
        }
        return `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/manage/${id}#information`;
    }
    created() {
        this.$listen(`reload_${this.computedId}`, this.onRefreshSelf);
    }
    async executeQuery(id) {
        if (id) {
            this.isFetching = true;
            try {
                const resp = await this.$http.get(`/assembly-templates/${id}`);
                this.isFetching = false;
                this.localEntity = resp.data;
                // update children with nested format for tree
                this.localEntity.children = buildTree(resp.data.children || []);
            }
            catch (err) {
                if (err instanceof Error) {
                    this.$errorUtility({
                        err
                    });
                }
                else {
                    console.log(`Unexpected error: ${err}`);
                }
            }
        }
    }
    onAssemblyTemplateCloned(assemblyTemplate) {
        if (assemblyTemplate.id) {
            window.open(`${this.buildAssemblyTemplateManageLink(assemblyTemplate.id)}`, assemblyTemplate.id);
        }
    }
    onFetchUpdate(data) { }
    // called from ui
    onClickOfCloneAssemblyTemplate() {
        if (!this.allowChildCreation) {
            return;
        }
        this.showModalAssemblyTemplateClone = true;
    }
    // Called from ui
    onClickOfCreateAssemblyTemplate() {
        if (!this.allowChildCreation) {
            return;
        }
        this.showModalAssemblyTemplateCreate = true;
    }
    async onClickOfDelete() {
        try {
            await this.$http.delete(`/assembly-templates/${this.computedId}`);
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: `Deleted Assembly Template.`
            });
            this.emitAssemblyTemplateDeleted();
        }
        catch (err) {
            this.$errorUtility({
                err,
                backupErrorText: 'Could not delete the Assembly Template'
            });
        }
    }
    // Called from ui
    onClickOfShowDetail() {
        this.showDetail = !this.showDetail;
    }
    // Called from ui
    onCloseOfModalAssemblyTemplateEdit() {
        this.showModalAssemblyTemplateEdit = false;
    }
    // Called from ui
    onCloseOfModalAssemblyTemplateCreate() {
        this.showModalAssemblyTemplateCreate = false;
    }
    // Called from ui
    onClickOfEdit() {
        if (!this.allowEdit) {
            return;
        }
        this.showModalAssemblyTemplateEdit = true;
    }
    // Called from ui
    onClickOfShowChildren() {
        this.localShowChildren = !this.localShowChildren;
    }
    // Called from ui as well as from $listen
    onRefreshSelf() {
        if (this.computedId) {
            this.executeQuery(this.computedId);
        }
    }
    // Called from ui
    onRefreshParent() {
        this.$message(`reload_${this.parentAssemblyTemplateId}`);
    }
    buildItemDrawingManageUrl(drawingNumber) {
        return `/hardware/${EntityType.ITEM_DRAWING}/manage/${encodeURIComponent(drawingNumber)}#information`;
    }
    buildItemMasterManageUrl(drawingNumber, asBuiltNumber, side) {
        return `/hardware/${EntityType.ITEM_MASTER}/manage/${encodeURIComponent(drawingNumber)}/${encodeURIComponent(asBuiltNumber)}/${side}#information`;
    }
    // Called from ui
    buildHardwareUrl(item) {
        if (!item.drawingNumber && !item.asBuiltNumber && !item.side) {
            this.$errorUtility({ err: new Error('Cannot build a URL without a drawing number, asBuilt number, or side.') });
            return null;
        }
        if (item.drawingNumber && item.asBuiltNumber && item.side) {
            // this is an ItemMaster
            return this.buildItemMasterManageUrl(item.drawingNumber, item.asBuiltNumber, item.side);
        }
        else if (item.drawingNumber) {
            // this is an ItemDrawing
            return this.buildItemDrawingManageUrl(item.drawingNumber);
        }
    }
    // Called from ui
    emitAssemblyTemplateDeleted() { }
    // Called from ui
    onClickOfInstall() {
        return this.localEntity;
    }
    onEntityChange(entity) {
        if (!entity) {
            return;
        }
        this.$set(this, 'localEntity', entity);
    }
    onData() {
        if (this.isEventAssemblyTemplate && this.$route.path.includes(EntityType.ASSEMBLY_TEMPLATE)) {
            window.document.title = 'Event Assembly Template Manage';
        }
    }
    onTrigger(id) {
        if (!id) {
            return;
        }
        this.executeQuery(id);
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniAssemblyTemplate.prototype, "allowInstall", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", Object)
], MiniAssemblyTemplate.prototype, "clearableId", void 0);
__decorate([
    Prop({
        default: null,
        type: Number
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], MiniAssemblyTemplate.prototype, "sequence", void 0);
__decorate([
    Emit('assembly-template-deleted'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "emitAssemblyTemplateDeleted", null);
__decorate([
    Emit('install'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onClickOfInstall", null);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof AssemblyTemplateResponseWithChildren !== "undefined" && AssemblyTemplateResponseWithChildren) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onEntityChange", null);
__decorate([
    Watch('localEntity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onData", null);
__decorate([
    Watch('id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], MiniAssemblyTemplate.prototype, "onTrigger", null);
MiniAssemblyTemplate = __decorate([
    Component
], MiniAssemblyTemplate);
export default MiniAssemblyTemplate;
