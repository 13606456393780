import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RadialProgressBar from 'vue-radial-progress';
let AppRadialProgress = class AppRadialProgress extends Vue {
    completed;
    total;
    get backgroundColor() {
        return '#398040'; // --v-documents-base
    }
    get completedPercentage() {
        return this.total === 0 ? `0%` : `${Math.round((this.completed / this.total) * 100)}%`;
    }
    get computedStartColor() {
        return '#a7eea4'; // --v-documents_accent-lighten5
    }
    get computedStopColor() {
        return '#569b56'; // --v-documents_accent-lighten2
    }
};
__decorate([
    Prop({
        type: Number,
        default: 0
    }),
    __metadata("design:type", Number)
], AppRadialProgress.prototype, "completed", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0
    }),
    __metadata("design:type", Number)
], AppRadialProgress.prototype, "total", void 0);
AppRadialProgress = __decorate([
    Component({
        components: { RadialProgressBar }
    })
], AppRadialProgress);
export default AppRadialProgress;
