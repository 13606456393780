var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": _vm.codeTitle,
      "html": _vm.authorityCodeDisplay
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": _vm.numberTitle,
      "html": _vm.authorityNumberDisplay
    }
  })], 1), _vm._l(_vm.authorityComments, function (comment, index) {
    return _c('v-flex', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hasAuthorityComments && !_vm.hideComments,
        expression: "hasAuthorityComments && !hideComments"
      }],
      key: index,
      attrs: {
        "xs12": ""
      }
    }, [_c('AppBlockquote', {
      attrs: {
        "title": _vm.commentTitle,
        "html": comment
      }
    })], 1);
  }), !_vm.hasAuthorityComments && !_vm.hideComments ? _c('v-flex', [_c('AppBlockquote', {
    attrs: {
      "title": _vm.commentTitle,
      "html": "-"
    }
  })], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };