var _a, _b, _c;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AssemblyTemplateResponseWithChildren, AssemblyTemplateType } from '~/db_types/swagger_types';
import BaseModalAssemblyTemplate from '~/nasa_ui/base/BaseModalAssemblyTemplate';
import { AlertType, EntityType, Maybe } from '~/nasa_ui/types';
import { AssemblyTemplateTypeDisplay } from '~/nasa_ui/types/enums/assembly';
/**
 * We're kind of overloading the base here so this comment is for clarity.
 *
 * This component displays an SelectorAssemblyTemplate. The user's selection will be
 * deeply cloned. The component emits the cloned AssemblyTemplate.
 *
 * If you want to also attach that clone to a parent AssemblyTemplate, you can
 * pass an optional `parentAssemblyTemplateNodeId` prop. If this prop is
 * received, the component will first clone the selected AssemblyTemplate, then
 * attach it to the parent AssemblyTemplate.
 */
let ModalAssemblyTemplateClone = class ModalAssemblyTemplateClone extends Mixins(BaseModalAssemblyTemplate) {
    selectedAssemblyTemplateId = null;
    assemblyTemplateId;
    subType;
    get allowInlineClear() {
        return Boolean(!this.assemblyTemplateId);
    }
    get computedColor() {
        if (this.isGenericAssemblyTemplate) {
            return EntityType.ASSEMBLY_TEMPLATE;
        }
        else if (this.isEventAssemblyTemplate) {
            return EntityType.EVENT;
        }
        else if (this.isPbsAssemblyTemplate) {
            return EntityType.PRODUCT_BREAKDOWN_STRUCTURE;
        }
        return EntityType.ASSEMBLY_TEMPLATE;
    }
    // using this to prevent selecting the parent as the AT to clone
    get existingValues() {
        if (!this.parentAssemblyTemplateId) {
            return null;
        }
        return [this.parentAssemblyTemplateId];
    }
    get isEventAssemblyTemplate() {
        return this.subType === AssemblyTemplateType.EVENT_ASSEMBLY;
    }
    get isGenericAssemblyTemplate() {
        return this.subType === AssemblyTemplateType.ASSEMBLY_TEMPLATE;
    }
    get isPbsAssemblyTemplate() {
        return this.subType === AssemblyTemplateType.PRODUCT_BREAKDOWN_STRUCTURE;
    }
    get newName() {
        return this.formData.name;
    }
    get shouldDisableCloneButton() {
        if (this.parentAssemblyTemplateId) {
            return !this.selectedAssemblyTemplateId || this.isSaving;
        }
        return this.isSaving;
    }
    get subTypeDisplay() {
        return AssemblyTemplateTypeDisplay.get(this.subType);
    }
    get subTypeFilter() {
        return {
            subType: this.subType
        };
    }
    async onAssemblyTemplateSelected(id) {
        this.selectedAssemblyTemplateId = id;
    }
    onClickOfClearSelection() {
        this.selectedAssemblyTemplateId = null;
    }
    async onClickOfCloneAssemblyTemplate() {
        if (!this.selectedAssemblyTemplateId) {
            return;
        }
        // don't need a trycatch here because the function already has one
        const newlyClonedAssemblyTemplate = await this.cloneAssemblyTemplate(this.selectedAssemblyTemplateId);
        if (!newlyClonedAssemblyTemplate) {
            return;
        }
        // if the parent prop was passed in, attach the clone to the parent
        if (this.parentAssemblyTemplateId && this.formData.sequence) {
            await this.attachAssemblyTemplateTreeToParent(newlyClonedAssemblyTemplate.id, this.parentAssemblyTemplateId, this.formData.sequence, newlyClonedAssemblyTemplate);
            this.$notification.add({
                text: `Cloned and inserted ${this.subTypeDisplay}.`,
                type: AlertType.SUCCESS
            });
        }
        else {
            this.$notification.add({
                text: `Cloned ${this.subTypeDisplay}.`,
                type: AlertType.SUCCESS
            });
        }
        this.emitClonedAssemblyTemplate(newlyClonedAssemblyTemplate);
        this.emitModalClose();
    }
    attachAssemblyTemplateTreeToParent(id, parentId, sequence, assemblyTemplateToAttach) {
        if (!id || !parentId || !sequence) {
            return;
        }
        try {
            this.editAssemblyTemplate(id, {
                parentId,
                sequence,
                installedOn: assemblyTemplateToAttach.installedOn,
                maxQuantity: assemblyTemplateToAttach.maxQuantity,
                minQuantity: assemblyTemplateToAttach.minQuantity,
                subType: assemblyTemplateToAttach.subType,
                attributes: assemblyTemplateToAttach.attributes
            });
            this.$notification.add({ type: AlertType.SUCCESS, text: 'Assembly Template attached.' });
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: 'Failed to attach Assembly Template to parent.' });
        }
    }
    async cloneAssemblyTemplate(uuid) {
        if (!uuid) {
            return null;
        }
        try {
            this.isSaving = true;
            const resp = await this.cloneAssemblyTemplateCall(uuid, this.subType, this.newName);
            this.isSaving = false;
            if (resp?.data) {
                return resp.data;
            }
            return null;
        }
        catch (err) {
            this.isSaving = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Failed to clone Assembly Template.'
            });
            return null;
        }
    }
    async onChangeOfAssemblyTemplateId(id) {
        this.selectedAssemblyTemplateId = id;
        const resp = await this.fetchEntity(id);
        if (resp) {
            // The clone only takes the non-commented out values
            // this.formData.installedOn = resp.assemblyTemplateById.installedOn;
            // this.formData.maxQuantity = resp.assemblyTemplateById.maxQuantity;
            // this.formData.minQuantity = resp.assemblyTemplateById.minQuantity;
            this.formData.name = `${resp.name} COPY`;
            this.formData.subType = resp.subType;
            // this.formData.pbsItemId = resp.assemblyTemplateById.pbsItemId;
            // this.formData.sequence = resp.assemblyTemplateById.sequence;
        }
    }
    cloneAssemblyTemplateCall(uuid, newRootSubType, newRootName) {
        if (!uuid) {
            return null;
        }
        return this.$http.post(`/assembly-templates/${uuid}/clone`, {
            newRootName,
            newRootSubType
        });
    }
    emitClonedAssemblyTemplate(assemblyTemplate) { }
};
__decorate([
    Prop({
        default: null,
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalAssemblyTemplateClone.prototype, "assemblyTemplateId", void 0);
__decorate([
    Prop({
        default: AssemblyTemplateType.ASSEMBLY_TEMPLATE,
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof AssemblyTemplateType !== "undefined" && AssemblyTemplateType) === "function" ? _b : Object)
], ModalAssemblyTemplateClone.prototype, "subType", void 0);
__decorate([
    Watch('assemblyTemplateId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], ModalAssemblyTemplateClone.prototype, "onChangeOfAssemblyTemplateId", null);
__decorate([
    Emit('cloned-assembly-template'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof AssemblyTemplateResponseWithChildren !== "undefined" && AssemblyTemplateResponseWithChildren) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyTemplateClone.prototype, "emitClonedAssemblyTemplate", null);
ModalAssemblyTemplateClone = __decorate([
    Component
], ModalAssemblyTemplateClone);
export default ModalAssemblyTemplateClone;
