import { endOfDay, isPast } from 'date-fns';
import { EventTypeDisplay } from '~/nasa_ui/types';
import { isWithinIntervalInclusive } from '../../dates';
import { addDateDisplays, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { transformLocations } from '../transformEntityToTableFormat';
export const transformEvents = (items) => {
    return items
        .map(addSubTypeDisplay(EventTypeDisplay))
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._isCurrentDateBetweenStartEnd =
            _item.startDate && _item.endDate
                ? isWithinIntervalInclusive(new Date(), {
                    start: new Date(_item.startDate),
                    end: new Date(_item.endDate)
                })
                : false;
        _item._isCurrentDateAfterEnd = _item.endDate ? isPast(endOfDay(new Date(_item.endDate))) : false;
        _item._location = _item.location ? transformLocations([_item.location])[0] : null;
        return _item;
    });
};
