import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetType } from '~/db_types/swagger_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { CosmicChangeSetStatusFragment } from '~/nasa_ui/DAL/changeSet';
import { EntityType } from '~/nasa_ui/types';
let MiniFailure = class MiniFailure extends BaseMini {
    ChangeSetType = ChangeSetType;
    entityType = EntityType.CHANGE_SET;
    localEntity = null;
    BASE_FETCH = gql `
    ${CosmicChangeSetStatusFragment}

    fragment FailureForMini on ChangeSet {
      asBuiltNumber
      computedStatus
      createdByUserId
      createdDateTime
      drawingNumber
      id
      nodeId
      payload
      side
      subType
      itemInstance {
        nodeId
        id
      }
      status {
        ...CosmicChangeSetStatus
      }
    }

    query FailureForMini($id: Int!) {
      changeSetById(id: $id) {
        ...FailureForMini
      }
    }
  `;
    get closedDateDisplay() {
        return this.$dateDisplay(this.localEntity?.status?.closedDate);
    }
    get failureItemInstanceNodeId() {
        return this.localEntity?.itemInstance?.nodeId || null;
    }
    get failureDescription() {
        return this.localEntity?.payload?.description;
    }
    get failureMode() {
        return this.localEntity?.payload?.failureMode;
    }
    get manageLink() {
        return this.localEntity ? `/hardware/failures/manage/${this.localEntity.id}` : '';
    }
    get openedDateDisplay() {
        return this.$dateDisplay(this.localEntity?.status?.openedDate);
    }
    // This mini doesnt have a refresh icon
    get shouldShowRefreshIcon() {
        return false;
    }
    get shouldShowManageLink() {
        return !window.location.pathname.includes('failures/manage');
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity) {
            return [];
        }
        let items = [];
        items = items.concat([
            {
                name: 'Opened On',
                value: this.openedDateDisplay
            }
        ]);
        if (this.localEntity.status?.closedDate) {
            items.push({
                name: 'Closed On',
                value: this.closedDateDisplay
            });
        }
        return items;
    }
    onFetchUpdate(data) {
        if (data.changeSetById) {
            this.localEntity = data.changeSetById;
        }
    }
};
MiniFailure = __decorate([
    Component
], MiniFailure);
export default MiniFailure;
