var _a, _b, _c, _d;
import { __decorate, __metadata } from "tslib";
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { Maybe } from '~/nasa_ui/types';
import { AuthorityCodeDisplay } from '~/nasa_ui/types/enums/authority-codes';
let AppAuthorityDisplay = class AppAuthorityDisplay extends Vue {
    authorityData;
    codeTitle;
    commentTitle;
    numberTitle;
    hideComments;
    get authorityCodeDisplay() {
        if (!this.authorityData?.authorityCode) {
            return DEFAULT_DASH;
        }
        return AuthorityCodeDisplay.get(this.authorityData.authorityCode);
    }
    get authorityComments() {
        return this.authorityData?.comments || [];
    }
    get authorityNumberDisplay() {
        return this.authorityData?.authorityNumber || DEFAULT_DASH;
    }
    get hasAuthorityCode() {
        return Boolean(this.authorityData?.authorityCode);
    }
    get hasAuthorityComments() {
        return Boolean(this.authorityComments.length);
    }
    get hasAuthorityNumber() {
        return Boolean(this.authorityData?.authorityNumber);
    }
};
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], AppAuthorityDisplay.prototype, "authorityData", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority code'
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], AppAuthorityDisplay.prototype, "codeTitle", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority comment'
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], AppAuthorityDisplay.prototype, "commentTitle", void 0);
__decorate([
    Prop({
        type: String,
        default: 'Authority number'
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], AppAuthorityDisplay.prototype, "numberTitle", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppAuthorityDisplay.prototype, "hideComments", void 0);
AppAuthorityDisplay = __decorate([
    Component
], AppAuthorityDisplay);
export default AppAuthorityDisplay;
