import { ItemMasterPropertyClass } from '~/db_types/swagger_types';
export const ItemMasterPropertyClassDisplay = new Map([
    [ItemMasterPropertyClass.BUILDINGS, 'Buildings'],
    [ItemMasterPropertyClass.COMPUTER_SOFTWARE, 'Computer software'],
    [ItemMasterPropertyClass.LAND, 'Land'],
    [ItemMasterPropertyClass.MATERIAL, 'Material'],
    [ItemMasterPropertyClass.NEEDS_ASSIGNMENT, 'Needs assignment'],
    [ItemMasterPropertyClass.OTHER_STRUCTURES, 'Other structures'],
    [ItemMasterPropertyClass.PLANT_EQUIPMENT, 'Plant equipment'],
    [ItemMasterPropertyClass.SENSITIVE_EQUIPMENT, 'Sensitive equipment'],
    [ItemMasterPropertyClass.SERVICE_MAINTENANCE, 'Service/maintenance'],
    [ItemMasterPropertyClass.SPACE_HARDWARE_APP, 'Space Hardware/APP'],
    [ItemMasterPropertyClass.SPECIAL_TEST_EQUIPMENT, 'Special test equipment'],
    [ItemMasterPropertyClass.SPECIAL_TOOLING, 'Special tooling']
]);
