var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "hardware_list_mini_child ml-4"
  }, [_vm.isEventAssembly ? _c('MiniAssemblyTemplate', _vm._b({
    attrs: {
      "allow-edit": _vm.allowEdit,
      "id": _vm.hardwareListItem.eventAssemblyTemplateId,
      "sequence": _vm.hardwareListItem.sequence,
      "show-children-initially": false,
      "show-children": ""
    }
  }, 'MiniAssemblyTemplate', _vm.$attrs, false)) : _vm._e(), _vm.isHardwareListTemplate ? _c('MiniHardwareListTemplateSlot', _vm._b({
    attrs: {
      "id": _vm.hardwareListItem.hardwareListTemplateSlotId,
      "allow-install": ""
    },
    on: {
      "add-to-hardware-list-at-sequence": _vm.onAddToHardwareListAtSequence
    }
  }, 'MiniHardwareListTemplateSlot', _vm.$attrs, false)) : _vm._e(), _vm.isInventory ? _c('MiniInventory', _vm._b({
    staticClass: "hl_inventory_mini",
    attrs: {
      "id": _vm.hardwareListItem.hardwareListInventoryId,
      "sequence": _vm.hardwareListItem.sequence
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([_vm.allowEdit ? {
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionEdit', {
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.emitShowModalHardwareListEditItemModal.apply(null, arguments);
                  }
                }
              })], 1)];
            }
          } : null], null, true)
        }, [_c('span', [_vm._v("Edit Slot")])])];
      },
      proxy: true
    }], null, false, 2795148586)
  }, 'MiniInventory', _vm.$attrs && _vm.$props, false)) : _vm._e(), _vm.isExternal ? _c('MiniInventory', _vm._b({
    staticClass: "hl_inventory_mini",
    attrs: {
      "entity": _vm.inventoryEntity,
      "readOnly": _vm.isReadOnly,
      "sequence": _vm.hardwareListItem.sequence,
      "inline-clear": "",
      "is-external": ""
    },
    on: {
      "inlineClear": function ($event) {
        _vm.showRemoveNonCosmicInventoryConfirmation = true;
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([_vm.allowEdit ? {
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c('div', _vm._g({}, on), [_c('ButtonMiniActionEdit', {
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.emitShowModalHardwareListEditItemModal.apply(null, arguments);
                  }
                }
              })], 1)];
            }
          } : null], null, true)
        }, [_c('span', [_vm._v("Edit Slot")])])];
      },
      proxy: true
    }], null, false, 2795148586)
  }, 'MiniInventory', _vm.$attrs && _vm.$props, false)) : _vm._e(), _vm.showRemoveNonCosmicInventoryConfirmation ? _c('AreYouSureDialog', {
    attrs: {
      "modal-width": "550px"
    },
    on: {
      "affirmativeClicked": _vm.onRemoveNonCosmicInventory,
      "negativeClicked": function ($event) {
        _vm.showRemoveNonCosmicInventoryConfirmation = false;
      }
    }
  }, [_c('p', {
    attrs: {
      "slot": "text"
    },
    slot: "text"
  }, [_vm._v("This will PERMANENTLY DELETE the non-" + _vm._s(_vm.currentSystemName) + " Inventory on this Hardware List.")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };