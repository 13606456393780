var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('c-checkbox', {
    attrs: {
      "data-cosmic-input": _vm.label || '',
      "data-appcheckbox": _vm.label || '',
      "disabled": _vm.disabled
    },
    domProps: {
      "cValue": _vm.val
    },
    on: {
      "c-input": function ($event) {
        _vm.val = $event.detail;
      },
      "mouseover": _vm.emitMouseover,
      "mouseleave": _vm.emitMouseleave
    },
    nativeOn: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.emitClick.apply(null, arguments);
      }
    }
  }, [_vm._t("default", function () {
    return [_c('span', _vm._g({
      directives: [{
        name: "safe-html",
        rawName: "v-safe-html",
        value: _vm.formattedLabel,
        expression: "formattedLabel"
      }]
    }, _vm.$listeners))];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };