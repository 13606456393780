var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cosmic_header",
    class: {
      uppercase: _vm.uppercase
    }
  }, [_vm.isPassedHtml ? _c('div', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.vHtml,
      expression: "vHtml"
    }],
    class: {
      [`${_vm.size}`]: _vm.size,
      divider: _vm.showHorizontalRule
    }
  }) : _c('div', {
    class: {
      [`${_vm.size}`]: _vm.size,
      divider: _vm.showHorizontalRule
    }
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };