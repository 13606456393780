import gql from 'graphql-tag';
import { Vue } from 'vue-property-decorator';
import { ChangeSetType } from '~/db_types';
import { apolloClient } from '~/nasa_ui/apollo.config';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicChangeSetStatusSlimFragment } from '~/nasa_ui/DAL/changeSet/slim';
import { CosmicItemDrawingSlimFragment } from '~/nasa_ui/DAL/itemDrawing/slim';
import { EntityType } from '~/nasa_ui/types';
import { ChangeSetStatusDisplay, ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { EquipmentGroupDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { FederalSupplyClassDisplay, FederalSupplyClassFCSRef } from '~/nasa_ui/types/enums/federal-supply-class';
import { GreenProcurementDisplay } from '~/nasa_ui/types/enums/green-procurement';
import { GroundSupportEquipmentTypeDisplay, ItemMasterCategoryCodeDisplay } from '~/nasa_ui/types/enums/hardware';
import { InventoryControlDisplay } from '~/nasa_ui/types/enums/inventory-control';
import { InventoryMethodDisplay } from '~/nasa_ui/types/enums/inventory-method';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/item-instance';
import { ItemMasterMakeBuySupplyDisplay } from '~/nasa_ui/types/enums/item-master';
import { FederalSupplyClassToObjectClass } from '~/nasa_ui/types/enums/object-class';
import { PreciousMetalDisplay } from '~/nasa_ui/types/enums/precious-metal';
import { ProjectCodeDisplay } from '~/nasa_ui/types/enums/project-code';
import { ItemMasterPropertyClassDisplay } from '~/nasa_ui/types/enums/property-class';
import { intervalDisplay } from '~/nasa_ui/utils/dates/intervals';
import { typeDisplay } from '~/nasa_ui/utils/helpers/displays';
import { markdownToHtml } from '~/nasa_ui/utils/helpers/markdownToHtml';
import { transformChangeSets, transformInventories, transformItemDrawings, transformItemInstances, transformItemMasters } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { typenameToEntityType } from '~/nasa_ui/utils/helpers/typenameToEntityType';
// ordered by preferred display order
const limitedLifeNonIntervalTypes = ['flight', 'cycle', 'eva'];
const limitedLifeIntervalTypes = ['shelf', 'usage', 'operation', 'pressurizedTime', 'interval', 'onOrbit', 'static'];
export default class Hardware extends Vue {
    hardware = null;
    relevantChangeSets = null;
    constructor(hardware) {
        super();
        if (hardware) {
            this.cloneToHardware(hardware);
        }
    }
    get activitiesFetchVariables() {
        if (!this.itemInstanceNodeId) {
            return null;
        }
        return {
            condition: {
                itemInstance: [this.itemInstanceNodeId]
            }
        };
    }
    get aliases() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return this.hardware?.aliases || [];
            case EntityType.ITEM_MASTER:
                return this.hardware?.itemDrawing?.aliases || [];
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemDrawing?.aliases || [];
            case EntityType.INVENTORY:
                return this.hardware?.itemDrawing?.aliases || [];
            default:
                return [];
        }
    }
    get alternates() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return null;
            case EntityType.ITEM_MASTER:
                return this.hardware?.alternatives?.nodes || [];
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.alternatives?.nodes || [];
            case EntityType.INVENTORY:
                return this.hardware?.itemMaster?.alternatives?.nodes || [];
            default:
                return null;
        }
    }
    get asBuiltNumber() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return null;
            case EntityType.ITEM_MASTER:
                return this.hardware?.asBuiltNumber;
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.asBuiltNumber || this.hardware?.asBuiltNumber;
            case EntityType.INVENTORY:
                return (this.hardware?.itemMaster?.asBuiltNumber ||
                    this.hardware?.itemInstance?.asBuiltNumber ||
                    this.hardware?.itemInstance?.itemMaster?.asBuiltNumber ||
                    this.hardware?.asBuiltNumber);
            default:
                return null;
        }
    }
    get asBuiltNumberDisplay() {
        if (!this.asBuiltNumber) {
            return DEFAULT_DASH;
        }
        return this.$asBuiltNumberDisplay(this.asBuiltNumber);
    }
    get asDesignNumberDisplay() {
        let asDesignNumber = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
            case EntityType.ITEM_MASTER:
                break;
            case EntityType.ITEM_INSTANCE:
                asDesignNumber = this.hardware?.asDesignNumber;
                break;
            case EntityType.INVENTORY:
                asDesignNumber = this.hardware?.itemInstance?.asDesignNumber;
                break;
            default:
                asDesignNumber = null;
        }
        return (asDesignNumber || DEFAULT_DASH);
    }
    get acquisitionDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.acquisitionDate || this.hardware?.itemInstance?.acquisitionDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get birthDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.birthDate || this.hardware?.itemInstance?.birthDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get categoryCodeDisplay() {
        let categoryCode = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                categoryCode = null;
                break;
            case EntityType.ITEM_MASTER:
                categoryCode = this.hardware?.categoryCode;
                break;
            case EntityType.ITEM_INSTANCE:
                categoryCode = this.hardware?.itemMaster?.categoryCode;
                break;
            case EntityType.INVENTORY:
                categoryCode = this.hardware?.itemInstace?.itemMaster?.categoryCode || this.hardware?.itemMaster?.categoryCode;
                break;
            default:
                categoryCode = null;
        }
        if (!categoryCode) {
            return DEFAULT_DASH;
        }
        return ItemMasterCategoryCodeDisplay.get(categoryCode) || DEFAULT_DASH;
    }
    get ceiNumberDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return null;
            case EntityType.ITEM_MASTER:
                return this.hardware?.contractEndingItemNumber || DEFAULT_DASH;
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.contractEndingItemNumber || DEFAULT_DASH;
            case EntityType.INVENTORY:
                return (this.hardware?.itemInstance?.itemMaster?.contractEndingItemNumber ||
                    this.hardware?.itemMaster?.contractEndingItemNumber ||
                    DEFAULT_DASH);
            default:
                return null;
        }
    }
    get ceiLevelDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.contractEndingItemLevel || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.contractEndingItemLevel || DEFAULT_DASH;
            default:
                return null;
        }
    }
    get componentIdDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.componentId || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.componentId || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get cureDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.cureDate || this.hardware?.itemInstance?.cureDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get descriptionDisplay() {
        let description = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                description = this.hardware?.description;
                break;
            case EntityType.ITEM_MASTER:
                description = this.hardware?.itemDrawing?.description || this.hardware?.itemDrawingDescription;
                break;
            case EntityType.ITEM_INSTANCE:
                description =
                    this.hardware?.itemMaster?.itemDrawing?.description ||
                        this.hardware?.itemMaster?.itemDrawingDescription ||
                        this.hardware?.itemDrawingDescription ||
                        this.hardware?.itemDrawing?.description ||
                        this.hardware?.description;
                break;
            case EntityType.INVENTORY:
                description =
                    this.hardware?.itemInstanceCrossContext?.description ||
                        this.hardware?.itemInstance?.itemMaster?.itemDrawing?.description ||
                        this.hardware?.itemMaster?.itemDrawing?.description ||
                        this.hardware?.itemInstance?.itemDrawing?.description ||
                        this.hardware?.itemDrawing?.description ||
                        this.hardware?.itemDrawingDescription ||
                        this.hardware?.description;
                break;
            default:
                description = null;
        }
        if (!description) {
            return DEFAULT_DASH;
        }
        return description;
    }
    get drawingNumber() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return this.hardware?.drawingNumber;
            case EntityType.ITEM_MASTER:
                return this.hardware?.drawingNumber || this.hardware?.itemDrawing?.drawingNumber;
            case EntityType.ITEM_INSTANCE:
                return (this.hardware?.itemMaster?.itemDrawing?.drawingNumber ||
                    this.hardware?.itemDrawing?.drawingNumber ||
                    this.hardware?.drawingNumber);
            case EntityType.INVENTORY:
                return (this.hardware?.itemInstance?.itemMaster?.itemDrawing?.drawingNumber ||
                    this.hardware?.itemDrawing?.drawingNumber ||
                    this.hardware?.drawingNumber);
            default:
                return null;
        }
    }
    get drawingNumberDisplay() {
        if (!this.drawingNumber) {
            return DEFAULT_DASH;
        }
        return this.drawingNumber;
    }
    get ecnDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return DEFAULT_DASH;
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.equipmentControlNumber || DEFAULT_DASH;
            case EntityType.INVENTORY:
                return this.hardware?.itemInstance?.equipmentControlNumber;
            default:
                return DEFAULT_DASH;
        }
    }
    get equipmentGroupDisplay() {
        let equipmentGroup = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                equipmentGroup = null;
                break;
            case EntityType.ITEM_MASTER:
                equipmentGroup = this.hardware?.subType || this.hardware?.itemMasterSubType || this.hardware?.equipmentGroup;
                break;
            case EntityType.ITEM_INSTANCE:
                equipmentGroup = this.hardware?.itemMaster?.subType || this.hardware?.itemMaster?.equipmentGroup;
                break;
            case EntityType.INVENTORY:
                equipmentGroup =
                    this.hardware?.itemInstance?.itemMaster?.subType ||
                        this.hardware?.itemInstance?.itemMaster?.equipmentGroup ||
                        this.hardware?.itemMaster?.subType ||
                        this.hardware?.itemMaster?.equipmentGroup;
                break;
            default:
                equipmentGroup = null;
        }
        if (!equipmentGroup) {
            return DEFAULT_DASH;
        }
        return EquipmentGroupDisplay.get(equipmentGroup) || DEFAULT_DASH;
    }
    get etcaNomenclatureDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.groundSupportEquipment?.etcaNomenclature || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.groundSupportEquipment?.etcaNomenclature || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get federalSupplyClass() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return null;
            case EntityType.ITEM_MASTER:
                return this.hardware?.federalSupplyClass;
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.federalSupplyClass;
            case EntityType.INVENTORY:
                return (this.hardware?.itemInstance?.itemMaster?.federalSupplyClass || this.hardware?.itemMaster?.federalSupplyClass);
            default:
                return null;
        }
    }
    get federalSupplyClassDisplay() {
        const fsc = this.federalSupplyClass;
        if (!fsc) {
            return DEFAULT_DASH;
        }
        return `${FederalSupplyClassFCSRef.get(fsc)} - ${FederalSupplyClassDisplay.get(fsc)}`;
    }
    get formattedForTable() {
        if (!this.hardwareEntityType || !this.hardware) {
            return [];
        }
        const data = { ...this.hardware };
        let displayData = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                displayData = transformItemDrawings([data]);
                break;
            case EntityType.ITEM_MASTER:
                displayData = transformItemMasters([data]);
                break;
            case EntityType.ITEM_INSTANCE:
                displayData = transformItemInstances([data]);
                break;
            case EntityType.INVENTORY:
                displayData = transformInventories([data]);
                break;
            default:
                displayData = [];
        }
        // We only want the first one bc we only have 1 in this case
        return data[0];
    }
    get gaugeTrackingCommentsDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.groundSupportEquipment?.gaugeTrackingComments || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.groundSupportEquipment?.gaugeTrackingComments || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get gseTypeDisplay() {
        let gseSubType = null;
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                gseSubType = this.hardware?.itemMaster?.groundSupportEquipment?.subType;
                break;
            case EntityType.ITEM_MASTER:
                gseSubType = this.hardware?.groundSupportEquipment?.subType;
                break;
        }
        return gseSubType ? GroundSupportEquipmentTypeDisplay.get(gseSubType) || DEFAULT_DASH : DEFAULT_DASH;
    }
    get greenProcurementCodeDisplay() {
        let greenProcurementCode = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                greenProcurementCode = null;
                break;
            case EntityType.ITEM_MASTER:
                greenProcurementCode = this.hardware?.greenProcurementCode;
                break;
            case EntityType.ITEM_INSTANCE:
                greenProcurementCode = this.hardware?.itemMaster?.greenProcurementCode;
                break;
            case EntityType.INVENTORY:
                greenProcurementCode =
                    this.hardware?.itemInstace?.itemMaster?.greenProcurementCode ||
                        this.hardware?.itemMaster?.greenProcurementCode;
                break;
            default:
                greenProcurementCode = null;
        }
        if (!greenProcurementCode) {
            return DEFAULT_DASH;
        }
        return GreenProcurementDisplay.get(greenProcurementCode) || DEFAULT_DASH;
    }
    get hardwareEntityType() {
        if (!this.hardware) {
            return null;
        }
        const typename = this.hardware?.__typename;
        if (typename === 'InventoryCrossContext') {
            return EntityType.INVENTORY;
        }
        return typenameToEntityType(typename);
    }
    get initializationStatus() {
        if (!this.hardware) {
            return null;
        }
        const entityType = this.hardwareEntityType;
        if (entityType === EntityType.ITEM_DRAWING) {
            return this.hardware?.initializationStatus;
        }
        else if (entityType === EntityType.ITEM_MASTER) {
            return this.hardware?.initializationStatus;
        }
        else if (entityType === EntityType.ITEM_INSTANCE) {
            return this.hardware?.itemMaster?.initializationStatus;
        }
        else if (entityType === EntityType.INVENTORY) {
            return (this.hardware?.itemInstance?.itemMaster?.initializationStatus || this.hardware?.itemMaster?.initializationStatus);
        }
        return null;
    }
    get initializationStatusDisplay() {
        if (!this.hardware) {
            return null;
        }
        const initStatus = this.initializationStatus;
        if (!initStatus) {
            return null;
        }
        return ChangeSetStatusDisplay.get(initStatus) || '?';
    }
    get initializationStatusIconDisplay() {
        let initStatus = this.initializationStatus;
        if (!initStatus) {
            initStatus = 'null';
        }
        const _statusText = initStatus.toLowerCase();
        const icon = this.$icons[`pipeline_${_statusText}`];
        return `
      <div class="row wrap">
        <div class="xs12 status-icon-container">
          <span class="i status-icon ${icon} ${_statusText}" title="Status: ${this.initializationStatus ? _statusText.toUpperCase() : 'NONE'}"></span>
        </div>
      </div>
    `;
    }
    get inventoryControlDisplay() {
        let inventoryControl = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                inventoryControl = null;
                break;
            case EntityType.ITEM_MASTER:
                inventoryControl = this.hardware?.inventoryControl;
                break;
            case EntityType.ITEM_INSTANCE:
                inventoryControl = this.hardware?.itemMaster?.inventoryControl;
                break;
            case EntityType.INVENTORY:
                inventoryControl =
                    this.hardware?.itemInstace?.itemMaster?.inventoryControl || this.hardware?.itemMaster?.inventoryControl;
                break;
            default:
                inventoryControl = null;
        }
        if (!inventoryControl) {
            return DEFAULT_DASH;
        }
        return InventoryControlDisplay.get(inventoryControl) || DEFAULT_DASH;
    }
    get inventoryMethodDisplay() {
        let inventoryMethod = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                inventoryMethod = null;
                break;
            case EntityType.ITEM_MASTER:
                inventoryMethod = this.hardware?.inventoryMethod;
                break;
            case EntityType.ITEM_INSTANCE:
                inventoryMethod = this.hardware?.itemMaster?.inventoryMethod;
                break;
            case EntityType.INVENTORY:
                inventoryMethod =
                    this.hardware?.itemInstace?.itemMaster?.inventoryMethod || this.hardware?.itemMaster?.inventoryMethod;
                break;
            default:
                inventoryMethod = null;
        }
        if (!inventoryMethod) {
            return DEFAULT_DASH;
        }
        return InventoryMethodDisplay.get(inventoryMethod) || DEFAULT_DASH;
    }
    get itemClassDisplay() {
        let itemClass = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                itemClass = null;
                break;
            case EntityType.ITEM_MASTER:
                itemClass = null;
                break;
            case EntityType.ITEM_INSTANCE:
                itemClass = this.hardware?.subType || this.hardware?.itemClass;
                break;
            case EntityType.INVENTORY:
                itemClass = this.hardware?.subType || this.hardware?.inventorySubType;
                break;
            default:
                itemClass = null;
        }
        if (!itemClass) {
            return DEFAULT_DASH;
        }
        return ItemInstanceInventoryTypeDisplay.get(itemClass) || DEFAULT_DASH;
    }
    get isArchived() {
        return Boolean(this.hardware?.isArchived);
    }
    get isCwc() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33120607');
    }
    /**
     * Is this hardware a Contingency Water Container that applies to the DUMP activity
     */
    get isDumpCwc() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33113140');
    }
    get isEmu() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === 'SED13101492';
    }
    get isEmuIonFilter() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33118472');
    }
    get isEmuMicronFilter() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        // SEG33118473-301
        return drawingNumber.startsWith('SEG33118473');
    }
    get isEmuBiocideFilter() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33118474');
    }
    get isEmuScrubAssembly() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        // SEG33125129, SEG33125129-801, SEG33125129-802
        return drawingNumber.startsWith('SEG33125129');
    }
    get isEmuWaterProcessingJumper() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33125118');
    }
    /**
     * Sometimes referred to as EMU BATT LLB
     */
    get isEmuLongLifeBattery() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === 'SV1014881';
    }
    get isFanPumpSeparator() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === 'SV787994';
    }
    // ISS EMU UMBILICAL
    get isIeuScu() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === 'SV819500-07-00';
    }
    get isLcvg() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('0107-10007');
    }
    /**
     * Li-Ion Rechargeable EVA battery assembly
     */
    get isLreba() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === '';
    }
    get isO2PoppetVerficationItem() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber === 'SV819500-07-00';
    }
    /**
     * Is this a Payload Water Reservoir (PWR)
     */
    get isPwr() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG46117689');
    }
    get isHutOru() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('0102-110102');
    }
    get isHlBattery() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG39130223');
    }
    get isLibCharger() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG13102011');
    }
    get isLpgt() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('??? LPGT');
    }
    get isMetox() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SV821799');
    }
    /**
     * Is this hardware a Pistol Grip Tool?
     */
    get isPgt() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('GE1557000');
    }
    get isPgtBattery() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33110979');
    }
    get isReba() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG3112213');
    }
    get isSafer() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SED33105900');
    }
    get isSafetyTether55() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33106674');
    }
    get isSafetyTether85() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SED33116109');
    }
    get isScof() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SV799099');
    }
    get isTetherExtension() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33112321') || drawingNumber.startsWith('TSEG33112321');
    }
    get isUiaBiocideFilter() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG39128318');
    }
    get isWaistTether() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('SEG33106943');
    }
    get isWlvta() {
        const drawingNumber = this.drawingNumber;
        if (!this.hardware || !drawingNumber) {
            return false;
        }
        return drawingNumber.startsWith('0102-213220');
    }
    /**
     * Returns the Hardware's pd nodeId if applicable
     */
    get itemDrawingNodeId() {
        if (!this.hardware) {
            return null;
        }
        const entityType = this.hardwareEntityType;
        if (entityType === EntityType.ITEM_DRAWING) {
            return this.hardware?.nodeId || null;
        }
        else if (entityType === EntityType.ITEM_MASTER) {
            return this.hardware?.itemDrawing?.nodeId || null;
        }
        else if (entityType === EntityType.ITEM_INSTANCE) {
            return this.hardware?.itemDrawing?.nodeId || this.hardware?.itemMaster?.itemDrawing?.nodeId || null;
        }
        else if (entityType === EntityType.INVENTORY) {
            return (this.hardware?.itemInstance?.itemMaster?.itemDrawing?.nodeId ||
                this.hardware?.itemInstance?.itemDrawing?.nodeId ||
                this.hardware?.itemDrawing?.nodeId ||
                this.hardware?.itemMaster?.itemDrawing?.nodeId ||
                null);
        }
        return null;
    }
    /**
     * Return the Hardware's pc nodeId if applicable
     */
    get itemMasterNodeId() {
        if (!this.hardware) {
            return null;
        }
        const entityType = this.hardwareEntityType;
        if (entityType === EntityType.ITEM_DRAWING) {
            return null;
        }
        else if (entityType === EntityType.ITEM_MASTER) {
            return this.hardware?.nodeId || null;
        }
        else if (entityType === EntityType.ITEM_INSTANCE) {
            return this.hardware?.itemMaster?.nodeId || null;
        }
        else if (entityType === EntityType.INVENTORY) {
            return this.hardware?.itemInstance?.itemMaster?.nodeId || this.hardware?.itemMaster?.nodeId || null;
        }
        return null;
    }
    /**
     * Returns the hardware's component nodeId if applicable
     */
    get itemInstanceNodeId() {
        if (!this.hardware) {
            return null;
        }
        const entityType = this.hardwareEntityType;
        if (entityType === EntityType.ITEM_DRAWING) {
            return null;
        }
        else if (entityType === EntityType.ITEM_MASTER) {
            return null;
        }
        else if (entityType === EntityType.ITEM_INSTANCE) {
            return this.hardware?.nodeId || null;
        }
        else if (entityType === EntityType.INVENTORY) {
            return this.hardware?.itemInstance?.nodeId || null;
        }
        return null;
    }
    get limitedLife() {
        if (!this.hardware) {
            return null;
        }
        return this.hardware.limitedLife;
    }
    get limitedLifeCycleTypeDisplay() {
        if (!this.limitedLife) {
            return [];
        }
        return limitedLifeNonIntervalTypes.map((llKey) => {
            const llData = this.limitedLife && this.limitedLife[llKey];
            return {
                limitedLifeName: this.$humanize(llKey),
                values: [
                    {
                        displayName: `Quota`,
                        displayValue: this.$quantityDisplay(llData?.value)
                    },
                    {
                        displayName: `Svc Quota`,
                        displayValue: this.$quantityDisplay(llData?.service)
                    }
                ]
            };
        });
    }
    get limitedLifeIntervalTypeDisplay() {
        if (!this.limitedLife) {
            return [];
        }
        return limitedLifeIntervalTypes.map((llKey) => {
            const llData = this.limitedLife && this.limitedLife[llKey];
            return {
                limitedLifeName: this.$humanize(llKey),
                values: [
                    {
                        displayName: `Quota`,
                        displayValue: this.$intervalDisplay(llData?.value)
                    },
                    {
                        displayName: `Svc Quota`,
                        displayValue: this.$intervalDisplay(llData?.service)
                    }
                ]
            };
        });
    }
    get limitedLifeRemaining() {
        if (!this.hardware) {
            return null;
        }
        return this.hardware.limitedLifeRemaining;
    }
    get limitedLifeRemainingCycleTypeDisplay() {
        if (!this.limitedLifeRemaining) {
            return [];
        }
        return limitedLifeNonIntervalTypes.map((llKey) => {
            const llValue = this.limitedLifeRemaining && this.limitedLifeRemaining[llKey];
            return {
                limitedLifeName: this.$humanize(llKey),
                values: [
                    {
                        displayName: `Remaining`,
                        displayValue: this.$quantityDisplay(llValue?.value)
                    },
                    {
                        displayName: `Svc Remaining`,
                        displayValue: this.$quantityDisplay(llValue?.service)
                    }
                ]
            };
        });
    }
    get limitedLifeRemainingIntervalTypeDisplay() {
        if (!this.limitedLifeRemaining) {
            return [];
        }
        return limitedLifeIntervalTypes.map((llKey) => {
            // you would think the extra existence check wouldn't be necessary bc of the above check
            const llData = this.limitedLifeRemaining && this.limitedLifeRemaining[llKey];
            return {
                limitedLifeName: this.$humanize(llKey),
                values: [
                    {
                        displayName: 'Expiry',
                        displayValue: this.$dateDisplay(llData?.valueDueDate)
                    },
                    {
                        displayName: 'Svc Expiry',
                        displayValue: this.$dateDisplay(llData?.serviceDueDate)
                    }
                ]
            };
        });
    }
    get lotNumberDisplay() {
        let lotNumber = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
            case EntityType.ITEM_MASTER:
                break;
            case EntityType.ITEM_INSTANCE:
                lotNumber = this.hardware?.lotNumber;
                break;
            case EntityType.INVENTORY:
                lotNumber = this.hardware?.itemInstance?.lotNumber || this.hardware?.lotNumber;
                break;
            default:
                lotNumber = null;
        }
        return this.$lotNumberDisplay(lotNumber);
    }
    get locationDisplay() {
        return this.$locationDisplay(this.hardware?.location);
    }
    get objectClassDisplay() {
        const fsc = this.federalSupplyClass;
        if (!fsc) {
            return DEFAULT_DASH;
        }
        return `${FederalSupplyClassToObjectClass.get(fsc)}`;
    }
    get operationalStartDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.operationalStartDate || this.hardware?.itemInstance?.operationalStartDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get lastInspectionDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.computedLastInspectionDate || this.hardware?.itemInstance?.computedLastInspectionDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get lastServiceDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.computedLastServiceDate || this.hardware?.itemInstance?.computedLastServiceDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get makeBuySupplyDisplay() {
        let makeBuySupply = null;
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                makeBuySupply = this.hardware?.itemMaster?.planning?.makeBuySupply;
                break;
            case EntityType.ITEM_MASTER:
                makeBuySupply = this.hardware?.planning?.makeBuySupply;
        }
        return makeBuySupply ? ItemMasterMakeBuySupplyDisplay.get(makeBuySupply) : DEFAULT_DASH;
    }
    get manufactureDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.manufactureDate || this.hardware?.itemInstance?.manufactureDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get meanTimeBetweenFailureDisplay() {
        let mtbf = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                mtbf = this.hardware?.meanTimeBetweenFailure;
                break;
            case EntityType.ITEM_MASTER:
            case EntityType.ITEM_INSTANCE:
            case EntityType.INVENTORY:
                mtbf = this.hardware?.itemDrawing?.meanTimeBetweenFailure;
        }
        return intervalDisplay(mtbf);
    }
    get meanTimeToRepairDisplay() {
        let mttr = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                mttr = this.hardware?.meanTimeToRepair;
                break;
            case EntityType.ITEM_MASTER:
            case EntityType.ITEM_INSTANCE:
            case EntityType.INVENTORY:
                mttr = this.hardware?.itemDrawing?.meanTimeToRepair;
        }
        return intervalDisplay(mttr);
    }
    get opsNomDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.opsNom || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.opsNom || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get overrideSoftwareVersionDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.overrideSoftwareVersion || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get overrideHazardousMaterialTextDisplay() {
        let text = null;
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                text = this.hardware?.overrideHazardousMaterialText;
                break;
            default:
                text = null;
        }
        return text ? markdownToHtml(text) : DEFAULT_DASH;
    }
    get overrideMeanTimeToRepairDisplay() {
        let time = null;
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
                time = this.hardware?.overrideMeanTimeToRepair;
                break;
            case EntityType.ITEM_INSTANCE:
                time = this.hardware?.itemInstance?.overrideMeanTimeToRepair;
                break;
            default:
                time = null;
        }
        return time ? intervalDisplay(time) : DEFAULT_DASH;
    }
    get pdaDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.pdaDate || this.hardware?.itemInstance?.pdaDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get piaDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.piaDate || this.hardware?.itemInstance?.piaDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get pointOfContactDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return this.$contactDisplay(this.hardware?.pointOfContact);
            case EntityType.ITEM_MASTER:
                return this.$contactDisplay(this.hardware?.itemDrawing?.pointOfContact);
            case EntityType.ITEM_INSTANCE:
                return this.$contactDisplay(this.hardware?.itemDrawing?.pointOfContact);
            case EntityType.INVENTORY:
                return this.$contactDisplay(this.hardware?.itemDrawing?.pointOfContact);
            default:
                return DEFAULT_DASH;
        }
    }
    get propertyClass() {
        let propClass = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                propClass = null;
                break;
            case EntityType.ITEM_MASTER:
                propClass = this.hardware?.propertyClass;
                break;
            case EntityType.ITEM_INSTANCE:
                propClass = this.hardware?.itemMaster?.propertyClass;
                break;
            case EntityType.INVENTORY:
                propClass = this.hardware?.itemInstance?.itemMaster?.propertyClass || this.hardware?.itemMaster?.propertyClass;
                break;
            default:
                propClass = null;
        }
        return propClass;
    }
    get propertyClassDisplay() {
        let propClass = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                propClass = null;
                break;
            case EntityType.ITEM_MASTER:
                propClass = this.hardware?.propertyClass;
                break;
            case EntityType.ITEM_INSTANCE:
                propClass = this.hardware?.itemMaster?.propertyClass;
                break;
            case EntityType.INVENTORY:
                propClass = this.hardware?.itemInstance?.itemMaster?.propertyClass || this.hardware?.itemMaster?.propertyClass;
                break;
            default:
                propClass = null;
        }
        if (!propClass) {
            return DEFAULT_DASH;
        }
        return ItemMasterPropertyClassDisplay.get(propClass) || DEFAULT_DASH;
    }
    get preciousMetalDisplay() {
        let preciousMetalCode = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                preciousMetalCode = null;
                break;
            case EntityType.ITEM_MASTER:
                preciousMetalCode = this.hardware?.preciousMetalCode;
                break;
            case EntityType.ITEM_INSTANCE:
                preciousMetalCode = this.hardware?.itemMaster?.preciousMetalCode;
                break;
            case EntityType.INVENTORY:
                preciousMetalCode =
                    this.hardware?.itemInstance?.itemMaster?.preciousMetalCode || this.hardware?.itemMaster?.preciousMetalCode;
                break;
            default:
                preciousMetalCode = null;
        }
        if (!preciousMetalCode) {
            return DEFAULT_DASH;
        }
        return (PreciousMetalDisplay.get(preciousMetalCode) || DEFAULT_DASH);
    }
    get projectCodeDisplay() {
        const projectCode = this.hardware?.projectCode;
        return projectCode || DEFAULT_DASH;
    }
    get projectCodeWithNameDisplay() {
        const projectCode = this.hardware?.projectCode;
        const projectCodeName = ProjectCodeDisplay.get(projectCode);
        if (!projectCodeName) {
            return projectCode;
        }
        return `${projectCode} — ${projectCodeName}`;
    }
    get purchaseRequestDateDisplay() {
        const date = this.$dateDisplay(this.hardware?.purchaseRequestDate || this.hardware?.itemInstance?.purchaseRequestDate);
        if (!this.hardware || !date) {
            return DEFAULT_DASH;
        }
        return date;
    }
    get referenceDesignatorDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.referenceDesignator || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.referenceDesignator || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    /**
     * Returns the relevant change_set subTypes based on the hardware type
     *
     * It returns those subTypes that START with the hardwaretype
     */
    get relevantChangeSetSubTypes() {
        const type = this.hardwareEntityType;
        if (!type) {
            return [];
        }
        // hacky removal of the 's' bc our entity types are weird
        const withoutS = type.substr(0, type.length - 1);
        return Object.keys(ChangeSetType).filter((csType) => csType.toLowerCase().startsWith(withoutS));
    }
    get relevantChangeSetSubTypeDisplays() {
        return this.relevantChangeSetSubTypes.map((csType) => ChangeSetTypeDisplay.get(csType));
    }
    // Intended to be overriden
    get relevantChangeSetFetchVariables() {
        if (!this.hardwareEntityType || !this.hardware) {
            return null;
        }
        const subType = this.relevantChangeSetSubTypes;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                return {
                    drawingNumber: [this.drawingNumber],
                    subType
                };
            case EntityType.ITEM_MASTER:
                return {
                    drawingNumber: [this.drawingNumber],
                    asBuiltNumber: [this.asBuiltNumber],
                    subType
                };
            case EntityType.ITEM_INSTANCE:
                return {
                    drawingNumber: [this.drawingNumber],
                    asBuiltNumber: [this.asBuiltNumber],
                    subType
                };
            default:
                return null;
        }
    }
    get relevantChangeSetsByType() {
        if (!this.relevantChangeSetSubTypes) {
            return {};
        }
        const tableReadyObjs = transformChangeSets(this.relevantChangeSets || []);
        return this.relevantChangeSetSubTypes.reduce((acc, curr) => {
            if (!acc.hasOwnProperty(curr)) {
                acc[curr] = tableReadyObjs.filter((cs) => cs.subType === curr);
            }
            return acc;
        }, {});
    }
    get serialNumberDisplay() {
        let serialNumber = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
            case EntityType.ITEM_MASTER:
                break;
            case EntityType.ITEM_INSTANCE:
                serialNumber = this.hardware?.serialNumber;
                break;
            case EntityType.INVENTORY:
                serialNumber = this.hardware?.itemInstance?.serialNumber || this.hardware?.serialNumber;
                break;
            default:
                serialNumber = null;
        }
        return this.$serialNumberDisplay(serialNumber);
    }
    get side() {
        let side = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
            case EntityType.ITEM_MASTER:
                side = this.hardware?.side;
                break;
            case EntityType.ITEM_INSTANCE:
                side = this.hardware?.side || this.hardware?.itemMaster?.side;
                break;
            case EntityType.INVENTORY:
                side = this.hardware?.itemInstance?.side || this.hardware?.itemMaster?.side || this.hardware?.side;
                break;
            default:
                side = null;
        }
        return side;
    }
    get sideDisplay() {
        return this.$sideDisplay(this.side);
    }
    get sizeDisplay() {
        let size = null;
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
            case EntityType.ITEM_MASTER:
                break;
            case EntityType.ITEM_INSTANCE:
                size = this.hardware?.size;
                break;
            case EntityType.INVENTORY:
                size = this.hardware?.itemInstance?.size || this.hardware?.size;
                break;
            default:
                size = null;
        }
        return this.$sizeDisplay(size);
    }
    get subTypeDisplay() {
        const subType = this.hardware?.subType;
        return subType ? typeDisplay(this.hardwareEntityType, subType) : DEFAULT_DASH;
    }
    get testStandNameDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.groundSupportEquipment?.testStandName || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.groundSupportEquipment?.testStandName || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get testStandNumberDisplay() {
        switch (this.hardwareEntityType) {
            case EntityType.INVENTORY:
            case EntityType.ITEM_INSTANCE:
                return this.hardware?.itemMaster?.groundSupportEquipment?.testStandNumber || DEFAULT_DASH;
            case EntityType.ITEM_MASTER:
                return this.hardware?.groundSupportEquipment?.testStandNumber || DEFAULT_DASH;
            default:
                return DEFAULT_DASH;
        }
    }
    get validSizes() {
        let validSizes = [];
        switch (this.hardwareEntityType) {
            case EntityType.ITEM_DRAWING:
                validSizes = this.hardware?.sizes || [];
                break;
            case EntityType.ITEM_MASTER:
                validSizes = this.hardware?.itemDrawing?.sizes || [];
                break;
            case EntityType.ITEM_INSTANCE:
                validSizes = this.hardware?.itemDrawing?.sizes || this.hardware?.itemMaster?.itemDrawing?.sizes || [];
                break;
            case EntityType.INVENTORY:
                validSizes =
                    this.hardware?.itemInstance?.itemMaster?.itemDrawing?.sizes ||
                        this.hardware?.itemInstance?.itemDrawing?.sizes ||
                        this.hardware?.itemDrawing?.sizes ||
                        [];
                break;
            default:
                validSizes = [];
        }
        return validSizes;
    }
    get validSizesDisplayString() {
        const validSizes = this.validSizes;
        if (!validSizes || validSizes.length === 0) {
            return DEFAULT_DASH;
        }
        return validSizes && validSizes.length > 0 ? validSizes.join(', ') : DEFAULT_DASH;
    }
    get validSizesDisplayChip() {
        const sizes = this.validSizes;
        return sizes && sizes.length > 0
            ? sizes.map((size) => {
                return {
                    text: size,
                    color: EntityType.ITEM_DRAWING,
                    disabled: true,
                    textColor: 'white',
                    value: size
                };
            })
            : [];
    }
    /**
     * Checks to see if limited Life form has value of certain property
     */
    limitedLifeFormHas(limitedLifeForm, field) {
        const limitedLifeField = this.$get(limitedLifeForm, field, null) || null;
        if (limitedLifeField === null) {
            return false;
        }
        // This is of type `string`
        if (field === 'description') {
            return limitedLifeField !== null;
        }
        // This is of type `somethingelse i foret`
        if (field === 'code') {
            return limitedLifeField !== null;
        }
        // not the best :/
        const reassign = limitedLifeField;
        return (reassign &&
            reassign.value &&
            Object.keys(reassign.value).filter((dateKey) => reassign[dateKey] !== null).length > 0);
    }
    async cloneToHardware(val) {
        if (val) {
            this.hardware = { ...val };
        }
        else {
            this.hardware = null;
        }
    }
    fetchReleventChangeSets() {
        if (!this.relevantChangeSetFetchVariables) {
            return Promise.resolve(null);
        }
        return apolloClient.query({
            query: gql `
        ${CosmicChangeSetStatusSlimFragment}
        ${CosmicItemDrawingSlimFragment}

        fragment SearchChangeSetWithinHardware on ChangeSet {
          asBuiltNumber
          drawingNumber
          id
          nodeId
          payload
          subType
          itemInstance {
            lotNumber
            nodeId
            serialNumber

            itemMaster {
              nodeId
              itemDrawing {
                ...CosmicItemDrawingSlim
              }
            }
          }
          status {
            ...CosmicChangeSetStatusSlim
          }
        }

        query changeSetSearchWithinHardware(
          $asBuiltNumber: [String]
          $drawingNumber: [String]
          $firstResults: Int
          $query: String
          $status: [ChangeSetStatus]
          $subType: [String]
        ) {
          searchChangeSets(
            asBuiltNumber: $asBuiltNumber
            drawingNumber: $drawingNumber
            first: $firstResults
            query: $query
            status: $status
            subType: $subType
          ) {
            nodes {
              ...SearchChangeSetWithinHardware
            }
          }
        }
      `,
            variables: this.relevantChangeSetFetchVariables
        });
    }
    fetchActivities() {
        if (!this.activitiesFetchVariables) {
            return Promise.resolve(null);
        }
        return apolloClient.query({
            query: gql `
        fragment ActivityForItemInstanceFragment on Activity {
          authorityNumber
          attributes
          commentText: computedCommentText
          endDateTime
          isManned
          itemInstance {
            asBuiltNumber
            drawingNumber
            itemMaster {
              nodeId
            }
            itemDrawing {
              nodeId
              description
            }
            nodeId
          }
          name
          nodeId
          startDateTime
          subType
        }

        query fetchActivitiesForItemInstanceManage($condition: ActivityCondition) {
          activities(condition: $condition, orderBy: START_DATE_TIME_DESC) {
            nodes {
              ...ActivityForItemInstanceFragment
            }
          }
        }
      `,
            variables: this.activitiesFetchVariables
        });
    }
}
