var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import Hardware from '~/nasa_ui/modules/Hardware';
import { EntityType, Maybe } from '~/nasa_ui/types';
import { stripHtmlFromString } from '~/nasa_ui/utils/helpers/stripHtmlFromString';
let MiniInventory = class MiniInventory extends BaseMini {
    entityType = EntityType.INVENTORY;
    localEntity = null;
    hardware = null;
    showDetail = false;
    BASE_FETCH = this.nodeId
        ? gql `
        ${CosmicLocationSlimFragment}

        fragment InventoryForMini on Inventory {
          asBuiltNumber
          drawingNumber
          id
          isInstalled
          itemDrawingDescription
          lotNumber
          nodeId
          quantity
          serialNumber
          side
          size
          subType
          unitCost

          location {
            ...CosmicLocationSlim
          }

          itemInstance {
            id
            nodeId
          }
        }

        query InventoryForMini($nodeId: ID!) {
          inventory(nodeId: $nodeId) {
            ...InventoryForMini
          }
        }
      `
        : gql `
        ${CosmicLocationSlimFragment}

        fragment InventoryForMini on Inventory {
          asBuiltNumber
          drawingNumber
          id
          isInstalled
          itemDrawingDescription
          lotNumber
          nodeId
          quantity
          serialNumber
          side
          size
          subType
          unitCost
          location {
            ...CosmicLocationSlim
          }

          itemInstance {
            id
            nodeId
          }
        }

        query InventoryByIdForMini($id: UUID!) {
          inventoryById(id: $id) {
            ...InventoryForMini
          }
        }
      `;
    allowEdit;
    isExternal;
    sequence;
    get asBuiltNumberDisplay() {
        return this.hardware?.asBuiltNumberDisplay;
    }
    get calibrationNumberDisplay() {
        if (this.localEntity && 'externalCalibrationNumber' in this.localEntity) {
            return this.localEntity.externalCalibrationNumber || DEFAULT_DASH;
        }
        return null;
    }
    get currentLocationDisplay() {
        return `${this.hardware?.locationDisplay} (<strong class="monospace_font">${this.localEntity?.quantity}</strong>)`;
    }
    get currentLocationSansHtml() {
        return stripHtmlFromString(this.currentLocationDisplay);
    }
    get classDisplay() {
        if (this.localEntity && 'externalClass' in this.localEntity) {
            return this.localEntity.externalClass
                ? this.$typeDisplay(EntityType.INVENTORY, this.localEntity.externalClass)
                : DEFAULT_DASH;
        }
        return this.hardware?.subTypeDisplay;
    }
    get descriptionDisplay() {
        if (this.localEntity && 'externalDescription' in this.localEntity) {
            return this.localEntity.externalDescription || DEFAULT_DASH;
        }
        return this.hardware?.descriptionDisplay;
    }
    get drawingNumber() {
        if (this.localEntity && 'externalDrawingNumber' in this.localEntity) {
            return this.localEntity.externalDrawingNumber || DEFAULT_DASH;
        }
        return this.hardware?.drawingNumberDisplay;
    }
    get itemInstanceNodeId() {
        return this.hardware?.itemInstanceNodeId;
    }
    get inventoryNodeId() {
        return this.localEntity?.nodeId;
    }
    get isTool() {
        if (this.isExternal && this.localEntity && 'externalIsTool' in this.localEntity) {
            return this.localEntity.externalIsTool;
        }
        return null;
    }
    get lotNumberDisplay() {
        if (this.localEntity && 'externalLotNumber' in this.localEntity) {
            return this.$lotNumberDisplay(this.localEntity.externalLotNumber);
        }
        return this.hardware?.lotNumberDisplay;
    }
    get manageLink() {
        return this.localEntity && this.itemInstanceNodeId
            ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${this.itemInstanceNodeId}#inventory`
            : '';
    }
    get name() {
        return this.drawingNumber || DEFAULT_DASH;
    }
    get shouldShowSequence() {
        return this.sequence !== null;
    }
    get shouldShowDetail() {
        return this.showDetail;
    }
    get serialNumberDisplay() {
        if (this.localEntity && 'externalSerialNumber' in this.localEntity) {
            return this.$serialNumberDisplay(this.localEntity.externalSerialNumber);
        }
        return this.hardware?.serialNumberDisplay;
    }
    get sideDisplay() {
        return this.hardware?.sideDisplay;
    }
    get sizeDisplay() {
        return this.hardware?.sizeDisplay;
    }
    onFetchUpdate(data) {
        if (data.inventory || data.inventoryById) {
            this.$set(this, 'localEntity', data.inventory || data.inventoryById);
            this.hardware = new Hardware(this.localEntity);
        }
    }
    onChangeOfEntity(entity) {
        if (!entity) {
            return;
        }
        if (this.isExternal) {
            entity.__typename = 'Inventory';
        }
        this.$set(this, 'localEntity', entity);
        this.hardware = new Hardware(entity);
    }
    // Called from the ui
    onClickOfShowDetail() {
        this.showDetail = !this.showDetail;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniInventory.prototype, "isExternal", void 0);
__decorate([
    Prop({
        default: null,
        type: Number
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], MiniInventory.prototype, "sequence", void 0);
__decorate([
    Watch('entity', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MiniInventory.prototype, "onChangeOfEntity", null);
MiniInventory = __decorate([
    Component
], MiniInventory);
export default MiniInventory;
