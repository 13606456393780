var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mb-2"
  }, [_vm.documentId ? _c('AppPageDescription', {
    attrs: {
      "remove-margin-left": ""
    }
  }, [_vm._v("Autosave enabled for Tags. ")]) : _vm._e(), _c('v-autocomplete', _vm._b({
    attrs: {
      "color": _vm.computedColor,
      "error-messages": _vm.errorMessages,
      "items": _vm.computedExistingTags,
      "label": _vm.label,
      "search-input": _vm.inputValue,
      "hide-no-data": "",
      "hint": "Hit ENTER to add a tag",
      "outline": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.inputValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.inputValue = $event;
      },
      "focusout": function ($event) {
        _vm.inputValue = '';
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.onEnter.apply(null, arguments);
      }
    }
  }, 'v-autocomplete', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false)), _c('div', {
    class: {
      selectedChips: _vm.computedChips.length
    }
  }, [_c('AppChip', {
    attrs: {
      "chips": _vm.computedChips,
      "color": _vm.computedColor
    },
    on: {
      "closeClicked": _vm.removeTag
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };