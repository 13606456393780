var _a, _b;
import { __decorate, __metadata } from "tslib";
import { FetchPolicy } from 'apollo-client';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { EntityType } from '~/nasa_ui/types';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
import AppInventoryCrossContextDisplay from './AppInventoryCrossContextDisplay/AppInventoryCrossContextDisplay.vue';
import AppInventoryDisplay from './AppInventoryDisplay/AppInventoryDisplay.vue';
import AppItemDrawingDisplay from './AppItemDrawingDisplay/AppItemDrawingDisplay.vue';
import AppItemInstanceDisplay from './AppItemInstanceDisplay/AppItemInstanceDisplay.vue';
import AppItemMasterDisplay from './AppItemMasterDisplay/AppItemMasterDisplay.vue';
let AppHardwareDisplay = class AppHardwareDisplay extends Vue {
    EntityType = EntityType;
    inventoryNodeId = '';
    inventoryQuantity = null;
    isSerialControlled = true;
    /* -- start IBaseHardwareDisplayProps -- */
    fetchPolicy;
    hideBorderBottom;
    hideButtons;
    nodeId;
    title;
    /* -- end IBaseHardwareDisplayProps -- */
    get computedAsBuiltNumber() {
        if (!this.isItemMaster) {
            return null;
        }
        return this.parsedNodeId.asBuiltNumber;
    }
    get computedDrawingNumber() {
        if (!this.isItemDrawing || !this.isItemMaster) {
            return null;
        }
        if (this.isItemDrawing) {
            return this.parsedNodeId.drawingNumber;
        }
        if (this.isItemMaster) {
            return this.parsedNodeId.drawingNumber;
        }
        return null;
    }
    get computedId() {
        if (!this.isItemInstance) {
            return null;
        }
        return this.parsedNodeId.id;
    }
    get computedSide() {
        if (!this.isItemMaster) {
            return null;
        }
        return this.parsedNodeId.side;
    }
    get computedTitle() {
        return this.title;
    }
    get hardwareType() {
        if (!this.nodeId) {
            return null;
        }
        return this.parsedNodeId?.entityType;
    }
    get parsedNodeId() {
        return parseNodeId(this.nodeId);
    }
    get hasComputedTitleOrSlot() {
        return this.hasTitleSlot || Boolean(this.computedTitle);
    }
    get hasTitleSlot() {
        return this.hasSlot('title');
    }
    get isInventory() {
        return this.hardwareType === EntityType.INVENTORY;
    }
    get isItemDrawing() {
        return this.hardwareType === EntityType.ITEM_DRAWING;
    }
    get isItemInstance() {
        return this.hardwareType === EntityType.ITEM_INSTANCE;
    }
    get isItemMaster() {
        return this.hardwareType === EntityType.ITEM_MASTER;
    }
    hardwareDataChange(val) {
        return val;
    }
    hasSlot(name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
};
__decorate([
    Prop({
        type: String,
        default: 'network-only'
    }),
    __metadata("design:type", typeof (_a = typeof FetchPolicy !== "undefined" && FetchPolicy) === "function" ? _a : Object)
], AppHardwareDisplay.prototype, "fetchPolicy", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplay.prototype, "hideButtons", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], AppHardwareDisplay.prototype, "title", void 0);
__decorate([
    Emit('hardwareDataChange'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], AppHardwareDisplay.prototype, "hardwareDataChange", null);
AppHardwareDisplay = __decorate([
    Component({
        components: {
            AppItemDrawingDisplay,
            AppItemMasterDisplay,
            AppItemInstanceDisplay,
            AppInventoryDisplay,
            AppInventoryCrossContextDisplay
        }
    })
], AppHardwareDisplay);
export default AppHardwareDisplay;
