var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.transformedActivity ? _c('v-card', {
    staticClass: "my-1"
  }, [_c('v-toolbar', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "light": "",
      "flat": ""
    },
    on: {
      "dblclick": function ($event) {
        return _vm.toggleExpansion(!_vm.localExpanded);
      }
    }
  }, [_c('v-toolbar-title', [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.computedIcon,
      "icon-color": "white",
      "tooltip": `${_vm.transformedActivity._subType} Activity`
    }
  }), _vm._v(" " + _vm._s(_vm.transformedActivity.name) + " ")], 1), _c('v-spacer'), _c('div', {
    staticClass: "d-flex"
  }, [_vm.localExpanded ? _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "icon-color": "#fff",
      "icon": "fad fa-window-minimize",
      "icon-size": "18",
      "tooltip": "Minimize card"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleExpansion(false);
      }
    }
  }) : _c('ButtonMiniAction', {
    staticClass: "mr-2",
    attrs: {
      "icon-color": "#fff",
      "icon": "fad fa-window-maximize",
      "icon-size": "18",
      "tooltip": "Maximize card"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleExpansion(true);
      }
    }
  }), _c('ButtonMiniActionManage', {
    attrs: {
      "icon-color": "#fff",
      "to": `/${_vm.EntityType.ACTIVITY}/manage/${_vm.transformedActivity.id}`,
      "tooltip": "View Activity details"
    }
  })], 1)], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.localExpanded ? _c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    },
    nativeOn: {
      "dblclick": function ($event) {
        return _vm.toggleExpansion(!_vm.localExpanded);
      }
    }
  }, [_c('v-list-tile', [_c('em', [_vm._v("Click maximize button to view.")])])], 1) : _vm._e()], 1), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.localExpanded ? _c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Type")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._subType) + " ")])])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Authority")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_vm.transformedActivity.authorityCode ? _c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._authorityCode) + " ")]) : _vm._e(), _vm.transformedActivity.authorityNumber ? _c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._authorityNumber) + " ")]) : _vm._e(), !_vm.transformedActivity.authorityCode && !_vm.transformedActivity.authorityNumber ? _c('strong', [_vm._v(" - ")]) : _vm._e()])], 1), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Created by")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._createdByUser) + " ")])])], 1), !_vm.hasUpdatedDate ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Created on")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._createdDateTime) + " ")]), _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedActivity._createdDateDistanceInWordsFromToday) + " ")])])])], 1) : _vm._e(), _vm.hasUpdatedDate ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Updated on")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._updatedDateTime) + " ")]), _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedActivity._updatedDateDistanceInWordsFromToday) + " ")])])])], 1) : _vm._e(), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Activity")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._startDate) + " ")]), _c('div', {
    staticClass: "caption"
  }, [_c('em', [_vm._v(" " + _vm._s(_vm.transformedActivity._startDateTimeDistanceInWordsFromToday) + " ")])])])], 1), !_vm.isNullOrUndefined(_vm.transformedActivity.isPreventativeMaintenance) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Preventative Maintenance?")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedActivity.isPreventativeMaintenance
    }
  })], 1)], 1) : _vm._e(), _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Manned?")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedActivity.isManned
    }
  })], 1)], 1), _vm.isActivityCalibrationType && !_vm.isNullOrUndefined(_vm.transformedActivity.calibrationType) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Calibration type")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._calibrationType) + " ")])])], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.operationTime) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Operation time")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._operationTime) + " ")])])], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.staticTime) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Static time")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._staticTime) + " ")])])], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.crewTime) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Crew time")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._crewTime) + " ")])])], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.cycles) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Cycles")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._cycles) + " ")])])], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.pressurizedTime) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Pressurized time")])]), _c('v-list-tile-content', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.transformedActivity._pressurizedTime,
      expression: "transformedActivity._pressurizedTime"
    }],
    staticClass: "align-end data_item"
  })], 1) : _vm._e(), !_vm.isNullOrUndefined(_vm.transformedActivity.pressurizationRange) ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Pressurization range")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedActivity._pressurizationRange) + " ")])])], 1) : _vm._e(), _vm.isActivityCheckOutType ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Check out type")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._checkOutType) + " ")])])], 1) : _vm._e(), _vm.isActivityInspectionType ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Inspection result")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedActivity._inspectionResult) + " ")])])], 1) : _vm._e(), _vm.isActivityInspectionType ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', [_c('span', [_vm._v("Inspection result")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_vm.transformedActivity.attributes.inspectionText ? _c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.transformedActivity.attributes.inspectionText
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.activityItemInstance ? _c('v-list-tile', {
    staticClass: "stat"
  }, [_c('v-list-tile-content', {
    staticClass: "mt-2 data_item"
  }, [_c('div', {
    staticClass: "white--text mini_mini pa-2 d-flex",
    class: _vm.EntityType.ITEM_INSTANCE
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-color": "white"
    }
  }), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.activityItemInstance.drawingNumber))]), _vm.activityItemInstance.asBuiltNumber !== _vm.DB_DEFAULT ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.activityItemInstance._asBuiltNumber) + " ")]) : _vm._e(), _vm.activityItemInstance.side !== _vm.Side.NONE ? _c('span', [_vm._v(" " + _vm._s(_vm.activityItemInstance._side) + " ")]) : _vm._e(), _vm.activityItemInstance.serialNumber ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" sn: " + _vm._s(_vm.activityItemInstance._serialNumber) + " ")]) : _vm._e(), _vm.activityItemInstance.lotNumber ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" lot: " + _vm._s(_vm.activityItemInstance._lotNumber) + " ")]) : _vm._e(), _vm.activityItemInstanceId ? _c('div', {
    staticClass: "external_link_container"
  }, [_c('ButtonMiniActionManage', {
    attrs: {
      "icon-color": "#fff",
      "to": `/hardware/${_vm.EntityType.ITEM_INSTANCE}/manage/${_vm.activityItemInstanceId}`,
      "tooltip": "View Item Instance in a new tab"
    }
  })], 1) : _vm._e()], 1)])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.transformedActivity ? _c('AppLoader', {
    attrs: {
      "color": _vm.EntityType.ACTIVITY,
      "type": "linear"
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };