var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--wrapper"
  }, [_vm.hardwareData && !_vm.isLoading ? _c('div', [_c('div', {
    staticClass: "ahd--grid-container"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._drawingNumberDisplay, _vm.drawingNumberHref),
      "label": "Drawing num:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._asBuiltNumberDisplay, _vm.asBuiltNoHref),
      "label": "AsBuilt:",
      "monospace-font": !_vm._asBuiltNumberDisplayIsDefault
    }
  }), _vm._hasItemInstance ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._serialNumberDisplay, _vm.serialLotHref),
      "label": "Serial:"
    }
  }) : _vm._e(), _vm._hasItemInstance ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._lotNumberDisplay, _vm.serialLotHref),
      "label": "Lot:"
    }
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sideDisplay,
      "label": "Side:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sizeDisplay,
      "label": "Size:"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._itemClassDisplay,
      "tooltipText": _vm._itemClassDisplay,
      "label": "Class:",
      "tooltipLabel": "Class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "label": "Fed cls:",
      "text": _vm._federalSupplyClassDisplayCode,
      "tooltipLabel": "Federal Supply Class",
      "tooltipText": _vm._federalSupplyClassDisplayDescription,
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._objectClassDisplay,
      "tooltipText": _vm._objectClassDisplay,
      "label": "Obj cls:",
      "tooltipLabel": "Object Class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._propertyClassDisplay,
      "tooltipText": _vm._propertyClassDisplay,
      "label": "Prop cls:",
      "tooltipLabel": "Property class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._equipmentGroupDisplay,
      "tooltipText": _vm._equipmentGroupTooltipDisplay,
      "label": "Equip grp:",
      "tooltipLabel": "Equipment group",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiNumberDisplay,
      "tooltipText": _vm._ceiNumberDisplay,
      "label": "CEI no:",
      "tooltipLabel": "CEI number",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiLevelDisplay,
      "tooltipText": _vm._ceiLevelDisplay,
      "label": "CEI lvl:",
      "tooltipLabel": "CEI level",
      "monospace-font": ""
    }
  }), _vm.hasSoftwareVersion ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._softwareVersion,
      "tooltipText": _vm._softwareVersion,
      "label": "Soft. version:",
      "tooltipLabel": "Software version"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-three"
  }, [_vm.shouldShow(_vm._birthDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._birthDateDisplay,
      "tooltipText": _vm._birthDateDisplay,
      "label": "Birth:",
      "tooltipLabel": "Birth Date",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._operationalStartDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._operationalStartDateDisplay,
      "tooltipText": _vm._operationalStartDateDisplay,
      "label": "Op Start:",
      "tooltipLabel": "Operational Start Date",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._acquisitionDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._acquisitionDateDisplay,
      "tooltipText": _vm._acquisitionDateDisplay,
      "label": "Acq:",
      "tooltipLabel": "Acquisition Date",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._computedLastServiceDate) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedLastServiceDate,
      "tooltipText": _vm._computedLastServiceDate,
      "label": "Last Svc:",
      "tooltipLabel": "Last Service Date",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._computedLastInspectionDate) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedLastInspectionDate,
      "tooltipText": _vm._computedLastInspectionDate,
      "label": "Last Insp:",
      "tooltipLabel": "Last Inspection Date",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._computedEquipmentControlNumber) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedEquipmentControlNumber,
      "tooltipText": _vm._computedEquipmentControlNumber,
      "label": "ECN:",
      "tooltipLabel": "Equipment Control Number"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm.hasLimitedLifeShelf ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._shelfLifeDisplay,
      "tooltipText": "Shelf life"
    }
  }) : _vm._e(), _vm.hasLimitedLifeUsage ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._usageLifeDisplay,
      "tooltipText": "Usage life"
    }
  }) : _vm._e(), _vm.hasLimitedLifeOperation ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._operationLifeDisplay,
      "tooltipText": "Operational life"
    }
  }) : _vm._e(), _vm.hasLimitedLifePressurizedTime ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._pressurizedTimeLifeDisplay,
      "tooltipText": "Pressurized Time"
    }
  }) : _vm._e(), _vm.hasLimitedLifeInterval ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._intervalLifeDisplay,
      "tooltipText": "Interval life"
    }
  }) : _vm._e(), _vm.hasLimitedLifeOnOrbit ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._onOrbitLifeDisplay,
      "tooltipText": "Orbit life"
    }
  }) : _vm._e(), _vm.hasLimitedLifeStatic ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._staticLifeDisplay,
      "tooltipText": "Static life"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-five"
  }, [_vm._initializationStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._initializationStatus ? 'Initialized' : 'Not initialized',
      "label": "Initialization:",
      "tooltipLabel": "Initialization status"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._initializationStatus,
            callback: function ($$v) {
              _vm._initializationStatus = $$v;
            },
            expression: "_initializationStatus"
          }
        })];
      },
      proxy: true
    }], null, false, 2967205490)
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign',
      "label": "Approved AD:",
      "tooltipLabel": "Is Approved AsDesign"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          attrs: {
            "title": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign'
          },
          model: {
            value: _vm.isApprovedAsDesign,
            callback: function ($$v) {
              _vm.isApprovedAsDesign = $$v;
            },
            expression: "isApprovedAsDesign"
          }
        })];
      },
      proxy: true
    }], null, false, 1927003056)
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "label": "Readiness:",
      "tooltip-text": _vm._readinessStatus,
      "tooltip-label": "The result of the Readiness report"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('a', {
          attrs: {
            "href": _vm._readinessHref,
            "target": "_blank"
          }
        }, [_vm._readinessStatus === _vm.ReadinessStatus.GO ? _c('AppBooleanDisplay', {
          attrs: {
            "value": true
          }
        }) : _vm._readinessStatus === _vm.ReadinessStatus.NO_GO ? _c('AppBooleanDisplay', {
          attrs: {
            "value": false
          }
        }) : _vm._readinessStatus === _vm.ReadinessStatus.CHECK ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.warning,
            "icon-color": "var(--v-warning-base)"
          }
        }) : _c('AppBooleanDisplay', {
          attrs: {
            "value": null
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 351748183)
  }), _vm._hazardousMaterialStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._hazardousMaterialText,
      "tooltipText": _vm._hazardousMaterialText,
      "label": "Hazard. Mat.",
      "tooltipLabel": "Hazardous Materials"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('c-icon-fa', {
          attrs: {
            "icon-size": "30px",
            "icon": _vm.$icons.warning,
            "icon-color": "var(--v-warning-base)",
            "tooltip": _vm._hazardousMaterialText
          }
        })];
      },
      proxy: true
    }], null, false, 3561712305)
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._limitedLifeAlertCountIsGreaterThanZero ? 'There are existing Limited life Alerts' : 'No Limited life alerts',
      "label": "LL Alerts:",
      "tooltipLabel": "Limited life Alerts"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._limitedLifeAlertCountIsGreaterThanZero ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.warning,
            "icon-color": "var(--v-warning-base)",
            "tooltip": "There are existing Limited life Alerts"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-success-base)",
            "icon": _vm.$icons[true],
            "tooltip": "No Limited life alerts"
          }
        })];
      },
      proxy: true
    }], null, false, 3118459183)
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._hasOpenPaperAssy ? 'There is existing Open Paperwork' : 'No open paperwork',
      "label": "Open Paper:",
      "tooltipLabel": "Open Paperwork"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._hasOpenPaperAssy ? _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-warning-base)",
            "icon": _vm.$icons.warning,
            "tooltip": "There is existing Oper Paperwork"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-warning-base)",
            "icon": _vm.$icons[true],
            "tooltip": "No open paperwork"
          }
        })];
      },
      proxy: true
    }], null, false, 3620022288)
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._isInstalled ? 'This item is INSTALLED on another item' : 'Not installed',
      "label": "Installed:",
      "tooltipLabel": "Installed"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._isInstalled ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons[true],
            "icon-color": "var(--v-success-base)",
            "tooltip": "This item is INSTALLED on another item"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-error-base)",
            "icon": _vm.$icons[false],
            "tooltip": "Not installed"
          }
        })];
      },
      proxy: true
    }], null, false, 1511882839)
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._isInstalled ? 'This item has children installed' : 'This assembly is blank',
      "label": "Children:",
      "tooltipLabel": "Children"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          attrs: {
            "value": _vm._hasChildren,
            "tooltip": `${_vm._hasChildren ? 'This item has children installed.' : 'This assembly is blank'}`
          }
        })];
      },
      proxy: true
    }], null, false, 3544106904)
  })], 1), _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Proj code:")]), _c('span', [_vm._v(_vm._s(_vm._projectCodeDisplay))])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Status:")]), _c('span', [_vm._v(_vm._s(_vm._inventoryStatusDisplay))])])])]), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Description:")]), _c('a', {
    staticClass: "font-weight-bold",
    attrs: {
      "href": _vm.drawingNumberHref,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._description))])]), _vm.isHazardousMaterial ? _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Hazard. Mat. Text:")]), _c('b', [_vm._v(_vm._s(_vm._hazardousMaterialText))])]) : _vm._e(), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Current Loc:")]), _vm.locationHref ? _c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.formatValuesForHref(_vm._locationDisplay, _vm.locationHref),
      expression: "formatValuesForHref(_locationDisplay, locationHref)"
    }]
  }) : _vm._e()]), _c('div', {
    staticClass: "ahd--button-wrapper"
  }, [_vm.shouldShowExternalLinksButton ? _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('v-btn', _vm._g({
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": "View Links"
          }
        }, on), [_c('c-icon-fa', {
          staticClass: "pt-2",
          attrs: {
            "icon-size": "16px",
            "icon": _vm.isViewLinksMenuOpen ? 'fal fa-angle-left' : 'fal fa-angle-right'
          }
        }), _c('c-icon-fa', {
          staticClass: "pr-2 pl-2",
          attrs: {
            "icon": _vm.$icons['link']
          }
        }), _vm._v(" View Links ")], 1)];
      }
    }], null, false, 2962063459),
    model: {
      value: _vm.isViewLinksMenuOpen,
      callback: function ($$v) {
        _vm.isViewLinksMenuOpen = $$v;
      },
      expression: "isViewLinksMenuOpen"
    }
  }, [_c('v-list', {
    attrs: {
      "dark": ""
    }
  }, _vm._l(_vm.linksFormatted, function (link, index) {
    return _c('v-list-tile', {
      key: index,
      attrs: {
        "href": link.url,
        "title": link.url
      }
    }, [_c('v-list-tile-title', [_c('c-icon-fa', {
      staticClass: "pr-2",
      attrs: {
        "icon-size": "16px",
        "icon": _vm.$icons.link
      }
    }), _c('span', [_vm._v(_vm._s(link.name))])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1)]) : _vm._e(), _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.INVENTORY
    }
  }) : _vm._e(), !_vm.isLoading && !_vm.hardwareData ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" Could not load data for this piece of Hardware. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };