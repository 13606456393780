var _a;
import { __decorate, __metadata } from "tslib";
import { add } from 'date-fns';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Side } from '~/db_types/swagger_types';
import NewBaseHardwareDisplay from '~/nasa_ui/base/NewBaseHardwareDisplay';
import CosmicLimitedLife from '~/nasa_ui/modules/CosmicLimitedLife';
import { EntityType } from '~/nasa_ui/types';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformItemInstanceResponses, transformItemMasterResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
let AppItemMasterDisplay = class AppItemMasterDisplay extends Mixins(NewBaseHardwareDisplay) {
    selectedEntity = null;
    myLimitedLife = null;
    asBuiltNumber;
    drawingNumber;
    side;
    get changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        return `/reporting/changelog/results?from=${from}&to=${to}&entityType=${EntityType.ITEM_MASTER}&drawingNumber=${encodeURIComponent(this.drawingNumber)}&asBuiltNumber=${encodeURIComponent(this.asBuiltNumber)}&side=${this.side}&itemMasterNodeId=${this.selectedEntityNodeId}&autoExecute=true&ahd=true`;
    }
    get hasItemInstances() {
        return this.transformedItemInstances.length > 0;
    }
    get itemInstances() {
        return this.searchHardwareResults?.itemInstances.results || [];
    }
    get selectedEntityNodeId() {
        return this.selectedEntity
            ? addNodeIdToResponseObj({
                asBuiltNumber: this.selectedEntity.asBuiltNumber,
                drawingNumber: this.selectedEntity.drawingNumber,
                side: this.selectedEntity.side
            }).nodeId
            : null;
    }
    get transformedItemInstances() {
        return transformItemInstanceResponses(this.itemInstances).sort(sortObjectNumericallyBy('asBuiltNumber'));
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformItemMasterResponseWithRefs([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.drawingNumber}_${this.asBuiltNumber}_${this.side}`, this.fetchTheShitYouNeed);
    }
    fetchTheShitYouNeed() {
        this.fetchEntity();
        this.fetchItemDrawing();
        this.fetchLinks();
        this.fetchHardware();
    }
    async fetchLinks() {
        if (!this.asBuiltNumber || !this.drawingNumber || !this.side) {
            return;
        }
        try {
            this.isFetching = true;
            const params = {
                drawingNumber: this.drawingNumber,
                asBuiltNumber: this.asBuiltNumber,
                side: this.side,
                take: -1
            };
            const respLinks = await this.$http.get(`/links`, {
                params
            });
            this.links = respLinks.data.results || [];
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchHardware() {
        if (!this.asBuiltNumber || !this.drawingNumber || !this.side) {
            return;
        }
        try {
            this.isFetchingHardware = true;
            const params = {
                drawingNumber: [this.drawingNumber],
                asBuiltNumber: [this.asBuiltNumber],
                side: [this.side],
                includeDrawings: false,
                includeMasters: false,
                includeInventory: false,
                take: -1
            };
            const resp = await this.$http.get(`/hardware`, {
                params
            });
            this.searchHardwareResults = resp.data;
            this.isFetchingHardware = false;
        }
        catch (err) {
            this.isFetchingHardware = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchItemDrawing() {
        if (!this.drawingNumber) {
            return;
        }
        try {
            this.isFetchingItemDrawing = true;
            const respItemDrawing = await this.$http.get(`/hardware/item-drawings/${encodeURIComponent(this.drawingNumber)}`);
            this.itemDrawing = respItemDrawing.data;
            this.isFetchingItemDrawing = false;
        }
        catch (err) {
            this.isFetchingItemDrawing = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchEntity() {
        if (!this.asBuiltNumber || !this.drawingNumber || !this.side) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-masters/${encodeURIComponent(this.drawingNumber)}/${encodeURIComponent(this.asBuiltNumber)}/${this.side}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    onSelectedEntityChanged() {
        if (this.selectedEntity?.limitedLife) {
            this.myLimitedLife = new CosmicLimitedLife(this.selectedEntity.limitedLife);
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], AppItemMasterDisplay.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], AppItemMasterDisplay.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof Side !== "undefined" && Side) === "function" ? _a : Object)
], AppItemMasterDisplay.prototype, "side", void 0);
__decorate([
    DebounceAll(20),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('drawingNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemMasterDisplay.prototype, "fetchLinks", null);
__decorate([
    DebounceAll(20),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('drawingNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemMasterDisplay.prototype, "fetchHardware", null);
__decorate([
    Watch('drawingNumber', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemMasterDisplay.prototype, "fetchItemDrawing", null);
__decorate([
    DebounceAll(20),
    Watch('asBuiltNumber', { immediate: true }),
    Watch('drawingNumber', { immediate: true }),
    Watch('side', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemMasterDisplay.prototype, "fetchEntity", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppItemMasterDisplay.prototype, "onSelectedEntityChanged", null);
AppItemMasterDisplay = __decorate([
    Component
], AppItemMasterDisplay);
export default AppItemMasterDisplay;
