import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
// The AHD grid item looks like this:
// Label: text | vhtml | text with link
//       |▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔▔|
//       | tooltipLabel                          |
//       |     tooltipText                       |
//       | tooltipVhtml                          |
//       |_______________________________________|
// tooltips are optional. If you do not pass `tooltipText` or `tooltipVhtml`,
// a grid item with no tooltip will be rendered
// You can pass both `text` and `vhtml` as well as `tooltipText` and `tooltipVhtml`
// It is most likely that you will only need one or the other
/**
 * AVAILABLE PROPS:
 *
 * direction - direction the tooltip should go,
      options: 'left', 'right', 'top', 'bottom'
 * label - label for AHD on the left side
      ex. Fed cls in Fed cls: Unknown
 * link - link that can be clicked on AHD value
      ex. 'http://localhost:8000/hardware/'
 * text - the value of the grid item that is shown on the right
      ex. Unknown in Fed cls: Unknown
 * tooltipText - text that will show on the second line of the tooltip
      ex. Unknown in
      Federal Supply Class
          Unknown
 * tooltipLabel - label that appears on the first line of the tooltip
      ex. Federal Supply Class in
      Federal Supply Class
          Unknown
 * tooltipVhtml - html you would like rendered inside the tooltip
      ex. `<span class="no-wrap">IN_TRANSIT/IN_TRANSIT/IN_TRANSIT</span>`
 * vhtml -  html that appears on the right side of the label
      ex. `<span>
             <a href="http://localhost:8000/hardware/" target="_blank">Hardware</a>
          </span>`
 */
let AppHardwareDisplayGridItem = class AppHardwareDisplayGridItem extends Vue {
    direction;
    label;
    link;
    monospaceFont;
    text;
    tooltipText;
    tooltipLabel;
    // should only pass tooltip text if there is no vhtml
    tooltipVhtml;
    vhtml;
    get transformedTooltipText() {
        return this.tooltipText === DEFAULT_DASH ? 'Value is not defined or DEFAULT.' : this.tooltipText;
    }
};
__decorate([
    Prop({
        default: 'bottom',
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "direction", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "label", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "link", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], AppHardwareDisplayGridItem.prototype, "monospaceFont", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], AppHardwareDisplayGridItem.prototype, "text", void 0);
__decorate([
    Prop({
        type: [String, Number]
    }),
    __metadata("design:type", Object)
], AppHardwareDisplayGridItem.prototype, "tooltipText", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "tooltipLabel", void 0);
__decorate([
    Prop({
        default: '',
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "tooltipVhtml", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], AppHardwareDisplayGridItem.prototype, "vhtml", void 0);
AppHardwareDisplayGridItem = __decorate([
    Component
], AppHardwareDisplayGridItem);
export default AppHardwareDisplayGridItem;
