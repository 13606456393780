import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicItemInstanceFragment } from '../itemInstance/slim';
import { CosmicItemMasterSlimFragment } from './slim';
export const CosmicItemMasterWithFlagsFragment = gql `
  ${CosmicItemMasterSlimFragment}

  fragment CosmicItemMasterWithFlags on ItemMaster {
    ...CosmicItemMasterSlim

    cleanlinessTrackingText
    hasSoftwareVersion
    hazardousMaterialText
    isApprovedAsDesign
    isApprovedDataPack
    isCleanlinessTracking
    isFractureCritical
    isHazardousMaterial
    isManifestable
    isPhantom
    isRefurbishable
    isSpecialHandling
    isSpecialPackaging
    isTool
    softwareVersion
    specialHandlingText
    specialPackagingText
  }
`;
export const GetSlimItemMasterByNodeId = gql `
  ${CosmicItemMasterSlimFragment}

  query GetSlimItemMasterByNodeId($nodeId: ID!) {
    itemMaster(nodeId: $nodeId) {
      ...CosmicItemMasterSlim
    }
  }
`;
export const GetSlimItemMasterByDrawingNumberAsBuiltSide = gql `
  ${CosmicItemMasterSlimFragment}

  query GetSlimItemMasterByDrawingNumberAsBuiltSide($drawingNumber: String!, $asBuiltNumber: String!, $side: Side!) {
    itemMasterByDrawingNumberAndAsBuiltNumberAndSide(
      drawingNumber: $drawingNumber
      asBuiltNumber: $asBuiltNumber
      side: $side
    ) {
      ...CosmicItemMasterSlim
    }
  }
`;
export const GetItemMasterByDrawingNumberAsBuiltSideWithOptionalHardware = gql `
  ${CosmicItemMasterSlimFragment}
  ${CosmicItemInstanceFragment}

  query GetItemMasterByDrawingNumberAsBuiltSideWithOptionalHardware(
    $asBuiltNumber: String!
    $drawingNumber: String!
    $shouldFetchItemDrawing: Boolean = false
    $shouldFetchItemInstances: Boolean = false
    $side: Side!
  ) {
    itemMasterByDrawingNumberAndAsBuiltNumberAndSide(
      asBuiltNumber: $asBuiltNumber
      drawingNumber: $drawingNumber
      side: $side
    ) {
      ...CosmicItemMasterSlim

      itemDrawing @include(if: $shouldFetchItemDrawing) {
        drawingNumber
        isArchived
        nodeId
      }

      itemInstances @include(if: $shouldFetchItemInstances) {
        nodes {
          ...CosmicItemInstance
        }
      }
    }
  }
`;
export const GetItemMasterWithFlagsByNodeId = gql `
  ${CosmicItemMasterWithFlagsFragment}

  query GetItemMasterWithFlagsByNodeId($nodeId: ID!) {
    itemMaster(nodeId: $nodeId) {
      ...CosmicItemMasterWithFlags

      groundSupportEquipment {
        etcaNomenclature
        gaugeTrackingComments
        subType
        testStandName
        testStandNumber
      }
    }
  }
`;
let ItemMasterRepoMixin = class ItemMasterRepoMixin extends Mixins(LoaderMixin) {
    getSlimItemMasterByNodeId = queryFactory(this, GetSlimItemMasterByNodeId);
    getSlimItemMasterByDrawingNumberAsBuiltSide = queryFactory(this, GetSlimItemMasterByDrawingNumberAsBuiltSide);
    getItemMasterByDrawingNumberAsBuiltSideWithOptionalHardware = queryFactory(this, GetItemMasterByDrawingNumberAsBuiltSideWithOptionalHardware);
    getItemMasterWithFlagsByNodeId = queryFactory(this, GetItemMasterWithFlagsByNodeId);
};
ItemMasterRepoMixin = __decorate([
    Component
], ItemMasterRepoMixin);
export { ItemMasterRepoMixin };
