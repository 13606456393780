import { Side } from '~/db_types';
import { EntityType } from '~/nasa_ui/types';
import { isNullOrUndefined, sortObjectBy } from '~/nasa_ui/utils';
const comparisonProperties = [
    'drawingNumber',
    'asBuiltNumber',
    'installedOn',
    'side'
];
const comparisonPropertiesDisplay = {
    drawingNumber: 'Drawing number',
    asBuiltNumber: 'AsBuilt',
    side: 'Side',
    installedOn: 'Installed On'
};
export const compareWorkingAssemblyNode = (lhs, rhs) => {
    if (!lhs && !rhs) {
        throw Error('Must supply at least one AssemblyNode');
    }
    const differences = getPropertyDifferences(lhs, rhs);
    const comparison = {
        nodeA: lhs,
        nodeB: rhs,
        hardwareEntityIdA: lhs?.hardwareEntityId,
        hardwareEntityIdB: rhs?.hardwareEntityId,
        hardwareEntityTypeA: lhs?.hardwareEntityType || null,
        hardwareEntityTypeB: rhs?.hardwareEntityType || null,
        isMatch: differences.every((p) => p.isMatch),
        differences
    };
    return comparison;
};
export const compareAssemblyTemplateNode = (lhs, rhs) => {
    if (!lhs && !rhs) {
        throw Error('Must supply at least one node for comparison');
    }
    const propertyDifferences = getPropertyDifferences(lhs, rhs);
    const partsDifference = {
        comparedPropertyName: 'parts',
        isMatch: assemblyTemplatePartsMatch(lhs?.parts || [], rhs?.parts || []),
        hardwareEntityAValue: lhs?.parts || [],
        hardwareEntityBValue: rhs?.parts || []
    };
    const differences = [...propertyDifferences, partsDifference];
    const comparison = {
        nodeA: lhs,
        nodeB: rhs,
        hardwareEntityIdA: lhs?.hardwareEntityId,
        hardwareEntityIdB: rhs?.hardwareEntityId,
        hardwareEntityTypeA: lhs?.hardwareEntityType || null,
        hardwareEntityTypeB: rhs?.hardwareEntityType || null,
        isMatch: differences.every((p) => p.isMatch),
        differences
    };
    return comparison;
};
export const compareTemplateAndWorkingAssemblyNode = (template, workingAssembly) => {
    if (!template && !workingAssembly) {
        throw Error('Must supply at least one node for comparison');
    }
    const templateParts = template?.parts || [];
    const hasTemplatePartOptions = Boolean(templateParts.length);
    const existsInTemplateParts = templateParts.some((part) => {
        if (part.drawingNumber?.toUpperCase() !== workingAssembly?.drawingNumber?.toUpperCase()) {
            return false;
        }
        if (part.asBuiltNumber && part.asBuiltNumber.toUpperCase() !== workingAssembly?.asBuiltNumber?.toUpperCase()) {
            return false;
        }
        if (part.side && part.side !== workingAssembly?.side) {
            return false;
        }
        return true;
    });
    const comparison = {
        nodeA: template,
        nodeB: workingAssembly,
        hardwareEntityIdA: template?.hardwareEntityId,
        hardwareEntityIdB: workingAssembly?.hardwareEntityId,
        hardwareEntityTypeA: template?.hardwareEntityType || null,
        hardwareEntityTypeB: workingAssembly?.hardwareEntityType || null,
        isMatch: hasTemplatePartOptions ? existsInTemplateParts : null,
        differences: [] // Should/Could this be populated?
    };
    return comparison;
};
const getPropertyDifferences = (lhs, rhs) => {
    const propertiesToCheck = Object.keys(lhs || rhs || {}).filter((key) => comparisonProperties.includes(key));
    return propertiesToCheck.map((key) => {
        return {
            comparedPropertyName: key,
            isMatch: !!rhs && !!lhs ? lhs[key] === rhs[key] : false,
            hardwareEntityAValue: lhs ? lhs[key] : null,
            hardwareEntityBValue: rhs ? rhs[key] : null
        };
    });
};
const getComparerFn = (nodeA, nodeB) => {
    const nodeAIsWorkingAssembly = nodeA?.hardwareEntityType === EntityType.ASSEMBLY;
    const nodeAIsTemplate = nodeA?.hardwareEntityType === EntityType.ASSEMBLY_TEMPLATE;
    const nodeAIsUnknown = isNullOrUndefined(nodeA);
    const nodeBIsWorkingAssembly = nodeB?.hardwareEntityType === EntityType.ASSEMBLY;
    const nodeBIsTemplate = nodeB?.hardwareEntityType === EntityType.ASSEMBLY_TEMPLATE;
    const nodeBIsUnknown = isNullOrUndefined(nodeB);
    if (nodeAIsWorkingAssembly && (nodeBIsUnknown || nodeBIsWorkingAssembly)) {
        return compareWorkingAssemblyNode;
    }
    if (nodeAIsTemplate && (nodeBIsUnknown || nodeBIsTemplate)) {
        return compareAssemblyTemplateNode;
    }
    if (nodeAIsUnknown && nodeBIsTemplate) {
        return compareAssemblyTemplateNode;
    }
    if (nodeAIsUnknown && nodeBIsWorkingAssembly) {
        return compareWorkingAssemblyNode;
    }
    return compareTemplateAndWorkingAssemblyNode;
};
const assemblyTemplatePartsMatch = (lhs, rhs) => {
    const hasEqualLength = lhs.length === rhs.length;
    const hasSameTemplateParts = lhs.every((leftPart) => rhs.some((rightPart) => leftPart.drawingNumber?.toUpperCase() === rightPart.drawingNumber?.toUpperCase() &&
        leftPart.asBuiltNumber?.toUpperCase() === rightPart.asBuiltNumber?.toUpperCase() &&
        leftPart.side === rightPart.side));
    return hasEqualLength && hasSameTemplateParts;
};
export const createComparisonTree = (lhs, rhs) => {
    /*
      1. Find what we'll call the roots by lowest node depth. There should only be 1 right?
      2. Find left children by filtering parent id. Same for right children
      3. Merge two lists into a IHardwareComparison[] by matching only on sequence number
      4. Repeat 2-3 for each IHardwareComparison recursively
    */
    if (!lhs.length && !rhs.length) {
        return {
            root: { comparison: null, children: [] }
        };
    }
    // Weird behavior, but if a template is one of the params it currently needs to go on the left
    if (rhs[0]?.hardwareEntityType === EntityType.ASSEMBLY_TEMPLATE) {
        [lhs, rhs] = [rhs, lhs];
    }
    const rootComparison = getRootComparison(lhs, rhs);
    return {
        root: {
            comparison: rootComparison,
            children: getChildren(rootComparison, lhs, rhs)
        }
    };
};
const getRootComparison = (lhs, rhs) => {
    const leftRoot = lhs.sort(sortObjectBy('nodeDepth'))[0];
    const rightRoot = rhs.sort(sortObjectBy('nodeDepth'))[0];
    if (leftRoot || rightRoot) {
        const comparer = getComparerFn(leftRoot, rightRoot || null);
        return comparer(leftRoot, rightRoot || null);
    }
    throw Error('Unable to determine a root node');
};
const mergeChildren = (lhs, rhs) => {
    const leftMapping = lhs.map((leftAssembly) => {
        const rightAssembly = rhs.find((a) => {
            return a.sequence === leftAssembly.sequence;
        });
        const comparer = getComparerFn(leftAssembly, rightAssembly || null);
        return comparer(leftAssembly, rightAssembly || null);
    });
    const rightMapping = rhs
        .filter((rightAssembly) => {
        return !lhs.some((l) => l.sequence === rightAssembly.sequence);
    })
        .map((rightAssembly) => {
        const comparer = getComparerFn(null, rightAssembly);
        return comparer(null, rightAssembly);
    });
    return sortBySequence([...leftMapping, ...rightMapping]);
};
const getChildren = (comparison, lhs, rhs) => {
    const leftRoot = comparison.nodeA;
    const rightRoot = comparison.nodeB;
    const leftChildren = lhs.filter((n) => Boolean(n.parentId) && n.parentId === leftRoot?.id);
    const leftRemaining = lhs.filter((n) => Boolean(n.parentId) && n.parentId !== leftRoot?.id);
    const rightChildren = rhs.filter((n) => Boolean(n.parentId) && n.parentId === rightRoot?.id);
    const rightRemaining = rhs.filter((n) => Boolean(n.parentId) && n.parentId !== rightRoot?.id);
    const comparisonChildren = mergeChildren(leftChildren, rightChildren);
    return comparisonChildren.map((comparison) => {
        return {
            comparison: comparison,
            children: getChildren(comparison, leftRemaining, rightRemaining)
        };
    });
};
const sortBySequence = (comparisons) => {
    return [...comparisons].sort((obj1, obj2) => {
        const sequence1 = obj1.nodeA?.sequence || obj1.nodeB?.sequence || 0;
        const sequence2 = obj2.nodeA?.sequence || obj2.nodeB?.sequence || 0;
        return sequence1 - sequence2;
    });
};
const transformWorkingAssembly = (fragment) => {
    const { workingAssembly, nodeDepth } = fragment;
    if (!workingAssembly || !workingAssembly.inventory) {
        throw Error('Invalid argument: assembly must have a working assembly with an inventory');
    }
    if (isNullOrUndefined(nodeDepth)) {
        throw Error('Assembly is missing a nodeDepth');
    }
    const { inventory } = workingAssembly;
    return {
        asBuiltNumber: inventory.asBuiltNumber ?? null,
        drawingNumber: inventory.drawingNumber,
        hardwareEntityId: workingAssembly.id,
        hardwareEntityType: EntityType.ASSEMBLY,
        id: workingAssembly.id,
        installedOn: workingAssembly.installedOn,
        inventory: inventory,
        lotNumber: inventory.lotNumber ?? null,
        name: inventory.itemDrawingDescription ?? null,
        nodeDepth: nodeDepth,
        nodeId: workingAssembly.nodeId,
        parentId: workingAssembly.parent?.id ?? null,
        parentNodeId: workingAssembly.parent?.nodeId,
        parentSequence: workingAssembly.parent?.sequence ?? null,
        quantity: inventory.quantity ?? null,
        sequence: workingAssembly.sequence,
        serialNumber: inventory.serialNumber ?? null,
        side: inventory.side,
        size: inventory.size ?? null
    };
};
const transformAssemblyTemplate = (fragment) => {
    const { assemblyTemplate, assemblyTemplateId, nodeDepth, sequence } = fragment;
    if (!assemblyTemplate) {
        throw Error('Invalid argument: fragment is missing assemblyTemplate');
    }
    if (isNullOrUndefined(nodeDepth) || isNullOrUndefined(sequence)) {
        throw Error('Assembly template requires nodeDepth and sequence');
    }
    if (isNullOrUndefined(assemblyTemplate?.nodeId)) {
        throw Error('Assembly template is missing nodeId');
    }
    if (isNullOrUndefined(assemblyTemplate?.id)) {
        throw Error('Assembly template is missing id');
    }
    const parts = assemblyTemplate.assemblyTemplateParts.nodes.map((part) => {
        return {
            asBuiltNumber: part?.asBuiltNumber ?? null,
            description: part?.itemDrawing?.description ?? null,
            drawingNumber: part?.drawingNumber ?? null,
            id: part?.id ?? null,
            nodeId: part?.nodeId ?? null,
            side: part?.side ?? null
        };
    });
    return {
        hardwareEntityId: assemblyTemplateId,
        hardwareEntityType: EntityType.ASSEMBLY_TEMPLATE,
        id: assemblyTemplateId,
        installedOn: assemblyTemplate.installedOn,
        maxQuantity: assemblyTemplate.maxQuantity,
        minQuantity: assemblyTemplate.minQuantity,
        name: assemblyTemplate.name ?? null,
        nodeDepth: nodeDepth,
        nodeId: assemblyTemplate.nodeId,
        parentId: assemblyTemplate.parent?.id,
        parentNodeId: assemblyTemplate.parent?.nodeId,
        parentSequence: assemblyTemplate.parent?.sequence ?? null,
        parts,
        pbsItemId: assemblyTemplate.pbsItemId ?? null,
        sequence: sequence
    };
};
export const transformWorkingAssemblies = ({ workingAssemblyById }) => {
    return workingAssemblyById?.inventory?.assemblyChildren.nodes.map((wa) => {
        if (!wa) {
            throw Error('Found null value for Working Assembly');
        }
        return transformWorkingAssembly(wa);
    });
};
export const transformAssemblyTemplates = ({ assemblyTemplateById }) => {
    return assemblyTemplateById?.children.nodes.map((at) => {
        if (!at) {
            throw Error('Found null value for Assembly Template');
        }
        return transformAssemblyTemplate(at);
    });
};
export const getTemplateAndAssemblyComparisonMessage = (templateNode, assemblyNode, isMatch) => {
    if (isMatch) {
        return {
            text: 'Hardware matches',
            options: null,
            optionsText: null
        };
    }
    if (!assemblyNode) {
        return {
            text: 'Empty slot in Assembly',
            options: null,
            optionsText: null
        };
    }
    if (!templateNode) {
        return {
            text: 'Installed Hardware not in Assembly Template',
            options: null,
            optionsText: null
        };
    }
    if (isMatch === null && !templateNode.parts.length) {
        return {
            text: 'Assembly Template did not define any valid Hardware',
            options: null,
            optionsText: null
        };
    }
    const { drawingNumber, asBuiltNumber, side } = assemblyNode;
    const { parts } = templateNode;
    const partsWithMatchingDrawingNumber = parts.filter((part) => {
        return part.drawingNumber?.toLowerCase() === drawingNumber?.toLowerCase();
    });
    if (!partsWithMatchingDrawingNumber.length) {
        const optionsText = parts.map((p) => p.drawingNumber);
        const text = `No Assembly Template Hardware matches Drawing ${drawingNumber}`;
        return { text, optionsText, options: parts };
    }
    const partsWithMatchingAsBuilt = partsWithMatchingDrawingNumber.filter((part) => {
        return part.asBuiltNumber?.toLowerCase() == asBuiltNumber?.toLowerCase();
    });
    if (!partsWithMatchingAsBuilt.length) {
        const optionsText = partsWithMatchingDrawingNumber.map((p) => p.asBuiltNumber);
        const text = `No Assembly Template Hardware matches AsBuilt ${asBuiltNumber}`;
        return { text, optionsText, options: partsWithMatchingDrawingNumber };
    }
    const sideOptions = partsWithMatchingAsBuilt.map((p) => p.side ?? Side.NONE);
    if (sideOptions.length === 1) {
        return {
            text: `Hardware was installed on ${side} but must be on ${sideOptions[0]}`,
            options: null,
            optionsText: null
        };
    }
    return {
        text: `No Assembly Template Hardware matching side ${side}`,
        options: partsWithMatchingAsBuilt,
        optionsText: sideOptions
    };
};
export const getWorkingAssemblyComparisonMessage = (comparison) => {
    const { nodeA, nodeB, isMatch, differences } = comparison;
    if (isMatch) {
        return {
            text: 'Hardware matches',
            options: null,
            optionsText: null
        };
    }
    if (!nodeA || !nodeB) {
        return {
            text: 'Empty slot in Working Assembly',
            options: null,
            optionsText: null
        };
    }
    const differenceMessages = differences
        .filter((d) => !d.isMatch)
        .filter(({ comparedPropertyName }) => comparedPropertyName in comparisonPropertiesDisplay)
        .map(({ comparedPropertyName, hardwareEntityAValue, hardwareEntityBValue }) => {
        const label = comparisonPropertiesDisplay[comparedPropertyName];
        return `${label}: ${hardwareEntityAValue ?? 'NONE'} and ${hardwareEntityBValue ?? 'NONE'}`;
    });
    return {
        text: 'Hardware does not match',
        options: null,
        optionsText: differenceMessages
    };
};
export const getAssemblyTemplateComparisonMessage = (templateA, templateB, isMatch) => {
    if (isMatch) {
        return {
            text: 'Templates match',
            options: null,
            optionsText: null
        };
    }
    if (!templateA || !templateB) {
        return {
            text: 'Empty slot in Assembly Template',
            options: null,
            optionsText: null
        };
    }
    return {
        text: 'Templates have different Assembly Template Hardware',
        options: null,
        optionsText: null
    };
};
export const getComparisonMessage = (comparison) => {
    const { nodeA, nodeB } = comparison;
    if (!nodeA || !nodeB) {
        return { text: 'Empty Slot', options: null, optionsText: null };
    }
    if (nodeA?.hardwareEntityType === EntityType.ASSEMBLY) {
        // working assembly to working assembly
        if (nodeB?.hardwareEntityType === EntityType.ASSEMBLY) {
            return getWorkingAssemblyComparisonMessage(comparison);
        }
        // working assembly to template
        getTemplateAndAssemblyComparisonMessage(nodeB, nodeA, comparison.isMatch ?? false);
    }
    if (nodeA?.hardwareEntityType === EntityType.ASSEMBLY_TEMPLATE) {
        // template to template
        if (nodeB?.hardwareEntityType === EntityType.ASSEMBLY_TEMPLATE) {
            return getAssemblyTemplateComparisonMessage(nodeA, nodeB, comparison.isMatch ?? false);
        }
        // template to working assembly
        return getTemplateAndAssemblyComparisonMessage(nodeA, nodeB, comparison.isMatch ?? false);
    }
    throw Error('Unable to build comparison message between two nodes based on their hardwareEntityType');
};
