var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppSelect', {
    attrs: {
      "allow-create": false,
      "disabled": _vm.disabled,
      "inverted": true,
      "select-options": _vm.computedSelectOptions,
      "label": _vm.computedLabel,
      "value": _vm.value,
      "sort-numerically": true,
      "multiple": true
    },
    on: {
      "input": _vm.emitInput
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };