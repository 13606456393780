import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
let StepMixin = class StepMixin extends Vue {
    docTypesUsingTasks = [DocumentType.C_WORKFLOW, DocumentType.C_RCA];
    usesTasks(docType) {
        return this.docTypesUsingTasks.includes(docType);
    }
    usesSteps(docType) {
        return !this.usesTasks(docType);
    }
    supportsStepRejection(docType) {
        return ![DocumentType.C_RCA].includes(docType);
    }
};
StepMixin = __decorate([
    Component
], StepMixin);
export default StepMixin;
