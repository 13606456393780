import { __decorate, __metadata } from "tslib";
import { Debounce } from 'lodash-decorators/debounce';
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { EntityType } from '~/nasa_ui/types';
import { HardwareListTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchHardwareList = class SearchHardwareList extends BaseSearch {
    entityType = EntityType.HARDWARE_LIST;
    hint = 'number, name';
    placeholder = 'search for hardware lists';
    selectedHardwareList = null;
    tableHeaders = [...HardwareListTableHeaders];
    disableSearch;
    async performSearch() {
        try {
            this.isLoading = true;
            let params = {
                query: this.searchInput,
                take: -1
            };
            if (this.filters) {
                params = { ...params, ...this.filters };
            }
            // Execute query
            const response = await this.$http.get(`/hardwarelist`, {
                params
            });
            this.isLoading = false;
            // Status errors are valid responses, it won't hit the catch because the Promise is fulfilled
            if (response.status > 399) {
                this.$errorUtility({
                    err: new Error('There was an error searching for this Assembly Template.'),
                    backupErrorText: 'There was an error searching for this Assembly Template.'
                });
            }
            // Set the raw response in case we need that
            this.rawSearchResponse = response.data;
            this.emitSearchPerformed();
        }
        catch (error) {
            this.isLoading = false;
            console.log(error);
            this.$errorUtility({ err: error });
        }
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    onMetaClickOfSearchResult(selectedItem) {
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
};
__decorate([
    Prop({
        default: false,
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchHardwareList.prototype, "disableSearch", void 0);
__decorate([
    Debounce(200),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], SearchHardwareList.prototype, "performSearch", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchHardwareList.prototype, "onClickOfSearchResult", null);
__decorate([
    Emit('metaRowClicked'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SearchHardwareList.prototype, "onMetaClickOfSearchResult", null);
SearchHardwareList = __decorate([
    Component
], SearchHardwareList);
export default SearchHardwareList;
