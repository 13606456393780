import { EventType } from '~/db_types';
export const EventTypeDisplay = new Map([
    [EventType.CHAMBER, 'Chamber'],
    [EventType.DIGITAL_RECORD_REVIEW, 'Digital Record review'],
    [EventType.DISPOSAL, 'Disposal'],
    [EventType.EVA, 'EVA'],
    [EventType.FIT_CHECK, 'Fit check'],
    [EventType.GENERAL, 'General'],
    [EventType.GLOVE_FIT_CHECK, 'Glove fit check'],
    [EventType.LAUNCH, 'Launch'],
    [EventType.MISHAP, 'Mishap'],
    [EventType.RETURN, 'Return'],
    [EventType.TRAINING, 'Training']
]);
