export var StockUnit;
(function (StockUnit) {
    StockUnit["BAG"] = "BAG";
    StockUnit["BOTTLE"] = "BOTTLE";
    StockUnit["BOX"] = "BOX";
    StockUnit["BUNDLE"] = "BUNDLE";
    StockUnit["CAN"] = "CAN";
    StockUnit["CARD"] = "CARD";
    StockUnit["CARTON"] = "CARTON";
    StockUnit["CASE"] = "CASE";
    StockUnit["CYLINDER"] = "CYLINDER";
    StockUnit["DOZEN"] = "DOZEN";
    StockUnit["EACH"] = "EACH";
    StockUnit["FOOT"] = "FOOT";
    StockUnit["GALLON"] = "GALLON";
    StockUnit["GRAM"] = "GRAM";
    StockUnit["INCH"] = "INCH";
    StockUnit["JAR"] = "JAR";
    StockUnit["JOB"] = "JOB";
    StockUnit["KILOGRAM"] = "KILOGRAM";
    StockUnit["KIT"] = "KIT";
    StockUnit["LITER"] = "LITER";
    StockUnit["LOT"] = "LOT";
    StockUnit["MONTH"] = "MONTH";
    StockUnit["PACKAGE"] = "PACKAGE";
    StockUnit["PAIR"] = "PAIR";
    StockUnit["PINT"] = "PINT";
    StockUnit["POUND"] = "POUND";
    StockUnit["QUART"] = "QUART";
    StockUnit["REAM"] = "REAM";
    StockUnit["REEL"] = "REEL";
    StockUnit["ROLL"] = "ROLL";
    StockUnit["SET"] = "SET";
    StockUnit["SHEET"] = "SHEET";
    StockUnit["SPOOL"] = "SPOOL";
    StockUnit["SQUARE_FOOT"] = "SQUARE_FOOT";
    StockUnit["SQUARE_INCH"] = "SQUARE_INCH";
    StockUnit["TUBE"] = "TUBE";
    StockUnit["UNIT"] = "UNIT";
    StockUnit["YARD"] = "YARD";
    StockUnit["YEAR"] = "YEAR";
})(StockUnit || (StockUnit = {}));
export const StockUnitDisplay = new Map([
    [StockUnit.BAG, 'Bag'],
    [StockUnit.BOTTLE, 'Bottle'],
    [StockUnit.BOX, 'Box'],
    [StockUnit.BUNDLE, 'Bundle'],
    [StockUnit.CAN, 'Can'],
    [StockUnit.CARD, 'Card'],
    [StockUnit.CARTON, 'Carton'],
    [StockUnit.CASE, 'Case'],
    [StockUnit.CYLINDER, 'Cylinder'],
    [StockUnit.DOZEN, 'Dozen'],
    [StockUnit.EACH, 'Each'],
    [StockUnit.FOOT, 'Foot'],
    [StockUnit.GALLON, 'Gallon'],
    [StockUnit.GRAM, 'Gram'],
    [StockUnit.INCH, 'Inch'],
    [StockUnit.JAR, 'Jar'],
    [StockUnit.JOB, 'Job'],
    [StockUnit.KILOGRAM, 'Kilogram'],
    [StockUnit.KIT, 'Kit'],
    [StockUnit.LITER, 'Liter'],
    [StockUnit.LOT, 'Lot'],
    [StockUnit.MONTH, 'Month'],
    [StockUnit.PACKAGE, 'Package'],
    [StockUnit.PAIR, 'Pair'],
    [StockUnit.PINT, 'Pint'],
    [StockUnit.POUND, 'Pound'],
    [StockUnit.QUART, 'Quart'],
    [StockUnit.REAM, 'Ream'],
    [StockUnit.REEL, 'Reel'],
    [StockUnit.ROLL, 'Roll'],
    [StockUnit.SET, 'Set'],
    [StockUnit.SHEET, 'Sheet'],
    [StockUnit.SPOOL, 'Spool'],
    [StockUnit.SQUARE_FOOT, 'Square foot'],
    [StockUnit.SQUARE_INCH, 'Square inch'],
    [StockUnit.TUBE, 'Tube'],
    [StockUnit.UNIT, 'Unit'],
    [StockUnit.YARD, 'Yard'],
    [StockUnit.YEAR, 'Year']
]);
