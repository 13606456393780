var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', {
    staticClass: "cosmic_page_description",
    class: {
      [_vm.color]: _vm.color,
      no_margin_left: _vm.removeMarginLeft,
      no_margin_bottom: _vm.removeMarginBottom
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };