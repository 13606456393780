var _a;
import { __decorate, __metadata } from "tslib";
import { FetchPolicy } from 'apollo-client';
import qs from 'qs';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { LimitedLifeType } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { AS_BUILT_DEFAULT_OPTIONS, DB_DEFAULT, DEFAULT_DASH } from '~/nasa_ui/constants';
import Hardware from '~/nasa_ui/modules/Hardware';
import { EntityType, UserPermission } from '~/nasa_ui/types';
import { limitInterval } from '~/nasa_ui/utils/dates/intervals';
import { formatValuesForHref } from '~/nasa_ui/utils/helpers/formatValuesForHref';
export const defaultInterval = {
    days: 0,
    hours: 0,
    minutes: 0,
    months: 0,
    seconds: 0,
    years: 0
};
let BaseHardwareDisplay = class BaseHardwareDisplay extends BaseCosmic {
    links = [];
    hardwareData = null;
    hardwareModule = null;
    hardwareType = null;
    isLoading = false;
    isViewAssemblyMenuOpen = false;
    isViewDrawingMenuOpen = false;
    isViewInventoryMenuOpen = false;
    isViewItemInstanceMenuOpen = false;
    isViewItemMasterMenuOpen = false;
    isViewLinksMenuOpen = false;
    formatValuesForHref = formatValuesForHref;
    fetchPolicy;
    hideBorderBottom;
    hideButtons;
    nodeId;
    title;
    get _acquisitionDateDisplay() {
        return this.hardwareModule && this.hardwareModule.acquisitionDateDisplay;
    }
    get _asBuiltNumberDisplay() {
        const asBuiltNumberDisplay = this.hardwareModule && this.hardwareModule.asBuiltNumberDisplay;
        return asBuiltNumberDisplay === AS_BUILT_DEFAULT_OPTIONS.DEFAULT_DASH
            ? AS_BUILT_DEFAULT_OPTIONS.VIEW_ITEM_MASTER
            : asBuiltNumberDisplay;
    }
    get _asBuiltNumberDisplayIsDefault() {
        return this._asBuiltNumberDisplay === AS_BUILT_DEFAULT_OPTIONS.VIEW_ITEM_MASTER;
    }
    get _birthDateDisplay() {
        return this.hardwareModule && this.hardwareModule.birthDateDisplay;
    }
    get _ceiLevelDisplay() {
        return this.hardwareModule && this.hardwareModule.ceiLevelDisplay;
    }
    get _ceiNumberDisplay() {
        return this.hardwareModule && this.hardwareModule.ceiNumberDisplay;
    }
    get _drawingNumberDisplay() {
        return this.hardwareModule && this.hardwareModule.drawingNumberDisplay;
    }
    get _description() {
        return this.hardwareModule && this.hardwareModule.descriptionDisplay;
    }
    get _equipmentGroupDisplay() {
        return this.hardwareModule && this.hardwareModule.equipmentGroupDisplay.split(DEFAULT_DASH)[0];
    }
    get _equipmentGroupTooltipDisplay() {
        return this.hardwareModule && this.hardwareModule.equipmentGroupDisplay.split(DEFAULT_DASH)[1];
    }
    get _federalSupplyClassDisplay() {
        return this.hardwareModule && this.hardwareModule.federalSupplyClassDisplay.split(DEFAULT_DASH);
    }
    get _federalSupplyClassDisplayDescription() {
        if (!this._federalSupplyClassDisplay) {
            return DEFAULT_DASH;
        }
        return this._federalSupplyClassDisplay[1] || DEFAULT_DASH;
    }
    get _federalSupplyClassDisplayCode() {
        if (!this._federalSupplyClassDisplay) {
            return DEFAULT_DASH;
        }
        return this._federalSupplyClassDisplay[0] || DEFAULT_DASH;
    }
    get _hazardousMaterialStatus() {
        if (!this.hardwareData) {
            return;
        }
        if (this.isItemInstance || this.isInventory) {
            return Boolean(this._hazardousMaterialText !== DEFAULT_DASH);
        }
        return false;
    }
    get _hazardousMaterialText() {
        if (!this.hardwareData) {
            return DEFAULT_DASH;
        }
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            return (this.hardwareData?.computedHazardousMaterialText ||
                this.hardwareData?.itemMaster?.hazardousMaterialText ||
                DEFAULT_DASH);
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            return (this.hardwareData?.itemInstance?.computedHazardousMaterialText ||
                this.hardwareData?.itemMaster?.hazardousMaterialText ||
                DEFAULT_DASH);
        }
        return DEFAULT_DASH;
    }
    get _initStatus() {
        return this.hardwareModule && this.hardwareModule.initializationStatusIconDisplay;
    }
    get isArchived() {
        return this.hardwareModule?.isArchived;
    }
    get _itemClassDisplay() {
        return this.hardwareModule && this.hardwareModule.itemClassDisplay;
    }
    get _lastInspectionDateDisplay() {
        return this.hardwareModule && this.hardwareModule.lastInspectionDateDisplay;
    }
    get _lastServiceDateDisplay() {
        return this.hardwareModule && this.hardwareModule.lastServiceDateDisplay;
    }
    get _locationDisplay() {
        if (!this.isInventory) {
            return DEFAULT_DASH;
        }
        const hwData = this.hardwareData;
        return this.$locationDisplay(hwData.location);
    }
    get _lotNumberDisplay() {
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            const lotNumberDisplay = this.hardwareModule && this.hardwareModule.lotNumberDisplay;
            return this.hardwareModule && this.hardwareModule.lotNumberDisplay && this.hardwareData.lotNumber
                ? `<span class="monospace_font">${lotNumberDisplay}</span>`
                : 'View Item Instance';
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            const lotNumberDisplay = this.hardwareModule && this.hardwareModule.lotNumberDisplay;
            return this.hardwareModule && this.hardwareModule.lotNumberDisplay && this.hardwareData?.itemInstance?.lotNumber
                ? `<span class="monospace_font">${lotNumberDisplay}</span>`
                : 'View Item Instance';
        }
    }
    get _manufactureDateDisplay() {
        return this.hardwareModule && this.hardwareModule.manufactureDateDisplay;
    }
    get _objectClassDisplay() {
        return this.hardwareModule && this.hardwareModule.objectClassDisplay;
    }
    get _operationalStartDateDisplay() {
        return this.hardwareModule && this.hardwareModule.operationalStartDateDisplay;
    }
    get _opsNom() {
        return this.hardwareModule?.hardware?.opsNom || DEFAULT_DASH;
    }
    get _pdaDateDisplay() {
        return this.hardwareModule && this.hardwareModule.pdaDateDisplay;
    }
    get _piaDateDisplay() {
        return this.hardwareModule && this.hardwareModule.piaDateDisplay;
    }
    get _projectCodeDisplay() {
        return this.hardwareModule && this.hardwareModule.projectCodeDisplay;
    }
    get _propertyClassDisplay() {
        return this.hardwareModule && this.hardwareModule.propertyClassDisplay;
    }
    get _purchaseRequestDateDisplay() {
        return this.hardwareModule && this.hardwareModule.purchaseRequestDateDisplay;
    }
    get _serialNumberDisplay() {
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            const serialNumberDisplay = this.hardwareModule && this.hardwareModule.serialNumberDisplay;
            return this.isSerialControlled && this.hardwareData.serialNumber
                ? `<span class="monospace_font">${serialNumberDisplay}</span>`
                : 'View Item Instance';
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            const serialNumberDisplay = this.hardwareModule && this.hardwareModule.serialNumberDisplay;
            return this.isSerialControlled && this.hardwareData?.itemInstance?.serialNumber
                ? `<span class="monospace_font">${serialNumberDisplay}</span>`
                : 'View Item Instance';
        }
    }
    get _sideDisplay() {
        return this.hardwareModule && this.hardwareModule.sideDisplay;
    }
    get _sizeDisplay() {
        return this.hardwareModule && this.hardwareModule.sizeDisplay;
    }
    get _softwareVersion() {
        if (!this.hardwareData) {
            return DEFAULT_DASH;
        }
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            return this.hardwareData.overrideSoftwareVersion || this.hardwareData.itemMaster?.softwareVersion || DEFAULT_DASH;
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            return (this.hardwareData?.itemInstance?.overrideSoftwareVersion ||
                this.hardwareData?.itemMaster?.softwareVersion ||
                DEFAULT_DASH);
        }
        return DEFAULT_DASH;
    }
    get canEditDrawing() {
        return this.currentUserHasPermission(UserPermission.ITEM_DRAWING_ADMIN);
    }
    get canEditItemInstance() {
        return this.currentUserHasPermission(UserPermission.ITEM_INSTANCE_ADMIN);
    }
    get canEditItemMaster() {
        return this.currentUserHasPermission(UserPermission.ITEM_MASTER_ADMIN);
    }
    get disableViewInventory() {
        return this.$route.fullPath.indexOf('#inventory') > -1;
    }
    get disableViewItemDrawing() {
        const path = this.$route.fullPath;
        return path.indexOf('item_drawings') > -1 && path.indexOf('#information') > -1;
    }
    get disableViewItemInstance() {
        const path = this.$route.fullPath;
        return path.indexOf('item_instances') > -1 && path.indexOf('#information') > -1;
    }
    get disableViewItemMaster() {
        const path = this.$route.fullPath;
        return path.indexOf('item_masters') > -1 && path.indexOf('#information') > -1;
    }
    get linkCondtionForFetch() {
        if (this.hardwareData && this.hardwareModule) {
            // building object with fetch conditions where the value isn't null
            return ['drawingNumber', 'asBuiltNumber', 'side'].reduce((fetchCondition, key) => {
                if (this.hardwareModule?.[key]) {
                    fetchCondition[key] = this.hardwareModule?.[key];
                }
                return fetchCondition;
            }, {});
        }
        return null;
    }
    get linksFormatted() {
        return this.links.reduce((links, link) => {
            if (link?.currentVersionUrl) {
                links.push({
                    name: `${link.subType} - Current Version`,
                    url: link.currentVersionUrl
                });
            }
            if (link?.workingVersionUrl) {
                links.push({
                    name: `${link.subType} - Working Version`,
                    url: link.workingVersionUrl
                });
            }
            return links;
        }, []);
    }
    get hasSoftwareVersion() {
        if (!this.hardwareData) {
            return false;
        }
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            return this.hardwareData?.computedSoftwareVersion;
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            return (this.hardwareData?.itemInstance?.computedSoftwareVersion || this.hardwareData.itemMaster?.hasSoftwareVersion);
        }
        return false;
    }
    get isHazardousMaterial() {
        if (!this.hardwareData) {
            return false;
        }
        if (this.isItemInstance) {
            this.hardwareData = this.hardwareData;
            return this.hardwareData?.computedHazardousMaterialText || this.hardwareData?.itemMaster?.isHazardousMaterial;
        }
        if (this.isInventory) {
            this.hardwareData = this.hardwareData;
            return (Boolean(this.hardwareData?.itemInstance?.overrideHazardousMaterialText) ||
                this.hardwareData?.itemMaster?.isHazardousMaterial);
        }
        return false;
    }
    get isItemDrawing() {
        return this.hardwareType === EntityType.ITEM_DRAWING;
    }
    get isItemMaster() {
        return this.hardwareType === EntityType.ITEM_MASTER;
    }
    get isItemInstance() {
        return this.hardwareType === EntityType.ITEM_INSTANCE;
    }
    get isInventory() {
        return this.hardwareType === EntityType.INVENTORY;
    }
    get isSerialControlled() {
        let serialNumber = null;
        switch (this.hardwareType) {
            case EntityType.ITEM_INSTANCE:
                serialNumber = this.$get(this, 'hardwareData.serialNumber');
                break;
            case EntityType.INVENTORY:
                serialNumber = (this.$get(this, 'hardwareData.itemInstance.serialNumber') ||
                    this.$get(this, 'hardwareData.serialNumber'));
                break;
            default:
                serialNumber = null;
        }
        return Boolean(serialNumber);
    }
    get itemDrawingNodeId() {
        return this.hardwareModule?.itemDrawingNodeId;
    }
    get itemInstanceNodeId() {
        return this.hardwareModule?.itemInstanceNodeId;
    }
    get itemMasterNodeId() {
        return this.hardwareModule?.itemMasterNodeId;
    }
    get drawingNumber() {
        return '';
    }
    get drawingNumberHref() {
        if (!this.hardwareData?.drawingNumber) {
            return;
        }
        return `/hardware/${EntityType.ITEM_DRAWING}/manage/${encodeURIComponent(this.hardwareData.drawingNumber)}#information`;
    }
    get limitedLifeReportLinks() {
        return {
            interval: `/reporting/interval_life/results?limitedLifeType=${LimitedLifeType.INTERVAL}&drawingNumberStartsWith=${this.drawingNumber}`,
            onOrbit: `/reporting/on_orbit_life/results?drawingNumberStartsWith=${this.drawingNumber}`,
            operation: `/reporting/interval_life/results?limitedLifeType=${LimitedLifeType.OPERATION}&drawingNumberStartsWith=${this.drawingNumber}`,
            pressurizedTime: `/reporting/pressurized_time/results?drawingNumberStartsWith=${this.drawingNumber}`,
            shelf: `/reporting/interval_life/results?limitedLifeType=${LimitedLifeType.SHELF}&drawingNumberStartsWith=${this.drawingNumber}`,
            static: `/reporting/static_life/results?drawingNumberStartsWith=${this.drawingNumber}`,
            usage: `/reporting/interval_life/results?limitedLifeType=${LimitedLifeType.USAGE}&drawingNumberStartsWith=${this.drawingNumber}`
        };
    }
    get locationHref() {
        if (!this.isInventory && this.hardwareData) {
            return;
        }
        this.hardwareData = this.hardwareData;
        const locationId = this.hardwareData?.location?.id;
        return locationId ? `/${EntityType.LOCATION}/manage/${locationId}#information` : '';
    }
    get asBuiltNoHref() {
        if (!this.itemMasterNodeId) {
            return;
        }
        return `/hardware/${EntityType.ITEM_MASTER}/manage/${this.itemMasterNodeId}#information`;
    }
    get serialLotHref() {
        if (!this.itemInstanceNodeId) {
            return;
        }
        return `/hardware/${EntityType.ITEM_INSTANCE}/manage/${this.itemInstanceNodeId}#information`;
    }
    get quantityHref() {
        if (!this.hardwareData) {
            return;
        }
        return `/hardware/${this.hardwareType}/manage/${this.hardwareData.nodeId}#information`;
    }
    get inventoryQuantityHref() {
        if (!this._drawingNumberDisplay) {
            return;
        }
        const _queryVariables = {
            drawingNumberStartsWith: this._drawingNumberDisplay,
            supportContext: this.currentUserActiveSupportContext
        };
        const _queryString = qs.stringify({ ..._queryVariables }, { indices: false });
        return `/reporting/inventory/results?${_queryString}`;
    }
    get itemMasterInventoryQuantityHref() {
        if (!this._drawingNumberDisplay) {
            return;
        }
        const getAsBuiltNumber = () => {
            return this._asBuiltNumberDisplay === AS_BUILT_DEFAULT_OPTIONS.VIEW_ITEM_MASTER
                ? DB_DEFAULT
                : this._asBuiltNumberDisplay;
        };
        const _queryVariables = {
            drawingNumberStartsWith: this._drawingNumberDisplay,
            asBuiltNumber: getAsBuiltNumber(),
            supportContext: this.currentUserActiveSupportContext
        };
        const _queryString = qs.stringify({ ..._queryVariables }, { indices: false });
        return `/reporting/inventory/results?${_queryString}`;
    }
    get itemInstanceInventoryQuantityHref() {
        if (!this._drawingNumberDisplay) {
            return;
        }
        const _queryVariables = {
            drawingNumberStartsWith: this._drawingNumberDisplay,
            asBuiltNumber: this.shouldShow(this._asBuiltNumberDisplay) ? this._asBuiltNumberDisplay : '',
            serialNumber: this.shouldShow(this._serialNumberDisplay) ? this._serialNumberDisplay : '',
            lotNumber: this.shouldShow(this._lotNumberDisplay) ? this._lotNumberDisplay : '',
            supportContext: this.currentUserActiveSupportContext
        };
        const _queryString = qs.stringify({ ..._queryVariables }, { indices: false });
        return `/reporting/inventory/results?${_queryString}`;
    }
    get itemDrawingCommentsReportHref() {
        if (!this._drawingNumberDisplay) {
            return null;
        }
        return `/reporting/hardware_comments/results?drawingNumbers=${this._drawingNumberDisplay}`;
    }
    get shouldShowDates() {
        const dates = [
            '_birthDateDisplay',
            '_operationalStartDateDisplay',
            '_acquisitionDateDisplay',
            '_operationalStartDateDisplay',
            '_lastInspectionDateDisplay',
            '_lastServiceDateDisplay'
        ];
        // show the dates column if any dates have a value
        return dates.map((key) => this.shouldShow(this[key])).some((value) => value === true);
    }
    get shouldShowExternalLinksButton() {
        return this.linksFormatted?.length > 0;
    }
    buildLimitedLifeDisplay(limitedLifeType, hardware) {
        const valueDueDate = hardware?.limitedLifeRemaining && hardware?.limitedLifeRemaining[limitedLifeType]
            ? this.$dateDisplay(hardware?.limitedLifeRemaining[limitedLifeType]?.valueDueDate)
            : '';
        const valueDueDateHtml = valueDueDate && valueDueDate !== DEFAULT_DASH
            ? `<div class="d-inline-block limitedLifeTypeDisplay">Exp:</div> <div class="d-inline-block limitedLifeTypeValueDisplay monospace_font">${valueDueDate}</div>`
            : '';
        const serviceDueDate = hardware?.limitedLifeRemaining && hardware?.limitedLifeRemaining[limitedLifeType]
            ? this.$dateDisplay(hardware?.limitedLifeRemaining[limitedLifeType]?.serviceDueDate)
            : '';
        const serviceDueDateHtml = serviceDueDate !== DEFAULT_DASH
            ? `<div class="d-inline-block limitedLifeTypeDisplay">Svc:</div> <div class="d-inline-block limitedLifeTypeValueDisplay monospace_font">${valueDueDate}</div>`
            : '';
        // pressure time used/remaining
        // total is used
        // remaining is value or service
        if (limitedLifeType === 'pressurizedTime') {
            const limitedLifeRemaining = hardware?.limitedLifeRemaining?.[limitedLifeType];
            const remaining = limitedLifeRemaining
                ? this.$intervalDisplay(limitInterval(limitedLifeRemaining.value ?? defaultInterval, 'minutes'))
                : null;
            const total = limitedLifeRemaining
                ? this.$intervalDisplay(limitInterval(limitedLifeRemaining.total ?? defaultInterval, 'minutes'))
                : null;
            const service = limitedLifeRemaining
                ? this.$intervalDisplay(limitInterval(limitedLifeRemaining.service ?? defaultInterval, 'minutes'))
                : null;
            const usedHtml = `<div class="d-inline-block limitedLifeTypeDisplay">Used:</div>
        <div class="d-inline-block limitedLifeTypeValueDisplay monospace_font">${total}</div>`;
            const serviceRemainingHtml = service
                ? `<div class="d-inline-block limitedLifeTypeDisplay">Svc:
        </div> <div class="d-inline-block limitedLifeTypeValueDisplay monospace_font">${service}</div>`
                : '';
            const remainingHtml = remaining
                ? `<div class="d-inline-block limitedLifeTypeDisplay">Remain:
        </div> <div class="d-inline-block limitedLifeTypeValueDisplay monospace_font">${remaining}</div>`
                : '';
            return (remaining && remaining !== DEFAULT_DASH) || (service && service !== DEFAULT_DASH)
                ? `<div>
            <div class="font-weight-regular">${this.formatValuesForHref('PT', this.limitedLifeReportLinks['pressurizedTime'])}</div>
            <div class="ml-1">${serviceRemainingHtml}</div>
            <div class="ml-1">${usedHtml}</div>
            <div class="ml-1">${remainingHtml}</div>
          <div>`
                : null;
        }
        const limitedLifeTypeDisplay = this.formatValuesForHref(`${this.$humanize(limitedLifeType)}`, this.limitedLifeReportLinks[limitedLifeType]);
        return (valueDueDate && valueDueDate !== DEFAULT_DASH) || (serviceDueDate && serviceDueDate !== DEFAULT_DASH)
            ? `<div>
          <div class="font-weight-regular">${limitedLifeTypeDisplay}</div>
          <div class="ml-1">${valueDueDateHtml}</div>
          <div class="ml-1">${serviceDueDateHtml}</div>
        <div>`
            : `<div>
          <div class="font-weight-regular">${limitedLifeTypeDisplay}</div>
          <div class="ml-1">-</div>
        <div>`;
    }
    createBooleanIcon(val) {
        if (val === null || val === undefined) {
            // this.$vuetify.icons.help just wanted to use fad
            return `<i class="default--text fad fa-question-circle"></i>`;
        }
        if (val === false) {
            // this.$vuetify.icons.clear
            return `<i class="error--text fad fa-times-circle"></i>`;
        }
        if (val === true) {
            // this.$vuetify.icons.circle_check
            return `<i class="success--text fad fa-check-circle"></i>`;
        }
    }
    created() {
        this.$listen('reload', () => {
            this.fetchHardwareEntity(this.nodeId);
        });
    }
    async fetchHardwareEntity(nodeId) {
        // Implement the fetch in each hardware display
        return Promise.resolve();
    }
    shouldShow(display) {
        return display && display !== DEFAULT_DASH;
    }
    onNodeIdChange() {
        if (this.nodeId) {
            this.fetchHardwareEntity(this.nodeId);
        }
    }
    onHardwareChange() {
        this.hardwareModule = new Hardware(this.hardwareData);
        return this.hardwareModule;
    }
};
__decorate([
    Prop({
        type: String,
        default: 'network-only'
    }),
    __metadata("design:type", typeof (_a = typeof FetchPolicy !== "undefined" && FetchPolicy) === "function" ? _a : Object)
], BaseHardwareDisplay.prototype, "fetchPolicy", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], BaseHardwareDisplay.prototype, "hideBorderBottom", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], BaseHardwareDisplay.prototype, "hideButtons", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], BaseHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], BaseHardwareDisplay.prototype, "title", void 0);
__decorate([
    Watch('nodeId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseHardwareDisplay.prototype, "onNodeIdChange", null);
__decorate([
    Emit('hardwareDataChange'),
    Watch('hardwareData'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], BaseHardwareDisplay.prototype, "onHardwareChange", null);
BaseHardwareDisplay = __decorate([
    Component
], BaseHardwareDisplay);
export default BaseHardwareDisplay;
