import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus, ReadinessStatus } from '~/db_types';
import BaseHardwareDisplay from '~/nasa_ui/base/BaseHardwareDisplay';
import { CosmicIntervalFragment } from '~/nasa_ui/DAL/base/interval';
import { CosmicLimitedLifeQuotaFragment, CosmicLimitedLifeRemainingFragment } from '~/nasa_ui/DAL/limitedLife';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { EntityType } from '~/nasa_ui/types';
import { InventoryStatusDisplay } from '~/nasa_ui/types/enums/hardware';
let AppInventoryDisplay = class AppInventoryDisplay extends BaseHardwareDisplay {
    ReadinessStatus = ReadinessStatus;
    get _computedEquipmentControlNumber() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData.itemInstance?.equipmentControlNumber;
    }
    get _computedLastServiceDate() {
        if (!this._hardwareData) {
            return;
        }
        return this.$dateDisplay(this._hardwareData?.itemInstance?.computedLastServiceDate);
    }
    get _computedLastInspectionDate() {
        if (!this._hardwareData) {
            return;
        }
        return this.$dateDisplay(this._hardwareData?.itemInstance?.computedLastInspectionDate);
    }
    get _hardwareData() {
        return this.hardwareData;
    }
    get _hasChildren() {
        return Boolean(this._hardwareData?.hasChildren);
    }
    get _hasItemInstance() {
        // need to guarantee there is an item instance on inventory displays, can otherwise
        // get into a state on the home page receive PR table where serial or lot display would
        // try to link to an undefined item instance
        return this._hardwareData?.itemInstance;
    }
    get _hasOpenPaperAssy() {
        if (!this._hardwareData) {
            return false;
        }
        const hasOpenPaperAssy = Boolean(this._hardwareData?.itemInstance?.openDocumentCount) ||
            Boolean(this._hardwareData?.itemInstance?.openDocumentCountAssembly);
        return hasOpenPaperAssy;
    }
    get _isInstalled() {
        return Boolean(this._hardwareData?.isInstalled);
    }
    get _initializationStatus() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData?.itemInstance?.initializationStatus === ChangeSetStatus.APPROVED;
    }
    get _intervalLifeDisplay() {
        return this.buildLimitedLifeDisplay('interval', this._hardwareData.itemInstance || null);
    }
    get inventoryMethod() {
        return this._hardwareData?.itemMaster?.inventoryMethod || this._hardwareData?.itemMaster?.inventoryUnitType;
    }
    get _inventoryId() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData?.id;
    }
    get _inventoryQuantity() {
        if (!this.hardwareData) {
            return '0';
        }
        return this._hardwareData?.quantity?.toString();
    }
    get _inventoryStatusDisplay() {
        return this._hardwareData?.status ? InventoryStatusDisplay.get(this._hardwareData?.status) : '?';
    }
    get isApprovedAsDesign() {
        if (!this._hardwareData) {
            return;
        }
        return (Boolean(this._hardwareData.computedIsApprovedAsDesign) ||
            Boolean(this._hardwareData.itemMaster?.isApprovedAsDesign));
    }
    get _limitedLifeAlertCountIsGreaterThanZero() {
        if (!this._hardwareData) {
            return false;
        }
        const limitedLifeAlertTotalCount = this._hardwareData?.itemInstance?.limitedLifeAlertCount;
        return limitedLifeAlertTotalCount ? limitedLifeAlertTotalCount > 0 : false;
    }
    get _onOrbitLifeDisplay() {
        return this.buildLimitedLifeDisplay('onOrbit', this._hardwareData.itemInstance || null);
    }
    get _operationLifeDisplay() {
        return this.buildLimitedLifeDisplay('operation', this._hardwareData.itemInstance || null);
    }
    get _pressurizedTimeLifeDisplay() {
        return this.buildLimitedLifeDisplay('pressurizedTime', this._hardwareData.itemInstance || null);
    }
    get _readinessHref() {
        if (!this._inventoryId) {
            return;
        }
        return `/reporting/readiness/results?inventoryIds=${this._inventoryId}`;
    }
    get _readinessStatus() {
        if (!this._hardwareData) {
            return;
        }
        return this._hardwareData?.computedReadinessStatus;
    }
    get _shelfLifeDisplay() {
        return this.buildLimitedLifeDisplay('shelf', this._hardwareData.itemInstance || null);
    }
    get _staticLifeDisplay() {
        return this.buildLimitedLifeDisplay('static', this._hardwareData.itemInstance || null);
    }
    get _usageLifeDisplay() {
        return this.buildLimitedLifeDisplay('usage', this._hardwareData.itemInstance || null);
    }
    get assemblyLink() {
        if (!this._hardwareData) {
            return null;
        }
        const iiId = this._hardwareData.itemInstance && this._hardwareData.itemInstance.id;
        const invId = this._hardwareData.id;
        return iiId && invId ? `/hardware/${EntityType.ITEM_INSTANCE}/manage/${iiId}?inventoryId=${invId}#assembly` : null;
    }
    get assemblyMultiLevelLink() {
        const invId = this._hardwareData.id;
        if (invId && this.isInventory) {
            return `/reporting/indentured_parts_list?inventoryId=${invId}`;
        }
        else {
            return null;
        }
    }
    get disableViewAssembly() {
        return this.disableViewAssemblySingleLine && !this.isRootNode;
    }
    get disableViewAssemblySingleLine() {
        return (this.$route.fullPath.indexOf('#assembly') > -1 &&
            this.assemblyLink &&
            window.location.href.includes(this.assemblyLink));
    }
    get disableViewMultiLevel() {
        if (!this.assemblyMultiLevelLink) {
            return true;
        }
        const path = this.$route.fullPath;
        return path.indexOf('indentured_parts_list') > -1 || !this.isRootNode;
    }
    get drawingNumber() {
        return this._hardwareData?.drawingNumber || '';
    }
    get hasLimitedLifeShelf() {
        return this._hardwareData.itemInstance?.hasLimitedLifeShelf;
    }
    get hasLimitedLifeUsage() {
        return this._hardwareData.itemInstance?.hasLimitedLifeUsage;
    }
    get hasLimitedLifeOperation() {
        return this._hardwareData.itemInstance?.hasLimitedLifeOperation;
    }
    get hasLimitedLifePressurizedTime() {
        return this._hardwareData.itemInstance?.hasLimitedLifePressurizedTime;
    }
    get hasLimitedLifeInterval() {
        return this._hardwareData.itemInstance?.hasLimitedLifeInterval;
    }
    get hasLimitedLifeOnOrbit() {
        return this._hardwareData.itemInstance?.hasLimitedLifeOnOrbit;
    }
    get hasLimitedLifeStatic() {
        return this._hardwareData.itemInstance?.hasLimitedLifeStatic;
    }
    get isRootNode() {
        const hardwareData = this.hardwareData;
        return hardwareData.hasChildren && !hardwareData.isInstalled;
    }
    async fetchHardwareEntity(nodeId) {
        try {
            this.isLoading = true;
            // TODO: add computedSoftwareVersion to ItemInstanceForAppHardwareDisplayInventory
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicLocationSlimFragment}
          ${CosmicLimitedLifeRemainingFragment}
          ${CosmicLimitedLifeQuotaFragment}
          ${CosmicIntervalFragment}
          ${CosmicLinkSlimFragment}

          fragment ItemInstanceForAppHardwareDisplayInventory on ItemInstance {
            acquisitionDate
            asBuiltNumber
            asDesignNumber
            availableQuantity
            birthDate
            computedHazardousMaterialText
            computedLastInspectionDate
            computedLastServiceDate
            computedSoftwareVersion
            cureDate
            drawingNumber
            equipmentControlNumber
            hasLimitedLifeCycle: hasLimitedLife(limitedLifeType: [CYCLE])
            hasLimitedLifeErrors
            hasLimitedLifeEva: hasLimitedLife(limitedLifeType: [EVA])
            hasLimitedLifeFlight: hasLimitedLife(limitedLifeType: [FLIGHT])
            hasLimitedLifeInterval: hasLimitedLife(limitedLifeType: [INTERVAL])
            hasLimitedLifeOnOrbit: hasLimitedLife(limitedLifeType: [ON_ORBIT])
            hasLimitedLifeOperation: hasLimitedLife(limitedLifeType: [OPERATION])
            hasLimitedLifePressurizedTime: hasLimitedLife(limitedLifeType: [PRESSURIZED_TIME])
            hasLimitedLifeProperties
            hasLimitedLifeShelf: hasLimitedLife(limitedLifeType: [SHELF])
            hasLimitedLifeStatic: hasLimitedLife(limitedLifeType: [STATIC])
            hasLimitedLifeUsage: hasLimitedLife(limitedLifeType: [USAGE])
            id
            initializationStatus
            installedQuantity
            inventoryQuantity
            itemDrawingDescription
            limitedLifeAlertCount
            lotNumber
            manufactureDate
            nodeId
            openDate
            openDocumentCount
            openDocumentCountAssembly
            operationalStartDate
            overrideHazardousMaterialText
            overrideSoftwareVersion
            pdaDate
            piaDate
            purchaseRequestDate
            serialNumber
            side
            size
            subType

            computedMannedPressurizedTime {
              ...CosmicInterval
            }
            computedUnmannedPressurizedTime {
              ...CosmicInterval
            }
            computedMannedPressurizedTimeSinceLastServ {
              ...CosmicInterval
            }
            computedUnmannedPressurizedTimeSinceLastSe {
              ...CosmicInterval
            }
            limitedLife {
              ...CosmicLimitedLifeQuota
            }
            limitedLifeRemaining {
              ...CosmicLimitedLifeRemaining
            }
            links {
              nodes {
                ...CosmicLinkSlim
              }
            }
          }

          fragment InventoryForAppHardwareDisplay on Inventory {
            drawingNumber
            computedReadinessStatus
            hasChildren
            isInstalled
            id
            subType
            itemDrawingDescription
            nodeId
            projectCode
            quantity
            status
            unitCost

            itemDrawing {
              nodeId
            }

            itemMaster {
              contractEndingItemLevel
              contractEndingItemNumber
              equipmentGroup: subType
              federalSupplyClass
              hasSoftwareVersion
              hazardousMaterialText
              inventoryControl
              inventoryMethod
              inventoryUnitType
              isHazardousMaterial
              isApprovedAsDesign
              nodeId
              propertyClass
              softwareVersion
              subType
            }

            itemInstance {
              ...ItemInstanceForAppHardwareDisplayInventory
            }

            location {
              ...CosmicLocationSlim
            }
          }

          query GetInventoryForAppHardwareDisplay($nodeId: ID!) {
            inventory(nodeId: $nodeId) {
              ...InventoryForAppHardwareDisplay
            }
          }
        `,
                variables: {
                    nodeId
                },
                fetchPolicy: this.fetchPolicy
            });
            if (!resp.data.inventory) {
                throw new Error('Couldnt fetch hardware');
            }
            this.hardwareType = EntityType.INVENTORY;
            this.hardwareData = resp.data.inventory;
            this.links = resp.data.inventory?.itemInstance?.links?.nodes || [];
            this.isLoading = false;
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: Unable to fetch Inventory for the hardware display.'
            });
        }
    }
};
AppInventoryDisplay = __decorate([
    Component
], AppInventoryDisplay);
export default AppInventoryDisplay;
