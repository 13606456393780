var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { InventoryClass } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import HardwareListAssemblyFormMixin from '~/nasa_ui/mixins/HardwareListAssemblyFormMixin';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { HardwareListAssemblyResponseDisplay } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let ModalHardwareListAssemblyEdit = class ModalHardwareListAssemblyEdit extends Mixins(BaseModal, BaseCosmic, HardwareListAssemblyFormMixin, HttpMixin) {
    hardwareListAssembly;
    showAdditionalMetadata;
    // this form is always valid, bc we're showing a db error
    get isFormValid() {
        return true;
    }
    get shouldDisableEditButton() {
        return !this.isFormValid;
    }
    get editHardwareListAssemblyVariables() {
        if (!this.isFormValid || isNullOrUndefined(this.formData)) {
            console.error('Invalid form or missing form data.');
            return null;
        }
        const notesAsNumbers = this.formData.notes?.map((note) => parseInt(note));
        return {
            addendumDate: this.formData.addendumDate,
            addendumNumber: this.formData.addendumNumber,
            externalAsBuiltNumber: this.formData.externalAsBuiltNumber,
            externalBirthDate: this.formData.externalBirthDate,
            externalCalibrationDate: this.formData.externalCalibrationDate,
            externalCalibrationNumber: this.formData.externalCalibrationNumber,
            externalClass: this.formData.externalClass,
            externalContractEndingItemNumber: this.formData.externalContractEndingItemNumber,
            externalDescription: this.formData.externalDescription,
            externalDrawingNumber: this.formData.externalDrawingNumber,
            externalIsTool: this.formData.externalIsTool,
            externalLocation: this.formData.externalLocation,
            externalLotNumber: this.formData.externalLotNumber,
            externalSerialNumber: this.formData.externalSerialNumber,
            externalShelfLifeDate: this.formData.externalShelfLifeDate,
            externalUsageLifeDate: this.formData.externalUsageLifeDate,
            inventoryId: this.formData?.inventory?.id,
            isExternal: this.formData.isExternal,
            notes: notesAsNumbers,
            quantity: this.formData.quantity,
            sequence: this.formData.sequence
        };
    }
    onClickOfEditHardwareListAssembly() {
        if (this.isFormValid && this.editHardwareListAssemblyVariables) {
            this.editHardwareListAssembly(this.editHardwareListAssemblyVariables);
        }
    }
    async editHardwareListAssembly(updates) {
        await this.httpPut(`/hardwarelist/assemblies/${this.hardwareListAssembly.id}`, updates);
        this.emitEntityEdited('Saved.');
        this.emitModalClose();
    }
    onHardwareListAssemblyChange(val) {
        const obj = Object.assign({}, val);
        const notesAsStrings = obj.notes?.map((note) => note + '');
        this.formData = {
            addendumDate: obj.addendumDate,
            addendumNumber: obj.addendumNumber,
            externalAsBuiltNumber: obj.externalAsBuiltNumber ?? null,
            externalBirthDate: obj.externalBirthDate,
            externalCalibrationDate: obj.externalCalibrationDate ?? null,
            externalCalibrationNumber: obj.externalCalibrationNumber ?? null,
            externalClass: obj.externalClass ?? InventoryClass.UNKNOWN,
            externalContractEndingItemNumber: obj.externalContractEndingItemNumber ?? null,
            externalDescription: obj.externalDescription ?? null,
            externalDrawingNumber: obj.externalDrawingNumber ?? null,
            externalIsTool: obj.externalIsTool ?? false,
            externalLocation: obj.externalLocation ?? null,
            externalLotNumber: obj.externalLotNumber ?? null,
            externalSerialNumber: obj.externalSerialNumber ?? null,
            externalShelfLifeDate: obj.externalShelfLifeDate ?? null,
            externalUsageLifeDate: obj.externalUsageLifeDate ?? null,
            inventory: obj.inventory ?? null,
            inventoryId: obj.inventoryId ?? null,
            isExternal: obj.isExternal ?? false,
            notes: notesAsStrings,
            quantity: obj.quantity ?? 0,
            sequence: obj.sequence ?? 999
        };
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof HardwareListAssemblyResponseDisplay !== "undefined" && HardwareListAssemblyResponseDisplay) === "function" ? _a : Object)
], ModalHardwareListAssemblyEdit.prototype, "hardwareListAssembly", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalHardwareListAssemblyEdit.prototype, "showAdditionalMetadata", void 0);
__decorate([
    Watch('hardwareListAssembly', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof HardwareListAssemblyResponseDisplay !== "undefined" && HardwareListAssemblyResponseDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalHardwareListAssemblyEdit.prototype, "onHardwareListAssemblyChange", null);
ModalHardwareListAssemblyEdit = __decorate([
    Component
], ModalHardwareListAssemblyEdit);
export default ModalHardwareListAssemblyEdit;
