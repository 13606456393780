import gql from 'graphql-tag';
import { CosmicIntervalFragment } from '../base/interval';
import { CosmicLimitedLifeCycleRemainingFragment, CosmicLimitedLifeIntervalRemainingFragment } from './slim';
export const CosmicLimitedLifeRemainingFragment = gql `
  ${CosmicLimitedLifeCycleRemainingFragment}
  ${CosmicLimitedLifeIntervalRemainingFragment}

  fragment CosmicLimitedLifeRemaining on LimitedLifeRemaining {
    shelf {
      ...CosmicLimitedLifeIntervalRemaining
    }
    usage {
      ...CosmicLimitedLifeIntervalRemaining
    }
    operation {
      ...CosmicLimitedLifeIntervalRemaining
    }
    pressurizedTime {
      ...CosmicLimitedLifeIntervalRemaining
    }
    interval {
      ...CosmicLimitedLifeIntervalRemaining
    }
    onOrbit {
      ...CosmicLimitedLifeIntervalRemaining
    }
    static {
      ...CosmicLimitedLifeIntervalRemaining
    }
    flight {
      ...CosmicLimitedLifeCycleRemaining
    }
    eva {
      ...CosmicLimitedLifeCycleRemaining
    }
    cycle {
      ...CosmicLimitedLifeCycleRemaining
    }
  }
`;
export const CosmicLimitedLifeQuotaFragment = gql `
  ${CosmicIntervalFragment}

  fragment CosmicLimitedLifeQuota on LimitedLifeQuota {
    code
    description
    disableAlerts

    shelf {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    usage {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    operation {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    pressurizedTime {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    interval {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    onOrbit {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }
    static {
      overrideValueDate
      value {
        ...CosmicInterval
      }
      service {
        ...CosmicInterval
      }
    }

    flight {
      value
      service
    }
    cycle {
      value
      service
    }
    eva {
      value
      service
    }
  }
`;
