import { EntityType } from '~/nasa_ui/types';
function addPrimaryKeyInformation(primaryKeys, 
// tslint:disable-next-line:max-line-length
resultObj) {
    const entityType = resultObj.entityType;
    switch (entityType) {
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/02-users.sql#L39
        case EntityType.ORGANIZATION: {
            const r = resultObj;
            r.code = primaryKeys[0];
            return r;
        }
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/06-hardware.sql#L108
        case EntityType.ITEM_MASTER: {
            const r = resultObj;
            r.drawingNumber = primaryKeys[0];
            r.asBuiltNumber = primaryKeys[1];
            return r;
        }
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/06-hardware.sql#L6
        case EntityType.ITEM_DRAWING: {
            const r = resultObj;
            r.drawingNumber = primaryKeys[0];
            return r;
        }
        case EntityType.USER_GROUP: {
            const r = resultObj;
            r.code = primaryKeys[0];
            r.organizationCode = primaryKeys[1];
            return r;
        }
        // If the PK is just the id
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/04-vehicles.sql#L9
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/05-documents.sql#L25
        // https://github.com/mridevteam/cosmic_graphql/blob/master/postgres/sql/09-events.sql#L33
        // II
        default: {
            const r = resultObj;
            r.id = primaryKeys[0];
            return r;
        }
    }
}
export const parseNodeId = (nodeId) => {
    try {
        if (!nodeId) {
            return null;
        }
        const [entityType, ...primaryKeys] = JSON.parse(atob(nodeId));
        const t = addPrimaryKeyInformation(primaryKeys, {
            entityType
        });
        return t;
    }
    catch (error) {
        return null;
    }
};
