var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.hardwareData && !_vm.isLoading ? _c('div', {
    staticClass: "ahd--wrapper"
  }, [_vm.hardwareData && _vm.hardwareData.supportOrganizationCode ? _c('div', [_c('Alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" This display shows the status of the hardware located in "), _c('strong', [_vm._v(_vm._s(_vm.hardwareData.supportOrganizationCode))])])], 1) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-container"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._drawingNumberDisplay,
      "vhtml": _vm.formatValuesForHref(_vm._drawingNumberDisplay, _vm.drawingNumberHref),
      "direction": "right",
      "label": "Drawing num:",
      "tooltipLabel": "Drawing number"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._asBuiltNumberDisplay,
      "vhtml": _vm.formatValuesForHref(_vm._asBuiltNumberDisplay, _vm.asBuiltNoHref),
      "direction": "right",
      "label": "AsBuilt:",
      "tooltipLabel": "AsBuilt number",
      "monospace-font": !_vm._asBuiltNumberDisplayIsDefault
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._serialNumberCrossContextDisplay,
      "text": _vm._serialNumberCrossContextDisplay,
      "direction": "right",
      "label": "Serial:",
      "tooltipLabel": "Serial"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._lotNumberCrossContextDisplay,
      "text": _vm._lotNumberCrossContextDisplay,
      "direction": "right",
      "label": "Lot:",
      "tooltipLabel": "Lot"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sideDisplay,
      "tooltipText": _vm._sideDisplay,
      "direction": "right",
      "label": "Side:",
      "tooltipLabel": "Side"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sizeDisplay,
      "tooltipText": _vm._sizeDisplay,
      "direction": "right",
      "label": "Size:",
      "tooltipLabel": "Size"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._description,
      "vhtml": _vm.formatValuesForHref(_vm._description, _vm.drawingNumberHref),
      "direction": "right",
      "label": "Description:",
      "tooltipLabel": "Description"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._itemClassDisplay,
      "tooltipText": _vm._itemClassDisplay,
      "label": "Class:",
      "tooltipLabel": "Class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "label": "Fed cls:",
      "text": _vm._federalSupplyClassDisplayCode,
      "tooltipLabel": "Federal Supply Class",
      "tooltipText": _vm._federalSupplyClassDisplayDescription,
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._objectClassDisplay,
      "tooltipText": _vm._objectClassDisplay,
      "label": "Obj cls:",
      "tooltipLabel": "Object class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._propertyClassDisplay,
      "tooltipText": _vm._propertyClassDisplay,
      "label": "Prop cls:",
      "tooltipLabel": "Property class",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._equipmentGroupDisplay,
      "tooltipText": _vm._equipmentGroupTooltipDisplay,
      "label": "Equip Grp:",
      "tooltipLabel": "Equipment group",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiNumberDisplay,
      "tooltipText": _vm._ceiNumberDisplay,
      "label": "CEI No:",
      "tooltipLabel": "CEI number",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._locationDisplay,
      "tooltipVhtml": _vm._locationDisplay,
      "label": "Loc:",
      "tooltipLabel": "Location"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._projectCodeDisplay,
      "tooltipText": _vm._projectCodeDisplay,
      "label": "Proj code:",
      "tooltipLabel": "Project code"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-three"
  }), _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm.hardwareData ? _c('AHDQuantityColumn', {
    attrs: {
      "inventory-method": _vm.inventoryMethod,
      "inventory-quantity": _vm.hardwareData._inventoryQuantity,
      "inventory-quantity-link": _vm.inventoryQuantityHref
    }
  }) : _vm._e()], 1)])]) : _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.ITEM_INSTANCE
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };