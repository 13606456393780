import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import HardwareListAssemblyFormMixin from '~/nasa_ui/mixins/HardwareListAssemblyFormMixin';
let ModalHardwareListAssemblyCreate = class ModalHardwareListAssemblyCreate extends Mixins(BaseModal, BaseCosmic, HardwareListAssemblyFormMixin) {
    selectedHardwareListAssembly = null;
    showAdditionalMetadata;
    get createHardwareListAssemblyVariables() {
        if (!this.parentHardwareListId || !this.selectedHardwareListAssembly) {
            return null;
        }
        const notesAsNumbers = this.formData.notes?.map((note) => {
            return parseInt(note);
        });
        return {
            addendumDate: this.selectedHardwareListAssembly.addendumDate,
            addendumNumber: this.selectedHardwareListAssembly.addendumNumber,
            externalAsBuiltNumber: this.selectedHardwareListAssembly.externalAsBuiltNumber,
            externalBirthDate: this.selectedHardwareListAssembly.externalBirthDate,
            externalCalibrationDate: this.selectedHardwareListAssembly.externalCalibrationDate,
            externalCalibrationNumber: this.selectedHardwareListAssembly.externalCalibrationNumber,
            externalClass: this.selectedHardwareListAssembly.externalClass,
            externalContractEndingItemNumber: this.selectedHardwareListAssembly.externalContractEndingItemNumber,
            externalDescription: this.selectedHardwareListAssembly.externalDescription,
            externalDrawingNumber: this.selectedHardwareListAssembly.externalDrawingNumber,
            externalIsTool: this.selectedHardwareListAssembly.externalIsTool,
            externalLocation: this.selectedHardwareListAssembly.externalLocation,
            externalLotNumber: this.selectedHardwareListAssembly.externalLotNumber,
            externalSerialNumber: this.selectedHardwareListAssembly.externalSerialNumber,
            externalShelfLifeDate: this.selectedHardwareListAssembly.externalShelfLifeDate,
            externalUsageLifeDate: this.selectedHardwareListAssembly.externalUsageLifeDate,
            inventoryId: this.selectedHardwareListAssembly?.inventory?.id,
            isExternal: this.selectedHardwareListAssembly.isExternal,
            notes: notesAsNumbers,
            quantity: this.selectedHardwareListAssembly.quantity,
            sequence: this.nextLogicalSequenceNumber
        };
    }
    get isFormValid() {
        return Boolean(this.selectedHardwareListAssembly);
    }
    async onClickOfCreate() {
        if (!this.createHardwareListAssemblyVariables) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$http.post(`/hardwarelist/${this.parentHardwareListId}/assemblies`, this.createHardwareListAssemblyVariables);
            if (resp) {
                this.emitEntityCreated('List updated.');
                this.emitModalClose();
            }
            this.isSaving = false;
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    onSerialNumberChange() {
        this.formData.quantity = this.formData.externalSerialNumber ? 1 : 0;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], ModalHardwareListAssemblyCreate.prototype, "showAdditionalMetadata", void 0);
__decorate([
    Watch('formData.externalSerialNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalHardwareListAssemblyCreate.prototype, "onSerialNumberChange", null);
ModalHardwareListAssemblyCreate = __decorate([
    Component
], ModalHardwareListAssemblyCreate);
export default ModalHardwareListAssemblyCreate;
