import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit } from 'vue-property-decorator';
import { CosmicLinkSlimFragment } from '~/nasa_ui/DAL/link';
import ModalLinkBase from '~/nasa_ui/components/ModalLinkBase/ModalLinkBase';
import { AlertType } from '~/nasa_ui/types';
let ModalLinkCreate = class ModalLinkCreate extends ModalLinkBase {
    get linkPayload() {
        const payload = {
            currentRevision: this.formData.currentRevision,
            currentVersionUrl: this.formData.currentVersionUrl,
            referenceNumber: this.formData.referenceNumber,
            subType: this.formData.subType,
            workingVersionUrl: this.formData.workingVersionUrl
        };
        if (this.itemDrawing) {
            return { drawingNumber: this.itemDrawing.drawingNumber, ...payload };
        }
        else if (this.itemMaster) {
            return {
                asBuiltNumber: this.itemMaster.asBuiltNumber,
                drawingNumber: this.itemMaster.drawingNumber,
                side: this.itemMaster.side,
                ...payload
            };
        }
        else if (this.itemInstance) {
            return {
                asBuiltNumber: this.itemInstance.asBuiltNumber,
                drawingNumber: this.itemInstance.drawingNumber,
                itemInstanceId: this.itemInstance.id,
                side: this.itemInstance.side,
                ...payload
            };
        }
        else if (this.documentId) {
            return {
                documentId: this.documentId,
                ...payload
            };
        }
        else if (this.eventId) {
            return {
                eventId: this.eventId,
                ...payload
            };
        }
        else if (this.riskId) {
            return {
                riskId: this.riskId,
                ...payload
            };
        }
        return null;
    }
    emitExternalLinkCreated() { }
    async onClickOfCreate() {
        try {
            await this.createLink(this.linkPayload);
            this.emitModalClose();
            this.emitExternalLinkCreated();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    // Uses API2 if possible
    async createLink(linkPayload) {
        if (!linkPayload) {
            return;
        }
        if (this.canUseApi2(linkPayload)) {
            const body = {
                currentRevision: linkPayload.currentRevision,
                currentVersionUrl: linkPayload.currentVersionUrl,
                referenceNumber: linkPayload.referenceNumber,
                subType: linkPayload.subType,
                workingVersionUrl: linkPayload.workingVersionUrl
            };
            const queryParams = { ...linkPayload };
            //@ts-ignore
            delete queryParams.currentRevision;
            //@ts-ignore
            delete queryParams.currentVersionUrl;
            //@ts-ignore
            delete queryParams.referenceNumber;
            //@ts-ignore
            delete queryParams.subType;
            //@ts-ignore
            delete queryParams.workingVersionUrl;
            const resp = await this.$http.post(`/links`, body, {
                params: queryParams
            });
            if (resp) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Created.'
                });
            }
        }
        else {
            const resp = await this.$apollo.mutate({
                mutation: gql `
            ${CosmicLinkSlimFragment}

            mutation CreateLinkLinkFromModal($link: LinkInput!) {
              createLink(input: { link: $link }) {
                link {
                  ...CosmicLinkSlim
                }
              }
            }
          `,
                variables: {
                    link: linkPayload
                }
            });
            this.$notification.add({
                type: AlertType.SUCCESS,
                text: 'Created.'
            });
        }
    }
};
__decorate([
    Emit('externalLinkCreated'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkCreate.prototype, "emitExternalLinkCreated", null);
ModalLinkCreate = __decorate([
    Component
], ModalLinkCreate);
export default ModalLinkCreate;
