import gql from 'graphql-tag';
export const CosmicCommentSlimFragment = gql `
  fragment CosmicCommentSlim on Comment {
    activityId
    asBuiltNumber
    changeSetAuthorityId
    changeSetId
    comment
    createdByUserId
    createdDateTime
    deliveryMatrixItemId
    documentId
    drawingNumber
    entityType
    eventId
    hardwareListId
    id
    itemInstanceId
    nodeId
    organizationCode
    physicalInventoryAuditId
    physicalInventoryAuditItemId
    side
    subType
    transactionId
    updatedByUserId
    updatedDateTime
  }
`;
