var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', _vm._g(_vm._b({
    attrs: {
      "color": _vm.computedColor,
      "disabled": _vm.computedDisabled,
      "icon": _vm.computedIcon,
      "show-loader": _vm.isRemoving,
      "text-color": _vm.computedTextColor,
      "loading-text": "Removing..."
    }
  }, 'ButtonGeneric', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), _vm.$listeners), [_vm.$slots.default ? _vm._t("default") : _c('span', [_vm._v("Remove")])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };