var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.info_strong,
      "icon-color": _vm.iconColor,
      "tooltip": _vm.computedTooltip,
      "tooltip-position": _vm.tooltipPosition
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };