import { __decorate, __metadata } from "tslib";
import { Component, Emit } from 'vue-property-decorator';
import AppSelect from '~/nasa_ui/components/inputs/AppSelect/AppSelect';
import { HardwareListNote, HardwareListNoteDisplay } from '~/nasa_ui/types/enums/hardware';
let HardwareListNoteDdl = class HardwareListNoteDdl extends AppSelect {
    get computedSelectOptions() {
        const options = [];
        for (const noteValue of Object.values(HardwareListNote)) {
            options.push({
                displayText: `${HardwareListNoteDisplay.get(noteValue)}`,
                value: noteValue
            });
        }
        return options;
    }
    get computedLabel() {
        return this.label ? this.label : 'Notes';
    }
    emitInput(val) {
        return val;
    }
};
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], HardwareListNoteDdl.prototype, "emitInput", null);
HardwareListNoteDdl = __decorate([
    Component
], HardwareListNoteDdl);
export default HardwareListNoteDdl;
