var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AppBreadcrumbs = class AppBreadcrumbs extends Vue {
    breadcrumbs;
    helpUrl;
    routerInstance;
    get hasBreadcrumbs() {
        return Boolean(this.breadcrumbs?.length);
    }
    get hasHelpUrl() {
        return Boolean(this.helpUrl);
    }
    get hasRouterInstance() {
        return Boolean(this.routerInstance);
    }
    onClickOfBreadcrumb(event, item) {
        event.preventDefault();
        if (this.routerInstance && item.to) {
            this.routerInstance.push(item.to);
        }
    }
};
__decorate([
    Prop({ type: Array }),
    __metadata("design:type", Object)
], AppBreadcrumbs.prototype, "breadcrumbs", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", Object)
], AppBreadcrumbs.prototype, "helpUrl", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], AppBreadcrumbs.prototype, "routerInstance", void 0);
AppBreadcrumbs = __decorate([
    Component
], AppBreadcrumbs);
export default AppBreadcrumbs;
