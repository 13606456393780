import { __decorate, __metadata } from "tslib";
import { add } from 'date-fns';
import { DebounceAll } from 'lodash-decorators';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import NewBaseHardwareDisplay from '~/nasa_ui/base/NewBaseHardwareDisplay';
import CosmicLimitedLife from '~/nasa_ui/modules/CosmicLimitedLife';
import { EntityType, LimitedLifeTypeShortDisplay } from '~/nasa_ui/types';
import { transformItemInstanceResponseWithRefs, transformItemMasterResponseWithRefs } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
let AppItemInstanceDisplay = class AppItemInstanceDisplay extends Mixins(NewBaseHardwareDisplay) {
    LimitedLifeTypeShortDisplay = LimitedLifeTypeShortDisplay;
    myLimitedLife = null;
    selectedEntity = null;
    id;
    get changelogHref() {
        const from = this.$dateDisplay(add(new Date(), { years: -1 }));
        const to = this.$dateDisplay(new Date());
        if (!this.transformedSelectedEntity) {
            return null;
        }
        return `/reporting/changelog/results?from=${from}&to=${to}&entityType=${EntityType.ITEM_INSTANCE}&id=${this.transformedSelectedEntity.id}&itemInstanceNodeId=${this.selectedEntityNodeId}&ahd=true`;
    }
    get hasAnyDateField() {
        return (this.transformedSelectedEntity &&
            (Boolean(this.transformedSelectedEntity.acquisitionDate) ||
                Boolean(this.transformedSelectedEntity.birthDate) ||
                Boolean(this.transformedSelectedEntity.closeDate) ||
                Boolean(this.transformedSelectedEntity.cureDate) ||
                Boolean(this.transformedSelectedEntity.openDate) ||
                Boolean(this.transformedSelectedEntity.operationalStartDate) ||
                Boolean(this.transformedSelectedEntity.pdaDate) ||
                Boolean(this.transformedSelectedEntity.piaDate) ||
                Boolean(this.transformedSelectedEntity.purchaseRequestDate)));
    }
    get selectedEntityNodeId() {
        return this.selectedEntity ? addNodeIdToResponseObj(this.selectedEntity).nodeId : null;
    }
    get transformedItemMaster() {
        return this.itemMaster ? transformItemMasterResponseWithRefs([this.itemMaster])[0] : null;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformItemInstanceResponseWithRefs([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen(`reload_${this.id}`, this.fetchTheShitYouNeed);
    }
    fetchTheShitYouNeed() {
        this.fetchEntity();
        this.fetchItemDrawing();
        this.fetchItemMaster();
        this.fetchLinks();
        // this.fetchHardware();
    }
    async fetchItemDrawing() {
        if (!this.selectedEntity?.drawingNumber) {
            return;
        }
        try {
            this.isFetchingItemDrawing = true;
            const respItemDrawing = await this.$http.get(`/hardware/item-drawings/${encodeURIComponent(this.selectedEntity.drawingNumber)}`);
            this.itemDrawing = respItemDrawing.data;
            this.isFetchingItemDrawing = false;
        }
        catch (err) {
            this.isFetchingItemDrawing = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchItemMaster() {
        if (!this.selectedEntity?.asBuiltNumber || !this.selectedEntity?.drawingNumber || !this.selectedEntity?.side) {
            return;
        }
        try {
            this.isFetchingItemMaster = true;
            const resp = await this.$http.get(`/hardware/item-masters/${encodeURIComponent(this.selectedEntity.drawingNumber)}/${encodeURIComponent(this.selectedEntity.asBuiltNumber)}/${this.selectedEntity.side}`);
            this.itemMaster = resp.data;
            this.isFetchingItemMaster = false;
        }
        catch (err) {
            this.isFetchingItemMaster = false;
            this.$errorUtility({
                err
            });
        }
    }
    onSelectedEntityChanged() {
        if (this.selectedEntity?.limitedLife) {
            this.myLimitedLife = new CosmicLimitedLife(this.selectedEntity.limitedLife, this.selectedEntity.limitedLifeReport);
        }
    }
    async fetchEntity() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const resp = await this.$http.get(`/hardware/item-instances/${this.id}`);
            this.selectedEntity = resp.data;
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
    async fetchLinks() {
        if (!this.id) {
            return;
        }
        try {
            this.isFetching = true;
            const params = {
                itemInstanceId: this.id,
                take: -1
            };
            const respLinks = await this.$http.get(`/links`, {
                params
            });
            this.links = respLinks.data.results || [];
            this.isFetching = false;
        }
        catch (err) {
            this.isFetching = false;
            this.$errorUtility({
                err
            });
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", String)
], AppItemInstanceDisplay.prototype, "id", void 0);
__decorate([
    DebounceAll(20),
    Watch('selectedEntity.drawingNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchItemDrawing", null);
__decorate([
    DebounceAll(20),
    Watch('selectedEntity.asBuiltNumber'),
    Watch('selectedEntity.drawingNumber'),
    Watch('selectedEntity.side'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchItemMaster", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AppItemInstanceDisplay.prototype, "onSelectedEntityChanged", null);
__decorate([
    DebounceAll(20),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchEntity", null);
__decorate([
    DebounceAll(20),
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], AppItemInstanceDisplay.prototype, "fetchLinks", null);
AppItemInstanceDisplay = __decorate([
    Component
], AppItemInstanceDisplay);
export default AppItemInstanceDisplay;
