var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disableSearch": _vm.shouldDisableSearch,
      "forceDisableSearch": _vm.shouldDisableSearch,
      "entity-type": _vm.EntityType.USER,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "chipsFromFilters": _vm.chipsFromFilters,
      "entity-type": _vm.EntityType.USER
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.USER,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "color": _vm.EntityType.USER,
      "display": _vm.UserRoleDisplay,
      "enum": _vm.UserRole,
      "sort": false,
      "label": "Role",
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.role,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "role", $$v);
      },
      expression: "searchFilters.role"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "label": "AUID",
      "clearable": "",
      "disabled": _vm.isFilterLocked('auid')
    },
    model: {
      value: _vm.searchFilters.auid,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "auid", $$v);
      },
      expression: "searchFilters.auid"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppListString', {
    attrs: {
      "label": "Email",
      "clearable": "",
      "disabled": _vm.isFilterLocked('email')
    },
    model: {
      value: _vm.searchFilters.email,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "email", $$v);
      },
      expression: "searchFilters.email"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.USER,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.USER,
      "headers": _vm.computedTableHeaders,
      "items": _vm.searchResults,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "dense-rows": "",
      "selectable-rows": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };