var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppSelect', _vm._b({
    attrs: {
      "allow-create": _vm.allowCreate,
      "chips": _vm.multiple,
      "color": _vm.EntityType.INVENTORY,
      "disabled": _vm.disabled,
      "loading": _vm.isLoading,
      "multiple": _vm.multiple,
      "select-options": _vm.projectCodeSelectOptions,
      "cache-items": "",
      "create-text": "project code",
      "monospace-selections": "",
      "label": "Project code",
      "lazy-load": ""
    },
    on: {
      "option-create": _vm.addNewProjectCode
    },
    model: {
      value: _vm.projectCodes,
      callback: function ($$v) {
        _vm.projectCodes = $$v;
      },
      expression: "projectCodes"
    }
  }, 'AppSelect', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };