import gql from 'graphql-tag';
import { CosmicItemDrawingSlimFragment } from '../itemDrawing/slim';
export const CosmicLinkSlimFragment = gql `
  fragment CosmicLinkSlim on Link {
    asBuiltNumber
    createdByUserId
    createdDateTime
    currentRevision
    currentVersionUrl
    documentId
    drawingNumber
    eventId
    id
    itemInstanceId
    organizationCode
    referenceNumber
    side
    subType
    updatedByUserId
    updatedDateTime
    workingVersionUrl
  }
`;
export const CosmicLinkFragment = gql `
  ${CosmicLinkSlimFragment}
  ${CosmicItemDrawingSlimFragment}

  fragment CosmicLink on Link {
    ...CosmicLinkSlim

    document {
      id
      nodeId
    }

    event {
      id
      nodeId
    }

    itemDrawing {
      ...CosmicItemDrawingSlim
    }

    itemInstance {
      id
      nodeId
    }

    itemMaster {
      asBuiltNumber
      drawingNumber
      nodeId
      side
    }

    organization {
      code
      nodeId
    }
  }
`;
