import { __decorate } from "tslib";
import axios from 'axios';
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { getActiveSupportContext } from '~/nasa_ui/mixins/CurrentUserMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { mutationFactory, queryFactory } from '../base/mixinFactories';
import { CosmicUserSlimFragment } from '../user';
import { CosmicAttachmentSlimFragment } from './slim';
export const getAS2DownloadUrl = (id, fileName) => `/api2/attachments/download/${id}/${fileName}`;
export const CosmicAttachmentFragment = gql `
  ${CosmicAttachmentSlimFragment}
  ${CosmicUserSlimFragment}

  fragment CosmicAttachment on Attachment {
    ...CosmicAttachmentSlim

    createdByUser {
      ...CosmicUserSlim
    }
  }
`;
export const CosmicAttachmentWithMetadataFragment = gql `
  ${CosmicAttachmentFragment}

  fragment CosmicAttachmentWithMetadata on Attachment {
    ...CosmicAttachment
    metadata
  }
`;
const GetDocumentAttachments = gql `
  ${CosmicAttachmentFragment}
  query GetDocumentAttachments($docId: UUID!) {
    documentById(id: $docId) {
      id
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
const GetItemDrawingAttachments = gql `
  ${CosmicAttachmentFragment}
  query GetItemDrawingAttachments($drawingNumber: String!) {
    itemDrawingByDrawingNumber(drawingNumber: $drawingNumber) {
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
const GetItemInstanceAttachments = gql `
  ${CosmicAttachmentFragment}
  query GetItemInstanceAttachments($id: UUID!) {
    itemInstanceById(id: $id) {
      id
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
const GetItemMasterAttachments = gql `
  ${CosmicAttachmentFragment}
  query GetItemMasterAttachments($drawingNumber: String!, $asBuilt: String!, $side: Side!) {
    itemMasterByDrawingNumberAndAsBuiltNumberAndSide(
      drawingNumber: $drawingNumber
      asBuiltNumber: $asBuilt
      side: $side
    ) {
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
export const GetWorkOrderStepAttachments = gql `
  ${CosmicAttachmentFragment}

  query GetWorkOrderStepAttachments($id: Int!) {
    workOrderStepById(id: $id) {
      id
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
export const GetDocumentAttachmentsToLinkToStep = gql `
  ${CosmicAttachmentFragment}

  query GetDocumentAttachmentsToLinkToStep($docId: UUID!, $stepAttachmentIds: [UUID!]) {
    documentById(id: $docId) {
      id
      nodeId
      attachments(orderBy: CREATED_DATE_TIME_DESC, filter: { id: { notIn: $stepAttachmentIds } }) {
        nodes {
          ...CosmicAttachment
        }
      }
    }
  }
`;
export const LinkAttachmentToDocumentMutation = gql `
  mutation LinkAttachmentToDocument($input: SetDocumentAttachmentsInput!) {
    setDocumentAttachments(input: $input) {
      clientMutationId
    }
  }
`;
export const LinkAttachmentToItemDrawingMutation = gql `
  mutation LinkAttachmentToItemDrawing($input: SetItemDrawingAttachmentsInput!) {
    setItemDrawingAttachments(input: $input) {
      clientMutationId
    }
  }
`;
export const LinkAttachmentToItemMasterMutation = gql `
  mutation LinkAttachmentToItemMaster($input: SetItemMasterAttachmentsInput!) {
    setItemMasterAttachments(input: $input) {
      clientMutationId
    }
  }
`;
export const LinkAttachmentToItemInstanceMutation = gql `
  mutation LinkAttachmentToItemInstance($input: SetItemInstanceAttachmentsInput!) {
    setItemInstanceAttachments(input: $input) {
      clientMutationId
    }
  }
`;
export const LinkAttachmentToWorkOrderStep = gql `
  mutation LinkAttachmentToWorkOrderStep($input: SetWorkOrderStepAttachmentsInput!) {
    setWorkOrderStepAttachments(input: $input) {
      clientMutationId
    }
  }
`;
const CreateAttachmentDetailsFragment = gql `
  ${CosmicAttachmentFragment}

  fragment CreateAttachmentDetails on Attachment {
    ...CosmicAttachment
  }
`;
const CreateAttachment = gql `
  ${CreateAttachmentDetailsFragment}

  mutation CreateAttachment($attachment: AttachmentInput!) {
    createAttachment(input: { attachment: $attachment }) {
      attachment {
        ...CreateAttachmentDetails
      }
    }
  }
`;
export const UpdateAttachment = gql `
  mutation UpdateAttachment($patch: AttachmentPatch!, $id: UUID!) {
    updateAttachmentById(input: { patch: $patch, id: $id }) {
      clientMutationId
    }
  }
`;
const uploadFile = (req) => {
    const request = new FormData();
    request.append('file', req.file, req.file.name);
    request.append('key', req.key);
    request.append('keyId', '0');
    return axios.post('/api/as2/upload', request, {
        headers: { 'x-organization': req.supportContext || '' }
    });
};
let AttachmentRepoMixin = class AttachmentRepoMixin extends Mixins(LoaderMixin) {
    getWorkOrderStepAttachments = queryFactory(this, GetWorkOrderStepAttachments);
    getDocumentAttachments = queryFactory(this, GetDocumentAttachments);
    getDocumentAttachmentsToLinkToStep = queryFactory(this, GetDocumentAttachmentsToLinkToStep);
    getItemDrawingAttachments = queryFactory(this, GetItemDrawingAttachments);
    getItemInstanceAttachments = queryFactory(this, GetItemInstanceAttachments);
    getItemMasterAttachments = queryFactory(this, GetItemMasterAttachments);
    updateAttachment = mutationFactory(this, UpdateAttachment);
    setStepAttachments = mutationFactory(this, LinkAttachmentToWorkOrderStep);
    createAttachment(data) {
        const currentUserActiveSupportContext = getActiveSupportContext();
        if (!data.file || !data.description || !data.type) {
            throw new Error('File, description, and subType are required to create an attachment.');
        }
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('description', data.description);
        formData.append('subType', data.type);
        // older axios doesn't have a headers type
        const headers = {
            'Content-Type': 'multipart/form-data',
            'x-organization': currentUserActiveSupportContext || ''
        };
        // using this instead of the HttpMixin because the mixin verson doesn't support multipart/form-data
        return this.$http.post('/attachments', formData, { headers });
    }
    async getAttachmentDownload(attachment) {
        const url = getAS2DownloadUrl(attachment.id, attachment.fileName || '');
        const currentUserActiveSupportContext = getActiveSupportContext();
        const headers = new Headers();
        headers.append('x-organization', currentUserActiveSupportContext || '');
        const resp = await fetch(url, { headers });
        const blob = await resp.blob();
        return new File([blob], '', { type: attachment.contentType || 'image/png' });
    }
    async uploadFile(req) {
        this.isSaving = true;
        try {
            const res = await uploadFile(req);
            return res;
        }
        catch (err) {
            this.$errorUtility({ err, backupErrorText: 'Failed to upload file.' });
            throw err;
        }
        finally {
            this.isSaving = false;
        }
    }
};
AttachmentRepoMixin = __decorate([
    Component
], AttachmentRepoMixin);
export { AttachmentRepoMixin };
