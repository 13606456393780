var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Watch } from 'vue-property-decorator';
import { CommentType, Maybe } from '~/db_types';
import { AlertType } from '~/nasa_ui/types';
import { CommentTypeDisplay } from '~/nasa_ui/types/enums/comment';
import ModalLinkBase from '../ModalLinkBase/ModalLinkBase';
let ModalLinkEdit = class ModalLinkEdit extends ModalLinkBase {
    CommentType = CommentType;
    CommentTypeDisplay = CommentTypeDisplay;
    isSaving = false;
    async onClickOfUpdate() {
        if (!this.isFormValid) {
            return;
        }
        try {
            this.isSaving = true;
            const body = {
                currentRevision: this.formData.currentRevision,
                currentVersionUrl: this.formData.currentVersionUrl,
                referenceNumber: this.formData.referenceNumber,
                subType: this.formData.subType,
                workingVersionUrl: this.formData.workingVersionUrl
            };
            const resp = await this.$http.put(`/links/${this.id}`, body);
            if (resp) {
                this.isSaving = false;
                this.$notification.add({
                    text: 'Updated.',
                    type: AlertType.SUCCESS
                });
                this.emitReloadExternalLinks();
                this.emitModalClose();
            }
        }
        catch (err) {
            this.$errorUtility({ err });
            this.isSaving = false;
        }
    }
    onIdSync(val) {
        if (val) {
            this.fetchEntity(this.id);
        }
    }
    onClickOfCloseModal() { }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], ModalLinkEdit.prototype, "onIdSync", null);
__decorate([
    Emit('modal-close'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalLinkEdit.prototype, "onClickOfCloseModal", null);
ModalLinkEdit = __decorate([
    Component
], ModalLinkEdit);
export default ModalLinkEdit;
