var _a, _b;
import { __decorate, __metadata } from "tslib";
import { max } from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ChangeSetType, Maybe, Side } from '~/db_types';
import { InventoryStatus } from '~/db_types/swagger_types';
import BaseModalAssembly from '~/nasa_ui/base/BaseModalAssembly';
import { transformAssemblyTemplateParts } from '~/nasa_ui/base/BaseModalAssemblyTemplate';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { OutputMode } from '~/nasa_ui/types/enums/general';
import { ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types/enums/item-instance';
import { parseNodeId } from '~/nasa_ui/utils/helpers/parseNodeId';
let ModalAssemblyCreate = class ModalAssemblyCreate extends BaseModalAssembly {
    selectedInventory = null;
    quantityFieldHasError = false;
    sequenceFieldHasError = false;
    parentsChildWorkingAssemblies;
    parentInventory;
    get hasError() {
        return this.quantityFieldHasError || this.sequenceFieldHasError;
    }
    get assemblyTemplatePartTableItems() {
        if (!this.assemblyTemplate) {
            return [];
        }
        return this.assemblyTemplate.parts.map(transformAssemblyTemplateParts);
    }
    get assemblyTemplateRowsPerPageItems() {
        return [
            {
                text: '50',
                value: 50
            },
            {
                text: '100',
                value: 100
            },
            {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                value: -1
            }
        ];
    }
    get buildHardwareMovementUrl() {
        if (!this.selectedInventory || !this.selectedInventory.id) {
            return '';
        }
        return `/hardware/movement?inventoryId=${this.selectedInventory.id}`;
    }
    get childClassDisplay() {
        return this.selectedInventory?.subType
            ? ItemInstanceInventoryTypeDisplay.get(this.selectedInventory?.subType)
            : DEFAULT_DASH;
    }
    get computedChildrenWorkingAssembliesIds() {
        if (!this.parentsChildWorkingAssemblies) {
            return [];
        }
        return this.parentsChildWorkingAssemblies.map((node) => node.id);
    }
    get createAssemblyMutationVariables() {
        if (!this.parentInventory?.id ||
            !this.formData.sequence ||
            !this.selectedInventory?.quantity ||
            !this.selectedInventory?.id) {
            return null;
        }
        const payload = {
            sequence: this.formData?.sequence.toString(),
            quantity: this.formData?.quantity || this.selectedInventory?.quantity,
            installedOn: this.formData?.installedOn || this.selectedInventory?.workingAssembly?.installedOn || Side.NONE
        };
        return {
            authorityCode: this.formData?.authorityCode,
            authorityNumber: this.formData?.authorityNumber,
            inventoryId: this.parentInventory?.id,
            childInventoryId: this.selectedInventory.id,
            openedDate: this.$dateDisplay(new Date()),
            payload,
            subType: ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL,
            ...(this.formData?.overrideInstalledDate && { openedDate: this.formData?.overrideInstalledDate })
        };
    }
    get drawingNumbersFilter() {
        if (!this.assemblyTemplate?.parts.length) {
            return null;
        }
        const drawingNumbers = this.assemblyTemplate.parts.map((part) => part.drawingNumber);
        return [...new Set(drawingNumbers)];
    }
    get excludeTheseInventories() {
        if (!this.parentInventory?.nodeId) {
            return;
        }
        let excludeTheseInventories = [];
        const povParsedId = parseNodeId(this.parentInventory.nodeId);
        if (!povParsedId || !povParsedId.id) {
            return [];
        }
        // Add Pov item instance id
        excludeTheseInventories.push(povParsedId.id);
        // Add Children item instance ids
        excludeTheseInventories = excludeTheseInventories.concat(this.computedChildrenWorkingAssembliesIds);
        return excludeTheseInventories;
    }
    get installSearchInventoryProps() {
        return {
            //existingValues: this.excludeTheseInventories,
            hideShowOtherContextFilter: true,
            showFiltersOnCreate: true,
            lockedFilters: ['isInstalled', 'hasQuantity', 'crossContext'],
            outputMode: OutputMode.object,
            filters: {
                drawingNumber: this.drawingNumbersFilter,
                hasQuantity: true,
                isInstalled: false,
                projectCode: this.parentInventory?.projectCode ? [this.parentInventory.projectCode] : []
            }
        };
    }
    get isDuplicateBySequenceByParent() {
        return Boolean(this.parentsChildWorkingAssemblies.filter((node) => node?.sequence === this.formData.sequence).length);
    }
    get isFormValid() {
        if (!this.formData.quantity || !this.formData.installedOn || this.hasError) {
            return false;
        }
        return Boolean(this.formData.sequence &&
            this.selectedInventory &&
            this.formData?.quantity &&
            this.selectedInventory?.quantity &&
            this.formData?.quantity <= this.selectedInventory.quantity &&
            !this.shouldShowLocationError);
    }
    get highestSequenceAmoungChildren() {
        return max(this.selectedEntity?.childWorkingAssemblies.nodes.map((node) => node?.sequence)) || 0;
    }
    get openParentChangeSets() {
        if (!this.parentInventory?.changeSets) {
            return [];
        }
        return this.parentInventory?.changeSets?.nodes.filter((node) => node?.subType === ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL ||
            node?.subType === ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE);
    }
    get openParentChangeSetIds() {
        return this.openParentChangeSets.map((cs) => {
            return cs?.id;
        });
    }
    get parentClassDisplay() {
        return this.parentInventory?.subType
            ? ItemInstanceInventoryTypeDisplay.get(this.parentInventory?.subType)
            : DEFAULT_DASH;
    }
    get parentProjectCode() {
        return this.parentInventory?.projectCode ?? null;
    }
    get shouldDisableCreateButton() {
        return !this.isFormValid || this.isSaving || (this.openParentChangeSets && this.openParentChangeSets.length > 0);
    }
    get shouldDisableQuantityField() {
        return Boolean(this.selectedInventory?.itemInstance?.serialNumber);
    }
    get shouldShowClassMismatchAlert() {
        return this.parentClassDisplay !== this.childClassDisplay && this.selectedInventory;
    }
    get shouldShowSearchInventory() {
        return Boolean(!this.selectedInventory);
    }
    get shouldShowLocationAlert() {
        if (!this.selectedInventory || !this.parentInventory) {
            return false;
        }
        return Boolean(this.parentInventory?.location?.id !== this.selectedInventory?.location?.id &&
            this.selectedInventory.status === InventoryStatus.ISSUED);
    }
    get shouldShowLocationError() {
        // if selectedInventory gets cleared, don't show
        if (!this.selectedInventory || !this.parentInventory) {
            return false;
        }
        return Boolean(this.parentInventory?.location?.id !== this.selectedInventory?.location?.id &&
            this.selectedInventory.status !== InventoryStatus.ISSUED);
    }
    get shouldShowProjectCodeAlert() {
        if (!this.selectedInventory?.projectCode || !this.parentInventory?.projectCode) {
            return false;
        }
        return this.selectedInventory.projectCode.toLowerCase() !== this.parentInventory.projectCode.toLowerCase();
    }
    get userNeedsApprovalToInstallAssembly() {
        // checks if there is an open change set template for assembly removals
        if (!this.parentInventory) {
            return;
        }
        return this.parentInventory?.assemblyInstallRequiresApproval;
    }
    async onClickOfCreateAssembly() {
        await this.createEntity(this.createAssemblyMutationVariables);
        this.isSaving = false;
    }
    onSelectOfInventoryFromSearch(val) {
        // set value to inventory mini
        // preset inv filters to things applicate to install
        this.selectedInventory = val;
        if (this.shouldDisableQuantityField) {
            this.formData.quantity = 1;
        }
        else {
            this.formData.quantity = 0;
        }
    }
    onErrorQuantity(hasError) {
        this.quantityFieldHasError = hasError;
    }
    onErrorSequence(hasError) {
        this.sequenceFieldHasError = hasError;
    }
    onChangeOfSequence(assemblyTemplate) {
        if (assemblyTemplate) {
            // if we get an assembly template, set the sequence field
            this.formData.sequence = assemblyTemplate.sequence;
            this.formData.installedOn = assemblyTemplate.installedOn;
        }
    }
    emitError(hasError) {
        return hasError;
    }
    onHighestSequenceAmoungChildren() {
        if (this.assemblyTemplate) {
            return;
        }
        this.formData.sequence = this.highestSequenceAmoungChildren + 10;
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => []
    }),
    __metadata("design:type", Array)
], ModalAssemblyCreate.prototype, "parentsChildWorkingAssemblies", void 0);
__decorate([
    Prop({
        type: Object,
        default: {}
    }),
    __metadata("design:type", typeof (_a = typeof ParentInventoryOnCreateAssembly !== "undefined" && ParentInventoryOnCreateAssembly) === "function" ? _a : Object)
], ModalAssemblyCreate.prototype, "parentInventory", void 0);
__decorate([
    Watch('assemblyTemplate', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalAssemblyCreate.prototype, "onChangeOfSequence", null);
__decorate([
    Watch('hasError'),
    Emit('error'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], ModalAssemblyCreate.prototype, "emitError", null);
__decorate([
    Watch('highestSequenceAmoungChildren', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalAssemblyCreate.prototype, "onHighestSequenceAmoungChildren", null);
ModalAssemblyCreate = __decorate([
    Component
], ModalAssemblyCreate);
export default ModalAssemblyCreate;
