import { DeliveryMatrixItemStatus, DeliveryMatrixItemType, NeedType } from '~/db_types/swagger_types';
export const DeliveryMatrixItemTypeDisplay = new Map([
    [DeliveryMatrixItemType.DEVELOPMENT, 'Development'],
    [DeliveryMatrixItemType.DOCUMENT, 'Document'],
    [DeliveryMatrixItemType.FLEET_LEADER, 'Fleet Leader'],
    [DeliveryMatrixItemType.NEW_BUILD, 'New Build'],
    [DeliveryMatrixItemType.PROCURE, 'Procure'],
    [DeliveryMatrixItemType.REFURBISH, 'Refurbish'],
    [DeliveryMatrixItemType.REPAIR, 'Repair'],
    [DeliveryMatrixItemType.SERVICE, 'Service']
]);
export var DeliveryMatrixItemRequirementsDocument;
(function (DeliveryMatrixItemRequirementsDocument) {
    DeliveryMatrixItemRequirementsDocument["J_21"] = "J_21";
    DeliveryMatrixItemRequirementsDocument["J_24"] = "J_24";
    DeliveryMatrixItemRequirementsDocument["J_26"] = "J_26";
    DeliveryMatrixItemRequirementsDocument["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(DeliveryMatrixItemRequirementsDocument || (DeliveryMatrixItemRequirementsDocument = {}));
export const DeliveryMatrixItemRequirementsDocumentDisplay = new Map([
    [DeliveryMatrixItemRequirementsDocument.J_21, 'J-21'],
    [DeliveryMatrixItemRequirementsDocument.J_24, 'J-24'],
    [DeliveryMatrixItemRequirementsDocument.J_26, 'J-26'],
    [DeliveryMatrixItemRequirementsDocument.NOT_APPLICABLE, 'Not Applicable']
]);
export var DeliveryMatrixOrgCodes;
(function (DeliveryMatrixOrgCodes) {
    DeliveryMatrixOrgCodes["COLLINS_WL"] = "COLLINS_WL";
    DeliveryMatrixOrgCodes["COLLINS_WL_BONDED"] = "COLLINS_WL_BONDED";
    DeliveryMatrixOrgCodes["ESOC_DATA_MANAGEMENT"] = "ESOC_DATA_MANAGEMENT";
    DeliveryMatrixOrgCodes["JSC"] = "JSC";
    DeliveryMatrixOrgCodes["KBR_HOU"] = "KBR_HOU";
    DeliveryMatrixOrgCodes["OSS"] = "OSS";
    DeliveryMatrixOrgCodes["SGT"] = "SGT";
})(DeliveryMatrixOrgCodes || (DeliveryMatrixOrgCodes = {}));
export const DeliveryMatrixItemStatusDisplay = new Map([
    [DeliveryMatrixItemStatus.COMPLETED, 'Completed'],
    [DeliveryMatrixItemStatus.IN_PROCESS, 'In Process'],
    [DeliveryMatrixItemStatus.VOID, 'Void']
]);
export const DeliveryMatrixNeedTypeDisplay = new Map([
    [NeedType.ETCA, 'ETCA'],
    [NeedType.GROUND, 'Ground'],
    [NeedType.LON2, 'LON-2'],
    [NeedType.LON3, 'LON-3'],
    [NeedType.MEGA14, 'MEGA #14'],
    [NeedType.MEGA15, 'MEGA #15'],
    [NeedType.MEGA16, 'MEGA #16'],
    [NeedType.MEGA17, 'MEGA #17'],
    [NeedType.NBL, 'NBL'],
    [NeedType.NOT_ASSIGNED, 'Not Assigned'],
    [NeedType.SPARE, 'Spare'],
    [NeedType.SUPER18, 'SUPER #18'],
    [NeedType.SUPER19, 'SUPER #19'],
    [NeedType.SUPER20, 'SUPER #20'],
    [NeedType.SUPER21, 'SUPER #21']
]);
