import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EntityType } from '~/nasa_ui/types';
import { HardwareListTypeDisplay } from '~/nasa_ui/types/enums/hardware';
import MiniHardwareListTemplateSlot from './MiniHardwareListTemplateSlot.vue';
let MiniHardwareListTemplate = class MiniHardwareListTemplate extends BaseMini {
    entityType = EntityType.HARDWARE_LIST_TEMPLATE; // we dont have a color for HLTs
    localEntity = null;
    showDetail = false;
    showModalHardwareListEditTemplate = false;
    showModalCreateHardwareListTemplate = false;
    localShowSlots = false;
    BASE_FETCH = gql `
    query HardwareListTemplateByIdForMini($id: UUID!) {
      hardwareListTemplateById(id: $id) {
        id
        name
        nodeId
        pbsItemId
        subType

        hardwareListTemplateSlots(orderBy: SEQUENCE_ASC) {
          nodes {
            id
            maxQuantity
            minQuantity
            name
            nodeId
            sequence
          }
        }
      }
    }
  `;
    allowChildCreation;
    allowEdit;
    showSlots;
    showSlotsInitially;
    transformedHardwareItems;
    get chevronIcon() {
        return this.shouldShowSlotContainer ? this.$icons.chevron_up : this.$icons.chevron_down;
    }
    get hasSlots() {
        return this.mySlots.length > 0;
    }
    get isRoot() {
        return true;
    }
    get manageLink() {
        return this.localEntity ? `/hardware/list_templates/manage/${this.localEntity.id}` : '';
    }
    get mySlots() {
        return this.localEntity?.hardwareListTemplateSlots?.nodes || [];
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.localEntity?.name || DEFAULT_DASH;
    }
    get pbsItemIdDisplay() {
        return this.localEntity?.pbsItemId || DEFAULT_DASH;
    }
    get shouldShowCreateChildIcon() {
        return this.allowChildCreation;
    }
    get shouldShowSlotContainer() {
        // this is stupid but the watchers that set localShowSlots aren't resolving
        // in a reliable order
        if (!this.showSlots) {
            return false;
        }
        return this.localShowSlots;
    }
    get shouldShowManageHLTButton() {
        const alreadyOnManagePage = window.location.href.includes(this.manageLink);
        return this.shouldShowManageLink && !alreadyOnManagePage;
    }
    get subTypeDisplay() {
        if (!this.localEntity?.subType) {
            return DEFAULT_DASH;
        }
        return HardwareListTypeDisplay.get(this.localEntity.subType) || DEFAULT_DASH;
    }
    onFetchUpdate(data) {
        if (data.hardwareListTemplate || data.hardwareListTemplateById) {
            this.localEntity = data.hardwareListTemplate || data.hardwareListTemplateById;
        }
    }
    created() {
        if (this.id) {
            this.executeQuery();
        }
        this.$listen(`reload_${this.id}`, () => {
            if (this.id) {
                this.executeQuery();
            }
        });
    }
    // Called from ui
    onClickOfCreateAssemblyTemplate() {
        if (!this.allowChildCreation) {
            return;
        }
        this.showModalCreateHardwareListTemplate = true;
    }
    // Called from ui
    onClickOfEdit() {
        if (!this.allowEdit) {
            return;
        }
        this.showModalHardwareListEditTemplate = true;
    }
    onClickOfShowSlots() {
        this.localShowSlots = !this.localShowSlots;
    }
    // Called from ui
    onClickOfShowDetail() {
        this.showDetail = !this.showDetail;
    }
    onClickOfAddItemToList() {
        this.showModalCreateHardwareListTemplate = true;
    }
    onClickOfCreateHardwareListFromTemplate() {
        console.log('¯_(ツ)_/¯');
    }
    // Called from ui as well as from $listen
    onRefreshSelf() {
        if (this.entity) {
            return;
        }
        if (this.id) {
            this.executeQuery();
        }
    }
    onShowSlotsChange() {
        this.localShowSlots = this.showSlots;
    }
    onshowSlotsInitiallyChange() {
        if (!this.showSlots) {
            this.localShowSlots = false;
        }
        this.localShowSlots = this.showSlotsInitially;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "allowChildCreation", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "allowEdit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "showSlots", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true
    }),
    __metadata("design:type", Boolean)
], MiniHardwareListTemplate.prototype, "showSlotsInitially", void 0);
__decorate([
    Prop({
        type: Array,
        required: false
    }),
    __metadata("design:type", Array)
], MiniHardwareListTemplate.prototype, "transformedHardwareItems", void 0);
__decorate([
    Watch('showSlots', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplate.prototype, "onShowSlotsChange", null);
__decorate([
    Watch('showSlotsInitially', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], MiniHardwareListTemplate.prototype, "onshowSlotsInitiallyChange", null);
MiniHardwareListTemplate = __decorate([
    Component({
        components: {
            MiniHardwareListTemplateSlot
        }
    })
], MiniHardwareListTemplate);
export default MiniHardwareListTemplate;
