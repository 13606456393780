var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ButtonGeneric', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.color,
      "disabled": _vm.disabled,
      "href": _vm.mailToHref
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.mail,
      "icon-color": "white"
    }
  }), _vm._t("default", function () {
    return [_vm._v(" Email " + _vm._s(_vm.organizationCode || 'ALL') + " Users ")];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };