import { InventoryUnitType } from '~/db_types/swagger_types';
export const InventoryUnitTypeDisplay = new Map([
    [InventoryUnitType.CENTIMETER, 'Centimeter'],
    [InventoryUnitType.CFU_PER_MILLILITER, 'CFU per milliliter'],
    [InventoryUnitType.CUBIC_FOOT, 'Cubic foot'],
    [InventoryUnitType.CUBIC_INCH, 'Cubic inch'],
    [InventoryUnitType.DAY, 'Day'],
    [InventoryUnitType.DEGREE, 'Degree'],
    [InventoryUnitType.DOZEN, 'Dozen'],
    [InventoryUnitType.FOOT, 'Foot'],
    [InventoryUnitType.GALLON, 'Gallon'],
    [InventoryUnitType.GRAM, 'Gram'],
    [InventoryUnitType.HOUR, 'Hour'],
    [InventoryUnitType.INCH, 'Inch'],
    [InventoryUnitType.KILOGRAM, 'Kilogram'],
    [InventoryUnitType.LITER, 'Liter'],
    [InventoryUnitType.METER, 'Meter'],
    [InventoryUnitType.MICRO_SEMENS_PER_CENTIMETER, 'Micro semens per centimeter'],
    [InventoryUnitType.MILLILITER, 'Milliliter'],
    [InventoryUnitType.MILLIMETER, 'Millimeter'],
    [InventoryUnitType.MINUTE, 'Minute'],
    [InventoryUnitType.MONTH, 'Month'],
    [InventoryUnitType.OUNCE, 'Ounce'],
    [InventoryUnitType.PARTS_PER_BILLION, 'Parts perbillion'],
    [InventoryUnitType.PH, 'Ph'],
    [InventoryUnitType.PINT, 'Pint'],
    [InventoryUnitType.POUND, 'Pound'],
    [InventoryUnitType.QUART, 'Quart'],
    [InventoryUnitType.RADIAN, 'Radian'],
    [InventoryUnitType.SECOND, 'Second'],
    [InventoryUnitType.SHOE_US, 'Shoe US'],
    [InventoryUnitType.SQUARE_FOOT, 'Square foot'],
    [InventoryUnitType.SQUARE_INCH, 'Square inch'],
    [InventoryUnitType.SQUARE_METER, 'Square meter'],
    [InventoryUnitType.SQUARE_YARD, 'Square yard'],
    [InventoryUnitType.YARD, 'Yard'],
    [InventoryUnitType.YEAR, 'Year']
]);
// converts InventoryUnitType's to their appropriate abbreviations
export const InventoryUnitTypeAbbreviationDisplay = new Map([
    [InventoryUnitType.CENTIMETER, 'cm'],
    [InventoryUnitType.CFU_PER_MILLILITER, 'CFU/mL'],
    [InventoryUnitType.CUBIC_FOOT, 'ft³'],
    [InventoryUnitType.CUBIC_INCH, 'cu³'],
    [InventoryUnitType.DAY, 'd'],
    [InventoryUnitType.DEGREE, 'deg'],
    [InventoryUnitType.DOZEN, 'doz'],
    [InventoryUnitType.FOOT, 'ft'],
    [InventoryUnitType.GALLON, 'gal'],
    [InventoryUnitType.GRAM, 'gm'],
    [InventoryUnitType.HOUR, 'hrs'],
    [InventoryUnitType.INCH, 'in'],
    [InventoryUnitType.KILOGRAM, 'kg'],
    [InventoryUnitType.LITER, 'ltr'],
    [InventoryUnitType.METER, 'm'],
    [InventoryUnitType.MICRO_SEMENS_PER_CENTIMETER, 'µS/cm'],
    [InventoryUnitType.MILLILITER, 'ml'],
    [InventoryUnitType.MILLIMETER, 'mm'],
    [InventoryUnitType.MINUTE, 'min'],
    [InventoryUnitType.MONTH, 'mo'],
    [InventoryUnitType.OUNCE, 'oz'],
    [InventoryUnitType.PARTS_PER_BILLION, 'ppb'],
    [InventoryUnitType.PH, 'ph'],
    [InventoryUnitType.PINT, 'pt'],
    [InventoryUnitType.POUND, 'lb'],
    [InventoryUnitType.QUART, 'qt'],
    [InventoryUnitType.RADIAN, 'rad'],
    [InventoryUnitType.SECOND, 'sec'],
    [InventoryUnitType.SHOE_US, 'shoe'],
    [InventoryUnitType.SQUARE_FOOT, 'ft²'],
    [InventoryUnitType.SQUARE_INCH, 'in²'],
    [InventoryUnitType.SQUARE_METER, 'm²'],
    [InventoryUnitType.SQUARE_YARD, 'yd²'],
    [InventoryUnitType.YARD, 'yd'],
    [InventoryUnitType.YEAR, 'yr']
]);
