import gql from 'graphql-tag';
import { CosmicIntervalFragment } from '../base/interval';
export const CosmicLimitedLifeIntervalRemainingFragment = gql `
  ${CosmicIntervalFragment}

  fragment CosmicLimitedLifeIntervalRemaining on LimitedLifeIntervalRemaining {
    alert
    baselineDate
    lastServiceDate
    overrideValueDate
    serviceDueDate
    valueDueDate

    service {
      ...CosmicInterval
    }
    total {
      ...CosmicInterval
    }
    totalSinceLastService {
      ...CosmicInterval
    }
    value {
      ...CosmicInterval
    }
  }
`;
export const CosmicLimitedLifeCycleRemainingFragment = gql `
  fragment CosmicLimitedLifeCycleRemaining on LimitedLifeCycleRemaining {
    alert
    lastServiceDate
    service
    total
    totalSinceLastService
    value
  }
`;
