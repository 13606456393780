var render = function render() {
  var _vm$favorites, _vm$favorites2, _vm$favorites3, _vm$favorites4, _vm$favorites5, _vm$favorites6, _vm$favorites7, _vm$favorites8, _vm$favorites9, _vm$favorites10;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.user && _vm.hasFavorites ? _c('div', [!_vm.hideTitle ? _c('AppHeader', {
    staticClass: "mb-2",
    attrs: {
      "uppercase": false
    }
  }, [_vm._v("Favorites")]) : _vm._e(), _c('section', {
    attrs: {
      "id": "mini-favorites"
    }
  }, [_vm._l((_vm$favorites = _vm.favorites) === null || _vm$favorites === void 0 ? void 0 : _vm$favorites[_vm.EntityType.ITEM_DRAWING], function (favorite) {
    return _c('MiniItemDrawing', {
      key: favorite.drawingNumber + '_drawingNumber',
      staticClass: "mb-1",
      attrs: {
        "inlineClear": false,
        "drawingNumberForMini": favorite.drawingNumber,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites2 = _vm.favorites) === null || _vm$favorites2 === void 0 ? void 0 : _vm$favorites2[_vm.EntityType.ITEM_MASTER], function (favorite, key) {
    return _c('MiniItemMaster', {
      key: key + '_item_master',
      staticClass: "mb-1",
      attrs: {
        "drawingNumberForMini": favorite.drawingNumber,
        "asBuiltNumberForMini": favorite.asBuiltNumber,
        "inlineClear": false,
        "sideForMini": favorite.side,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites3 = _vm.favorites) === null || _vm$favorites3 === void 0 ? void 0 : _vm$favorites3[_vm.EntityType.ITEM_INSTANCE], function (favorite, key) {
    return _c('MiniItemInstance', {
      key: key + '_item_instance',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites4 = _vm.favorites) === null || _vm$favorites4 === void 0 ? void 0 : _vm$favorites4[_vm.EntityType.INVENTORY], function (favorite, key) {
    return _c('MiniInventory', {
      key: key + '_inventory',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites5 = _vm.favorites) === null || _vm$favorites5 === void 0 ? void 0 : _vm$favorites5[_vm.EntityType.APPROVAL_ROUTING_TEMPLATE], function (favorite, key) {
    return _c('MiniApprovalRoutingTemplate', {
      key: key + '_approval_routing_template',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites6 = _vm.favorites) === null || _vm$favorites6 === void 0 ? void 0 : _vm$favorites6[_vm.EntityType.ASSEMBLY], function (favorite, key) {
    return _c('MiniAssembly', {
      key: key + '_assembly',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites7 = _vm.favorites) === null || _vm$favorites7 === void 0 ? void 0 : _vm$favorites7[_vm.EntityType.ASSEMBLY_TEMPLATE], function (favorite, key) {
    return _c('MiniAssemblyTemplate', {
      key: key + '_assembly_template',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false
      }
    });
  }), _vm._l((_vm$favorites8 = _vm.favorites) === null || _vm$favorites8 === void 0 ? void 0 : _vm$favorites8[_vm.EntityType.HARDWARE_LIST], function (favorite, key) {
    return _c('MiniHardwareList', {
      key: key + '_hardware_list',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false,
        "showSlotsInitially": false
      }
    });
  }), _vm._l((_vm$favorites9 = _vm.favorites) === null || _vm$favorites9 === void 0 ? void 0 : _vm$favorites9[_vm.EntityType.HARDWARE_LIST_TEMPLATE], function (favorite, key) {
    return _c('MiniHardwareListTemplate', {
      key: key + '_hardware_list_template',
      staticClass: "mb-1",
      attrs: {
        "id": favorite,
        "read-only": false,
        "showSlotsInitially": false
      }
    });
  }), _vm._l(_vm.favoritesFlattened, function (favorite, key) {
    return _c(_vm.miniByFavorite(favorite), {
      key: key,
      tag: "component",
      staticClass: "mb-1",
      attrs: {
        "id": favorite.id,
        "numberForMini": favorite.numberForMini,
        "code": favorite.code,
        "read-only": false,
        "showSlotsInitially": false
      }
    });
  }), _vm._l((_vm$favorites10 = _vm.favorites) === null || _vm$favorites10 === void 0 ? void 0 : _vm$favorites10[_vm.EntityType.USER_GROUP], function (groupCode, key) {
    return _c('MiniUserGroup', {
      key: key + '_group',
      staticClass: "mb-1",
      attrs: {
        "groupCodeForMini": groupCode,
        "read-only": false
      }
    });
  })], 2)], 1) : _vm.user && !_vm.hasFavorites ? _c('div', [_c('AppPageDescription', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" You have no favorites. ")])], 1) : _c('AppLoader', {
    attrs: {
      "type": "linear"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };