var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "cosmic_app_markdown"
  }, [_c('div', {
    staticClass: "cosmic_app_markdown_label",
    class: {
      required: _vm.required && !_vm.value
    }
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-toolbar', {
    class: {
      required: _vm.required && !_vm.value
    },
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('ButtonGeneric', {
    staticClass: "ml-0",
    class: {
      active_view_btn: _vm.shouldShowTextarea
    },
    attrs: {
      "flat": "",
      "text-color": "var(--cosmic-text)"
    },
    on: {
      "click": _vm.onClickOfWrite
    }
  }, [_vm._v(" Write ")]), _c('ButtonGeneric', {
    class: {
      active_view_btn: _vm.shouldShowMarkdownDisplay
    },
    attrs: {
      "flat": "",
      "text-color": "var(--cosmic-text)"
    },
    on: {
      "click": _vm.onClickOfPreview
    }
  }, [_vm._v(" Preview ")]), _c('v-spacer'), _c('div', {
    staticClass: "toolbar_icons"
  }, [_c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-bold",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Bolds the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfBold
    }
  }), _c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-italic",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Italicises the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfItalic
    }
  }), _c('ButtonMiniAction', {
    staticClass: "pr-1",
    attrs: {
      "disabled": !_vm.isAnythingSelected,
      "icon": "fal fa-strikethrough",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Strikes through the selected text",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfStrikethrough
    }
  }), _c('ButtonMiniAction', {
    attrs: {
      "disabled": _vm.shouldShowMarkdownDisplay,
      "icon": "fas fa-quote-right",
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip": "Insert a quote",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfQuote
    }
  })], 1)], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTextarea ? _c('AppTextarea', _vm._g(_vm._b({
    ref: "app_markdown_textarea",
    attrs: {
      "outline": false,
      "placeholder": _vm.$props.placeholder,
      "required": _vm.required,
      "value": _vm.value
    },
    on: {
      "input": _vm.emitInput
    }
  }, 'AppTextarea', {
    ..._vm.$attrs
  }, false), _vm.$listeners)) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowMarkdownDisplay ? _c('AppMarkdownDisplay', {
    staticClass: "markdown_display",
    attrs: {
      "markdown": _vm.value
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };