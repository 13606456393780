/****************************************************************
 * Frequently used together cols
 ****************************************************************/
import { ADDENDUM_DATE_COL, ADDENDUM_NUM_COL, AS_BUILT_COL, AS_DESIGN_COL, ATTRIBUTES_COL, AUTHORITY_NUMBER, AUTHORIZED_BY_COL, AUTHORIZED_DATE_COL, BASE_DATE_COL, BASE_DATE_TIME_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, BUILDING_BIN_STOCK_COL, BUILDING_COL, CLASS_COL, CLOSED_BY_USER_COL, CLOSED_DATE_COL, COMMENT_COL, CREATED_BY_COL, CREATED_DATE_COL, CREATED_DATE_TIME_COL, CREW_TIME_COL, CYCLES_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, DUE_DATE_COL, END_DATE_COL, FILE_NAME_COL, FILE_TYPE_COL, FROM_LOCATION_COL, HEIGHT_COL, INITIALIZATION_STATUS_ICON_COL, INITIATOR_CONTACT_COL, INVENTORY_QTY_COL, IS_INSTALLED_COL, ITEM_NUMBER_COL, LENGTH_COL, LOT_COL, NAME_COL, NAME_DISPLAY_COL, NOTES_COL, NUMBER_COL, NUMBER_NO_UNDERSCORE_COL, OPENED_BY_USER_COL, OPENED_DATE_COL, OPERATION_TIME_COL, PRESSURIZATION_RANGE_COL, PRESSURIZATION_TIME_COL, PREVENTATIVE_MAINTENANCE_ICON_COL, PROJECT_CODES_COL, PROJECT_CODE_COL, QUANTITY_COL, REVISION_COL, REVISION_NUMBER_COL, SEQUENCE_COL, SERIAL_COL, SIDE_COL, SIZE_COL, START_DATE_COL, STATIC_TIME_COL, STATUS_COL, STATUS_ICON_COL, SUB_TYPE_COL, TITLE_COL, TO_LOCATION_COL, UNIT_COST_COL, UPDATED_BY_COL, UPDATED_DATE_TIME_COL, WEIGHT_COL, WIDTH_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EntityType, HeaderSortOptions } from '~/nasa_ui/types';
import { composeTableHeader } from './composeTableHeader';
export const STANDARD_MEASUREMENT_COLUMNS = [HEIGHT_COL, WIDTH_COL, LENGTH_COL, WEIGHT_COL];
export const STANDARD_HARDWARE_COLUMNS = [
    DRAWING_NUMBER_COL,
    AS_BUILT_COL,
    SIDE_COL,
    SIZE_COL,
    SERIAL_COL,
    LOT_COL,
    DESCRIPTION_COL
];
export const ActionTableHeaders = [
    {
        text: 'Actions',
        value: 'col_actions',
        align: 'center',
        sortable: false
    }
];
export const ActivityTableHeaders = [
    SUB_TYPE_COL,
    AUTHORITY_NUMBER,
    DRAWING_NUMBER_COL,
    AS_BUILT_COL,
    SIDE_COL,
    SIZE_COL,
    SERIAL_COL,
    DESCRIPTION_COL,
    {
        text: 'Activity date',
        value: '_startDate',
        sortable: true,
        noEscape: true,
        noWrap: true,
        align: 'right',
        headerSortType: HeaderSortOptions.DATE
    },
    CYCLES_COL,
    OPERATION_TIME_COL,
    STATIC_TIME_COL,
    CREW_TIME_COL,
    PRESSURIZATION_TIME_COL,
    PRESSURIZATION_RANGE_COL,
    PREVENTATIVE_MAINTENANCE_ICON_COL,
    ATTRIBUTES_COL
];
export const AssembleTemplateTableHeaders = [
    {
        text: 'Type',
        value: '_entityTypeIcon',
        sortable: true,
        width: '150px'
    },
    NAME_COL,
    {
        text: 'PBS Item ID',
        value: '_pbsItemId',
        sortable: true
    }
];
export const AssemblyTemplatePartTableHeaders = [
    {
        text: 'Hardware<br/>level',
        value: '_entityTypeIcon',
        width: '50px',
        noEscape: true
    },
    DRAWING_NUMBER_COL,
    DESCRIPTION_COL,
    AS_BUILT_COL,
    SIDE_COL
];
export const AssemblyTemplateTreeTableHeaders = [...AssemblyTemplatePartTableHeaders, SERIAL_COL];
// these are generic columns that most if not all approvals tables need
export const BaseApprovalTableHeaders = [
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Group',
        value: '_userGroupName',
        sortable: true
    }),
    STATUS_ICON_COL,
    AUTHORIZED_BY_COL,
    composeTableHeader(AUTHORIZED_DATE_COL, { value: '_authorizedDate' }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Authority comment',
        value: '_computedCommentText',
        noEscape: true,
        sortable: true
    })
];
// colunns for document-level approvals (has to be after BaseApprovalTableHeaders)
export const ApprovalHistoryTableHeaders = [
    composeTableHeader(REVISION_NUMBER_COL, {
        align: 'right',
        monospacedFont: true,
        width: '75px'
    }),
    composeTableHeader(SUB_TYPE_COL, {
        text: 'Approval<br/>type',
        width: '150px'
    }),
    ...[...BaseApprovalTableHeaders]
];
export const CosmicManagedDocumentTableHeaders = [
    SUB_TYPE_COL,
    NUMBER_NO_UNDERSCORE_COL,
    CREATED_BY_COL,
    INITIATOR_CONTACT_COL,
    INITIALIZATION_STATUS_ICON_COL,
    {
        text: 'Status',
        value: '_computedStatusIcon',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    OPENED_DATE_COL,
    CLOSED_DATE_COL,
    DUE_DATE_COL,
    TITLE_COL
];
export const DocumentTableHeaders = [
    NUMBER_NO_UNDERSCORE_COL,
    SUB_TYPE_COL,
    CREATED_BY_COL,
    OPENED_DATE_COL,
    CLOSED_DATE_COL,
    TITLE_COL,
    {
        text: 'Line item progress',
        value: '_lineItemCounts',
        noEscape: true
    }
];
export const DocumentListingTableHeaders = [
    SUB_TYPE_COL,
    NUMBER_NO_UNDERSCORE_COL,
    TITLE_COL,
    {
        text: 'Revision',
        value: '_computedRevisionNumber',
        sortable: true,
        noWrap: true
    },
    {
        text: 'Release<br/>status',
        value: '_releaseStatus',
        sortable: true,
        noWrap: true,
        noEscape: true
    },
    OPENED_DATE_COL,
    CLOSED_DATE_COL,
    {
        align: 'right',
        headerSortType: HeaderSortOptions.DATE,
        noWrap: true,
        sortable: true,
        text: 'Last review',
        value: '_reviewDate'
    },
    {
        align: 'right',
        headerSortType: HeaderSortOptions.DATE,
        noWrap: true,
        sortable: true,
        text: 'Next review',
        value: '_computedReviewDate'
    },
    {
        text: 'Archive box',
        value: '_archiveBoxNumber',
        sortable: true,
        noWrap: true
    },
    {
        text: 'Line item<br/>progress',
        value: '_lineItemCounts',
        sortable: false,
        noEscape: true
    },
    {
        text: 'External links',
        value: 'externalLinks',
        sortable: true,
        noEscape: true,
        noWrap: true
    }
];
export const ChangeLogTableHeaders = [
    {
        text: 'Date',
        value: '_createdDateTime',
        sortable: true
    },
    {
        text: 'Attribute',
        value: '_key',
        sortable: true
    },
    {
        text: 'Previous value',
        value: '_previous',
        sortable: false
    },
    {
        text: 'Updated value',
        value: '_updated',
        sortable: false
    },
    {
        text: 'User',
        value: '_user',
        sortable: true
    }
];
export const ChangeSetTableHeaders = [
    SUB_TYPE_COL,
    STATUS_COL,
    composeTableHeader(BASE_DATE_TIME_COL, {
        text: 'Opened',
        value: '_openedDateTimeWithBreak'
    }),
    CREATED_BY_COL,
    composeTableHeader(BASE_DATE_TIME_COL, {
        text: 'Closed',
        value: '_closedDateTimeWithBreak'
    }),
    ...STANDARD_HARDWARE_COLUMNS
];
export const ChangeSetTemplatesTableHeaders = [
    SUB_TYPE_COL,
    {
        text: 'Approval order',
        value: 'id',
        sortable: true,
        width: '5%'
    },
    {
        text: 'Equipment<br/>group',
        value: '_itemMasterType',
        sortable: true,
        width: '5%'
    },
    {
        text: 'Class',
        value: '_itemInstanceType',
        sortable: true,
        width: '5%'
    },
    {
        text: 'Approval constraints',
        value: '_approvalConstraints',
        sortable: false,
        width: '5%',
        noEscape: true
    }
];
export const CommentTableHeaders = [
    Object.assign({}, UPDATED_BY_COL, { width: '5%' }),
    COMMENT_COL,
    {
        noWrap: true,
        sortable: true,
        text: 'Comment<br/>type',
        value: '_subType',
        width: '5%'
    },
    {
        noEscape: true,
        noWrap: true,
        text: 'System<br/>type',
        value: '_entityType',
        width: '15%'
    },
    Object.assign({}, UPDATED_DATE_TIME_COL, { width: '10%' })
];
export const ContractTableHeaders = [
    NUMBER_NO_UNDERSCORE_COL,
    NAME_DISPLAY_COL,
    START_DATE_COL,
    END_DATE_COL
];
export const ClosedLineItemTableHeaders = [
    ITEM_NUMBER_COL,
    AS_DESIGN_COL,
    SERIAL_COL,
    LOT_COL,
    DESCRIPTION_COL,
    OPENED_DATE_COL,
    CLOSED_DATE_COL,
    CLOSED_BY_USER_COL
];
export const DeliveryMatrixTableHeaders = [
    composeTableHeader(BASE_NUMBER_COL, {
        noWrap: true,
        text: 'DIL',
        value: '_deliverableItemsListNumber',
        width: '130px'
    }),
    DESCRIPTION_COL,
    STATUS_COL,
    composeTableHeader(SUB_TYPE_COL, {
        width: '130px'
    }),
    composeTableHeader(BASE_NUMBER_COL, {
        text: 'CEI',
        value: '_contractEndingItemNumber'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        noWrap: true,
        text: 'Need',
        value: '_needType',
        width: '100px'
    }),
    DUE_DATE_COL,
    composeTableHeader(BASE_DATE_COL, {
        text: 'Est. completion',
        value: '_estimatedCompletionDate',
        width: '130px'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        noWrap: true,
        text: 'Task owner',
        value: '_taskOwnerContact'
    })
];
export const DocumentWorkflowTableHeaders = [
    NUMBER_NO_UNDERSCORE_COL,
    CREATED_BY_COL,
    CREATED_DATE_COL,
    TITLE_COL,
    INITIALIZATION_STATUS_ICON_COL,
    INITIATOR_CONTACT_COL,
    Object.assign({}, START_DATE_COL, {
        value: '_computedStartDate'
    }),
    {
        text: 'Received',
        value: '_workflowReceivedDate',
        sortable: true,
        noEscape: true
    },
    CLOSED_DATE_COL
];
export const DocumentRcaTableHeaders = [
    NUMBER_NO_UNDERSCORE_COL,
    CREATED_BY_COL,
    CREATED_DATE_COL,
    TITLE_COL,
    INITIALIZATION_STATUS_ICON_COL,
    INITIATOR_CONTACT_COL,
    {
        text: 'RCA owner',
        value: '_rcaOwnerContact',
        sortable: true,
        noEscape: true
    },
    {
        text: 'Technial PoC',
        value: '_technicalPocContact',
        sortable: true,
        noEscape: true
    },
    CLOSED_DATE_COL
];
export const EmailNotificationTableHeaders = [
    composeTableHeader(CREATED_DATE_TIME_COL, {
        value: '_createdDateTime'
    }),
    CREATED_BY_COL,
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Email subject',
        value: 'subject'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Email recipients',
        value: '_recipients',
        noEscape: true
    }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Sent',
        value: '_sentDateTime',
        headerSortType: HeaderSortOptions.DATETIME
    }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Last failure',
        value: '_lastFailureDateTime',
        headerSortType: HeaderSortOptions.DATETIME
    }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Last attempt',
        value: '_lastAttemptDateTime',
        headerSortType: HeaderSortOptions.DATETIME
    })
];
export const EventTableHeaders = [SUB_TYPE_COL, NAME_COL, START_DATE_COL, END_DATE_COL];
export const FileAttachmentTableHeaders = [
    { ...SUB_TYPE_COL, width: '200px' },
    DESCRIPTION_COL,
    FILE_NAME_COL,
    composeTableHeader(FILE_TYPE_COL, { align: 'center' })
];
export const HardwareListTemplateTableHeaders = [
    NAME_DISPLAY_COL,
    SUB_TYPE_COL,
    {
        text: 'Template items',
        value: 'hardwareListTemplateSlotsCount',
        align: 'right',
        sortable: true,
        noEscape: true
    },
    {
        text: 'Hardware List<br/>created from',
        value: 'hardwareListCount',
        align: 'right',
        width: '100px',
        sortable: true
    }
];
export const HardwareListTableHeaders = [
    NUMBER_COL,
    NAME_DISPLAY_COL,
    SUB_TYPE_COL,
    {
        text: 'Built using<br/>Template?',
        value: '_builtUsingTemplate',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    composeTableHeader(BASE_NUMBER_COL, {
        text: 'Items',
        value: '_itemCount'
    }),
    REVISION_COL
];
export const HardwareListItemsTableHeaders = [
    SEQUENCE_COL,
    {
        text: 'Item type',
        value: '_entityIcon',
        noEscape: true,
        align: 'center'
    },
    ...STANDARD_HARDWARE_COLUMNS,
    {
        text: 'Event Assembly<br/>name',
        value: '_name',
        sortable: true,
        noEscape: true
    },
    QUANTITY_COL,
    ADDENDUM_NUM_COL,
    ADDENDUM_DATE_COL,
    NOTES_COL
    // is doing a map here bad?
].map((header) => {
    const output = Object.assign({}, header, { sortable: false });
    // the table will be automatically sorted numerically by sequence
    // if we allow sorting for sequence via clicking the header, vueitfy will do it
    // by string instead of number. so i'm just disallowing all sorting. -bw
    return output;
});
export const HardwareListAssemblyTableHeaders = [
    SEQUENCE_COL,
    QUANTITY_COL,
    ...STANDARD_HARDWARE_COLUMNS
];
export const InventoryTableHeaders = [
    ...STANDARD_HARDWARE_COLUMNS,
    CLASS_COL,
    PROJECT_CODE_COL,
    IS_INSTALLED_COL,
    INVENTORY_QTY_COL,
    UNIT_COST_COL,
    BUILDING_BIN_STOCK_COL
];
export const ItemDrawingTableHeaders = [
    DRAWING_NUMBER_COL,
    DESCRIPTION_COL,
    INITIALIZATION_STATUS_ICON_COL,
    {
        text: 'Aliases',
        value: '_aliases',
        sortable: true,
        noEscape: true
    },
    Object.assign({}, BASE_ICON_COL, {
        text: 'EEE<br/>identifier?',
        value: '_eeeIdentifier'
    })
];
export const ItemInstancesTableHeaders = [
    ...STANDARD_HARDWARE_COLUMNS,
    CLASS_COL,
    INVENTORY_QTY_COL,
    PROJECT_CODES_COL
];
export const ItemMastersTableHeaders = [
    DRAWING_NUMBER_COL,
    AS_BUILT_COL,
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Approved AD',
        noEscape: true,
        value: '_approvalStatusIcon'
    }),
    SIDE_COL,
    DESCRIPTION_COL,
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Federal Supply<br/>Class',
        value: '_federalSupplyClass',
        sortable: true
    }),
    composeTableHeader(BASE_TEXT_COL, {
        text: 'Equipment<br/>group',
        value: '_subType',
        sortable: true,
        noWrap: true
    }),
    INITIALIZATION_STATUS_ICON_COL
];
export const LocationTableHeaders = [
    {
        text: 'Managed<br/>Location?',
        value: '_isManagedLocationDisplay',
        sortable: true,
        noEscape: true,
        align: 'center'
    },
    {
        text: 'Organization<br/>name / code',
        value: '_organizationDisplay',
        sortable: true,
        noWrap: true
    },
    BUILDING_COL,
    composeTableHeader(BASE_TEXT_COL, {
        monospacedFont: true,
        sortable: true,
        text: 'Room',
        value: '_room'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        monospacedFont: true,
        sortable: true,
        text: 'Stock',
        value: '_stock'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        monospacedFont: true,
        sortable: true,
        text: 'Bin',
        value: '_bin'
    }),
    {
        text: 'Address',
        value: '_address',
        sortable: true,
        noEscape: true
    }
];
// columns related to manual inspection points
export const ManualInspectionPointTableHeaders = [
    {
        text: 'MIP/GMIP',
        value: '_authorityType',
        sortable: true,
        noEscape: true
    },
    {
        text: 'MIP type',
        value: '_manualInspectionPointType',
        sortable: true,
        noEscape: true
    },
    {
        text: 'MIP explanation',
        value: '_manualInspectionPointExplanation',
        sortable: true,
        noEscape: true
    }
];
export const MeasurementTableHeaders = [
    CREATED_DATE_COL,
    SUB_TYPE_COL,
    ...STANDARD_MEASUREMENT_COLUMNS
];
export const OnOrbitFailuresTableHeaders = [
    ...STANDARD_HARDWARE_COLUMNS,
    {
        text: 'Failure',
        value: '_failureDate',
        sortable: true,
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Failure mode',
        value: '_failureMode',
        sortable: true
    },
    CLOSED_DATE_COL,
    STATUS_COL
];
export const OpenLineItemTableHeaders = [
    ITEM_NUMBER_COL,
    AS_DESIGN_COL,
    SERIAL_COL,
    LOT_COL,
    DESCRIPTION_COL,
    OPENED_DATE_COL,
    {
        text: 'Projected<br/>closure',
        value: '_projectedDate',
        sortable: true,
        headerSortType: HeaderSortOptions.DATE
    },
    OPENED_BY_USER_COL,
    { text: 'Has movement?', value: 'hasMovement', sortable: true, noEscape: true, align: 'center' }
];
export const OrganizationTableHeaders = [
    NAME_DISPLAY_COL,
    {
        text: 'Code',
        value: 'code',
        sortable: true
    },
    SUB_TYPE_COL
];
export const PhysicalInventoryAuditsTableHeaders = [
    BUILDING_COL,
    SUB_TYPE_COL,
    CREATED_DATE_COL,
    CLOSED_DATE_COL,
    { text: 'Item count', value: '_itemCount', sortable: true, headerSortType: HeaderSortOptions.NUMBER, align: 'right' },
    {
        text: 'Sample size',
        value: '_sampleSize',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right'
    },
    STATUS_ICON_COL
];
export const PhysicalInventoryAuditItemsTableHeaders = [
    {
        text: 'Bin',
        value: '_bin',
        sortable: true,
        noWrap: true
    },
    ...STANDARD_HARDWARE_COLUMNS,
    CLASS_COL,
    UNIT_COST_COL,
    {
        text: 'Shelf life<br/>exp',
        // headerSortType: HeaderSortOptions.DATE, cant use this bc this could be an icon
        value: '_shelfLifeExpirationDate',
        noEscape: true,
        sortable: false,
        noWrap: true
    },
    {
        text: 'Bin<br/>qty',
        value: '_binQty',
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        sortable: true,
        noEscape: true
    },
    {
        text: 'Actual bin<br/>qty',
        value: '_actualQty',
        headerSortType: HeaderSortOptions.NUMBER,
        align: 'right',
        sortable: true,
        noEscape: true
    },
    {
        text: 'Adjustments',
        value: '_adjustmentData',
        noEscape: true,
        noWrap: true,
        sortable: false
    }
];
export const PhysicalInventoryAuditSummaryTableHeaders = [
    {
        text: 'Object class',
        value: '_objectClass',
        sortable: true
    },
    {
        text: 'Drawing count',
        value: '_drawingsCount',
        align: 'right',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'Detail count',
        align: 'right',
        value: '_inventoryCount',
        sortable: true,
        headerSortType: HeaderSortOptions.NUMBER
    },
    {
        text: 'Total cost',
        align: 'right',
        value: '_totalCost',
        sortable: true,
        headerSortType: HeaderSortOptions.CURRENCY
    }
];
// columns for step/task approvals tables
export const StepApprovalTableHeaders = [
    {
        text: 'Step',
        value: '_stepNumber',
        align: 'right'
    },
    {
        text: 'Group',
        value: '_userGroupName',
        sortable: true
    },
    STATUS_ICON_COL,
    AUTHORIZED_BY_COL,
    AUTHORIZED_DATE_COL,
    {
        text: 'Authority comment',
        value: '_computedCommentText',
        noEscape: true,
        sortable: true
    },
    ...[...ManualInspectionPointTableHeaders]
];
export const TransactionTableHeaders = [
    {
        align: 'right',
        text: 'Transaction',
        value: '_transactionDateTime',
        sortable: true,
        headerSortType: HeaderSortOptions.DATETIME
    },
    SUB_TYPE_COL,
    QUANTITY_COL,
    UNIT_COST_COL,
    FROM_LOCATION_COL,
    TO_LOCATION_COL,
    {
        text: 'Authority',
        value: '_authority',
        sortable: true,
        noEscape: true,
        noWrap: true
    },
    COMMENT_COL,
    CREATED_BY_COL
];
export const RiskTableHeaders = [
    NUMBER_NO_UNDERSCORE_COL,
    STATUS_COL,
    TITLE_COL,
    composeTableHeader(BASE_TEXT_COL, {
        noEscape: true,
        text: 'Category',
        value: '_category'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        noEscape: true,
        text: 'Organization',
        value: '_organization.code'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
        noEscape: true,
        noWrap: true,
        text: 'Cause',
        value: '_cause'
    }),
    composeTableHeader(BASE_TEXT_COL, {
        headerSortType: HeaderSortOptions.BLOCK_OF_TEXT,
        noEscape: true,
        noWrap: true,
        text: 'Effect',
        value: '_effect'
    }),
    composeTableHeader(BASE_DATE_COL, {
        noEscape: true,
        noWrap: true,
        text: 'Assigned',
        value: '_createdDateTime'
    }),
    composeTableHeader(BASE_DATE_COL, {
        noEscape: true,
        noWrap: true,
        text: 'Initial ECD',
        value: '_initialProjectedCloseDate'
    }),
    composeTableHeader(BASE_DATE_COL, {
        noEscape: true,
        noWrap: true,
        text: 'Latest ECD',
        value: '_latestEstimatedCompletionDate'
    })
];
export const UserSupportsTableHeaders = [
    {
        text: 'Organization code',
        value: 'organizationCode',
        sortable: true
    },
    {
        text: 'Contract',
        value: 'contractNumber',
        sortable: true
    }
];
export const UserWithoutAuidHeaders = [
    {
        text: 'Last name',
        value: 'lastName',
        sortable: true
    },
    {
        text: 'First name',
        value: 'firstName',
        sortable: true
    },
    {
        text: 'Email',
        value: 'email',
        sortable: true
    }
];
export const UserTableHeaders = [
    {
        text: 'Last name',
        value: '_lastName',
        sortable: true
    },
    {
        text: 'First name',
        value: '_firstName',
        sortable: true
    },
    {
        text: 'AUID',
        value: '_auid',
        sortable: true
    },
    {
        text: 'Email',
        value: '_email',
        sortable: true,
        noEscape: true
    }
];
export const UserGroupTableHeaders = [
    {
        text: 'Group name',
        value: 'name',
        sortable: true
    },
    {
        text: 'Group code',
        value: 'code',
        sortable: true
    }
];
export const UserHistoryTableHeaders = [
    NAME_COL,
    {
        text: 'Date',
        value: 'date',
        sortable: true,
        align: 'right',
        headerSortType: HeaderSortOptions.DATE
    },
    {
        text: 'Time since',
        value: 'timeSince',
        sortable: true
    }
];
export const VehicleTableHeaders = [
    NAME_COL,
    {
        text: 'Organization',
        value: 'organization.code',
        sortable: true
    }
];
export const getTableHeader = (entityType) => {
    switch (entityType) {
        case EntityType.ACTIVITY:
            return ActivityTableHeaders;
        case EntityType.COMMENT:
            return CommentTableHeaders;
        case EntityType.CONTRACT:
            return ContractTableHeaders;
        case EntityType.CHANGE_SET:
            return ChangeSetTableHeaders;
        case EntityType.DOCUMENT:
            return DocumentTableHeaders;
        case EntityType.EVENT:
            return EventTableHeaders;
        case EntityType.LOCATION:
            return LocationTableHeaders;
        case EntityType.ORGANIZATION:
            return OrganizationTableHeaders;
        case EntityType.PHYSICAL_INVENTORY_AUDIT:
            return PhysicalInventoryAuditsTableHeaders;
        case EntityType.ITEM_MASTER:
            return ItemMastersTableHeaders;
        case EntityType.ITEM_DRAWING:
            return ItemDrawingTableHeaders;
        case EntityType.ITEM_INSTANCE:
            return ItemInstancesTableHeaders;
        case EntityType.INVENTORY:
            return InventoryTableHeaders;
        case EntityType.USER:
            return UserTableHeaders;
        case EntityType.USER_GROUP:
            return UserGroupTableHeaders;
        case EntityType.VEHICLE:
            return VehicleTableHeaders;
        default:
            return [];
    }
};
