var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Maybe } from '~/nasa_ui/types';
let EntityLinksMixin = class EntityLinksMixin extends Vue {
    linksColor = 'grey darken-1';
    deliveryMatrixItemId;
    documentId;
    eventId;
    hideIfNone;
    itemDrawing;
    itemInstance;
    itemInstanceIds;
    itemMaster;
    organizationCode;
    riskId;
    workOrderStepId;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], EntityLinksMixin.prototype, "deliveryMatrixItemId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object)
], EntityLinksMixin.prototype, "documentId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_c = typeof Maybe !== "undefined" && Maybe) === "function" ? _c : Object)
], EntityLinksMixin.prototype, "eventId", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], EntityLinksMixin.prototype, "hideIfNone", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], EntityLinksMixin.prototype, "itemDrawing", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], EntityLinksMixin.prototype, "itemInstance", void 0);
__decorate([
    Prop({
        type: Array
    }),
    __metadata("design:type", Array)
], EntityLinksMixin.prototype, "itemInstanceIds", void 0);
__decorate([
    Prop({
        type: Object
    }),
    __metadata("design:type", Object)
], EntityLinksMixin.prototype, "itemMaster", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], EntityLinksMixin.prototype, "organizationCode", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_d = typeof Maybe !== "undefined" && Maybe) === "function" ? _d : Object)
], EntityLinksMixin.prototype, "riskId", void 0);
__decorate([
    Prop({
        type: Number
    }),
    __metadata("design:type", typeof (_e = typeof Maybe !== "undefined" && Maybe) === "function" ? _e : Object)
], EntityLinksMixin.prototype, "workOrderStepId", void 0);
EntityLinksMixin = __decorate([
    Component
], EntityLinksMixin);
export default EntityLinksMixin;
