var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { InventoryClass } from '~/db_types/swagger_types';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { sortObjectBy } from '../utils';
let HardwareListAssemblyFormMixin = class HardwareListAssemblyFormMixin extends Mixins(BaseVue) {
    parentHardwareList = null;
    formData = {
        addendumDate: null,
        addendumNumber: null,
        externalAsBuiltNumber: null,
        externalBirthDate: null,
        externalCalibrationDate: null,
        externalCalibrationNumber: null,
        externalContractEndingItemNumber: null,
        externalClass: InventoryClass.UNKNOWN,
        externalDescription: null,
        externalDrawingNumber: null,
        externalIsTool: false,
        externalLocation: null,
        externalLotNumber: null,
        externalSerialNumber: null,
        externalShelfLifeDate: null,
        externalUsageLifeDate: null,
        inventory: null,
        isExternal: false,
        notes: [],
        quantity: 0,
        sequence: 0
    };
    hideItemType;
    hideAllNonInventoryFields;
    parentHardwareListId;
    templateItems;
    get availableDrawingNumbers() {
        if (!this.matchingTemplateItem) {
            return null;
        }
        return this.matchingTemplateItem.parts.nodes.map((part) => part?.drawingNumber).filter(Boolean);
    }
    // Intended to be overriden by the consuming class
    get createHardwareListAssemblyMutationVariables() {
        return null;
    }
    get existingInventoryValues() {
        return this.hardwareListAssemblies.filter((item) => !item.isExternal).map((item) => item.inventory?.id);
    }
    get hardwareListAssemblies() {
        return this.parentHardwareList?.assemblies || [];
    }
    get inventorySearchFilters() {
        if (!this.availableDrawingNumbers) {
            return { hasQuantity: true };
        }
        return {
            drawingNumber: this.availableDrawingNumbers,
            hasQuantity: true
        };
    }
    get isEventAssembly() {
        return false;
    }
    get matchingTemplateItem() {
        if (!this.templateItems) {
            return null;
        }
        return this.templateItems.find((ti) => ti?.sequence === this.formData.sequence) ?? null;
    }
    get sequenceIsWithinSmallIntRange() {
        // 32,767 is the maximum whole number value a SMALLINT data type can store.
        return (this.formData?.sequence || 0) < 32767;
    }
    get sequenceNumberIsInteger() {
        return Number.isInteger(Number(this.formData.sequence));
    }
    get isSequenceNumberAlreadyInUse() {
        return this.parentHardwareList?.assemblies?.some((node) => node?.sequence === this.formData.sequence) ?? false;
    }
    get isSequenceValid() {
        const isValidNumber = Boolean(this.formData.sequence && this.sequenceIsWithinSmallIntRange);
        return isValidNumber && !this.isSequenceNumberAlreadyInUse;
    }
    get nextLogicalSequenceNumber() {
        const lastNode = this.parentHardwareList?.assemblies?.sort(sortObjectBy('sequence', true))[0];
        return (lastNode?.sequence ?? 0) + 10;
    }
    async onParentHardwareListIdChange() {
        if (!this.parentHardwareListId) {
            return;
        }
        try {
            const resp = await this.$http.get(`/hardwarelist/${this.parentHardwareListId}`);
            if (resp.data) {
                this.parentHardwareList = resp.data;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], HardwareListAssemblyFormMixin.prototype, "hideItemType", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], HardwareListAssemblyFormMixin.prototype, "hideAllNonInventoryFields", void 0);
__decorate([
    Prop({
        required: true,
        type: String
    }),
    __metadata("design:type", Object)
], HardwareListAssemblyFormMixin.prototype, "parentHardwareListId", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], HardwareListAssemblyFormMixin.prototype, "templateItems", void 0);
__decorate([
    Watch('parentHardwareListId', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], HardwareListAssemblyFormMixin.prototype, "onParentHardwareListIdChange", null);
HardwareListAssemblyFormMixin = __decorate([
    Component
], HardwareListAssemblyFormMixin);
export default HardwareListAssemblyFormMixin;
