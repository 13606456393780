import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { CosmicContractSlimFragment } from '~/nasa_ui/DAL/contract/slim';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import LoaderMixin from '~/nasa_ui/mixins/LoaderMixin';
import { queryFactory } from '../base/mixinFactories';
import { CosmicContactSlimFragment } from '../contact/slim';
import { CosmicOrganizationSlimFragment } from '../organization/slim';
import { CosmicLocationSlimFragment } from './slim';
export const CosmicLocationFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicContactSlimFragment}

  fragment CosmicLocation on Location {
    ...CosmicLocationSlim

    contact {
      ...CosmicContactSlim
    }
  }
`;
export const CosmicLocationWithOrgFragment = gql `
  ${CosmicLocationFragment}
  ${CosmicOrganizationSlimFragment}

  fragment CosmicLocationWithOrg on Location {
    ...CosmicLocation

    organization {
      ...CosmicOrganizationSlim
    }
  }
`;
export const LocationInformationFragment = gql `
  ${CosmicContractSlimFragment}
  ${CosmicLocationSlimFragment}
  ${CosmicOrganizationSlimFragment}

  fragment LocationInformation on Location {
    ...CosmicLocationSlim

    contract {
      ...CosmicContractSlim
    }

    inventories(condition: { isBeingAudited: true }) {
      totalCount
    }

    organization {
      ...CosmicOrganizationSlim
    }

    physicalInventoryAuditItems {
      nodes {
        physicalInventoryAudit {
          nodeId
          status
        }
      }
    }
  }
`;
export const LocationByIdInformationFragment = gql `
  ${CosmicLocationSlimFragment}
  ${CosmicContractSlimFragment}

  fragment LocationByIdInformation on Location {
    ...CosmicLocationSlim

    contract {
      ...CosmicContractSlim
    }

    inventories(condition: { isBeingAudited: true }) {
      totalCount
    }

    organization {
      nodeId
    }

    physicalInventoryAuditItems {
      nodes {
        physicalInventoryAudit {
          nodeId
          status
        }
      }
    }
  }
`;
export const GetLocationById = gql `
  ${LocationInformationFragment}

  query GetLocationById($id: UUID!) {
    locationById(id: $id) {
      ...LocationInformation
    }
  }
`;
export const GetSlimLocationById = gql `
  ${CosmicLocationSlimFragment}

  query GetSlimLocationById($id: UUID!) {
    locationById(id: $id) {
      ...CosmicLocationSlim
    }
  }
`;
export const LocationInventoryFragment = gql `
  ${CosmicLocationSlimFragment}

  fragment LocationInventory on Inventory {
    asBuiltNumber
    drawingNumber
    id
    isInstalled
    itemDrawingDescription
    lotNumber
    nodeId
    projectCode
    quantity
    serialNumber
    side
    size
    subType
    unitCost

    itemInstance {
      nodeId
    }

    location {
      ...CosmicLocationSlim
    }
  }
`;
export const LocationByIdInventoryFragment = gql `
  ${CosmicLocationSlimFragment}

  fragment LocationByIdInventory on Inventory {
    asBuiltNumber
    drawingNumber
    isInstalled
    itemDrawingDescription
    lotNumber
    nodeId
    projectCode
    quantity
    serialNumber
    side
    size
    subType
    unitCost

    itemInstance {
      nodeId
    }

    location {
      ...CosmicLocationSlim
    }
  }
`;
export const OrganizationWithGeneralLocationFragment = gql `
  ${LocationInformationFragment}
  ${CosmicOrganizationSlimFragment}

  fragment OrganizationWithGeneralLocation on Organization {
    ...CosmicOrganizationSlim

    locations(condition: { bin: "GENERAL", building: "GENERAL", stock: "GENERAL" }) {
      nodes {
        ...LocationInformation
      }
    }
  }
`;
export const GetGeneralLocationForOrgByCode = gql `
  ${OrganizationWithGeneralLocationFragment}

  query GetGeneralLocationForOrgByCode($code: String!) {
    organizationByCode(code: $code) {
      ...OrganizationWithGeneralLocation
    }
  }
`;
let LocationRepoMixin = class LocationRepoMixin extends Mixins(HttpMixin, LoaderMixin) {
    getGeneralLocationForOrgByCode = queryFactory(this, GetGeneralLocationForOrgByCode);
    getLocationById = queryFactory(this, GetLocationById);
    getSlimLocationById = queryFactory(this, GetLocationById);
};
LocationRepoMixin = __decorate([
    Component
], LocationRepoMixin);
export { LocationRepoMixin };
