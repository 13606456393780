var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-select', _vm._b({
    attrs: {
      "cache-options": _vm.cacheOptions,
      "clearable": ""
    },
    domProps: {
      "cValue": _vm.value,
      "chipColor": _vm.color,
      "chipTextColor": _vm.chipTextColor,
      "label": _vm.label,
      "multiple": _vm.multiple,
      "selectOptions": _vm.options
    },
    on: {
      "c-input": function ($event) {
        return _vm.onChange($event.detail);
      },
      "c-search": function ($event) {
        _vm.searchTerm = $event.detail;
      }
    }
  }, 'c-select', {
    ..._vm.$attrs
  }, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };