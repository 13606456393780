var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppAutocomplete', _vm._b({
    attrs: {
      "get-by-value-fn": _vm.transformStringsToSelectOptions,
      "multiple": _vm.multiple,
      "search-fn": _vm.search,
      "search-term-min-length": 1,
      "value": _vm.value,
      "chip-text-color": "white",
      "color": "var(--v-locations-base)",
      "label": "Rooms",
      "placeholder": "Search Rooms"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, 'AppAutocomplete', {
    ..._vm.$attrs
  }, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };