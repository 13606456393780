import { __decorate, __metadata } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import { UserSupportContextToValidDocumentTypes } from '~/application.config';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { CLOSED_DATE_COL, CREATED_BY_COL, NUMBER_NO_UNDERSCORE_COL, OPENED_DATE_COL, SUB_TYPE_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { GetDocuments } from '~/nasa_ui/DAL/document/Search';
import { EntityType } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { isBeforeInclusive } from '~/nasa_ui/utils/dates/isBeforeInclusive';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { CosmicManagedDocumentTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
let SearchDocument = class SearchDocument extends BaseSearch {
    CosmicManagedDocumentTableHeaders = [...CosmicManagedDocumentTableHeaders];
    createdByUserId = null;
    DocumentTableHeaders = [
        composeTableHeader(NUMBER_NO_UNDERSCORE_COL, {
            width: '200px'
        }),
        composeTableHeader(SUB_TYPE_COL, {
            width: '150px'
        }),
        CREATED_BY_COL,
        OPENED_DATE_COL,
        CLOSED_DATE_COL,
        TITLE_COL,
        {
            text: 'Line item progress',
            value: '_lineItemCounts',
            noEscape: true
        }
    ];
    entityType = EntityType.DOCUMENT;
    hint = 'number, title';
    NOT_APPLICABLE = NOT_APPLICABLE;
    SEARCH_QUERY = GetDocuments;
    userSelectedMaxResults = 100;
    searchFilters = {
        createdByUserId: [],
        initiatorContactId: [],
        isCosmicManaged: null,
        number: [],
        query: '',
        subType: null,
        tags: []
    };
    onlyCosmicManaged;
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.buildAppChipForProperty('subType', 'documents', DocumentTypeDisplay));
        return appChips.filter((key) => key !== undefined);
    }
    get cosmicManagedDocumentTypes() {
        if (!this.onlyCosmicManaged) {
            return [];
        }
        if (!this.currentUserActiveSupportContext) {
            return [];
        }
        if (!this.validTypesForCurrentlyActiveCtx) {
            console.log(`No valid Document types found for ctx: ${this.currentUserActiveSupportContext}`);
            return [];
        }
        return this.validTypesForCurrentlyActiveCtx
            .filter((documentType) => documentType.startsWith('C_'))
            .map((type) => {
            return {
                displayText: DocumentTypeDisplay.get(type) || '',
                value: type
            };
        });
    }
    get placeholderText() {
        return `search for ${this.onlyCosmicManaged ? '' : 'tracking '}documents`;
    }
    get resultTableTitle() {
        const documentType = `${this.onlyCosmicManaged ? '' : 'Tracking '} Documents`;
        return !this.searchResults
            ? this.entityTypeDisplay
            : `Found <span class="monospace_font">${this.searchResults.length}</span> ${documentType}`;
    }
    get validTypesForCurrentlyActiveCtxToSelectOptions() {
        return this.validTypesForCurrentlyActiveCtx.map((type) => {
            return {
                displayText: DocumentTypeDisplay.get(type) || '',
                value: type
            };
        });
    }
    get validTypesForCurrentlyActiveCtx() {
        return UserSupportContextToValidDocumentTypes.get(this.currentUserActiveSupportContext) || [];
    }
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults
        };
        const query = {};
        if (this.searchInput.trim().length > 0) {
            query.query = this.searchInput;
        }
        // If you want only cDocs we need to auto query for only those docs that are cosmic managed
        if (isNullOrUndefined(this.searchFilters.subType) || this.searchFilters.subType?.length === 0) {
            this.searchFilters.subType = UserSupportContextToValidDocumentTypes.get(this.currentUserActiveSupportContext);
        }
        const variables = Object.assign({}, base, this.searchFilters, query, { createdByUserId: this.createdByUserId });
        return variables;
    }
    isBeforeTodayInclusive = (date) => {
        return isBeforeInclusive(new Date(date), new Date());
    };
    onCreatedByContactChanged(val) {
        this.createdByUserId = val ? val.map((contact) => contact?.user?.id) : [];
    }
    onSearchInputChanged() {
        const appChips = [];
        const currentActiveDocTypesBasedOnCtx = UserSupportContextToValidDocumentTypes.get(this.currentUserActiveSupportContext);
        // if you typed something AND didnt select a subType try to infer the subType from the 'query'
        if (this.searchInput &&
            (isNullOrUndefined(this.searchFilters.subType) || this.searchFilters.subType?.length === 0)) {
            const matches = currentActiveDocTypesBasedOnCtx.filter((documentType) => {
                return this.searchInput.includes(DocumentTypeDisplay.get(documentType));
            });
            if (matches.length) {
                this.searchFilters.subType = matches;
                return appChips.filter((key) => key !== undefined);
            }
            else {
                this.searchFilters.subType = [];
            }
        }
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
        required: false
    }),
    __metadata("design:type", Boolean)
], SearchDocument.prototype, "onlyCosmicManaged", void 0);
__decorate([
    Watch('searchInput'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchDocument.prototype, "onSearchInputChanged", null);
SearchDocument = __decorate([
    Component
], SearchDocument);
export default SearchDocument;
