var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.tooltipText || _vm.tooltipVhtml !== '' ? _c('v-tooltip', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('div', {
          staticClass: "ahd--grid-item"
        }, [_vm.label ? _c('p', {
          staticClass: "text-xs-right mb-0"
        }, [_c('span', _vm._g({
          staticClass: "ahd--label"
        }, on), [_vm._v(_vm._s(_vm.label))])]) : _vm._e(), _vm._t("text-content-slot"), _vm.link ? _c('a', _vm._g({
          class: {
            monospace_font: _vm.monospaceFont
          },
          attrs: {
            "href": _vm.link,
            "target": "_blank"
          }
        }, on), [_vm._v(_vm._s(_vm.text))]) : _vm._e(), _vm.vhtml ? _c('span', _vm._g({
          directives: [{
            name: "safe-html",
            rawName: "v-safe-html",
            value: _vm.vhtml,
            expression: "vhtml"
          }],
          class: {
            monospace_font: _vm.monospaceFont
          }
        }, on)) : _c('span', _vm._g({
          class: {
            monospace_font: _vm.monospaceFont
          }
        }, on), [_vm._v(_vm._s(_vm.text))])], 2)];
      }
    }], null, true)
  }, "v-tooltip", _vm._d({}, [_vm.direction, true])), [_c('span', [_vm.tooltipLabel || _vm.tooltipText ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.tooltipLabel ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.tooltipLabel) + " ")]) : _vm._e(), _vm.tooltipText ? _c('v-flex', {
    attrs: {
      "xs11": "",
      "offset-xs1": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedTooltipText) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.tooltipVhtml ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.tooltipVhtml,
      expression: "tooltipVhtml"
    }]
  })])], 1) : _vm._e()], 1)]) : _c('div', {
    staticClass: "ahd--grid-item"
  }, [_vm.label ? _c('p', {
    staticClass: "ahd--label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.vhtml ? _c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.vhtml,
      expression: "vhtml"
    }],
    class: {
      monospace_font: _vm.monospaceFont
    }
  }) : _c('span', {
    class: {
      monospace_font: _vm.monospaceFont
    }
  }, [_vm._v(_vm._s(_vm.text))])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };